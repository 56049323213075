import dayjs from 'dayjs'
import { useRoute } from '@oahz/neact'
import { BEMGenerator } from '@oahz/neact-utils'
import { Button, Form, Input, Divider, Link, Modal } from '@bedrock/components'
import { AsyncButton } from '@feature/views'
import { apiService, defs } from '@/services'
import { rsaEncrypt } from '@/shared'
import './style.less'

const bem = BEMGenerator('plogin')

export default () => {
  const [fm] = Form.useForm()
  const { query } = useRoute()

  const onSubmit = async () => {
    await fm.validateFields()
    const formVal = await fm.getFieldsValue()
    const params: defs.IdLoginCommand = {
      ...formVal,
      password: rsaEncrypt(formVal.password),
    }
    await apiService.identityAuthenticationLogin(params)
    window.location.replace(query.originUri || '/')
  }

  const onContact = () => {
    Modal.info({
      title: '邮箱：xiongfeiyang@corp.netease.com',
    })
  }

  return (
    <div className={bem()}>
      <div className={bem('welcome')}>
        <div className={bem('welcome-text')}>欢迎登录</div>
        <div className={bem('welcome-supplier')}>网易供应商协同平台</div>
      </div>
      <div className={bem('c')}>
        <div className={bem('c-h')}>{/* <LoginInLocal /> */}</div>
        <div className={bem('c-c')}>
          <div className={bem('c-c-title')}>账号登录</div>
          <Form form={fm} layout="vertical" autoComplete="off" className={bem('c-c-form')}>
            <Form.Item name="account" label="账号" rules={[{ required: true, message: '请输入邮箱' }]}>
              <Input size="large" placeholder="请输入邮箱" />
            </Form.Item>
            <Form.Item name="password" label="密码" rules={[{ required: true, message: '请输入密码' }]}>
              <Input.Password size="large" />
            </Form.Item>
          </Form>
          <AsyncButton type="primary" size="large" className={bem('c-c-submit')} onClick={onSubmit}>
            登录
          </AsyncButton>
          <Link className={bem('c-c-password')} type="primary" href={'/resetpwd'}>
            忘记密码？
          </Link>
        </div>
        <div className={bem('c-f')}>
          <div className={bem('c-f-link')}>
            <div className={bem('c-f-link-item')} onClick={onContact}>
              联系我们
            </div>
            <Divider />
            <Link className={bem('c-f-link-item')} href={'https://jubao.163.com'} target="_blank">
              廉政举报
            </Link>
          </div>
          <div className={bem('c-f-copyright')}>{`Copyright@${dayjs().year()} 网易杭州有限公司｜浙ICP备123999X号`}</div>
        </div>
      </div>
    </div>
  )
}

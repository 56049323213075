import { ReactNode, useState } from 'react'
import {
  BEMGenerator,
  useBoolean,
  useMultiState,
  useRCComputed,
  useRCMount,
  useRCUpdate,
  useRCVars,
  useRCWatch,
} from '@oahz/neact-utils'
import {
  Button,
  Cascader,
  Form,
  Input,
  InputNumber,
  Message,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Tag,
} from '@bedrock/components'
import { Check, Close, Plus } from '@bedrock/icons-react'
import { useRouter, useService } from '@oahz/neact'
import type { CascaderProps } from '@bedrock/components/lib/Cascader'
import type { SelectProps } from '@bedrock/components/lib/Select'
import type { FormInstance, FormProps } from '@bedrock/components/lib/Form'
import type { InputProps } from '@bedrock/components/lib/Input'
import type { TextAreaProps } from '@bedrock/components/lib/Input/TextArea'
import I18N from '@feature/i18n'
import { createTableColumns } from '@feature/views'
import { formatDateTime, formatStartEndDate } from '@feature/shared'
import {
  AsyncButton,
  CurrencySelect,
  DataBlock,
  DataForm,
  DataFormItem,
  DataPanel,
  FileUpload,
  FileUploadProps,
  YearPicker,
  DataDivider,
  StringInputDatePicker,
  useFormLeavePagePrompt,
} from '@/components/data-view'
import { apiService, bizEnums, bizService, defs } from '@/services'
import { BusinessLineLabel, StageLabel } from '../supplier-view'
import {
  BankAccount,
  SupplierAttachments,
  SupplierTable,
  isChinaCountryCode,
  isChinaEnterprise,
  isChinaPerson,
  isOverseaEnterprise,
  isOverseaPerson,
  supplierFilePreviewAndDownloadServices,
} from '../supplier-show'
import type { SpContentData } from '../supplier-show'
import './style.less'

const bem = BEMGenerator('sp-form')

export const convertFormFieldsValueToSubmitCommand = (data: any) => {
  const { categoryIds } = data.serviceCategories
  if (data.credentials?.some((v: any) => !categoryIds.includes(v.categoryId))) {
    const errMsg = I18N.auto.ziZhiPinLeiYu
    Message.error({ title: errMsg, buttonText: I18N.auto.woZhiDaoLe })
    throw new Error(errMsg)
  }

  let { startAt_endAt, provinceId_provinceCode__city, ...realCheckIn } = data.checkIn

  if (startAt_endAt) {
    realCheckIn = { ...realCheckIn, startAt: startAt_endAt[0], endAt: startAt_endAt[1] }
  }

  if (provinceId_provinceCode__city) {
    const [provinceId, provinceCode, city] = provinceId_provinceCode__city.split('_').filter(Boolean)
    realCheckIn = { ...realCheckIn, provinceId, provinceCode, city }
  }

  data.checkIn = realCheckIn

  return data
}

export const convertDetailDtoToFormFieldsValue = (data: SpContentData) => {
  const { checkIn, serviceCategories, ...restData } = data
  const fmCheckIn = {} as any

  if (isChinaEnterprise(data) || isOverseaEnterprise(data)) {
    const { startAt, endAt, provinceId, provinceCode, city } = data.checkIn || {}
    if (startAt && endAt) {
      fmCheckIn.startAt_endAt = [startAt, endAt]
    }
    if (provinceId && provinceCode && city) {
      fmCheckIn.provinceId_provinceCode__city = `${provinceId}_${provinceCode}__${city}`
    }
  }

  return {
    checkIn: { ...checkIn, ...fmCheckIn },
    serviceCategories: { categoryIds: serviceCategories?.map(v => v.categoryId) },
    ...restData,
  }
}

export type SupplierFormItemProps = { defaultValue: SpContentData; createMode?: boolean }

export const SupplierForm = (props: FormProps) => {
  const { className, ...rest } = props

  return <DataForm className={bem('fm', [className])} {...rest} />
}

export const useSupplierForm = () => {
  const [fm] = Form.useForm()
  const { replace } = useRouter()
  const { setCanLeave } = useFormLeavePagePrompt()
  const [canSubmit, setCanSubmit] = useState(false)

  const onValuesChange = () => {
    setCanLeave(false)
    setCanSubmit(true)
  }

  const replacePage = (routePath: string) => {
    setCanLeave(true)
    setTimeout(() => replace(routePath), 500)
  }

  return { fm, canSubmit, onValuesChange, replacePage }
}

export const SupplierFormActions = (props: {
  fm: FormInstance
  onValidateFailed?: FormProps['onFinishFailed']
  onSubmit?: () => Promise<void>
  submitDisabled?: boolean
  children?: (v: { cancelButton: ReactNode; submitButton: ReactNode }) => ReactNode
}) => {
  const { fm, onValidateFailed, onSubmit, submitDisabled, children } = props

  const doSubmit = async () => {
    await fm.validateFields().catch(e => {
      fm.scrollToField(e.errorFields[0].name, { behavior: 'smooth', scrollMode: 'if-needed', block: 'center' })
      onValidateFailed?.(e)
      throw e
    })
    await onSubmit?.()
  }

  const cancelButton = (
    <Button type="checked-neutral" icon={<Close />} onClick={window.close}>
      {I18N.auto.quXiao}
    </Button>
  )

  const submitButton = (
    <AsyncButton type="primary" icon={<Check />} onClick={doSubmit} disabled={submitDisabled}>
      {I18N.auto.tiJiao}
    </AsyncButton>
  )

  if (children) return <>{children({ cancelButton, submitButton })}</>

  return (
    <>
      {cancelButton}
      {submitButton}
    </>
  )
}

export const SupplierContentFormItems = (props: SupplierFormItemProps & { createMode?: boolean }) => {
  const { defaultValue, createMode } = props
  const { data: countries } = useService(bizService.supplierConfigCountries)
  // 表单中使用countries数据id2name，所以前置获取一下
  if (!countries) return null

  return (
    <>
      {createMode && <OnboardingInfo {...props} />}
      <CheckInInfo {...props} />
      <ServiceCategoriesAndEnterpriseCredentialsInfo {...props} />
      <ContactsInfo {...props} />
      <BankInfo {...props} />
      {(isChinaEnterprise(defaultValue) || isOverseaEnterprise(defaultValue)) && (
        <>
          <FinancenInfo />
          <SuccessCasesInfo />
          <InterestRelationsInfo />
        </>
      )}
    </>
  )
}

export const SupplierInput = (props: InputProps) => {
  return <Input trimOnBlur maxLength={200} showCount {...props} />
}

export const SupplierTextArea = (props: TextAreaProps) => {
  return <Input.TextArea trimOnBlur maxLength={1000} showCount rows={2} {...props} />
}

export const CooperateStatusSelect = (props: Omit<SelectProps, 'options'>) => {
  const cooperateStatusOptions = useRCComputed(() => {
    return bizEnums.SpCooperateStatusEnum.values().filter(
      v =>
        v.value === bizEnums.SpCooperateStatusEnum.POTENTIAL_SMALL_AMOUNT ||
        v.value === bizEnums.SpCooperateStatusEnum.POTENTIAL_REGULAR
    )
  }, [])

  return <Select options={cooperateStatusOptions} {...props} />
}

export const BusinessLineSelect = (props: Omit<SelectProps, 'options'>) => {
  const { data: businessLines } = useService(apiService.identityEnterpriseConfigListBusinessLines)
  return (
    <Select
      className={bem('bizlines')}
      // @ts-ignore
      options={businessLines}
      fieldNames={{ value: 'businessId', name: 'businessName' }}
      multiple
      {...props}
    />
  )
}

const SourceSelect = (props: Omit<SelectProps, 'options'>) => {
  const options = bizEnums.SpOnboardingSourceEnum.values()
  return <Select options={options} {...props} />
}

const SupplierBlockTitle = (props: { title: string; required?: boolean }) => {
  const { title, required } = props
  return (
    <>
      {title}
      {required && <Tag className={bem('blockTag')} size="small" color="light-red" text={I18N.auto.biTian} />}
    </>
  )
}

const OnboardingInfo = (props: SupplierFormItemProps) => {
  const { defaultValue } = props
  const [cooperateStatus, setCooperateStatus] = useState(defaultValue.onboardingInfo?.cooperateStatus)

  const getFieldNamePath = (...name: string[]) => ['onboardingInfo', ...name]

  return (
    <DataPanel>
      <DataBlock title={<SupplierBlockTitle title={I18N.auto.zhunRuXinXi} required />}>
        <DataFormItem label={<StageLabel />} name={getFieldNamePath('cooperateStatus')} required>
          <CooperateStatusSelect onChange={v => setCooperateStatus(v as defs.SpCooperateStatus)} />
        </DataFormItem>
        {cooperateStatus !== bizEnums.SpCooperateStatusEnum.POTENTIAL_SMALL_AMOUNT && (
          <DataFormItem label={<BusinessLineLabel />} name={getFieldNamePath('businessId')} required>
            <BusinessLineSelect />
          </DataFormItem>
        )}
        <DataFormItem
          label={I18N.auto.gongYingShangLaiYuan}
          name={getFieldNamePath('source')}
          initialValue={'01'}
          required>
          <SourceSelect />
        </DataFormItem>
        <DataFormItem label={I18N.auto.tuiJianRenXingMing} name={getFieldNamePath('recommenderMail')} required>
          <SupplierInput />
        </DataFormItem>
        <DataFormItem w667 label={I18N.auto.tuiJianYuanYin} name={getFieldNamePath('recommendReason')} required>
          <SupplierInput />
        </DataFormItem>
        <DataFormItem w100 label={I18N.auto.zhunRuShuoMing} name={getFieldNamePath('remark')}>
          <SupplierTextArea />
        </DataFormItem>
      </DataBlock>
    </DataPanel>
  )
}

const CheckInInfo = (props: SupplierFormItemProps) => {
  const { defaultValue, createMode } = props

  const getFieldNamePath = (...name: string[]) => ['checkIn', ...name]

  const renderEnterprise = (v: { isChina?: boolean }) => {
    return (
      <>
        <DataFormItem label={I18N.auto.gongYingShangMingCheng} name={getFieldNamePath('enterpriseName')} required>
          <Input trimOnBlur disabled />
        </DataFormItem>
        {v.isChina ? (
          <DataFormItem label={I18N.auto.tongYiSheHuiXin} name={getFieldNamePath('creditCode')} required>
            <Input trimOnBlur disabled />
          </DataFormItem>
        ) : (
          <DataFormItem label="TIN" name={getFieldNamePath('tin')} required>
            {/* 新建时不可修改，编辑时支持修改 */}
            <Input trimOnBlur disabled={createMode} />
          </DataFormItem>
        )}

        {!v.isChina && (
          <DataFormItem label={I18N.auto.dengBaiShiBianMa} name={getFieldNamePath('duns')}>
            <SupplierInput />
          </DataFormItem>
        )}

        <DataFormItem label={I18N.auto.gongYingShangJianCheng} name={getFieldNamePath('enterpriseAliasName')}>
          <SupplierInput />
        </DataFormItem>
        {v.isChina && (
          <DataFormItem label={I18N.auto.faDingDaiBiaoRen} name={getFieldNamePath('legalRepresentative')} required>
            <SupplierInput />
          </DataFormItem>
        )}

        <DataFormItem label={I18N.auto.zhuCeZiBenWan} name={getFieldNamePath('registerCapital')} required>
          <CommandMoneyInput />
        </DataFormItem>
        <DataFormItem label={I18N.auto.gongSiZhuYeWang} name={getFieldNamePath('homeSite')}>
          <SupplierInput />
        </DataFormItem>
        <DataFormItem label={I18N.auto.yingYeQiXian} name={getFieldNamePath('startAt_endAt')} required>
          <StringInputDatePicker range checkable />
        </DataFormItem>
        <DataFormItem label={I18N.auto.chengLiRiQi} name={getFieldNamePath('establishTime')} required>
          <StringInputDatePicker />
        </DataFormItem>
        <DataFormItem label={I18N.auto.naShuiRenZiZhi} name={getFieldNamePath('taxpayerQualification')}>
          <Select options={bizEnums.TaxpayerQualificationEnum.values()} />
        </DataFormItem>
        <DataFormItem label={I18N.auto.shengShi} name={getFieldNamePath('provinceId_provinceCode__city')}>
          <SupplierProvinceCityCascader countryId={defaultValue.countryId!} />
        </DataFormItem>
        <DataFormItem label={I18N.auto.quXian} name={getFieldNamePath('district')}>
          <SupplierInput />
        </DataFormItem>
        <DataFormItem label={I18N.auto.xiangXiDiZhi} name={getFieldNamePath('address')}>
          <SupplierInput />
        </DataFormItem>
        <DataFormItem
          w100
          label={I18N.auto.jingYingFanWei}
          name={getFieldNamePath('businessScope')}
          required={v.isChina}>
          <SupplierTextArea />
        </DataFormItem>
        <DataFormItem label={I18N.auto.yingYeZhiZhaoFu} name={getFieldNamePath('licenseFile')} required>
          <SupplierUpload defaultValue={(defaultValue.checkIn as defs.EnterpriseCheckInDto)?.licenseFile} />
        </DataFormItem>
        <DataFormItem label={I18N.auto.heFaZhuCeZheng} name={getFieldNamePath('legalRegistrationLicenseFile')}>
          <SupplierUpload
            defaultValue={(defaultValue.checkIn as defs.EnterpriseCheckInDto)?.legalRegistrationLicenseFile}
          />
        </DataFormItem>
        <DataFormItem label={I18N.auto.zhunRuZiLiao} name={getFieldNamePath('admittanceFile')}>
          <SupplierUpload defaultValue={(defaultValue.checkIn as defs.EnterpriseCheckInDto)?.admittanceFile} />
        </DataFormItem>
      </>
    )
  }

  const renderPerson = () => {
    return (
      <>
        <DataFormItem label={I18N.auto.xingMing} name={getFieldNamePath('userName')} required>
          <Input trimOnBlur disabled />
        </DataFormItem>
        <DataFormItem label={I18N.auto.zhengJianLeiXing} name={getFieldNamePath('personIdType')} required>
          <Select placeholder={I18N.auto.qingShuRuZhengJian} options={bizEnums.PersonIdTypeEnum.values()} disabled />
        </DataFormItem>
        <DataFormItem label={I18N.auto.zhengJianHaoMa} name={getFieldNamePath('personId')}>
          <Input trimOnBlur disabled />
        </DataFormItem>
      </>
    )
  }

  const renderInputs = () => {
    if (isChinaEnterprise(defaultValue)) return renderEnterprise({ isChina: true })
    if (isOverseaEnterprise(defaultValue)) return renderEnterprise({ isChina: false })
    if (isChinaPerson(defaultValue) || isOverseaPerson(defaultValue)) return renderPerson()
  }

  return (
    <DataPanel>
      <DataBlock title={<SupplierBlockTitle title={I18N.auto.dengJiXinXi} required />}>{renderInputs()}</DataBlock>
    </DataPanel>
  )
}

const CommandMoneyInput = (props: { value?: defs.MoneyCommand; onChange?: (v?: defs.MoneyCommand) => void }) => {
  const { value = {}, onChange } = props
  const [data, setData] = useMultiState(value as { amount?: string | number; currency?: defs.Currency })

  useRCUpdate(() => {
    if (value.amount && value.currency && (value.amount !== data.amount || value.currency !== data.currency)) {
      setData(value)
    }
  }, [data])

  return (
    <div className={bem('command-money')}>
      <CurrencySelect
        value={data.currency}
        onChange={v => {
          const currency = v as defs.Currency
          setData({ currency })
          onChange?.(currency && data?.amount ? { currency, amount: +data.amount } : undefined)
        }}
      />

      <InputNumber
        direction="vertical"
        toFixed={2}
        value={data.amount}
        onChange={v => {
          const amount = v as string
          setData({ amount })
          onChange?.(amount && data?.currency ? { amount: +amount, currency: data.currency } : undefined)
        }}
      />
    </div>
  )
}

const SupplierCountrySelect = (props: SelectProps) => {
  const { data } = useService(bizService.supplierConfigCountries)
  const options = useRCComputed(() => data?.map(v => ({ name: v.name!, value: `${v.id}_${v.code}` })), [data])
  return <Select options={options} showSearch="inner" {...props} />
}

const SupplierProvinceCityCascader = (props: {
  countryId: number
  value?: string
  onChange?: (v?: string) => void
}) => {
  const { countryId, value, onChange } = props
  const [options, setOptions] = useState([])
  const vars = useRCVars({ changed: false })

  const getCities = async (provinceId: number) => {
    const res = await bizService.supplierConfigCities({ provinceId })
    return res?.map(v => ({
      label: v.name!,
      value: v.name!,
      children: undefined,
      isLeaf: true,
    }))
  }

  useRCWatch(async () => {
    const res = await bizService.supplierConfigProvinces({ countryId })
    // 因为省市联动中，市是异步拉取了，所以这里，如果Value值中有市的ID，那就得拉取出来市
    if (value?.includes('__')) {
      const provinceId = +(value as string).split('_')[0]
      const cities = await getCities(provinceId)
      const province = res?.find(v => v.id === provinceId)
      // @ts-ignore 这里强行加一个children吧
      province && (province.children = cities)
    }
    // @ts-ignore 这里取值children
    setOptions(res?.map(v => ({ label: v.name!, value: `${v.id}_${v.code}`, children: v.children, isLeaf: false })))
  }, [countryId])

  return (
    <Cascader
      options={options}
      value={vars.changed ? value?.split('__') : value?.split('__').slice(0, 1)}
      onChange={(v: any) => {
        vars.changed = true
        onChange?.(v.join('__'))
      }}
      loadData={async selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1]
        if (targetOption.children) return

        // @ts-ignore
        targetOption.loading = true

        if (selectedOptions.length === 1) {
          const provinceId = +(targetOption.value as string).split('_')[0]
          const res = await getCities(provinceId)
          // @ts-ignore
          targetOption.loading = false
          targetOption.children = res
          targetOption.isLeaf = !targetOption.children?.length
        }

        setOptions([...options])
      }}
    />
  )
}

const SupplierUpload = (props: Omit<FileUploadProps, 'dataId'>) => {
  return (
    <FileUpload
      {...props}
      dataId=""
      requestUploadFile={apiService.identityEnterpriseBoardedRequestUploadFile}
      completeUploadFile={apiService.identityEnterpriseBoardedCompleteUploadFile}
      {...supplierFilePreviewAndDownloadServices}
      limit={1}
      tip={I18N.auto.danGeWenJianBu}
    />
  )
}

const SupplerCategoriesCascader = (
  props: Omit<CascaderProps, 'options' | 'value' | 'onChange'> & {
    categoryId2Value: Record<number, { pathIds: number[]; names: string }>
    options?: defs.SpCategoryDto[]
    value?: number | number[]
    onChange?: (v: number | number[]) => void
  }
) => {
  const { categoryId2Value, options, value: propValue, onChange, multiple, ...rest } = props

  const value = useRCComputed(() => {
    if (propValue === undefined) return propValue
    if (multiple) {
      return (propValue as number[]).map(v => categoryId2Value[v]?.pathIds).filter(Boolean)
    } else {
      return categoryId2Value[propValue as number]?.pathIds
    }
  }, [categoryId2Value, propValue])

  return (
    <Cascader
      // @ts-ignore
      options={options}
      fieldNames={{ value: 'id', label: 'name' }}
      value={value}
      multiple={multiple}
      showCheckedStrategy="SHOW_CHILD"
      showSearch
      onChange={(v: any) => {
        onChange?.(multiple ? (v as number[][]).map(t => t[t.length - 1]) : v[v.length - 1])
      }}
      {...rest}
    />
  )
}

const ServiceCategoriesAndEnterpriseCredentialsInfo = (props: SupplierFormItemProps) => {
  const { defaultValue } = props
  const { data: categories } = useService(apiService.identityEnterpriseConfigListCategories)
  const [categoryIds, setCategoryIds] = useState([] as number[])

  const getServiceCategoriesFieldNamePath = (...name: string[]) => ['serviceCategories', ...name]

  useRCMount(() => {
    setCategoryIds(defaultValue?.serviceCategories?.map(v => v.categoryId!) || [])
  })

  const categoryId2Value = useRCComputed(() => {
    if (!categories) return {}

    const inner = (
      nodeList: NonNullable<typeof categories>,
      parentNodes: NonNullable<typeof categories>
    ): Record<number, { pathIds: number[]; names: string }> => {
      return nodeList.reduce((acc, node) => {
        const nodes = [...parentNodes, node]
        const children = node.children || []
        return {
          ...acc,
          ...inner(children, nodes),
          [node.id!]: { pathIds: nodes.map(v => v.id), names: nodes.map(v => v.name).join('/') },
        }
      }, {})
    }

    return inner(categories, [])
  }, [categories])

  if (!categories?.length) return null

  return (
    <>
      <DataPanel>
        <DataBlock title={<SupplierBlockTitle title={I18N.auto.fuWuPinLei} required />}>
          <DataFormItem
            w100
            label={I18N.auto.fuWuPinLeiNei}
            name={getServiceCategoriesFieldNamePath('categoryIds')}
            required>
            <SupplerCategoriesCascader
              options={categories}
              multiple
              disabled
              categoryId2Value={categoryId2Value}
              onChange={v => setCategoryIds(v as number[])}
            />
          </DataFormItem>
        </DataBlock>
      </DataPanel>
      {(isChinaEnterprise(defaultValue) || isOverseaEnterprise(defaultValue)) && (
        <DataPanel>
          <DataBlock bodyClassName={bem('fm-dbBodyForTable')} title={I18N.auto.ziZhiXinXi}>
            <DataFormItem disableLabel w100 name="credentials">
              <EnterpriseCredentialsInput
                serviceCategories={categories || []}
                serviceCategoryIds={categoryIds}
                categoryId2Value={categoryId2Value}
              />
            </DataFormItem>
          </DataBlock>
        </DataPanel>
      )}
    </>
  )
}

const EnterpriseCredentialsInput = (props: {
  serviceCategories: defs.SpCategoryDto[]
  serviceCategoryIds: number[]
  categoryId2Value: Record<number, { pathIds: number[]; names: string }>
  value?: defs.EnterpriseCredentialCommand[]
  onChange?: (v?: defs.EnterpriseCredentialCommand[]) => void
}) => {
  const { serviceCategories, serviceCategoryIds, categoryId2Value, value, onChange } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [draftData, setDraftData] = useState({} as defs.EnterpriseCredentialCommand)
  const [fm] = Form.useForm()
  const vars = useRCVars({ draftIdx: -1 })

  const draftCategories = useRCComputed(() => {
    if (!serviceCategories) return []

    type T = NonNullable<typeof serviceCategories>[number]
    const mapping = (v: T): typeof v | undefined => {
      const children = v.children?.map(mapping).filter(Boolean) as T[]
      if (serviceCategoryIds.includes(v.id!) || children?.length) {
        return { ...v, children }
      }
    }

    return serviceCategories?.map(mapping).filter(Boolean) as T[]
  }, [serviceCategories, serviceCategoryIds])

  const columns = createTableColumns<defs.EnterpriseCredentialCommand>([
    {
      title: I18N.auto.pinLei,
      dataIndex: 'categoryId',
      width: 230,
      fixed: 'left',
      render: v => categoryId2Value[v]?.names,
    },
    {
      title: I18N.auto.ziZhiMingCheng,
      dataIndex: 'credentialName',
      width: 223,
    },
    {
      title: I18N.auto.ziZhiWenJian,
      dataIndex: 'credentialFile',
      width: 194,
      render: (_, record) => record.credentialFile && <SupplierAttachments attachments={[record.credentialFile]} />,
    },
    {
      title: I18N.auto.ziZhiYouXiaoQi,
      dataIndex: 'startAt_endAt',
      width: 240,
      render: (_, record) => formatStartEndDate(record.startAt, record.endAt),
    },
    {
      title: I18N.auto.beiZhu,
      dataIndex: 'remark',
      width: 204,
      paragraph: true,
    },
    {
      title: I18N.auto.caoZuo,
      dataIndex: 'operate',
      width: 115,
      fixed: 'right',
      render: (_, record, idx) => (
        <div className={bem('fm-operate')}>
          <Button
            type="text"
            onClick={() => {
              const { startAt, endAt, ...restData } = record
              setDraftData(record)
              fm.resetFields()
              fm.setFieldsValue({
                startAt_endAt: startAt && endAt ? [startAt, endAt] : undefined,
                ...restData,
              })
              vars.draftIdx = idx
              show()
            }}>
            {I18N.auto.bianJi}
          </Button>
          <Button
            type="text"
            danger
            onClick={() => {
              const newValue = value?.slice()
              newValue?.splice(idx, 1)
              onChange?.(newValue)
            }}>
            {I18N.auto.shanChu}
          </Button>
        </div>
      ),
    },
  ])

  const doOk = async () => {
    await fm.validateFields()
    const { startAt_endAt, ...restFmData } = fm.getFieldsValue()

    if (
      value?.some(
        (v, i) =>
          i !== vars.draftIdx &&
          v.categoryId === restFmData.categoryId &&
          v.credentialName === restFmData.credentialName
      )
    ) {
      Message.error(I18N.auto.yiCunZaiXiangTong)
      return
    }

    const [startAt, endAt] = startAt_endAt

    if (vars.draftIdx === -1) {
      onChange?.([...(value || []), { startAt, endAt, ...restFmData }])
    } else {
      const idx = vars.draftIdx
      const newValue = value?.slice()
      newValue && idx > -1 && (newValue[idx] = { startAt, endAt, ...restFmData })
      onChange?.(newValue)
    }

    hide()
  }

  return (
    <>
      <SupplierTable
        columns={columns}
        data={value}
        footer={() => (
          <Button
            type="text"
            icon={<Plus />}
            onClick={() => {
              setDraftData({})
              fm.resetFields()
              vars.draftIdx = -1
              show()
            }}>
            {I18N.auto.tianJiaZiZhi}
          </Button>
        )}
      />

      <Modal
        className={bem('modal')}
        title={I18N.template(I18N.auto.ziZhiXinXi_2, {
          val1: vars.draftIdx === -1 ? I18N.auto.tianJia : I18N.auto.bianJi,
        })}
        visible={visible}
        onCancel={hide}
        onOk={doOk}>
        <DataForm className={bem('fm')} form={fm}>
          <DataBlock>
            <DataFormItem w500 label={I18N.auto.pinLei} name="categoryId" required>
              <SupplerCategoriesCascader options={draftCategories} categoryId2Value={categoryId2Value} />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.ziZhiMingCheng} name="credentialName" required>
              <SupplierInput />
            </DataFormItem>
            <DataFormItem w100 label={I18N.auto.ziZhiWenJian} name="credentialFile" required>
              <SupplierUpload defaultValue={draftData.credentialFile} />
            </DataFormItem>
            <DataFormItem w100 label={I18N.auto.ziZhiYouXiaoQi} name="startAt_endAt" required>
              <StringInputDatePicker range checkable />
            </DataFormItem>
            <DataFormItem w100 label={I18N.auto.beiZhu} name="remark">
              <SupplierTextArea />
            </DataFormItem>
          </DataBlock>
        </DataForm>
      </Modal>
    </>
  )
}

const ContactsInfo = (props: SupplierFormItemProps) => {
  const { defaultValue } = props
  return (
    <DataPanel>
      <DataBlock
        bodyClassName={bem('fm-dbBodyForTable')}
        title={<SupplierBlockTitle title={I18N.auto.lianXiRenXinXi} required />}>
        <DataFormItem disableLabel w100 name="contacts" required>
          <ContactsInput isEnterprise={isChinaEnterprise(defaultValue) || isOverseaEnterprise(defaultValue)} />
        </DataFormItem>
      </DataBlock>
    </DataPanel>
  )
}

type ContactCommand = defs.EnterpriseContactCommand | defs.PersonContactCommand
const ContactsInput = (props: {
  isEnterprise: boolean
  value?: ContactCommand[]
  onChange?: (v?: ContactCommand[]) => void
}) => {
  const { isEnterprise, value, onChange } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [fm] = Form.useForm()
  const vars = useRCVars({ draftIdx: -1 })

  type T = Parameters<typeof createTableColumns<ContactCommand>>[0]
  const columns = createTableColumns<ContactCommand>([
    {
      title: I18N.auto.lianXiRenXingMing,
      dataIndex: 'name',
      width: 131,
      fixed: 'left',
    },
    ...(isEnterprise
      ? ([
          {
            title: I18N.auto.lianXiRenLeiXing,
            dataIndex: 'contactType',
            width: 174,
            render: v => bizEnums.ContactTypeEnum.pick(v)?.name,
          },
          {
            title: I18N.auto.zhiWu,
            dataIndex: 'post',
            width: 156,
            render: v => v ?? '-',
          },
        ] as T)
      : []),
    {
      title: I18N.auto.youXiang,
      dataIndex: 'mail',
      width: 169,
    },
    {
      title: I18N.auto.shouJiHao,
      dataIndex: 'phone',
      width: 173,
      render: v => v ?? '-',
    },
    ...(!isEnterprise
      ? ([
          {
            title: 'SNS',
            dataIndex: 'sns',
            width: 146,
            render: v => v ?? '-',
          },
        ] as T)
      : []),
    {
      title: I18N.auto.beiZhu,
      dataIndex: 'remark',
      width: 173,
      paragraph: true,
    },
    {
      title: I18N.auto.caoZuo,
      dataIndex: 'operate',
      width: 115,
      fixed: 'right',
      render: (_, record, idx) => (
        <div className={bem('fm-operate')}>
          <Button
            type="text"
            onClick={() => {
              fm.resetFields()
              fm.setFieldsValue(record)
              vars.draftIdx = idx
              show()
            }}>
            {I18N.auto.bianJi}
          </Button>
          <Button
            type="text"
            danger
            onClick={() => {
              const newValue = value?.slice()
              newValue?.splice(idx, 1)
              onChange?.(newValue)
            }}>
            {I18N.auto.shanChu}
          </Button>
        </div>
      ),
    },
  ])

  const doOk = async () => {
    await fm.validateFields()
    const fmData = fm.getFieldsValue()

    if (
      value?.some(
        (v, i) =>
          i !== vars.draftIdx &&
          v.mail === fmData.mail &&
          v.name === fmData.name &&
          (!isEnterprise || (v as defs.EnterpriseContactCommand).contactType === fmData.contactType)
      )
    ) {
      Message.error(I18N.auto.yiCunZaiXiangTong_2)
      return
    }

    if (vars.draftIdx === -1) {
      onChange?.([...(value || []), fmData])
    } else {
      const idx = vars.draftIdx
      const newValue = value?.slice()
      newValue && idx > -1 && (newValue[idx] = fmData)
      onChange?.(newValue)
    }

    hide()
  }

  return (
    <>
      <SupplierTable
        columns={columns}
        data={value}
        footer={() => (
          <Button
            type="text"
            icon={<Plus />}
            onClick={() => {
              fm.resetFields()
              vars.draftIdx = -1
              show()
            }}>
            {I18N.auto.tianJiaLianXiRen}
          </Button>
        )}
      />

      <Modal
        className={bem('modal')}
        title={I18N.template(I18N.auto.lianXiRen_2, {
          val1: vars.draftIdx === -1 ? I18N.auto.tianJia : I18N.auto.bianJi,
        })}
        visible={visible}
        onCancel={hide}
        onOk={doOk}>
        <DataForm className={bem('fm')} form={fm}>
          <DataBlock>
            <DataFormItem name="id" hidden />
            {isEnterprise && (
              <DataFormItem w500 label={I18N.auto.lianXiRenLeiXing} name="contactType" required>
                <Select options={bizEnums.ContactTypeEnum.values()} />
              </DataFormItem>
            )}

            <DataFormItem w500 label={I18N.auto.lianXiRenXingMing} name="name" required>
              <SupplierInput />
            </DataFormItem>
            {isEnterprise && (
              <DataFormItem w500 label={I18N.auto.zhiWu} name="post">
                <SupplierInput />
              </DataFormItem>
            )}

            <DataFormItem
              w500
              label={I18N.auto.youXiang}
              name="mail"
              required
              rules={[{ type: 'email', message: I18N.auto.qingShuRuZhengQue_3 }]}>
              <SupplierInput />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.shouJiHao} name="phone">
              <SupplierInput type="number" />
            </DataFormItem>
            {!isEnterprise && (
              <DataFormItem w500 label="SNS" name="sns">
                <SupplierInput />
              </DataFormItem>
            )}

            <DataFormItem w100 label={I18N.auto.beiZhu} name="remark">
              <SupplierTextArea />
            </DataFormItem>
          </DataBlock>
        </DataForm>
      </Modal>
    </>
  )
}

const StatusRadioSelect = (props: {
  options?: { value: defs.StatusEnum; name: string; disabled?: boolean }[]
  value?: defs.StatusEnum
  onChange?: (v: defs.StatusEnum) => void
  yesConfirm?: boolean
  yesDisabled?: boolean
  noDisabled?: boolean
}) => {
  const { options: propOptions = [], value, onChange, yesConfirm, yesDisabled, noDisabled } = props

  const options = useRCComputed(() => {
    return propOptions.map(v => ({
      ...v,
      disabled:
        (yesDisabled && v.value === bizEnums.StatusBooleanEnum.YES) ||
        (noDisabled && v.value === bizEnums.StatusBooleanEnum.NO),
    }))
  }, [propOptions, yesDisabled, noDisabled])

  if (!yesConfirm) return <Radio.Group options={options} value={value} onChange={e => onChange?.(e.target.value)} />

  return (
    <>
      {options.map(v =>
        v.value === bizEnums.StatusBooleanEnum.YES && (!value || value === bizEnums.StatusBooleanEnum.NO) ? (
          <Popconfirm key={v.value} content={I18N.auto.yiCunZaiMoRen} onOk={() => onChange?.(v.value)}>
            <Radio value={v.value} checked={value === v.value} disabled={v.disabled}>
              {v.name}
            </Radio>
          </Popconfirm>
        ) : (
          <Radio key={v.value} value={v.value} checked={value === v.value} onClick={() => onChange?.(v.value)}>
            {v.name}
          </Radio>
        )
      )}
    </>
  )
}

const BankInfo = (props: SupplierFormItemProps) => {
  const { defaultValue } = props

  return (
    <DataPanel>
      <DataBlock
        bodyClassName={bem('fm-dbBodyForTable')}
        title={<SupplierBlockTitle title={I18N.auto.yinHangZhangHaoXin} required />}>
        <DataFormItem disableLabel w100 name="bankInfos" required>
          <BankInfoInput
            defaultCountry={{ id: defaultValue.countryId!, code: defaultValue.countryCode! }}
            isEnterprise={isChinaEnterprise(defaultValue) || isOverseaEnterprise(defaultValue)}
          />
        </DataFormItem>
      </DataBlock>
    </DataPanel>
  )
}

type BankInfoCommand = defs.EnterpriseBankInfoCommand | defs.PersonBankInfoCommand
const BankInfoInput = (props: {
  defaultCountry: { id: number; code: string }
  isEnterprise: boolean
  value?: BankInfoCommand[]
  onChange?: (v?: BankInfoCommand[]) => void
}) => {
  const { defaultCountry, isEnterprise, value, onChange } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [draftData, setDraftData] = useState({} as BankInfoCommand)
  const [fm] = Form.useForm()
  const vars = useRCVars({ draftIdx: -1 })

  type T = Parameters<typeof createTableColumns<BankInfoCommand>>[0]
  const columns = createTableColumns<BankInfoCommand>([
    {
      title: I18N.auto.yinHangZhangHao,
      dataIndex: 'account',
      width: 200,
      fixed: 'left',
      render: (_, recored) => <BankAccount data={recored} />,
    },
    {
      title: I18N.auto.zhangHaoMingCheng,
      dataIndex: 'accountName',
      width: 123,
      fixed: 'left',
    },
    {
      title: I18N.auto.jieSuanBiZhong,
      dataIndex: 'currency',
      width: 157,
    },
    ...(isEnterprise
      ? ([
          {
            title: I18N.auto.guoJiaDiQu_2,
            dataIndex: 'countryId',
            width: 150,
            render: v => v && bizService.getSupplierCountryName(v),
          },
        ] as T)
      : []),
    {
      title: I18N.auto.kaiHuHangMingCheng_2,
      dataIndex: 'openBankName',
      width: 141,
    },
    {
      title: I18N.auto.zhiHangMingCheng,
      dataIndex: 'branchName',
      width: 135,
    },
    {
      title: I18N.auto.caoZuo,
      dataIndex: 'operate',
      width: 186,
      fixed: 'right',
      render: (_, record, idx) => (
        <div className={bem('fm-operate')}>
          <Button
            type="text"
            onClick={() => {
              vars.draftIdx = idx
              doEditOrCopy(record)
            }}>
            {I18N.auto.bianJi}
          </Button>
          <Button
            type="text"
            onClick={() => {
              vars.draftIdx = -1
              // 复制新增，要剔除掉ID
              const { id, ...restRecord } = record
              doEditOrCopy(restRecord)
            }}>
            {I18N.auto.fuZhiXinZeng}
          </Button>
          <Button
            type="text"
            danger
            onClick={() => {
              const newValue = value?.slice()
              newValue?.splice(idx, 1)
              onChange?.(newValue)
            }}>
            {I18N.auto.shanChu}
          </Button>
        </div>
      ),
    },
  ])

  const doEditOrCopy = (record: BankInfoCommand) => {
    const { countryId, countryCode, openProvinceId, openProvinceCode, openCity, ...restData } =
      record as defs.EnterpriseBankInfoCommand
    const fieldsValue = {
      countryId_countryCode:
        countryId !== undefined && countryCode !== undefined ? `${countryId}_${countryCode}` : undefined,
      ...restData,
    }
    if (isEnterprise) {
      setDraftData(record)
      fm.resetFields()
      fm.setFieldsValue({
        ...fieldsValue,
        ...(openProvinceId
          ? {
              openProvinceId_openProvinceCode__openCity: `${openProvinceId}_${openProvinceCode}__${openCity}`,
            }
          : {}),
      })
    } else {
      setDraftData(record)
      fm.resetFields()
      fm.setFieldsValue(fieldsValue)
    }
    show()
  }

  const isDraftChina = isChinaCountryCode(draftData.countryCode)
  const primaryNeedConfirm = value?.some((v, i) => i !== vars.draftIdx && v.primary === bizEnums.StatusBooleanEnum.YES)

  const doOk = async () => {
    await fm.validateFields()

    const { countryId_countryCode, openProvinceId_openProvinceCode__openCity, ...restFmData } = fm.getFieldsValue()
    const [countryId, countryCode] = countryId_countryCode.split('_')

    let fmData = restFmData
    let inputData = { countryId, countryCode, ...restFmData }

    if (isEnterprise) {
      if (isDraftChina && openProvinceId_openProvinceCode__openCity) {
        const [openProvinceId, openProvinceCode, openCity] = openProvinceId_openProvinceCode__openCity
          .split('_')
          .filter(Boolean)
        inputData = { ...inputData, openProvinceId, openProvinceCode, openCity }
      }
    }

    if (
      value?.some(
        (v, i) =>
          i !== vars.draftIdx &&
          v.account === fmData.account &&
          v.accountName === fmData.accountName &&
          v.currency === fmData.currency
      )
    ) {
      Message.error(I18N.auto.yiCunZaiXiangTong_3)
      return
    }

    // 如果当前编辑的值是默认账号，那么其他的值就都不是默认值
    const newValue = value?.slice()
    if (inputData.primary === bizEnums.StatusBooleanEnum.YES) {
      newValue?.forEach(v => (v.primary = bizEnums.StatusBooleanEnum.NO))
    }

    if (vars.draftIdx === -1) {
      onChange?.([...(newValue || []), { ...inputData, status: bizEnums.StatusEnum.ENABLE }])
    } else {
      const idx = vars.draftIdx
      newValue && idx > -1 && (newValue[idx] = inputData)
      onChange?.(newValue)
    }

    hide()
  }

  return (
    <>
      <SupplierTable
        columns={columns}
        data={value}
        footer={() => (
          <Button
            type="text"
            icon={<Plus />}
            onClick={() => {
              setDraftData({ countryId: defaultCountry.id, countryCode: defaultCountry.code })
              fm.resetFields()
              fm.setFieldsValue({
                countryId_countryCode: `${defaultCountry.id}_${defaultCountry.code}`,
              })
              vars.draftIdx = -1
              show()
            }}>
            {I18N.auto.tianJiaYinHangXin}
          </Button>
        )}
      />

      <Modal
        className={bem('modal')}
        title={I18N.template(I18N.auto.yinHangXinXi_2, {
          val1: vars.draftIdx === -1 ? I18N.auto.tianJia : I18N.auto.bianJi,
        })}
        visible={visible}
        onCancel={hide}
        onOk={doOk}>
        <DataForm className={bem('fm')} form={fm}>
          <DataBlock>
            <DataFormItem name="id" hidden />
            <DataFormItem name="invalidAt" hidden />
            <DataFormItem w500 label={I18N.auto.yinHangZhangHao} name="account" required>
              <SupplierInput type="number" />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.zhangHaoMingCheng} name="accountName" required>
              <SupplierInput />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.jieSuanBiZhong} name="currency" required>
              <CurrencySelect />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.guoJiaDiQu_2} name="countryId_countryCode" required>
              <SupplierCountrySelect
                onChange={v => {
                  const [id, code] = ((v as string) || '').split('_')
                  setDraftData({ ...draftData, countryId: +id, countryCode: code })
                }}
              />
            </DataFormItem>
            {isEnterprise ? (
              <>
                {isDraftChina ? (
                  <>
                    <DataFormItem w500 label={I18N.auto.lianHangHao} name="cnaps" required>
                      <SupplierInput />
                    </DataFormItem>
                    <DataFormItem
                      w500
                      label={I18N.auto.kaiHuHangShengShi}
                      name="openProvinceId_openProvinceCode__openCity">
                      <SupplierProvinceCityCascader
                        countryId={(draftData as defs.EnterpriseBankInfoCommand).countryId!}
                      />
                    </DataFormItem>
                  </>
                ) : (
                  <>
                    <DataFormItem w500 label="SWIFT CODE" name="swiftCode" required>
                      <SupplierInput />
                    </DataFormItem>
                    <DataFormItem w500 label="IBAN" name="iban">
                      <SupplierInput />
                    </DataFormItem>
                  </>
                )}
              </>
            ) : (
              !isDraftChina && (
                <DataFormItem w500 label="SWIFT CODE" name="swiftCode" required>
                  <SupplierInput />
                </DataFormItem>
              )
            )}

            <DataFormItem w500 label={I18N.auto.kaiHuHangMingCheng_2} name="openBankName" required>
              <SupplierInput />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.zhiHangMingCheng} name="branchName" required>
              <SupplierInput />
            </DataFormItem>
            {isEnterprise && (
              <>
                <DataFormItem w100 label={I18N.auto.yinHangDiZhi} name="address">
                  <SupplierInput />
                </DataFormItem>
                <DataDivider />
                <DataFormItem w500 label={I18N.auto.shouXuFeiChengDan} name="handlingFeeType" required>
                  <Select options={bizEnums.HandlingFeeTypeEnum.values()} />
                </DataFormItem>
                <DataFormItem w500 label={I18N.auto.zhangQi} name="accountPeriod">
                  <Select options={bizEnums.SpAccountPeriodEnum.values()} />
                </DataFormItem>
              </>
            )}

            <DataFormItem w500 label={I18N.auto.shiFouWeiMoRen} name="primary" required>
              <StatusRadioSelect
                options={bizEnums.StatusBooleanEnum.values()}
                yesConfirm={primaryNeedConfirm}
                yesDisabled={draftData.status === bizEnums.StatusBooleanEnum.NO}
                onChange={v => setDraftData({ ...draftData, primary: v })}
              />
            </DataFormItem>
            {vars.draftIdx > -1 && (
              <DataFormItem w500 label={I18N.auto.zhuangTai} name="status" required>
                <StatusRadioSelect
                  options={bizEnums.StatusInUseEnum.values()}
                  noDisabled={draftData.primary === bizEnums.StatusBooleanEnum.YES}
                  onChange={v => setDraftData({ ...draftData, status: v })}
                />
              </DataFormItem>
            )}

            <DataFormItem w100 label={I18N.auto.beiZhu} name="remark">
              <SupplierTextArea rows={2} />
            </DataFormItem>
            {isEnterprise && (
              <DataFormItem w100 label={I18N.auto.kaiHuXuKeZheng} name="createPermitFile">
                <SupplierUpload defaultValue={(draftData as defs.EnterpriseBankInfoCommand).createPermitFile} />
              </DataFormItem>
            )}
          </DataBlock>
        </DataForm>
      </Modal>
    </>
  )
}

const FinancenInfo = () => {
  return (
    <DataPanel>
      <DataBlock bodyClassName={bem('fm-dbBodyForTable')} title={I18N.auto.caiWuZhuangKuang}>
        <DataFormItem disableLabel w100 name="financeInfos">
          <FinancenInput />
        </DataFormItem>
      </DataBlock>
    </DataPanel>
  )
}

const FinancenInput = (props: {
  value?: defs.EnterpriseFinanceCommand[]
  onChange?: (v?: defs.EnterpriseFinanceCommand[]) => void
}) => {
  const { value, onChange } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [draftData, setDraftData] = useState({} as defs.EnterpriseFinanceCommand)
  const [fm] = Form.useForm()
  const vars = useRCVars({ draftIdx: -1 })

  const columns = createTableColumns<defs.EnterpriseFinanceCommand>([
    {
      title: I18N.auto.nianFen,
      dataIndex: 'year',
      width: 100,
      fixed: 'left',
    },
    {
      title: I18N.auto.yingYeShouRuWan,
      dataIndex: 'income',
      width: 144,
      render: (v, record) => (v !== undefined ? `${record.currency} ${v}` : '-'),
    },
    {
      title: I18N.auto.liRunZongEWan,
      dataIndex: 'profit',
      width: 144,
      render: (v, record) => (v !== undefined ? `${record.currency} ${v}` : '-'),
    },
    {
      title: I18N.auto.shiJiaoZiBenWan,
      dataIndex: 'paidUpCapital',
      width: 144,
      render: (v, record) => (v !== undefined ? `${record.currency} ${v}` : '-'),
    },
    {
      title: I18N.auto.nianDuShenJiBao,
      dataIndex: 'annualAuditReportFile',
      width: 187,
      render: (_, record) =>
        record.annualAuditReportFile ? <SupplierAttachments attachments={[record.annualAuditReportFile]} /> : '-',
    },
    {
      title: I18N.auto.caiWuBaoBiao,
      dataIndex: 'financialStatementFile',
      width: 187,
      render: (_, record) =>
        record.financialStatementFile ? <SupplierAttachments attachments={[record.financialStatementFile]} /> : '-',
    },
    {
      title: I18N.auto.caoZuo,
      dataIndex: 'operate',
      width: 115,
      fixed: 'right',
      render: (_, record, idx) => (
        <div className={bem('fm-operate')}>
          <Button
            type="text"
            onClick={() => {
              setDraftData(record)
              fm.resetFields()
              fm.setFieldsValue(record)
              vars.draftIdx = idx
              show()
            }}>
            {I18N.auto.bianJi}
          </Button>
          <Button
            type="text"
            danger
            onClick={() => {
              const newValue = value?.slice()
              newValue?.splice(idx, 1)
              onChange?.(newValue)
            }}>
            {I18N.auto.shanChu}
          </Button>
        </div>
      ),
    },
  ])

  const doOk = async () => {
    await fm.validateFields()
    const fmData = fm.getFieldsValue()

    if (value?.some((v, i) => i !== vars.draftIdx && v.year === fmData.year)) {
      Message.error(I18N.auto.yiCunZaiXiangTong_4)
      return
    }

    if (vars.draftIdx === -1) {
      onChange?.([...(value || []), fmData])
    } else {
      const idx = vars.draftIdx
      const newValue = value?.slice()
      newValue && idx > -1 && (newValue[idx] = fmData)
      onChange?.(newValue)
    }

    hide()
  }

  return (
    <>
      <SupplierTable
        columns={columns}
        data={value}
        footer={() => (
          <Button
            type="text"
            icon={<Plus />}
            onClick={() => {
              setDraftData({})
              fm.resetFields()
              vars.draftIdx = -1
              show()
            }}>
            {I18N.auto.tianJiaCaiWuZhuang}
          </Button>
        )}
      />

      <Modal
        className={bem('modal')}
        title={I18N.template(I18N.auto.caiWuZhuangKuang_2, {
          val1: vars.draftIdx === -1 ? I18N.auto.tianJia : I18N.auto.bianJi,
        })}
        visible={visible}
        onCancel={hide}
        onOk={doOk}>
        <DataForm className={bem('fm')} form={fm}>
          <DataBlock>
            <DataFormItem name="id" hidden />
            <DataFormItem w500 label={I18N.auto.nianFen} name="year" required>
              <YearPicker />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.biZhong} name="currency" required>
              <CurrencySelect />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.yingYeShouRuWan} name="income" required>
              <InputNumber direction="vertical" toFixed={2} />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.liRunZongEWan} name="profit" required>
              <InputNumber direction="vertical" min={Number.MIN_SAFE_INTEGER} toFixed={2} />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.shiJiaoZiBenWan} name="paidUpCapital">
              <InputNumber direction="vertical" toFixed={2} />
            </DataFormItem>
            <DataFormItem w100 label={I18N.auto.nianDuShenJiBao} name="annualAuditReportFile">
              <SupplierUpload defaultValue={draftData.annualAuditReportFile} />
            </DataFormItem>
            <DataFormItem w100 label={I18N.auto.caiWuBaoBiao} name="financialStatementFile">
              <SupplierUpload defaultValue={draftData.financialStatementFile} />
            </DataFormItem>
            <DataFormItem w100 label={I18N.auto.beiZhu} name="remark">
              <SupplierTextArea />
            </DataFormItem>
          </DataBlock>
        </DataForm>
      </Modal>
    </>
  )
}

const SuccessCasesInfo = () => {
  return (
    <DataPanel>
      <DataBlock bodyClassName={bem('fm-dbBodyForTable')} title={I18N.auto.chengGongAnLi}>
        <DataFormItem disableLabel w100 name="successCases">
          <SuccessCasesInput />
        </DataFormItem>
      </DataBlock>
    </DataPanel>
  )
}

const SuccessCasesInput = (props: {
  value?: defs.EnterpriseSuccessCaseCommand[]
  onChange?: (v?: defs.EnterpriseSuccessCaseCommand[]) => void
}) => {
  const { value, onChange } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [draftData, setDraftData] = useState({} as defs.EnterpriseSuccessCaseCommand)
  const [fm] = Form.useForm()
  const vars = useRCVars({ draftIdx: -1 })

  const columns = createTableColumns<defs.EnterpriseSuccessCaseCommand>([
    {
      title: I18N.auto.anLiMingCheng,
      dataIndex: 'name',
      width: 180,
      fixed: 'left',
    },
    {
      title: I18N.auto.keHuMingCheng,
      dataIndex: 'customerName',
      width: 123,
    },
    {
      title: I18N.auto.xiangMuGuiMoWan,
      dataIndex: 'projectCapitalAmount',
      width: 150,
    },
    {
      title: I18N.auto.xiangMuShiJian,
      dataIndex: 'projectTime',
      width: 135,
      render: v => formatDateTime(v, 'YYYY-MM-DD') ?? '-',
    },
    {
      title: I18N.auto.xiangMuJianJie,
      dataIndex: 'introduction',
      width: 157,
      paragraph: { rows: 2 },
    },
    {
      title: I18N.auto.xiangMuChengGuo,
      dataIndex: 'achievement',
      width: 141,
      paragraph: { rows: 2 },
    },
    {
      title: I18N.auto.caoZuo,
      dataIndex: 'operate',
      width: 186,
      fixed: 'right',
      render: (_, record, idx) => (
        <div className={bem('fm-operate')}>
          <Button
            type="text"
            onClick={() => {
              doEditOrCopy(record)
              vars.draftIdx = idx
              show()
            }}>
            {I18N.auto.bianJi}
          </Button>
          <Button
            type="text"
            onClick={() => {
              const { id, ...restRecord } = record
              doEditOrCopy(restRecord)
              vars.draftIdx = -1
              show()
            }}>
            {I18N.auto.fuZhiXinZeng}
          </Button>
          <Button
            type="text"
            danger
            onClick={() => {
              const newValue = value?.slice()
              newValue?.splice(idx, 1)
              onChange?.(newValue)
            }}>
            {I18N.auto.shanChu}
          </Button>
        </div>
      ),
    },
  ])

  const doEditOrCopy = (record: defs.EnterpriseSuccessCaseCommand) => {
    setDraftData(record)
    fm.resetFields()
    fm.setFieldsValue(record)
  }

  const doOk = async () => {
    await fm.validateFields()
    const fmData = fm.getFieldsValue()

    if (value?.some((v, i) => i !== vars.draftIdx && v.name === fmData.name)) {
      Message.error(I18N.auto.yiCunZaiXiangTong_5)
      return
    }

    if (vars.draftIdx === -1) {
      onChange?.([...(value || []), fmData])
    } else {
      const idx = vars.draftIdx
      const newValue = value?.slice()
      newValue && idx > -1 && (newValue[idx] = fmData)!
      onChange?.(newValue)
    }

    hide()
  }

  return (
    <>
      <SupplierTable
        columns={columns}
        data={value}
        footer={() => (
          <Button
            type="text"
            icon={<Plus />}
            onClick={() => {
              setDraftData({})
              fm.resetFields()
              vars.draftIdx = -1
              show()
            }}>
            {I18N.auto.tianJiaChengGongAn}
          </Button>
        )}
      />

      <Modal
        className={bem('modal')}
        title={I18N.template(I18N.auto.chengGongAnLi_2, {
          val1: vars.draftIdx === -1 ? I18N.auto.tianJia : I18N.auto.bianJi,
        })}
        visible={visible}
        onCancel={hide}
        onOk={doOk}>
        <DataForm className={bem('fm')} form={fm}>
          <DataBlock>
            <DataFormItem name="id" hidden />
            <DataFormItem w500 label={I18N.auto.anLiMingCheng} name="name" required>
              <SupplierInput showCount={false} />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.keHuMingCheng} name="customerName" required>
              <SupplierInput showCount={false} />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.xiangMuShiJian} name="projectTime" required>
              <StringInputDatePicker />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.xiangMuGuiMoWan} name="projectCapitalAmount" required>
              <InputNumber direction="vertical" toFixed={2} />
            </DataFormItem>
            <DataFormItem w100 label={I18N.auto.xiangMuJianJie} name="introduction" required>
              <SupplierTextArea />
            </DataFormItem>
            <DataFormItem w100 label={I18N.auto.xiangMuChengGuo} name="achievement">
              <SupplierTextArea />
            </DataFormItem>
            <DataDivider />
            <DataFormItem w500 label={I18N.auto.lianXiRenXingMing} name="contactName">
              <SupplierInput showCount={false} />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.lianXiRenZhiWei} name="contactPost">
              <SupplierInput showCount={false} />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.lianXiFangShi} name="contact">
              <SupplierInput type="number" showCount={false} />
            </DataFormItem>
            <DataFormItem w100 label={I18N.auto.beiZhu} name="remark">
              <SupplierTextArea />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.keHuPingJiaTui} name="recommendationFile">
              <SupplierUpload defaultValue={draftData.recommendationFile} />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.heTongZhengMingHuo} name="projectFile">
              <SupplierUpload defaultValue={draftData.projectFile} />
            </DataFormItem>
          </DataBlock>
        </DataForm>
      </Modal>
    </>
  )
}

const InterestRelationsInfo = () => {
  return (
    <DataPanel>
      <DataBlock bodyClassName={bem('fm-dbBodyForTable')} title={I18N.auto.liYiGuanXiShen}>
        <DataFormItem disableLabel w100 name="interestRelations">
          <InterestRelationsInput />
        </DataFormItem>
      </DataBlock>
    </DataPanel>
  )
}

const InterestRelationsInput = (props: {
  value?: defs.EnterpriseInterestRelationCommand[]
  onChange?: (v?: defs.EnterpriseInterestRelationCommand[]) => void
}) => {
  const { value, onChange } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [fm] = Form.useForm()
  const vars = useRCVars({ draftIdx: -1 })

  const columns = createTableColumns<defs.EnterpriseInterestRelationCommand>([
    {
      title: I18N.auto.yuanGongXingMing,
      dataIndex: 'name',
      width: 128,
      fixed: 'left',
    },
    {
      title: I18N.auto.yuanGongSuoZaiGong,
      dataIndex: 'company',
      width: 241,
    },
    {
      title: I18N.auto.gongHao,
      dataIndex: 'jobNumber',
      width: 144,
      render: v => v ?? '-',
    },
    {
      title: I18N.auto.youXiang,
      dataIndex: 'mail',
      width: 238,
    },
    {
      title: I18N.auto.guanXi,
      dataIndex: 'relation',
      width: 202,
      render: v => v ?? '-',
    },
    {
      title: I18N.auto.beiZhu,
      dataIndex: 'remark',
      width: 171,
      paragraph: true,
    },
    {
      title: I18N.auto.caoZuo,
      dataIndex: 'operate',
      width: 115,
      fixed: 'right',
      render: (_, record, idx) => (
        <div className={bem('fm-operate')}>
          <Button
            type="text"
            onClick={() => {
              fm.resetFields()
              fm.setFieldsValue(record)
              vars.draftIdx = idx
              show()
            }}>
            {I18N.auto.bianJi}
          </Button>
          <Button
            type="text"
            danger
            onClick={() => {
              const newValue = value?.slice()
              newValue?.splice(idx, 1)
              onChange?.(newValue)
            }}>
            {I18N.auto.shanChu}
          </Button>
        </div>
      ),
    },
  ])

  const doOk = async () => {
    await fm.validateFields()
    const fmData = fm.getFieldsValue()

    if (value?.some((v, i) => i !== vars.draftIdx && v.mail === fmData.mail && v.company === fmData.company)) {
      Message.error(I18N.auto.yiCunZaiXiangTong_6)
      return
    }

    if (vars.draftIdx === -1) {
      onChange?.([...(value || []), fmData])
    } else {
      const idx = vars.draftIdx
      const newValue = value?.slice()
      newValue && idx > -1 && (newValue[idx] = fmData)
      onChange?.(newValue)
    }

    hide()
  }

  return (
    <>
      <SupplierTable
        columns={columns}
        data={value}
        footer={() => (
          <Button
            type="text"
            icon={<Plus />}
            onClick={() => {
              fm.resetFields()
              vars.draftIdx = -1
              show()
            }}>
            {I18N.auto.tianJiaLiYiGuan}
          </Button>
        )}
      />

      <Modal
        className={bem('modal')}
        title={I18N.template(I18N.auto.liYiGuanXi, {
          val1: vars.draftIdx === -1 ? I18N.auto.tianJia : I18N.auto.bianJi,
        })}
        visible={visible}
        onCancel={hide}
        onOk={doOk}>
        <DataForm className={bem('fm')} form={fm}>
          <DataBlock>
            <DataFormItem name="id" hidden />
            <DataFormItem w500 label={I18N.auto.yuanGongXingMing} name="name" required>
              <SupplierInput />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.yuanGongSuoZaiGong} name="company" required>
              <SupplierInput />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.gongHao} name="jobNumber">
              <SupplierInput />
            </DataFormItem>
            <DataFormItem
              w500
              label={I18N.auto.youXiang}
              name="mail"
              required
              rules={[{ type: 'email', message: I18N.auto.qingShuRuZhengQue_3 }]}>
              <SupplierInput />
            </DataFormItem>
            <DataFormItem w500 label={I18N.auto.guanXi} name="relation">
              <SupplierInput />
            </DataFormItem>
            <DataFormItem w100 label={I18N.auto.beiZhu} name="remark">
              <SupplierTextArea />
            </DataFormItem>
          </DataBlock>
        </DataForm>
      </Modal>
    </>
  )
}

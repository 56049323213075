import { ReactNode } from 'react'
import I18N from '@feature/i18n'
import { BEMGenerator } from '@oahz/neact-utils'
import { ELightSelect, ELightDatePicker, type QueryParams } from '@bedrock-e/common'
import { formatDateTime } from '@feature/shared'
import { AppTable, createTableColumns, AppDefaultTableHeader, NameWithPopo, DataMoney } from '@feature/views'
import { apiService, bizEnums, defs, enums } from '@/services'
import { InvoiceAttachments, InvoiceCheckStatus, PayStatus, SettlementCompanySelect } from '@/components/invoice'
import { PageContainer, PageList } from '@/components/page-view'
import CreateInvoice from './create'
import Detail from './detail'
import './style.less'

const bem = BEMGenerator('psei')

export default () => {
  const colums = ({ doRequest }: { doRequest: () => void }) =>
    createTableColumns<defs.InvoiceListDto>([
      {
        dataIndex: 'number',
        title: I18N.auto.faPiaoHaoMa,
        width: 170,
        align: 'left',
        fixed: 'left',
        paragraph: true,
      },
      {
        dataIndex: 'payStatus',
        title: I18N.auto.zhiFuZhuangTai,
        width: 111,
        render: v => (v ? <PayStatus value={v} /> : '-'),
      },
      {
        dataIndex: 'type',
        title: I18N.auto.faPiaoLeiXing,
        width: 140,
        render: v => bizEnums.StInvoiceTypeEnum.pick(v)?.name,
      },
      {
        dataIndex: 'settlementCompanyName',
        title: I18N.auto.jieSuanGongSi,
        width: 200,
        paragraph: true,
        render: v => (v ? v : '-'),
      },
      {
        dataIndex: 'supplierName',
        title: I18N.auto.gongYingShang,
        width: 160,
        paragraph: true,
        render: v => (v ? `${v}` : '-'),
      },
      {
        dataIndex: 'taxMoney',
        title: I18N.auto.jinEHanShui,
        width: 160,
        align: 'right',
        render: v => <DataMoney value={v} size="xsmall" highlight={false} showSign={false} />,
      },
      {
        dataIndex: 'tax',
        title: I18N.auto.shuiE,
        width: 160,
        align: 'right',
        render: v => <DataMoney value={v} size="xsmall" highlight={false} showSign={false} />,
      },
      {
        dataIndex: 'orderIds',
        title: I18N.auto.guanLianDanJu,
        width: 186,
        align: 'left',
        render: v => {
          return v ? v.join(',') : '-'
        },
      },
      {
        dataIndex: 'openDate',
        title: I18N.auto.kaiPiaoRiQi,
        width: 130,
        render: v => formatDateTime(v, 'YYYY-MM-DD'),
      },
      {
        dataIndex: 'expiredDate',
        title: I18N.auto.faPiaoDaoQiRi,
        width: 130,
        render: v => formatDateTime(v, 'YYYY-MM-DD'),
      },
      {
        dataIndex: 'checkingState',
        title: '验真状态',
        width: 158,
        render: v => (v ? <InvoiceCheckStatus value={v} /> : '-'),
      },

      {
        dataIndex: 'createdAt',
        title: I18N.auto.shangChuanShiJian,
        width: 200,
        render: v => formatDateTime(v, 'YYYY-MM-DD HH:mm:ss'),
      },
      {
        dataIndex: 'creator',
        title: '创建人',
        width: 158,
        paragraph: true,
        render: v => (v ? `${v.name}${v.mail ? `(${v.mail})` : ''}` : '-'),
      },
      {
        dataIndex: 'attachment',
        title: I18N.auto.fuJian,
        width: 130,
        resizable: false,
        render: v => {
          if (v) {
            v.size = undefined
            return <InvoiceAttachments attachments={[v]} />
          }
          return '-'
        },
      },
      {
        dataIndex: 'canUpdate',
        title: I18N.auto.caoZuo,
        width: 68,
        fixed: 'right',
        render: (v, record) => {
          const invoiceId = record.id
          // 采购员的发票，在供应商这边不能编辑
          return v && record.sourceParty === enums.InvoiceSourcePartyEnum.SUPPLIER_PARTNER ? (
            <CreateInvoice refresh={doRequest} editId={invoiceId} />
          ) : (
            <Detail invoiceData={record} />
          )
        },
      },
    ])

  const request = (params: Pick<QueryParams, 'orderItemList'>) => {
    // @ts-ignore
    const [expiredDateStart, expiredDateEnd] = params.expiredDate || []
    const genParmas: defs.InvoiceListQuery = {
      ...params,
      // @ts-ignore
      //状态内容待处理
      expiredDateStart,
      expiredDateEnd,
    }

    return apiService.settlementListForSupplier(genParmas).then(res => {
      return {
        records: res?.records || [],
        total: res?.total,
      }
    })
  }

  const queryItems = [
    // {
    //   name: 'settlementCompanyCode',
    //   label: '结算公司',
    //   children: <SettlementCompanySelect forELight />,
    // },
    {
      name: 'invoiceTypes',
      label: I18N.auto.faPiaoLeiXing,
      children: <ELightSelect multiple={false} options={bizEnums.StInvoiceTypeEnum.values()} />,
    },
    {
      name: 'payStatus',
      label: I18N.auto.zhiFuZhuangTai,
      children: <ELightSelect multiple={false} options={bizEnums.PayStatusEnum.values()} />,
    },
    {
      name: 'expiredDate',
      label: I18N.auto.faPiaoDaoQiRi,
      children: <ELightDatePicker range />,
    },
  ]

  //列表头部
  const renderHeader = ({ setting, doRequest }: { setting: ReactNode; doRequest: (params?: any) => void }) => (
    <AppDefaultTableHeader
      onQuery={doRequest}
      queryItems={queryItems}
      actions={
        <div className={bem('action')}>
          <CreateInvoice refresh={doRequest} />
        </div>
      }
      settings={setting}
      queryInput={{
        placeholder: '请输入发票号码/PO单号',
      }}
    />
  )

  return (
    <PageContainer className={bem()} htmlTitle={'发票'}>
      <PageList className={bem('list')}>
        <AppTable
          rowKey={'id'}
          settable={{ id: 'invoiceList' }}
          request={request}
          className={bem('table')}
          renderColumns={colums}
          renderHeader={renderHeader}
        />
      </PageList>
    </PageContainer>
  )
}

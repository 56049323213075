import { ReactNode, useState, useRef } from 'react'
import {
  ETableContextProvider,
  ETable,
  ETableFieldsConfig,
  ETableSettings,
  EFullScreen,
  ELightQuery,
  ELightSelect,
  ELightCascader,
  ELightDatePicker,
} from '@bedrock-e/common'
import type { ETableProps, ETableRef, QueryParams } from '@bedrock-e/common'
import type { ELightQueryItem, IStarsApi } from '@bedrock-e/common/lib/types/components/ELightQuery/types'
import { Checkbox, Input, Typography, ConfigProvider } from '@bedrock/components'
import type { RecordType } from '@bedrock/components/lib/Table/interfaces'
import type { TypographyProps } from '@bedrock/components/lib/Typography/interface'
import type { InputProps } from '@bedrock/components/lib/Input'
import { BEMGenerator, useRCComputed, useRCMount, useRCVars, useRCWatch } from '@oahz/neact-utils'
import { CheckboxChangeEvent } from '@bedrock/components/lib/Checkbox'
import { getLanguage, LangEnum } from '@feature/i18n'
import './style.less'

const bem = BEMGenerator('ftapp-table')

export function createTableColumns<T extends RecordType>(
  columns: (NonNullable<ETableProps<T>['columns']>[number] & { paragraph?: true | TypographyProps['ellipsis'] })[]
): ETableProps<T>['columns'] {
  return columns?.map(v => {
    const _render = v.render
    v.render = (_render ? (...args) => _render(...args) : v => v) ?? '-'

    return {
      align: 'left',
      // 这里不用ellipsis参数，单元格使用Typography.Paragraph，表头通过css控制
      ellipsis: false,
      // 默认都可以列宽调整
      resizable: true,
      ...v,
      // TODO: 全局共用的MoneyDto、日期之类的也可以这里统一展示
      ...(v.paragraph
        ? {
            render: (value, record, idx) => {
              const result = v.render ? v.render(value, record, idx) : value
              if (!result) return '-'
              return (
                <Typography.Paragraph
                  ellipsis={{
                    rows: 1,
                    showTooltip: true,
                    cssEllipsis: true,
                    ...(v.paragraph === true ? {} : v.paragraph),
                  }}>
                  {result}
                </Typography.Paragraph>
              )
            },
          }
        : {}),
    }
  })
}

export function AppTable<P extends { current?: number; limit?: number }, T extends RecordType>(
  props: Omit<ETableProps<T>, 'request' | 'beforePaginationAppend'> & {
    request?: (params: Pick<QueryParams, 'orderItemList'> & P) => Promise<{ records?: T[]; total?: number } | undefined>
    beforePaginationAppend?: (v: { doRequest: () => void }) => ReactNode
    settable?: { id: string }
    renderHeader?: (v: { setting: ReactNode; doRequest: (params?: P) => void }) => ReactNode
    renderColumns?: (v: { getRecords: () => T[]; doRequest: () => void }) => ETableProps<T>['columns']
    onBatchSelect?: (v: T[]) => void | ETableProps<T>['rowSelection']
  }
) {
  const {
    className,
    data,
    request,
    beforePaginationAppend,
    rowKey,
    columns: propColumns,
    settable,
    renderHeader,
    renderColumns,
    onBatchSelect,
    ...resetProps
  } = props
  const [batchDatas, setBatchDatas] = useState([] as T[])
  const vars = useRCVars({ rowSelectionProps: {} as ETableProps<T>['rowSelection'], records: [] as T[] })
  const sectionRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<ETableRef<T>>(null)

  // TODO: 后期添加 ETable 中的 QueryParams 中的 filters
  const queryService: NonNullable<ETableProps<T>['request']>['queryService'] = async data => {
    const { params, orderItemList, pageNo, pageSize } = data
    // ETable中只能通过queryService来获取值，这里封装再支持下可以从props.data设置，所以request可能为空
    if (!request) return { data: { list: [], total: 0 }, code: '0' }
    return request({ current: pageNo, limit: pageSize, ...params, orderItemList } as unknown as P).then(v => {
      return { data: { list: v?.records || [], total: v?.total || 0 }, code: '0' }
    })
  }

  const doRequest = (params?: P) => {
    if (!request) return

    if (params) {
      tableRef.current?.queryData(params)
    } else {
      tableRef.current?.refreshData()
    }
  }

  const columns = propColumns || renderColumns?.({ getRecords: () => vars.records, doRequest }) || []

  // 支持props.request获取数据
  useRCMount(doRequest)

  // 支持一下props.data设置数据
  useRCWatch(() => {
    if (data === undefined) return
    tableRef.current?.updateData(data)
  }, [data])

  const renderSettings = () => {
    if (!settable) return null
    return (
      <div className={bem('setting')}>
        <ETableFieldsConfig />
        <ETableSettings />
        <EFullScreen target={sectionRef} />
      </div>
    )
  }

  const renderTable = () => {
    return (
      <>
        {(renderHeader || settable) &&
          (renderHeader ? renderHeader({ setting: renderSettings(), doRequest }) : renderSettings())}
        <ETable
          className={bem('table')}
          ref={tableRef}
          request={{ queryService }}
          onDataLoad={v => (vars.records = v)}
          {...(settable ? {} : { columns })}
          rowKey={rowKey}
          {...((onBatchSelect
            ? {
                rowSelection: {
                  type: 'checkbox',
                  selectedRowKeys: batchDatas.map(v => v[rowKey as keyof T]),
                  ...vars.rowSelectionProps,
                },
                onSelectChange: v => {
                  vars.rowSelectionProps = onBatchSelect?.(v) || {}
                  setBatchDatas(v)
                },
              }
            : {}) as ETableProps<T>)}
          size="default"
          outerBorder
          emptyCellRender={() => '-'}
          scroll={{ x: true, y: 'calc(100vh - 322px)' }}
          hideOnSinglePage={false}
          pagination={{ size: 'default', pageSize: 100, pageSizeOptions: [100, 200, 300] }}
          beforePaginationAppend={beforePaginationAppend?.({ doRequest })}
          {...resetProps}
        />
      </>
    )
  }

  return (
    <ConfigProvider locale={getLanguage() === 'fr-FR' ? LangEnum['en-US'] : getLanguage()}>
      <section className={bem('full-container')}>
        <section className={bem([className])} ref={sectionRef}>
          {settable ? (
            <ETableContextProvider
              dataPath={settable.id}
              defaultTableSettings={{ oddColor: false, size: 'medium' }}
              resizable
              columns={columns}>
              {renderTable()}
            </ETableContextProvider>
          ) : (
            renderTable()
          )}
        </section>
      </section>
    </ConfigProvider>
  )
}

// TODO：因为列表的头部UI是有特点固定的，所以这里写一个默认的
export function AppDefaultTableHeader<T>(props: {
  actions?: ReactNode
  queryInput?: InputProps
  queryItems?: ELightQueryItem[]
  checkProps?: { label: string; key: string } | { label: string; key: string }[]
  onQuery?: (v: T) => void
  settings?: ReactNode
}) {
  const { actions, queryInput, queryItems, checkProps, onQuery, settings } = props
  const vars = useRCVars({ params: {} as T })

  useRCWatch(() => {
    if (queryInput?.defaultValue) {
      onQuery?.((vars.params = { ...vars.params, keyword: queryInput?.defaultValue }))
    }
  })

  return (
    <div className={bem('header')}>
      <div className={bem('header-row')}>
        <Input.Search
          allowOnSearchByDel={false}
          className={bem('header-keyword')}
          {...queryInput}
          onSearch={v => onQuery?.((vars.params = { ...vars.params, keyword: v }))}
        />
        {actions}
      </div>
      <div className={bem('header-row')}>
        {queryItems && (
          <AppQueryForm<T>
            checkProps={checkProps}
            items={queryItems}
            onChange={v => onQuery?.((vars.params = { ...vars.params, ...v }))}
          />
        )}
        {settings}
      </div>
    </div>
  )
}

// [
//   {
//     label: '性别',
//     name: 'sex',
//     children: <ELightSelect options={bizEnums.PurposeEnum.values()} />,
//   },
//   {
//     label: '分类',
//     name: 'cate',
//     children: (
//       <ELightCascader
//         options={[
//           {
//             label: '1',
//             value: '1',
//           },
//           {
//             label: '2',
//             value: '2',
//             children: [
//               {
//                 label: '2-1',
//                 value: '2-1',
//               },
//             ],
//           },
//         ]}
//       />
//     ),
//   },
//   {
//     label: '生日',
//     name: 'birthday',
//     children: <ELightDatePicker range />,
//   },
// ]
const genCheckValues = (arr: { label: string; key: string }[]) => {
  return (arr || [])
    .map(v => ({ [v?.key!]: false }))
    .reduce((acc, cur) => {
      Object.keys(cur).forEach(key => {
        acc[key] = cur[key]
      })
      return acc
    }, {})
}
// 构建多个check筛选项的value数据
export function AppQueryForm<T>(props: {
  checkProps?: { label: string; key: string } | { label: string; key: string }[]
  items: ELightQueryItem[]
  onChange?: (v: T) => void
}) {
  const { items: propItems, onChange, checkProps } = props
  const [queryVal, setQueryVal] = useState<any>()
  // 构建多个check的情况的数据
  const genCheckProps = Array.isArray(checkProps) ? checkProps : checkProps ? [checkProps] : undefined
  // 多个check筛选项的value数据
  const [checkVal, setCheckVal] = useState<Record<string, boolean | undefined>>(genCheckValues(genCheckProps || []))

  const items = useRCComputed(() => propItems.map(v => ({ isDefault: true, ...v })), [propItems])
  const checkChange = (e: CheckboxChangeEvent, key: string) => {
    setCheckVal(prev => ({ ...prev, [key]: e.target.checked }))
    const params = { ...queryVal, [key]: e.target.checked }
    onChange?.(params)
    setQueryVal(params)
  }
  const queryChange: (changeValue: any, values: any) => void = (_, v) => {
    console.log('v-v', v)
    const doReset = Object.values(v).every(value => value === undefined)
    // 所有的check的筛选都置为空
    const checkVal = genCheckValues(genCheckProps || [])
    const params = doReset ? { ...v, ...checkVal } : v
    onChange?.(params)
    setQueryVal(params)
    doReset && setCheckVal(checkVal)
  }

  // const starsApi: IStarsApi = async (get: () => Promise<any>, save: () => Promise<any>) => {
  //   const getVal = await get()
  //   console.log('getVal', getVal)
  // }

  return genCheckProps ? (
    <div className={bem('query-container')}>
      {(genCheckProps || []).map((v, i) => (
        <AppQueryCheckbox
          key={v?.key!}
          label={v?.label!}
          value={checkVal[v?.key]}
          onChange={e => checkChange(e, v?.key!)}
        />
      ))}
      <ELightQuery
        className={bem('query')}
        name={'app-query'}
        items={items}
        onValuesChange={queryChange}
        // starsApi={starsApi}
      />
    </div>
  ) : (
    <ELightQuery className={bem('query')} name={'app-query'} items={items} onValuesChange={queryChange} />
  )
}

export const AppQuerySelect = ELightSelect
// TODO: cascarder目前有bug，待修复新版本上使用
export const AppQueryCascader = ELightCascader
export const AppQueryDatePicker = ELightDatePicker

// TODO：封装一个Checkbox的查询条件，参考UX，需要支持：value & onChange
export const AppQueryCheckbox = (props: {
  label: string
  value?: boolean
  onChange: (v: CheckboxChangeEvent) => void
}) => {
  const { label, value, ...rest } = props
  return (
    <div className={bem('query-checkbox')}>
      <span className={bem('query-checkbox-label')}>{`${label}:`}</span>
      <Checkbox checked={value} {...rest} />
    </div>
  )
}

// TODO: 具体多出使用的QueryItem封装，注入是为了内置Select的，注意：value & onChange 的透传
// TODO: Cascader也是照例这样封装一个具体带有数据的
export const AppQueryPrCategory = (props: Parameters<typeof ELightSelect>[0]) => {
  return <ELightSelect {...props} />
}

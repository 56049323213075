import { FC } from 'react'
import { BEMGenerator } from '@oahz/neact-utils'
import './index.less'

const bem = BEMGenerator('ct')

export const Tag: FC<{ status: any; statusMap: Record<string | number, any>; className?: string }> = ({
  status,
  className,
  statusMap,
}) => {
  if (!statusMap[status]) return null
  const target = statusMap[status]
  return (
    <div
      className={bem([className])}
      style={{
        color: target.textColor,
        background: target.bgColor,
      }}>
      {target.name}
    </div>
  )
}

export const SpecialTag: FC<{ name: string; background: string }> = ({ name, background }) => {
  return (
    <div
      className={bem()}
      style={{
        color: '#FFF',
        background: background,
      }}>
      {name}
    </div>
  )
}

export { TagEnumMap } from './consts'
export default Tag

import { Fragment, ReactNode } from 'react'
import ppIntl from '@pp-i18n/intl'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'
import 'dayjs/locale/fr'

import { getBrowserLanguage } from '@oahz/neact-utils'
import enUsLangs from '../locales/en-US'
import zhCNLangs from '../locales/zh-CN'
import jaJPLangs from '../locales/ja-JP'
import frFRLangs from '../locales/fr-FR'

export enum LangEnum {
  'zh-CN' = 'zh-CN',
  'en-US' = 'en-US',
  'ja-JP' = 'ja-JP',
  'fr-FR' = 'fr-FR',
}

export enum ServerEnum {
  'zh' = 'zh-CN',
  'en' = 'en-US',
  'ja' = 'ja-JP',
  'fr' = 'fr-FR',
}

let cacheLang = ''
export const getLanguage = () => {
  if (cacheLang) return cacheLang as LangEnum

  const urlLang = new URL(window.location.href).searchParams.get('lang')
  if (Object.keys(LangEnum).includes(urlLang as string)) {
    return (cacheLang = urlLang!) as LangEnum
  }

  const cookieLang = (document.cookie.match(/ntes-purchase-locale=([^;$]+)/) || [
    null,
    ServerEnum[getBrowserLanguage() as 'zh'],
  ])[1]
  const lang = (cookieLang as string).split(' ')[0]
  return (cacheLang = lang) as LangEnum
}

enum LangObjectEnum {
  'zh-CN' = 'zh',
  'en-US' = 'en',
  'ja-JP' = 'ja',
  'fr-FR' = 'fr',
}
export function translate<T = string>(langObject?: Partial<Record<LangObjectEnum, T>>) {
  if (!langObject) return ''

  const lang = getLanguage()

  if (lang === LangEnum['en-US']) return langObject.en || langObject.zh || langObject.ja
  if (lang === LangEnum['ja-JP']) return langObject.ja || langObject.en || langObject.zh
  if (lang === LangEnum['fr-FR']) return langObject.fr || langObject.en || langObject.zh

  return langObject.zh || langObject.en || langObject.ja || langObject.fr
}

export const getLanguageSymbol = () => {
  return translate({ zh: 'zh', en: 'en', ja: 'ja', fr: 'fr' })
}

export const LANG_SYMBOL = getLanguageSymbol()

const init = () => {
  // 从 Cookie 中取语言值, 默认为 zh-CN
  const lang = getLanguage()

  const dayjsLocale = {
    'en-US': 'en',
    'zh-CN': 'zh-cn',
    'ja-JP': 'ja',
    'fr-FR': 'fr',
  }
  dayjs.locale(dayjsLocale[lang])

  const langs = {
    'en-US': enUsLangs,
    'zh-CN': zhCNLangs,
    'ja-JP': jaJPLangs,
    'fr-FR': frFRLangs,
  }
  return ppIntl.init(lang, langs)
}

const I18N = init()

// 支持动态node节点的占位符替换操作 需要将当前文件名改成tsx
export const templateNode = (str: string, args: Record<string, ReactNode>) => {
  const arr: ReactNode[] = []
  str.split(/\{(.+?)\}/).forEach((item, index) => {
    if (index % 2 !== 0) {
      if (args[item]) {
        arr.push(args[item])
      }
    } else {
      if (item !== '') {
        arr.push(item.replace(/\s/g, '\u00A0'))
      }
    }
  })
  return arr.map((item, index) => <Fragment key={index}>{item}</Fragment>)
}

Object.assign(I18N, { templateNode })

export default I18N as typeof I18N & { templateNode: typeof templateNode }

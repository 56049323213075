import { useManualService } from '@oahz/neact'
import { BEMGenerator } from '@oahz/neact-utils'
import { ELightDatePicker } from '@bedrock-e/common'
import I18N from '@feature/i18n'
import { apiService } from '@/services'
import { QuoteListItemView } from '@/components/workbench/quote'
import { DiyTable } from '@/components/workbench'
import './quote.less'

const bem = BEMGenerator('pwbq')

export default () => {
  const { data, loading, request } = useManualService(apiService.quotationWorkbenchQueryQuotationList)

  const queryItems = [
    {
      name: 'deadline',
      label: '报价截止时间',
      children: <ELightDatePicker range />,
    },
  ]

  const onTableChange = (queryParams: any) => {
    const params = {
      current: queryParams.current,
      limit: queryParams.limit,
      deadlineStart: queryParams?.deadline?.[0],
      deadlineEnd: queryParams?.deadline?.[1],
      keyword: queryParams.keyword,
    }
    request?.(params)
  }

  return (
    <section className={bem()}>
      <DiyTable
        queryItems={queryItems}
        total={data?.total}
        request={onTableChange}
        loading={loading}
        placeholder="请输入询价单号/商品名称">
        {data?.records?.map(item => {
          return <QuoteListItemView key={item.id} data={item} />
        })}
      </DiyTable>
    </section>
  )
}

import { useState } from 'react'
import { Button, Form, Modal, Input, Switch, Select, Popconfirm, Tag, Message } from '@bedrock/components'
import { BEMGenerator, useBoolean, useRCComputed } from '@oahz/neact-utils'

import { AppTable, createTableColumns } from '@feature/views'
import { useManualService } from '@oahz/neact'
import { Plus } from '@bedrock/icons-react'
import I18N, { translate } from '@feature/i18n'
import { forEach, has } from 'lodash'
import { DataForm, DataFormItem } from '@/components/data-view'
import { rsaEncrypt } from '@/shared'
import { apiService, bizEnums, type defs } from '@/services'
import './style.less'

const bem = BEMGenerator('pwd-ca')

const EditButton = (props: { data: defs.IdAccountListDto; refresh?: () => void }) => {
  const { refresh, data } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [fm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  if (!data?.id) return <></>

  const onClose = () => {
    fm.resetFields()
    hide()
  }

  const onSubmit = async (id: number) => {
    try {
      setLoading(true)
      await fm.validateFields()
      const formVal = await fm.getFieldsValue()
      const params = {
        name: formVal.name,
        tel: formVal.tel,
        mail: formVal.mail,
        status: formVal.status ? '1' : '0',
      }
      await apiService.identityEnterpriseAccountUpdate({ accountId: id }, params)
      refresh?.()
      onClose()
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button style={{ marginRight: '20px' }} onClick={show} type="text">
        编辑
      </Button>
      <Modal
        width={500}
        title={'编辑账号'}
        visible={visible}
        onCancel={onClose}
        onOk={() => {
          data.id && onSubmit(data.id)
        }}
        okButtonProps={{ loading }}>
        <Form
          form={fm}
          layout="vertical"
          className={bem('form')}
          initialValues={{ ...data, status: data.status === bizEnums.AccountStatusEnum.ENABLED }}>
          <Form.Item name={'status'} label={'账号ID'}>
            <div>{data.id}</div>
          </Form.Item>
          <Form.Item name={'name'} label={'用户名'} required rules={[{ required: true, message: '请输入用户名' }]}>
            <Input />
          </Form.Item>
          <Form.Item name={'tel'} label={'手机号'} required rules={[{ required: true, message: '请输入手机号' }]}>
            <Input type="number" maxLength={200} />
          </Form.Item>
          <Form.Item
            name={'mail'}
            label={'邮箱'}
            required
            rules={[{ required: true, message: '请输入邮箱', type: 'email' }]}>
            <Input />
          </Form.Item>
          <Form.Item name={'status'} label={'状态'} required>
            <Switch labelOn="启用" labelOff="禁用" disabled={data.type === bizEnums.AccountTypeEnum.MAIN} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const CreatButton = (props: { refresh?: () => void }) => {
  const { refresh } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [fm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [hasPwdField, setHasPwdField] = useState(true)

  const onClose = () => {
    fm.resetFields()
    hide()
  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      await fm.validateFields()
      const { password, status, ...restFmData } = await fm.getFieldsValue()
      const params = {
        ...restFmData,
        ...(hasPwdField
          ? {
              password: rsaEncrypt(password),
            }
          : {}),
        status: status ? '1' : '0',
      }
      await apiService.identityEnterpriseAccountAdd(params)
      refresh?.()
      onClose()
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button style={{ marginBottom: '20px' }} onClick={show} size={'small'} type="primary" icon={<Plus />}>
        新建账号
      </Button>
      <Modal
        width={500}
        title={'新建账号'}
        visible={visible}
        onCancel={onClose}
        onOk={onSubmit}
        okButtonProps={{ loading }}>
        <Form form={fm} layout="vertical" className={bem('form')}>
          <Form.Item name={'name'} label={'用户名'} required rules={[{ required: true, message: '请输入用户名' }]}>
            <Input />
          </Form.Item>
          <Form.Item name={'tel'} label={'手机号'} required rules={[{ required: true, message: '请输入手机号' }]}>
            <Input type="number" maxLength={200} />
          </Form.Item>
          <Form.Item
            name={'mail'}
            label={'邮箱'}
            required
            rules={[{ required: true, message: '请输入邮箱', type: 'email' }]}>
            <Input
              onBlur={async () => {
                const { mail } = fm.getFieldsValue()
                if (mail && (await apiService.identityEnterpriseAccountExistSameMail({ mail }))) {
                  setHasPwdField(false)
                } else {
                  setHasPwdField(true)
                }
              }}
            />
          </Form.Item>
          {hasPwdField && (
            <Form.Item
              name={'password'}
              label={'密码'}
              required
              rules={[
                {
                  required: true,
                  validateTrigger: 'blur',
                  validator: (_, value) => {
                    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{9,15}$/
                    if (!value) {
                      return Promise.reject('请输入密码')
                    }
                    if (!regex.test(value)) {
                      return Promise.reject('请输入9-15位的大小写字母、数字、特殊字符')
                    }
                    return Promise.resolve()
                  },
                },
              ]}>
              <Input.Password trimOnBlur minLength={9} maxLength={15} />
            </Form.Item>
          )}
          <Form.Item name={'status'} label={'状态'} required initialValue={true}>
            <Switch labelOn="启用" labelOff="禁用" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const Transfer = (props: { id: number; refresh?: () => void; hasBelongSupplier?: boolean }) => {
  const { refresh, id } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [fm] = Form.useForm()
  const { data, loading, request } = useManualService(apiService.identityEnterpriseAccountList)
  const onClose = () => {
    hide()
    fm.resetFields()
  }

  const onSubmit = async () => {
    await fm.validateFields()
    const formData = fm.getFieldsValue()
    await apiService.identityEnterpriseAccountTransfer({
      targetAccountId: formData.targetAccountId,
      sourceAccountId: id,
    })
    Message.success('转交成功')
    onClose()
    refresh?.()
  }

  const options = useRCComputed(() => {
    const _options: any[] = []
    forEach(data?.records, item => {
      if (item.type === bizEnums.AccountTypeEnum.SUB) {
        _options.push({
          ...item,
          value: item.id,
          name: item.mail,
          disabled: item.status === bizEnums.AccountStatusEnum.DISABLED,
        })
      }
    })
    return _options
  }, [data])

  const openModal = () => {
    request({ current: 1, limit: 999 })
    show()
  }
  //TODO option类型待处理ts
  return (
    <>
      {console.log(data, 'data')}
      <Button type="text" onClick={openModal}>
        {I18N.auto.zhuanJiao}
      </Button>
      <Modal width={500} title={I18N.auto.zhuanJiao} visible={visible} onCancel={onClose} onOk={onSubmit}>
        <DataForm form={fm} className={bem('content')}>
          <DataFormItem w100 name={'targetAccountId'} label={I18N.auto.zhuanJiaoYouXiang} required>
            <Select showSearch="inner" options={options || []} />
          </DataFormItem>
        </DataForm>
      </Modal>
    </>
  )
}

const TableAction = (props: { record?: defs.IdAccountListDto; refresh?: () => void }) => {
  const { record = {}, refresh } = props
  const { status, id, type } = record
  if (!id) return <></>

  const isDisabled = status === bizEnums.AccountStatusEnum.DISABLED

  const onChangeStatus = async (accountId: number) => {
    const api = isDisabled ? apiService.identityEnterpriseAccountEnable : apiService.identityEnterpriseAccountDisable
    await api({ accountId })
    Message.success(isDisabled ? '启用成功' : '禁用成功')
    refresh?.()
  }

  return (
    <div className={bem('action')}>
      <EditButton refresh={refresh} data={record} />
      {type === bizEnums.AccountTypeEnum.MAIN ? (
        id && <Transfer id={id} refresh={refresh} />
      ) : (
        <Popconfirm
          overlayClassName={bem('action-status')}
          title={isDisabled ? '确定启用吗？' : '确定禁用吗？'}
          onOk={() => onChangeStatus(id)}>
          <Button type="text" danger={!isDisabled} className={bem('action-btn')}>
            {isDisabled ? I18N.auto.qiYong : I18N.auto.jinYong}
          </Button>
        </Popconfirm>
      )}
    </div>
  )
}

export default () => {
  const columns = ({ doRequest }: { doRequest: () => void }) =>
    createTableColumns<defs.IdAccountListDto>([
      {
        dataIndex: 'name',
        title: '用户名',
        width: 186,
        align: 'left',
      },
      {
        dataIndex: 'id',
        title: '账号',
        width: 186,
        align: 'left',
      },
      {
        dataIndex: 'type',
        title: I18N.auto.leiXing,
        width: 120,
        align: 'left',
        render: v => v && bizEnums.AccountTypeEnum.pick(v)?.name,
      },
      {
        dataIndex: 'mail',
        title: I18N.auto.youXiang,
        width: 262,
        align: 'left',
        ellipsis: true,
      },
      {
        dataIndex: 'tel',
        title: '手机号',
        width: 156,
        align: 'left',
        ellipsis: true,
      },
      {
        dataIndex: 'status',
        title: I18N.auto.zhuangTai,
        width: translate({ zh: 84, en: 140, ja: 140, fr: 155 }),
        align: 'left',
        render: v => (
          <Tag
            color={v === bizEnums.AccountStatusEnum.DISABLED ? 'light-grey' : 'light-green'}
            text={bizEnums.AccountStatusEnum.pick(v)?.name}
          />
        ),
      },
      {
        title: I18N.auto.caoZuo,
        width: translate({ zh: 150, en: 200, ja: 150, fr: 230 }),
        align: 'left',
        fixed: 'right',
        render: (_, record) => <TableAction record={record} refresh={doRequest} />,
      },
    ])

  const renderHeader = ({ doRequest }: { doRequest: () => void }) => <CreatButton refresh={doRequest} />

  return (
    <div>
      <AppTable
        renderColumns={columns}
        renderHeader={renderHeader}
        request={apiService.identityEnterpriseAccountList}
        hideOnSinglePage
      />
    </div>
  )
}

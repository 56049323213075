import { useInViewport } from 'ahooks'
import I18N from '@feature/i18n'
import { BEMGenerator, useRCWatch, useRefState } from '@oahz/neact-utils'
import { Select, Spin } from '@bedrock/components'
import { SelectProps } from '@bedrock/components/es/Select'
import './style.less'

const bem = BEMGenerator('rls')

export const RollingLoadSelect = (props: SelectProps & { hasMore?: boolean; request: any }) => {
  const { hasMore = false, request, options, renderLabel, dropdownClassName, ...reset } = props
  const [ref, setRef] = useRefState<any>(null)
  const [inViewport] = useInViewport(ref)

  useRCWatch(() => {
    if (hasMore && inViewport) {
      request()
    }
  }, [hasMore, inViewport])

  return (
    <Select
      {...reset}
      dropdownClassName={bem('dd', { load: hasMore }, [dropdownClassName])}
      options={
        !options?.length
          ? []
          : options?.length && hasMore
          ? (options || [])?.concat([{ value: 'loading', name: I18N.auto.jiaZaiZhong }])
          : options
      }
      renderLabel={v =>
        !renderLabel ? undefined : v.value === 'loading' ? (
          <div id={bem('load-view')} ref={v => setRef(v)} className={bem('load-view')}>
            <Spin size={'small'} />
          </div>
        ) : (
          renderLabel(v)
        )
      }
    />
  )
}

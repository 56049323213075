import { BEMGenerator } from '@oahz/neact-utils'
import { useSelector } from '@/store'
import { bizEnums } from '@/services'
import { EnterpriseHomePage } from './homepage'
import { OnboundSubmitPage } from './onbound-submit'
import { OnboundShowPage } from './onbound-show'
import './style.less'

const bem = BEMGenerator('pei')

export default () => {
  const { supplierInfo } = useSelector(state => state.account)

  if (supplierInfo?.onboardProgress === bizEnums.SpOnboardProgressEnum.SUCCEED) {
    return <EnterpriseHomePage />
  }

  if (supplierInfo?.onboardProgress === bizEnums.SpOnboardProgressEnum.NOT_BEGIN) {
    return <OnboundSubmitPage />
  }

  return <OnboundShowPage />
}

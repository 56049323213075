import type { ReactNode } from 'react'
import { useRoute } from '@oahz/neact'
import { BEMGenerator, useRCVars } from '@oahz/neact-utils'
import { ELightDatePicker } from '@bedrock-e/common'
import type { QueryParams } from '@bedrock-e/common'
import I18N from '@feature/i18n'
import { AppTable, createTableColumns, AppDefaultTableHeader } from '@feature/views'
import { formatDateTime } from '@feature/shared'
import { PageContainer, PageList } from '@/components/page-view'
import { apiService, type defs } from '@/services'
import { DeliveryPageLink, QueryCreatorIds } from '@/components/order'
import { useSelector } from '@/store'
import './style.less'

const bem = BEMGenerator('pdl')

export default () => {
  const { query } = useRoute()
  const vars = useRCVars({ keyword: query.keyword as string })
  const { userInfo } = useSelector(state => state.account)

  const colums = createTableColumns<defs.FmFulfillmentListDto>([
    {
      dataIndex: 'id',
      title: 'ASN单号',
      width: 200,
      align: 'left',
      fixed: 'left',
      render: v => <DeliveryPageLink deliveryId={v} />,
    },
    {
      dataIndex: 'receiver',
      title: I18N.auto.shouHuoRen,
      width: 110,
      align: 'left',
      render: v => v || '-',
    },
    {
      dataIndex: 'contactWay',
      title: I18N.auto.shouHuoDianHua,
      width: 170,
      align: 'left',
      render: v => v || '-',
    },
    {
      dataIndex: 'address',
      title: I18N.auto.shouHuoDiZhi,
      width: 170,
      align: 'left',
    },
    {
      dataIndex: 'trackingNo',
      title: I18N.auto.wuLiuDanHao,
      width: 245,
      align: 'left',
      render: v => v || '-',
      // record.courierNumber ? <DataLink name={record.courierNumber} href={record.courierUrl} /> : '-',
    },
    {
      dataIndex: 'deliveryTime',
      title: I18N.auto.faHuoRiQi,
      width: 160,
      align: 'left',
      render: v => formatDateTime(v, 'YYYY-MM-DD'),
    },
    {
      dataIndex: 'estimateArrivalTime',
      title: '预计到货时间',
      width: 200,
      align: 'left',
      render: v => formatDateTime(v, 'YYYY-MM-DD HH:mm:ss'),
    },
    //本期不要
    // {
    //   dataIndex: 'createdAt',
    //   title: '妥投/签收时间',
    //   width: 167,
    //   align: 'left',
    //   render: v => formatDateTime(v, 'YYYY-MM-DD HH:mm:ss'),
    // },
    {
      dataIndex: 'settlementCompanyName',
      title: I18N.auto.jieSuanGongSi,
      width: 200,
      align: 'left',
    },
    {
      dataIndex: 'creator',
      title: I18N.auto.chuangJianRen,
      width: 100,
      align: 'left',
      render: v => v?.name || '-',
    },
    {
      dataIndex: 'createdAt',
      title: I18N.auto.chuangJianShiJian,
      width: 200,
      align: 'left',
      render: v => formatDateTime(v, 'YYYY-MM-DD HH:mm:ss'),
    },
  ])

  const queryItems = [
    {
      name: 'creatorIds',
      label: I18N.auto.chuangJianRen,
      children: <QueryCreatorIds />,
    },
    {
      name: 'deliveryTime',
      label: I18N.auto.faHuoShiJian,
      children: <ELightDatePicker range />,
    },
  ]

  const request = (params: Pick<QueryParams, 'params'>) => {
    const [deliveryTimeStart, deliveryTimeEnd] = params.deliveryTime || []
    const genParmas: defs.FmFulfillmentListQuery = {
      ...params,
      // @ts-ignore
      orderTime: undefined,
      deliveryTimeStart: deliveryTimeStart,
      deliveryTimeEnd: deliveryTimeEnd,
    }

    return apiService.fmFmFulfillmentList(genParmas)
  }

  const renderHeader = ({ setting, doRequest }: { setting: ReactNode; doRequest: (params: any) => void }) => (
    <AppDefaultTableHeader
      onQuery={doRequest}
      queryItems={queryItems}
      actions={<div />}
      settings={setting}
      queryInput={{
        defaultValue: vars.keyword,
        placeholder: '请输入ASN单号',
      }}
    />
  )

  return (
    <PageContainer className={bem()} htmlTitle={'履约列表'}>
      <PageList className={bem('list')}>
        <AppTable
          rowKey={(_, i) => i!}
          request={request}
          columns={colums}
          settable={{ id: 'deliveryList' }}
          className={bem('table')}
          renderHeader={renderHeader}
        />
      </PageList>
    </PageContainer>
  )
}

import { BEMGenerator, useRCComputed } from '@oahz/neact-utils'
import { useService } from '@oahz/neact'
import { Message } from '@bedrock/components'
import I18N from '@feature/i18n'
import { MainContainer, PageContainer, PageLoading } from '@/components/page-view'
import { DataBlock, DataFormItem, DataPanel } from '@/components/data-view'
import {
  SupplierContentFormItems,
  SupplierForm,
  SupplierFormActions,
  SupplierHeader,
  SupplierTextArea,
  convertDetailDtoToFormFieldsValue,
  convertFormFieldsValueToSubmitCommand,
  isChinaEnterprise,
  isChinaPerson,
  isOverseaEnterprise,
  isOverseaPerson,
  pickHeaderDataFromDetailDto,
  useSupplierForm,
} from '@/components/supplier'
import { useDispatch, useSelector } from '@/store'
import { apiService, bizService } from '@/services'
import './style.less'

const bem = BEMGenerator('peinup')

export default () => {
  const { supplierInfo } = useSelector(state => state.account)
  const { refreshSupplierInfo } = useDispatch(dispatch => dispatch.account)
  const { data, loading } = useService(() => bizService.supplierQuerySupplierDetail({ subSet: supplierInfo.subSet }))
  const { fm, canSubmit, onValuesChange, replacePage } = useSupplierForm()

  const doSubmit = async () => {
    if (!data) return

    await fm.validateFields()
    const fmData = fm.getFieldsValue()

    const mdata = { id: supplierInfo.id, ...convertFormFieldsValueToSubmitCommand(fmData) }

    if (isChinaEnterprise(data)) {
      await apiService.identityEnterpriseBoardedModifyChinaEnterprise(mdata)
    } else if (isChinaPerson(data)) {
      await apiService.identityEnterpriseBoardedModifyChinaPerson(mdata)
    } else if (isOverseaEnterprise(data)) {
      await apiService.identityEnterpriseBoardedModifyOverseaEnterprise(mdata)
    } else if (isOverseaPerson(data)) {
      await apiService.identityEnterpriseBoardedModifyOverseaPerson(mdata)
    }

    // 刷新最新的供应商基础信息，如其applicationId发送的变化
    await refreshSupplierInfo()

    Message.success(I18N.auto.tiJiaoChengGong)
    // 重新进入供应商主页
    replacePage(`/enterprise/info-changes`)
  }

  const initialValue = useRCComputed(() => {
    return data ? convertDetailDtoToFormFieldsValue(data) : {}
  }, [data])

  const headerData = useRCComputed(() => (data ? pickHeaderDataFromDetailDto(data) : {}), [data])

  if (!data) return loading ? <PageLoading /> : null

  return (
    <PageContainer className={bem()} htmlTitle={data?.id}>
      <SupplierHeader
        data={headerData}
        action={<SupplierFormActions fm={fm} onSubmit={doSubmit} submitDisabled={!canSubmit} />}
      />

      <MainContainer w1280 className={bem('main')}>
        <SupplierForm form={fm} initialValues={initialValue} onValuesChange={onValuesChange}>
          <ChangeInfo />
          <SupplierContentFormItems defaultValue={data} />
        </SupplierForm>
      </MainContainer>
    </PageContainer>
  )
}

const ChangeInfo = () => {
  return (
    <DataPanel>
      <DataBlock title={I18N.auto.bianGengXinXi}>
        <DataFormItem w100 label={I18N.auto.bianGengShuoMing} name="remark">
          <SupplierTextArea />
        </DataFormItem>
      </DataBlock>
    </DataPanel>
  )
}

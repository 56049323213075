import { apiService, bizService } from '@/services'

class AccountService {
  // 本地开发时的用户登录方式，DEV_USER_ID取自本地开发环境变量
  devLogin = () => {
    // return apiService.identityDevelopLogin({ mail: 'shenlisong@corp.netease.com' })
    // return apiService.identityDevelopLogin({ mail: 'chensiqing@corp.netease.com' })
    // return apiService.identityDevelopLogin({ mail: 'wb.zhoulu@mesg.corp.netease.com' })
    // return apiService.identityDevelopLogin({ mail: 'zhoulu@oc.netease.com' })
    return apiService.identityDevelopLogin({ mail: 'wb.wuhuaqiao@mesg.corp.netease.com' })
    // return apiService.identityDevelopLogin({ mail: 'zhanghaotian03@corp.netease.com' })
    // return apiService.identityDevelopLogin({ mail: 'taihe@oc.com' })
  }

  getSupplierInfo = () => {
    return apiService.identityEnterpriseBoardedQuerySupplierDetail()
  }

  getAccountInfo = async () => {
    // 本地开始时，自动登录一下
    if (process.env.NODE_ENV === 'development') {
      await this.devLogin()
    }

    const userInfo = await apiService.identityAuthenticationGetCurrentUser({ msgable: false })
    const supplierInfo = await apiService.identityEnterpriseBoardedQuerySupplierDetail({ msgable: false })

    // 初始化业务接口服务
    await bizService.bootstrap()

    return { userInfo, supplierInfo }
  }
}

export default new AccountService()

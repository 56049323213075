export default {
  jiZhunJia: 'Standard Price',
  weiShuiJinE: 'Amount(Tax Excl.)',
  keFaHuoJinE_2: 'Shipable Amount(Tax Excl.)',
  keFaHuoJinE_3: 'Shipable Amount(Tax Incl.)',
  benCiFaHuoJin_2: 'Shipping Amount(Tax Excl.)',
  benCiFaHuoJin_3: 'Shipping Amount(Tax Incl.)',
  xuQiuJiZhunJia: 'Standard Price of PR',
  dingBiaoZongEWei: 'Award Amount (Tax Excl.)',
  dingBiaoZongEHan: 'Award Amount (Tax Incl.)',
  daiYanShouJinE_3: 'Pending Receipt Amount(Tax Excl.)',
  daiYanShouJinE_4: 'Pending Receipt Amount(TaxIncl.)',
  benCiYanShouJin_4: 'Receiving Amount (Tax Excl.)',
  benCiYanShouJin_5: 'Receiving Amount (Tax Incl.)',
  weiShui_2: '(Tax Excl.)',
  hanShui_2: '(Tax Incl.)',
  benCiYanShouJin_6: 'Receiving Amount(Tax Excl.):',
  weiShui_3: '{val1}(Tax Excl.)',
  hanShui_3: '{val1}(Tax Incl.)',
  xuQiuDanXiaHai: 'Below items can be received together',
  xiaDanJinEWei: 'Ordered Amount(Tax Excl.)',
  xiaDanJinEHan: 'Ordered Amount(Tax Incl.)',
  daiKaiBiao: 'Pending Award',
  yanShouJinEWei: 'Receipt Amount(Tax Excl.)',
  hanShuiDanJia: 'Unit Price(Tax Incl.)',
  guanLianZongJinE_2: 'Linked total amount(Tax Incl.)',
  shengYuKeKaiPiao_2: 'Pending invoice amount(Tax Incl.)',
  guanLianJinEHan: 'Linked amount(Tax Incl.)',
  buTongJiZhunJia: 'Can not link with different Standard Price',
  oaLineNoBuYiZhiBuKe: 'Can not create for dfiferent OA source',
  pingFenManFenYiBai: '得点（満点100点）',
  queDingYaoFangQi: '評価を辞めますか？',
  fangQiHouBuKe: '続行すると、再度評価できなくなります。',
  wuLiHaiGuanXi: '無利害関係の誓約書',
  zhaoBiaoPingShiXiao: '「（入札評価チームの誓約書）」',
  benRen: 'NetEaseグループの従業員  ',
  zuoWeiWangYiJi: 'は、',
  biaoShuBianHaoZhao: '入札書類番号【{val1}】、プロジェクト【{val2}】関連の',
  zhaoTouBiaoXiaoZu: '入札募集チームのメンバーとして、以下の事項を厳守することを誓約いたします。',
  yiZunShouZhongHua: '１．『中華人民共和国政府調達法』の関連規定を遵守し、本プロジェクトの入札者と',
  wuLiHaiGuanXi_2: '利害関係がないことを誓います。',
  erZaiPingShenGuo: '２．審査過程で、',
  zhongHuaRenMinGong_6: '『中華人民共和国の入札募集・入札法』、',
  zhongHuaRenMinGong_7: '『中華人民共和国政府調達法』',
  jiYouGuanFaLu:
    'および関連法令に厳守し、公平・公正・科学性を確保したうえ、最良の入札者を選択する原則を堅持し、客観的かつ公正に職務を果たし、職業倫理を遵守し、労働規律と秘密保持規定を守り、審査業務を確実に遂行することを誓います。',
  yYYYNian: 'YYYY年M月D日',
  queRenQianShu: '署名する',
  fangQiPingGu: '評価を辞める',
  zaiCiFaSong: '再送信',
  zaiCiFaSong_2: '再送信（{val1}）',
  qingShuRuKaiBiao: '開札パスワードを入力してください',
  queRenKaiBiao: '開札する',
  weiShouDaoYanZheng: '認証コードを受け取れない方はこちら',
  jiShuBiaoMiFeng: '封印中の技術評価待ち内容',
  shangWuBiaoMiFeng: '封印中のビジネス評価待ち内容',
  huoQuKaiBiaoMi: '開札パスワードを取得',
  dianJiHouKaiBiao: 'クリックすると、開札パスワードがご利用のメールに送信されます。5分間有効です。',
  kaiBiaoHouJiangZi: '開札後、以下のメンバーに入札評価通知が自動的に送信されます。',
  pingBiaoZuYuan: '評価チームメンバー',
  jiShuPingGuZhong: '技術評価中',
  zhaoBiaoShiJianHao: '入札募集イベント番号',
  pinPai_3: 'ブランド：',
  peiZhi_3: '構成：',
  beiZhu_2: '備考：{val1}',
  beiZhu_3: '備考：',
  shangPinFuWuMing_2: '商品／サービス名',
  xuQiuXingFuJian: '関連注文明細の添付ファイル',
  jiShuBiaoFuJian: '技術評価添付ファイル',
  shangWuBiaoFuJian: 'ビジネス評価添付ファイル',
  baoJiaShiJianU: '提示日時（UTC+08:00）',
  touBiaoLieBiao: '入札情報一覧',
  zhaoBiaoXuQiuFu: '入札募集申請の添付ファイル',
  pingBiaoZuZhang: '評価チームリーダー',
  heJiaXinXi: '価格確認情報',
  jiangBenJinE: '値引き額',
  dingBiaoFuJian: '落札添付ファイル',
  feiBiao: '無効',
  pingFenZuiYou: '最高得点者',
  paiMing: 'ランキング',
  zongHePingFen: '総合得点',
  baoJiaZongJinE: '見積総額（税込）',
  pingFenRen: '評価担当者',
  pingFen: '得点',
  pingFenFuJian: '評価添付ファイル',
  jiShuBiao: '技術評価',
  shangWuBiao: 'ビジネス評価',
  pingFenQuanZhong: '評価の重み：',
  mingXi: '明細',
  pingFenMingXi: '評価明細',
  zongHeFenShu: '総合得点',
  baoJiaWeiShui: '見積（税抜）',
  baoJiaMingXi: '見積明細',
  touBiaoXiangQing: '入札詳細情報',
  weiPingFen: '未評価',
  yiFangQi: '放棄済み',
  yiPingFen: '評価済み',
  daiChengQing: 'クリア待ち',
  yiChengQing: 'クリア済み',
  pingGuGuanLi: '評価管理',
  jiShuPingFenQuan: '技術評価の重み',
  shangWuPingFenQuan: 'ビジネス評価の重み',
  jiShuBiaoPingFen: '技術評価のテンプレート',
  xiaoZuChengYuan: 'チームメンバー',
  shangWuBiaoPingFen: 'ビジネス評価のテンプレート',
  pingBiaoChengYuanQian: 'すべての担当者が無利害関係誓約書に署名済み',
  zuoFeiZhaoBiao: '募集中止',
  zhaoBiaoLiuChengJie: '入札募集を中止し、入札不可になります。',
  zuoFeiChengGong: '募集を中止しました',
  feiBiaoYuanYin: '募集中止の理由',
  zhaoTouBiaoShiJian: '関連イベント',
  zhaoTouBiao: '入札募集',
  yuFuKuan: '前払金',
  heJiaTiJiaoShen: '価格確認申請日',
  dingBiaoZongJinE_2: '落札総額',
  pingFenBuNengWei: '得点は必須項目です',
  shangWuPingGuZhong: 'ビジネス評価中',
  touBiaoKaiShiShi: '入札開始日',
  touBiaoJieShuShi: '入札終了日',
  jiShuBiaoKaiBiao: '技術開札者',
  shangWuBiaoKaiBiao: 'ビジネス開札者',
  jiShuPingGu: '技術評価',
  shangWuPingGu: 'ビジネス評価',
  jiShuPingFenQuan_2: '技術評価の重み',
  shangWuPingFenQuan_2: 'ビジネス評価の重み',
  queDingYaoFaSong: '評価結果を送信しますか？',
  pingGuTiJiaoHou: '送信すると、落札者決定の段階に進み、評価結果を変更できなくなります。',
  pingGu: '評価',
  faSongPingGu: '評価結果を送信',
  zanWuQuanXian: '権限がありません',
  dingBiaoZhuangTai: '落札ステータス',
  caoZuoRen_4: '操作者：',
  shiJian_2: '日時：',
  yuanYin: '理由：',
  dingBiaoShenPiShi: '落札者承認日時',
  shiFouFeiBiao: '無効にしますか',
  baoJiaZongEHan: '見積総額（税込）',
  queDingYaoJinRu: '価格確認を開始しますか？',
  dangQianCunZaiWei: '評価未完了のメンバーは{val1}名います',
  tiJiaoHouJiangJin: '送信すると、価格確認段階に進みます',
  zhaoBiaoXiangQing: '入札募集詳細情報',
  jinRuHeJia: '価格確認を開始',
  jiShuKaiBiaoRen: '技術開札者',
  shangWuKaiBiaoRen: 'ビジネス開札者',
  baoJiaJinEWei_2: '`見積もり（税込）`',
  zhaoBiaoXuQiuFu_2: '入札参加要件添付ファイル（ベンダーに送信）',
  yingBiaoQueRenHan: '応募確認書',
  baoMiXieYi: '秘密保持契約',
  gongYingShangXiangGuan: 'ベンダー関連添付ファイル',
  shenFen: 'ロール',
  pingFenZhuangTai: '評価ステータス',
  wuFaTiJiao: '送信できません',
  zanWeiTianJiaPing: 'まず評価チームリーダーを設定してください',
  queDingYaoTiJiao_2: '入札募集を開始しますか？',
  tiJiaoHouJiangJin_2: '続行すると、入札募集段階に進みます',
  chuangJianZhaoBiao: '新規入札募集プロジェクト',
  faSongZhaoBiao: '募集開始',
  zhaoBiaoXuQiuFu_3: '入札参加要件添付ファイル（ベンダーに送信）',
  shiJianSheZhi: 'イベント設定',
  shiQu_2: 'タイムゾーン',
  faSongYingBiaoQue: '応募確認書を送信',
  queRenHanMuBan: '確認書のテンプレート',
  faSongBaoMiXie: '秘密保持契約を送信',
  baoMiXieYiMo: '秘密保持契約のテンプレート',
  zhongBiaoGongGaoFan: '落札結果公告範囲',
  tiJiaoHouKaiShi: '送信後に開始',
  dingBiaoTiJiaoHou_2: '送信後に承認段階に進みます。',
  dingBiaoShenQingShi: '落札者決定申請日時',
  shiFouDingBiao_2: '落札にしますか',
  pingBiaoRen: '評価担当者',
  jiShuPingBiaoZu: '技術評価チームリーダー',
  shangWuPingBiaoZu: 'ビジネス評価チームリーダー',
  jinRuPingBiaoShi: '入札評価の開始に失敗しました',
  jiShuPingBiaoZu_2: '技術評価チームリーダーはまだ設定されていません',
  shangWuPingBiaoZu_2: 'ビジネス評価チームリーダーはまだ設定されていません',
  jinRuPingBiao: '評価開始',
  jiangYaoQingPingBiao: '続行すると、入札評価チームのリーダーに評価開始申請を送信しますか。',
  xuQiuLieBiao: '要件リスト',
  daiYingBiao: '応募待ち',
  daiTouBiao: '入札待ち',
  yiTouBiao: '入札済み',
  yiQiBiao: '放棄済み',
  wenTiBiaoTi: '問題点',
  tiChuGongYingShang: '差出人',
  wenTiChuangJianRen: '作成者',
  wenTiTiJiaoShi: '作成日時',
  chengQingGongGaoBiao: '内訳のタイトル',
  chengQingRen: '内訳提示者',
  chengQingGongGao: '内訳',
  zhaoBiao: '入札募集',
  touBiao: '入札',
  heJia: '価格確認',
  touBiaoZhong: '入札中',
  yiKaiBiao: '開札済み',
  pRShenPiZhong: 'PR審査中/入札中',
  pRYiShenPi: 'PR承認済み/入札中',
  pRShenPiZhong_2: 'PR審査中/開札済み',
  pRYiShenPi_2: 'PR承認済み/開札済み',
  wuGongGao: '公告しない',
  canYuCaiGouShang: '入札に参加した会社に公告',
  gongYingShangMenHu: 'システム内のすべての会社に公告',
  zuZhangFangQiPing: 'チームリーダーが評価を諦めました',
  zuZhangWeiQianShu: 'チームリーダーが無利害関係誓約書に署名していません',
  zuZhangWeiKaiBiao: 'チームリーダーは開札していません',
  zuChangYiKaiBiao: 'チームリーダーは開札しました',
  zuYuanWeiQianShu: 'チームメンバーが無利害関係誓約書に署名していません',
  zuYuanYiQianShu: 'チームメンバーが無利害関係誓約書に署名しました',
  zuYuanYiFangQi: 'チームメンバーが評価を諦めました',
  zuZhang: 'チームリーダー',
  zuYuan: 'チームメンバー',
  weiQianShuLiHai: '無利害関係誓約書に署名していません',
  qingShuRuBiaoTi: 'タイトルを入力してください',
  qingShuRuZiXun: 'お問い合わせ内容を入力してください',
  chengQingHuiFu: 'お問い合わせに返信',
  weiTianXieNeiRong: '入力していません',
  huiFuZiXun: 'お問い合わせに返信',
  chengQingZiXun: 'お問い合わせ',
  ziXunXiangQing: 'お問い合わせの詳細情報',
  jiaZaiZhong: '読み込み中…',
  sheZhiYunFei: '運賃設定',
  dianShangDiZhi: 'ECサイト受取情報管理',
  zhouShengDiQu: '州省地域',
  chengShi: '都市',
  qingXuanZeRenYuan: 'ユーザーを選択してください',
  qingXianSouSuoZai: 'まず検索してください',
  xinJianDianShangDi: '新規受取情報',
  bianJiDianShangDi: '受取情報を編集',
  xinJianDiZhiCheng: '受取情報を作成しました',
  bianJiDianShangDi_2: '受取情報を編集しました',
  xinJianDiZhi: '新規受取情報',
  dianShangPingTai: 'ECプラットフォーム',
  dianShangPingTaiBu: 'ECプラットフォームは必須項目です',
  diZhiBianMa: '住所コード',
  diZhiBianMaBu: '住所コードは必須項目です',
  shouHuoRenBuNeng: '受取人は必須項目です',
  qingShuRuZhengQue_4: '正しい連絡先を入力してください',
  fenPeiLeiXing: '利用可能ユーザー',
  shouHuoRenXingMing: '受取人氏名',
  qingShuRuDiZhi: '住所コードまたは氏名を入力してください',
  yaMaXun: 'アマゾン',
  daiEr: 'Dell',
  shiTaiBo: 'STAPLES',
  jingDong: 'JD.com',
  wuRenYuanShiYong: '使用者なし',
  quanYuanShiYong: '全員使用可能',
  buFenYuanGong: '指定された従業員のみ',
  zuiDuoShuRuGe: '最大で20文字までです',
  yanShouJianJie: '検収概要',
  yanShouJinEChao:
    '検収金額がCEOに承認された金額を超えた場合、再審査申請がPOPOで送信されます。申請内容には「検収概要、検収説明、検収証憑」が含まれます。',
  tiJiaoZhong: '送信中',
  tiJiaoShenPi: '送信する',
  benCiYanShouJin_3: '今回の検収金額（税込み）：',
  benCiYanShouJin_2: '今回の検収金額/検収待ち金額',
  benCiYanShouShu_2: '今回の検収数/検収待ち数',
  qiPaiJiaWeiShuiDanJia: '開始価格（税抜き単価）',
  yuFuJinEXuDaYu: '前払金額が0より大きい必要がある',
  zhiFuFangShiWeiYuFu: '支払方法は前払金であり、契約を関連付けなければならない',
  tiaoZhengBuFuDanJia: '値上げ幅（単価）',
  xinZengBaoJia_2: '新規金額',
  diYiMing: '第1位',
  diErMing: '第２位',
  diSanMing: '第3位',
  anJinE: '金額別',
  anBiLi: '割合別',
  buXian: '制限なし',
  qiPaiJia: '入札最低額',
  diYiMingJiaGe: '第1位の価格',
  jianGe: '間隔',
  fenZhong: '分間おきに',
  zuiHou: '最後の',
  fenZhongNeiChuXian: '分間以内に、第1位が更新されると',
  yanShi: '延長する',
  wuYanShi: '延長しない',
  kaiQiZaiXianJing: 'オンラインオークションを有効',
  shiFouZhengDanZhong: '募集内容全体のために一括で落札者決定',
  jingJiaMoShi: 'オークション形式',
  tiaoJiaBuFuZheng: '値上げ幅（全入札募集書）',
  tiaoJiaBuFuB: '値上げ幅（入札募集明細）',
  jingJiaGuiZe: '入札ルール',
  baoJiaJianGeShi: '金額提示間隔',
  ziDongYanShiShi: '自動延長時間',
  gongYingShangBaoJia_2: '見積り表示',
  anJinE_2: '金額別（{val1}{val2}）',
  anBiLi_2: '比率別（{val1} %）',
  fenZhongNeiChuXian_2: '{val1} 分間以内に、第1位が更新されると {val2} 分間延長',
  baoJiaShiJian: '提示日時',
  jiaGe: '価格',
  gengGaiBaoJiaBian: '金額変更:{val1} から {val2}まで',
  caoZuoXing: '操作対象',
  weiCanYu: '未参加',
  baoJiaJinEHan_2: '見積（税込）：',
  shiFouDingBiao: '落札状況：',
  wuBaoJiaJinE: '見積なし',
  zanWuGongYingShang: 'ランキングなし',
  baoJiaLiShi: '見積履歴',
  gongYingShangPaiMing: 'ベンダーランキング',
  canYuGongYingShang_2: '参加するベンダーは少ない',
  kaiQiZaiXianJing_2: 'オンラインオークション開始後、2社以上のベンダーの参加が必要です',
  yingShiBaoJiaFan: 'イギリス式オークション（逆）',
  qianSanMingBuYun: '上位 3 社は同じ価格を見積もることを禁止',
  suoYouBaoJiaXu: '第1位の価格を超える金額を提示することは必須',
  suoYouPaiMingBu: 'すべての参加者は同じ価格を見積もることを禁止',
  juJieShuHaiSheng: 'オークション終了まであと',
  tiaoZhengHeTongJieSuan: '支払会社、ベンダー、通貨情報を変更すると、価格表がクリアされます。ご注意ください。',
  chaiFenDingBiaoMing: '落札者明細を分割',
  xingVALDing_2: 'Line{val1}：分割済み落札者明細の合計金額は落札金額と等しくありません',
  duiWaiLiPin: '贈答品',
  banGongYongFeiI: 'オフィス用品（非IT管理中のハード・ソフトウェア）',
  zhuanShou: '転売',
  banShuSongShen: '版号審査',
  banGongYongIT: 'オフィス用品-IT共有資産',
  banGongYongYeWu: 'オフィス用品-ビジネス部署共有資産',
  guoNeiITRuan: '中国本土ITソフト・ハードウェア受領申請及び購買',
  zhuCeDiWei: '{val1}の登録国/地域は：{val2}',
  cunZaiDuoZhong: '複数あります。{val1}',
  cunZaiDuoKaHao: '{val1}（複数のカード番号があります。{val2}）',
  yuFuJinE_2: '{val1}（前払金額：',
  zhuangTaiWeiBianHua: 'ステータスは変更されていません',
  zhuangTaiXiuGaiCheng: 'ステータスを変更しました',
  xiuGaiZhuangTai: 'ステータスの変更',
  guanLianWangYiJi: 'NetEaseグループのすべての会社と関連付ける',
  yiGuanLian: '関連付けられている会社：',
  guanLianGongSi: '関連会社',
  rFXXingBao: 'RFx-明細番号-見積もり',
  peiZhiGuiGe: '構成/仕様',
  xuanZeRFX: 'RFxを選択',
  qingShuRuRF: 'RFx番号を入力してください',
  ruoTianLeShuLiang:
    '数量を入力した場合、税込み金額（数量＊税込み単価）が自動的に算出されますが、{val1}税込み金額のみを入力した場合、数量は算出されません。',
  xingHao_2: '型番',
  shuLiangBuKeWei: '数量を0にすることはできません',
  bianJiJiaGeQing: '価格表の編集',
  tianJiaJiaGeQing: '価格表の作成',
  laiYuanXinXi: '情報源',
  mingXiXinXi: '明細',
  jiaGeYouXiaoQi: '有効期限',
  xuJieChuDuiYing: '編集するには、他の伝票との関連付けを解除する必要があります',
  xuJieChuDuiYing_2: '削除するには、他の伝票との関連付けを解除する必要があります',
  jiaGeQingDan: '価格表',
  zanWuNeiRong: 'データがありません',
  cRPHeTong: 'CRP契約書',
  nETBU: 'Netbuy契約書',
  heTongLeiXing: '契約書種類',
  heTongYuE: '契約書残高',
  qiShiRiQi: '開始日',
  zhongZhiRiQi: '終了日',
  tianJiaHeTong: '契約書の追加',
  woFangZhuTi: '当方',
  qingShuRuHeTong: '契約書番号/契約書名を入力してください',
  shengXiaoRiQi: '効力発生日',
  qingXuanZeShengXiao: '効力発生日を入力してください',
  heTongZhuangTaiFei: '無効になった契約書を選択できません',
  biZhongBuYiZhi: '通貨が異なるため、選択できません',
  heTongYuEBu: '残高が足りません。再確認してください',
  dingDanHanShuiJin: '注文の税込金額は：',
  heTong: '契約書',
  dangQianKeYongYu: '現在利用可能な残高は：',
  qingJianChaHouZai: '再確認してください',
  jiaGeKuangJiaHe: '価格フレームワーク契約',
  xuXuanZeYouJia: '有効で、残高が０を超え、関連付けられている価格表がある契約書を選択してください',
  xuanZeJiaGeQing: '価格表の選択',
  xiaDanFangShiLei: '注文方法が一致していません。再確認してください。',
  baoJiaQingDan: '見積表',
  dingBiaoShuLiangBi: '落札数は0より大きい必要があります',
  dingBiaoJinEBi: '落札金額は0より大きい必要があります',
  shangPinFuWuMing: '品名/サービス名',
  yiYouYunFeiBu: '送料がすでに存在する場合は追加できません',
  chuangJian: '作成',
  gaiHeTongYiCun: '既に存在している契約です。編集ページに移動しますか？',
  qieHuanHeTongBi: '契約通貨を変更すると、価格表が削除されます。ご注意ください',
  jiaGeQingDanBu: '価格表は必須項目です',
  chuangJianHeTong: '新規契約',
  qingXuanZeZhengQue: '正しい当方主体/ベンダー/契約情報を選択してください。変更すると他の入力内容が削除されます。',
  daoQiCaoZuo: '期限切れた後の操作',
  xuQianTiXing: '契約更新の通知を送信',
  buXuQian: '契約を更新しない',
  woFangZhuTiJie: '当方（支払会社）',
  faBuRiQi: '発行日',
  youXiaoQi: '有効期間',
  danJuLeiXing: '伝票種類',
  guanLianShiJian: '関連付けられた日時',
  guanLianJiaGeQing: '関連価格表-番号',
  guanLianJiaGeQing_2: '関連価格表-品名',
  heTongXiangQing: '契約書情報-{val1}',
  faBuShiJian: '発行日時',
  heTongYuEHe: '契約残高＝契約金額－入札金額',
  xuQian: '契約を更新する',
  guanLianRFX: '関連するRFx/PO',
  rFXShenPi_3: 'RFx審査中＋注文済み＋有効な注文書',
  heTongBianJi: '契約書の編集-{val1}',
  dingDanJinEHan_2: '注文金額（運賃を含む）',
  shangPin_2: '商品：{val1}',
  jiaGeQingDanDe: '価格表中の落札金額の合計は落札金額を超えています',
  rFXDingBiao: 'RFx中の落札金額は：',
  dangQianKeYongYu_2: '現在利用可能な残高は',
  yiYouJiaGeQing: '価格表は既に存在しているので、追加できません',
  kuangJiaXiaDan: 'ワークフレーム契約上の注文',
  daiShengXiao: '反映待ち',
  yiGuoQi: '期限切れ',
  kuangJiaHeTong: 'フレームワーク契約',
  danXiangMuHeTong: 'プロジェクト契約',
  yiBanHeTong: '一般契約',
  shouGong: '情報の手入力',
  oADanHaoDing_2: 'OA申請番号/注文番号/追跡番号/ASN番号',
  zhiFuJinE: '支払金額',
  oADanHaoFa: 'OA申請番号/ASN番号/受取チケット番号/注文番号/計上チケット番号',
  zhiFuZhong: '支払中',
  yuGuJinE: '予想金額',
  shenPiJuJueYuan: '否認理由',
  shenQingFuKuanJin: '申請金額は請求金額と異なります',
  shenQingFuKuanJin_2: '申請金額は請求金額と一致します',
  shenQingFuKuanJin_3: '申請金額（税込）',
  faPiaoJinEHan: '請求金額（税込）',
  chaYiJinEHan: '差額（税込）',
  jinEWeiShui: '金額（税抜）',
  faPiaoXinXi: '請求書情報',
  benCiShenQingJin: '今回の申請金額（税込）',
  fuKuanXinXi: '支払情報',
  lianXingHangHao: 'CNAPS番号',
  benCiShiJiFu: '今回の支払額\r\n（税込）',
  qiTaFuJian: '添付ファイル',
  daiJiTiRuZhang: '計上待ち',
  yiGuiDang: 'アーカイブ済み',
  yiCheHui: '取下済み',
  fuKuanDan: '支払チケット',
  gongYingShangBaoJia: 'ベンダーの見積り',
  qingShuRuShangPin: '品名を入力してください',
  heJiaCeLue: '価格確認方法',
  fuKuanShenQing: '支払申請',
  guanLianXinXi: '関連情報',
  shiFouXuYongHu: 'ユーザーによる確認が必要ですか',
  xuanZeShiJiangYou: '「はい」を選択すると、ユーザーのOAでの請求書と検収情報の確認が可能になります。',
  dingDanHaoHangHao: '注文書番号-明細番号',
  oCRShiBie: 'OCR認識に失敗しました',
  oCRShiBie_2: 'OCR認識中...',
  haiWaiFaPiao: '海外の証憑',
  queRenHouJiangJie: '続行すると、請求書と注文書の紐付けが解除されます。手動で再度関連付けることができます。',
  jieChuGuanLian: '紐付けを解除',
  yanZhenZhuangTai: '真偽検証結果',
  chuangJianFang: '作成者',
  qingShuRuFaPiao_2: '請求書番号/注文書番号/ベンター名を入力してください',
  shangPinMingChengHuo: '品名でまたはサービス名',
  guiGeXingHao: '構成情報',
  danWei: '単位',
  xinZengXing: '行を追加',
  shanChuXing: '行を削除',
  faPiaoXing: '請求書明細',
  zhiHangMingCheng_2: '銀行支店名：',
  lianXingHangHao_2: 'CNAPS番号：',
  zhangHuMingCheng_2: '口座名義：',
  yinHangZhangHao_2: '口座番号：',
  shouXuFeiChengDan_2: '手数料負担側：',
  xuanZeKaiHuHang: '取引銀行を選択してください',
  xuanZeYinHangXin: '銀行の選択',
  chuangJianFuKuanShen: '支払申請を作成',
  baoXiaoRen: '申請者',
  shenQingFuKuanShuo: '申請説明',
  danGeWenJianXiao: '最大で100MB/ファイル',
  fuKuanShenQingXiang: '支払申請の詳細情報：{val1}',
  jieSuanGongSiGong:
    '支払会社、ベンダー、契約番号、通貨が異なるチケットを組み合わせることができません。ご確認ください。',
  qingShuRuFaPiao_3: '請求書番号/注文書番号を入力してください',
  jieSuanDanHao: '決算チケット番号',
  fuKuanJinEHan: '申請額（税込）',
  fuKuanDanHao: '支払チケット番号',
  zhiFuJinEHan: '支払金額（税込）',
  qingShuRuJieSuan: '決算チケット号/注文書番号/OA申請番号を入力してください',
  tian_8: '0日間',
  caiGouFang: '買方',
  weiYanZhen: '真偽検証待ち',
  yanZhenChengGong: '真偽検証完了',
  yanZhenShiBai: '真偽検証失敗',
  zengZhiShuiZhuanYong: '増値税専用発票',
  zengZhiShuiDianZi: '増値税電子専用発票',
  zengZhiShuiPuTong: '増値税普通発票',
  zengZhiShuiDianZi_2: '増値税電子普通発票',
  zengZhiShuiDianZi_3: '増値税電子普通発票（通行費）',
  quKuaiLianDianZi: '区塊鏈電子発票',
  zengZhiShuiPuTong_2: '増値税普通発票（卷票）',
  jiDongCheXiaoShou: '自動車販売統一発票',
  tongYongJiDaFa: '通用機打発票（紙）',
  tongYongJiDaFa_2: '通用機打発票（電子）',
  hangKongXingChengDan: 'eチケットお客様控え',
  huoChePiao: '列車乗車券',
  keYunQiChePiao: 'バス乗車券',
  chuanPiao: '乗船券',
  chuZuChePiao: 'タクシー領収書',
  dingEFaPiao: '定額発票',
  diDiXingChengDan: '滴滴行程単',
  haiWaiINV: '中国本土以外の請求書',
  shi_2: '時',
  fen: '分',
  miao: '秒',
  jieSuanGongSiZhu: '支払会社の登録国/地域は：',
  gongYingShangZhuCe_2: 'ベンダーの登録国/地域は：',
  wuYunFei: '送料なし',
  wangYiGongSiBan: 'Copyright © 1997- {val1} NetEaseInc. All Rights Reserved.',
  oADanHao_2: 'OA申請番号:',
  xunJiaBeiZhu: '備考',
  faGeiGongYingShang: '（ベンダーに送信）',
  xunJiaFuJian: '添付ファイル',
  dingBiaoJinEChao: '落札金額は見積もり金額の',
  dingBiaoJinEDi: '落札金額は見積もり金額の',
  xunJiaShuLiang: '数量',
  gongJiJiaBaoJia: '計 {val1} 社のベンダー',
  shouQiQuanBu: '折り畳む',
  zhanKaiQuanBu: '展開する',
  gongYingShangZhuCe: '会社の登録国/地域は{val1}です',
  jinEBuWanZheng: '正しい金額を入力してください',
  buTongOADan: 'OA申請番号が一致しないため、見積書を作成できません',
  qingShuRuXingMing_2: '氏名/従業員番号/メールアドレスを入力してください',
  daiHeJia: '見積確認待ち',
  xunJiaGongYingShang: 'ベンダー',
  kaiBiaoHeJia: '見積確認',
  pinPai_2: 'ブランド：',
  peiZhi_2: '構成：',
  shiFouZhongBiao: '落札状況',
  shiFouZuiYouJia: '最低価格ですか',
  zhongBiaoShuLiang: '商品数量',
  zhongBiaoJinEHan: '金額（税込み）',
  baoJiaDanJiaWei: '見積単価（税抜）',
  weiBaoQiYue: '保証期間（月）',
  shouQuanQiRuanJian: 'ソフトライセンス有効期間（月）',
  jiaoFuZhouQiZi: '納期（暦日）',
  canKaoDanJiaHan: '参考単価（税込）',
  canKaoZongJiaHan: '参考総額（税込）',
  shenPiJieDian: 'ステップ',
  shenPiRen: '承認者',
  caiGouXuQiuShen: '購買申請の情報',
  xuQiuShenQingRen: '申請者',
  xuQiuShenQingRen_2: '申請者部署',
  xuQiuShenQingYuan: '申請理由',
  xuQiuShenPiFu: '添付ファイル',
  xuQiuYiShenPi: '操作者',
  dingJiaDingBiaoShen: '価格/落札者審査',
  xunJiaDingBiaoHao_2: '見積書番号',
  dingBiaoShenPiBiao: '件名',
  canKaoJiaJinE: '参考金額',
  dingBiaoZongJinE: '落札金額（税込）',
  dingBiaoShuoMing: '説明',
  dingJiaDingBiaoMing: '価格/落札者明細',
  dingJiaDingBiaoFu: '添付ファイル',
  qingZaiDingBiaoMing: '詳細情報を確認するには、落札者明細をご覧ください',
  dingBiaoShenQing: '落札者決定申請-{val1}',
  xunYuanFangShi: 'ベンダー選択方法',
  dingBiaoCeLue: '落札者決定基準',
  heJiaFuJian: '添付ファイル',
  heJiaShenQingTi: '申請日時',
  canKaoJiaHeJi_2: '参考金額（税込）',
  qingZaiBaoJiaXin: '詳細情報を確認するには、を見積情報をご覧ください',
  renYuanXinXi: '関係者情報',
  shiYongRenYouXiang: '使用者メール',
  shiYongRenSuoShu: '使用者の所属部署',
  shouHuoRenYouBian: '受取人の郵便番号',
  shouQuanQi: 'ライセンス有効期間',
  qiWangJiaoHuoRi: 'お届け希望日',
  caiGouXinXi: '購買情報',
  baoJiaXinXi: '見積情報',
  fuJianXinXi: '追加情報',
  baoJiaYouXiaoQi: '見積情報の有効期限',
  gongHuoZhouQiZi: '納期（暦日）',
  dingDanSheZhi: '注文設定',
  faSongDingDanZhi: '注文をベンダーに送信',
  ziDongFaSongDing_2: '自動的に注文を送信',
  baoJiaHeJia: '見積書作成・価格確認',
  chuangJianBaoJiaDan: '見積書の作成',
  xunYuanCeLue: 'ベンダー選択方法',
  dingJiaCeLue: '落札者決定基準',
  tiJiaoHeJiaHou: '申請送信後に表示',
  queDingYaoShanChu_2: 'このデータを削除しますか？',
  zanWeiXuanZeGong: 'ベンダーを選択してください',
  dingBiaoShenQingTi: '申請日時',
  kaiBiaoShiJian: '開札日時',
  heJiaRen: '価格確認者',
  baoJia_3: '見積書作成',
  faSongXunJia: '引き合い書を送信',
  queRenYaoFaSong: 'RFxをベンダーに送信しますか？',
  baoJiaJieZhiShi: '見積書提出締切日',
  shiQu: 'タイムゾーン：',
  xunJiaShiJian: '引き合い日時',
  faChuXunJiaHou: '引き合い書の送信後に表示',
  canYuGongYingShang: 'ベンダー情報',
  lianXiRen_3: '連絡担当者：{val1}',
  xunJiaShuLiang_2: '数量',
  xunJiaXiangQing: '引き合い明細{val1}',
  baoJiaXiangQing_2: '見積明細{val1}',
  quXiaoXunJia: '引き合いをキャンセル',
  quXiaoChengGong: 'キャンセルしました',
  xunJiaFaQiRen: '引き合い書作成者',
  tiaoZhengBaoJiaShi: '見積書提出締切日の変更',
  tiaoZhengHouKeNeng: 'ベンダーの見積結果に影響する可能性があります',
  dangQianYiYouJia: '現在、{val1}社のベンダーが見積書を提出しました',
  tiaoZhengShiJian: '締切日を変更',
  queDingTiJiaoKai: '開札しますか？',
  cunZaiBuFenBao: 'まだ見積書を提出していないベンダーがあります。続行しますか？',
  baoJiaTiJiaoHou: '価格確認段階に進むと、ベンダーが見積書の提出を行うことができなくなります。',
  baoJiaDaoJiShi: '見積書提出締切日まであと：',
  xunJiaJieZhiShi: '見積書提出締切日まであと',
  yiBaoJiaGongYing: '見積書提出済みベンダー：{val1}',
  weiBaoJia: '見積書提出待ち',
  yiBaoJia: '見積書提出済み',
  buCanYu: '入札しない',
  caiGouFangShi: '購入方法',
  zhiDingCaiGouLei: '商品カテゴリー',
  yuanYinShuoMing: '理由',
  juJue: '否認',
  tongYi: '承認',
  shenHeXinXi: '承認情報',
  shenHeShuoMing: '説明',
  caiGouYuanDaiLi: '購買担当者による情報登録',
  gongYingShangZiZhu: 'ベンダーによる情報登録',
  faSongYaoQingCheng: '招待状を送信しました',
  yaoQingGongYingShang: 'ベンダー招待状の送信',
  faQiYaoQing: '招待状を送信',
  tiJiaoChengGongHou: '送信した後に、ベンダーに通知が送信されます',
  qingXuanZeFuWu: '商品カテゴリを選択してください',
  zhangHaoGuanLi: 'アカウント管理',
  xianXiaBaoJia: '手動で見積情報を登録',
  chuangJianXunJia: 'オンライン引き合い書を作成',
  yiZhongBiao: '落札者',
  weiZhongBiao: '落札されない',
  xianXiaBaoJiaHe: 'オフライン-見積書作成・価格確認',
  xianXiaDingBiao: 'オフライン-落札者決定',
  xianShangXunJia: 'オンライン-引き合い',
  xianShangBaoJia: 'オンライン-見積書を作成',
  xianShangHeJia: 'オンライン-価格確認',
  xianShangDingBiao: 'オンライン-落札者決定',
  xunBiJia: '見積りを比較してからベンターを決定',
  zhiJieCaiGou: '直接に購買',
  zhiDingCaiGou: 'ベンターを指定',
  zuiDiJiaFa: '最低価格',
  zongHePingFenFa: '総合評価',
  zuiGaoJiaFa: '最高価格',
  dingBiaoShenPiZhong: '落札者審査中',
  baoJiaZhong: '見積書募集中',
  heJiaZhong: '価格確認中',
  shenPiZhongBaoJia: '審査待ち/見積書募集中',
  yiShenPiBaoJia: '審査済み/見積書募集中',
  shenPiZhongHeJia: '審査待ち/価格確認中',
  yiShenPiHeJia: '審査済み/価格確認中',
  feiITWuZi: 'IT資産以外',
  xingZhengZhuanYongGong: '総務部専用-一般購買',
  weiXiuYongPin: '守修繕用品',
  yuanGongFuLi: '従業員福祉',
  qingJieLei: '清掃用品',
  shiTangBaoXiang: '食堂個室用品',
  canYinShangPei: '飲食店用商品券',
  changGuiCaiGou: '一般購買',
  gongYingShangXuYue: 'ベンダーとの契約更新',
  cunZaiJiShuBi:
    '技術上の必要性がある場合：が必要な場合、または付随するサービスや技術の継続性が求められる場合、さらには他の製品やサービスを代替できない場合は、関連部署の技術責任者（二級部署以上の責任者または関連業務の最高技術責任者）がその技術の必要性を確認する必要があります。購買部との意見が対立する場合は、購買する前に評価会議を実施することをお勧めします。',
  youTeShuBaoMi: '特定の秘密保持やサービス制限条件がある場合',
  keHuZhiDingDe:
    '取引先がベンダーを指定した場合、明確なベンダー指定証憑（関連文書、チャット履歴など）を取得し、関連する一級部署の責任者からの確認を得る必要があります。',
  shouDaoCaiGouShi: '購買の時限や地域の制約により、適格なサービスや製品を提供できるのは一社しかない場合',
  zhengFuHangYeZhun: '政府、業界基準、資格管理機関、または法律規制で指定されたベンダー',
  shuDianFaPiaoZhuan: 'デジタル発票（専用発票）',
  shuDianFaPiaoPu: 'デジタル発票（普通発票）',
  haiWaiCaiGou: 'OA購買申請（国際版）',
  guoNeiZongHeCai: 'OA購買申請（中国版）',
  xingJinErBuHanShui: '金額（税抜）',
  jinEHanBuShui: '金額（税抜）',
  yiJuJue: '否認済み',
  qingShuRu: '入力してください',
  qingShuRuFaPiaoJinE: '請求書金額を入力してください',
  chuangJianLuYueJi: '新規納品計画',
  meiYue: '毎月',
  ri: '日',
  geYue_2: 'ヶ月ごとに',
  faPiaoHaoMa: '請求書番号',
  faPiaoDaiMa: '請求書コード',
  jiaoYanMa: '検証コード',
  kaiPiaoRiQi: '請求書発行日',
  zhangQiTian: '仕入債務回転期間 （日）',
  daoQiRi: '有効期限',
  shuiE: '税額',
  buHanShuiJinE: '金額（税抜）',
  faPiaoBiZhong: '通貨',
  yanCongXiang: '闫从翔',
  weiZhiFu: '未払い',
  yiZhiFu: '支払済み',
  buFenZhiFu: '一部支払済み',
  jieSuan: '支払',
  jieSuanGuanLi: '支払管理',
  faPiao: '請求書',
  shangChuanFaPiao: '請求書のアップロード',
  luYueXinXi: '納品情報',
  jiaoFuFangShi: '納品方法',
  luYueRiQi: '納品日',
  jiaoFuZhouQiMei: '納品の周期（毎月）',
  luYueJiHuaXiang: '納品計画の詳細情報{val1}',
  meiYueRi: '毎月の{val1}日',
  geYueMeiYueRi: '{val1}ヶ月ごとに、毎月の{val2}日',
  guanLianDingDanZheng: '関連する注文書',
  guanLianDingDanMing: '関連する注文明細',
  guanLianYanShouDan: '関連する検収チケット',
  gaiFaPiaoZanWei: '関連する注文がありません。関連するには、関連方法を選択してください：',
  guanLianZongJinE: '合計（税抜）：',
  jinEHanShui: '金額（税込）',
  shengYuKeKaiPiao: '残高（税抜）',
  guanLianJinEBu: '関連付られている金額（税抜）：',
  xingJinE: '明細金額',
  yanShouDingDan: '検収チケット',
  guanLian: 'チケットを選択',
  queDing: 'OK',
  qingJianChaBiZhong: '通貨、支払会社、ベンターなどの情報が正しく入力されているかご確認ください。',
  qingShuRuCaiGou: '注文番号/OA申請番号を入力してください',
  faPiaoFuJian: '添付ファイル',
  zhiChiPNG: 'png、jpg、jpeg、pdf、ofd形式のファイルをアップロードしてください',
  daoQiRiBuKe: '期限日を発行日より前の日に設定することができません。再確認してください。',
  buHanShuiJinE_2: '税抜金額は税込金額を超えることはできません。再確認してください。',
  guanLianJinEBu_2: '関連金額（税抜）は請求金額と一致する必要があります。',
  shanChuHouBuKe: '削除した後に元に戻さなくなります。',
  faPiao_2: '{val1}請求書',
  shangChuan: 'アップロード',
  faPiaoXiangQing: '請求書情報',
  guanLianDanJu: '関連チケット',
  zhiFuZhuangTai: '支払ステータス',
  guanLianFuKuanDan: '関連する支払チケット',
  faPiaoDaoQiRi: '請求書有効期限',
  faPiaoID: '請求書ID',
  shangChuanShiJian: 'アップロード日時',
  qingShuRuFaPiao: '請求書番号、ベンター名を入力してください',
  fuWuLeiJiaoFu: 'サービス',
  zhongGuoDaLuFa: '中国本土の請求書',
  feiZhongGuoDaLu: '中国本土以外の請求書',
  yiCiXingJiaoFu: '一括納品',
  meiYueGuDingJiao: '分割納品（定量）',
  meiYueAnXuJiao: '分割納品（不定量）',
  shiJiLvYueJinE: '実際の納品金額',
  shiJiLvYueShuLiang: '実際の納品数',
  lvYueDanHangHao: '納品チケット-明細',
  lvYueXinXi: '納品情報（{val1}）',
  buTongShouHuoQu: 'Different delivery region PR cannot mix in 1 RFx.',
  zhiFuFangShiCunZaiYu: '支払方法には前払が含まれているため、一括で注文を作成できません。',
  tuiJianRenXingMing: '推薦者（氏名＋メールアドレス）',
  guanLiYuanShiZhi: '管理者とは、ベンダー側のベンダーポータルシステムを管理する従業員のことを指します。',
  gongYingShangLaiYuan: '情報提供者',
  caiGouXunYuan: 'ベンダー開拓担当',
  qiTaRenTuiJian: '他の従業員',
  yiTiJiao: '送信済み',
  dingBiaoJinEWeiLing: '注文金額は 0 です。送信できませんでした。',
  shuRuYouWu: '不正なデータです',
  biTian: '必須項目',
  biZhongBuYiZhiBuKe: '通貨が不一致のため、作成できません',
  gaiShuJuBuCunZai: '存在しないデータです',
  caiGouFuJian: '添付ファイル',
  xunJiaDanShenPiZhong: 'RFx承認待ち',
  ziDongChuangJianDing: '注文書の自動作成',
  baoJiaJinEHan: '見積金額（税込）',
  dingDanJinEWeiShui: '金額（税抜）',
  weiBaoQiXianYue_2: '保証期間（月）',
  shouQuanQiXianYue_2: 'ライセンス有効期間（月）',
  xuQiuBeiZhu: '備考',
  xuQiuFuJian: '添付ファイル',
  daiShouHuo: '受取待ち',
  xianShiLiuChengTu: 'フローチャートを表示',
  shenPiBuTongGuo: '否認済み',
  jinDuFanKui: '進捗状況報告',
  zheSuanRenMinBi: '金額（人民元、税込）',
  xuQiuYuYuanYin: '申請理由',
  zhiFuFangShiBu: '支払方法が異なるため、作成に失敗しました',
  qingShuRuZhengQue_3: '正しいメールアドレスを入力してください',
  caiGouXuQiuXiang: '購買依頼詳細情報{val1}',
  kaPian: 'カード',
  lieBiao: 'リスト',
  dengDaiShiChangChao: '保留時間が３日間以上',
  qingShuRuDingDan: '注文番号/OA申請番号を入力してください',
  dingDanXiangQing: '詳細情報',
  baoJiaHangHao: '見積明細行番号',
  ziDongFaSongDing: '自動的に注文をベンダーに送信',
  bianGeng: '変更あり',
  chaKanBianGeng: '変更履歴',
  chaKanBianGeng_2: '変更履歴-{val1}',
  fuWuLei_2: 'サービス類',
  shenQingJieShuRi: '申請終了日',
  yongJiuYouXiao: '永続',
  shiQuShuoMing: 'タイムゾーンについて',
  gaiShiJianShiAn: 'これは現地のタイムゾーンに合わせて表示された時刻です。',
  fuZhiChengGong: 'コピーしました',
  queDingLiKaiYe: 'このページを離れますか？',
  xunJiaDanChuangJian: 'RFx作成日',
  caiGouPinLei: '商品カテゴリー',
  buTongXuQiuBan: '１つ見積書には異なるバージョンの購買リクエストを含めることはできません',
  shouHuoDiQu: 'お届け先所在地',
  xuQiuRen: 'リクエスター',
  heZuoZhuangTai: 'ベンダーステータス',
  heZuoZhuangTaiShuo: 'ベンダーステータスの説明：',
  chaKanXiangQing: '詳細情報',
  caiGouYuanKeDui:
    '購買担当者はベンダーのレベル（登録済み、潜在、馴染み、淘汰、ブラックリスト登録済み）を変更できます。',
  wangQi: '王琦（オウ・キ）',
  shangHaiXingZhengZong: '上海総務に関する総合サービス',
  wangYunHui: '王运輝（オウ・ウンキ）',
  jiTuanRenLiGong:
    'Human resources except for Global HRO Center,Global Studios BP Center, Global Total Rewards,Mutual Entertainment Pay Centre comprehensive service Requirements',
  liuLingYan: '劉灵燕（リュウ・レイエン）',
  gLOBA:
    "Human Resources Global HRO Center, Global Studios BP Center,Global Total Rewards' comprehensive service Requirements",
  linYang: '林楊（リン・ヨウ）',
  jiTuanRenLiRen:
    'グループ人材オペレーションセンター/人材トレーニングセンター/組織開発・文化センター/EHRプロダクトセンターに関する一般的リクエスト',
  liuFang: '劉芳（リュウ・ファン）',
  yunFuWuDC:
    'Cloud services, DC hardware; procurement requirements for comprehensive services other than Shanghai、Beijing administration and HR departments；Hangzhou、Beijing Park Renovation Projects',
  guoXia: '郭霞（カク・カ）',
  iTRuanYingJian: 'IT software and hardware, other IDC resources、text message',
  shiMengQing: '史夢清（シ・ムセイ）',
  qingPuSanYaMeng: 'Xuhui、Qingpu, Sanya, Montreal renovation project',
  xuHuiBeiJingYuan: 'Comprehensive administrative service requirements in Shanghai；Xuhui Park Renovation Projects',
  dengWenJing: '鄧文婧（デン・ブンセイ）',
  hangSanGuiAnHang:
    "Hangzhou Third, Gui'an, Guangzhou Park Infrastructure Projects and Weak current transformation；Comprehensive administrative service requirements in Beijing",
  duiJieRen: '担当者',
  duiJieYeWu: '担当内容',
  yeWuXianShuoMing: '関連業務の説明',
  yeWuXian: '関連業務',
  zhuanJiao: '譲渡',
  suoShuGongYingShang: '所属ベンダー',
  zhiFuBaoZhongGuo: 'Alipay (China) Network Technology Co., Ltd.',
  zhuanJiaoYouXiang: '譲渡先',
  zhuCe: '登録',
  qianZaiXiaoJinE: '潜在/小口',
  qianZaiChangGui: '潜在/標準',
  heZuoChangGui: '馴染み/標準',
  heZuoXiaoJinE: '馴染み/小口',
  taoTai: '淘汰',
  heiMingDan: 'ブラックリスト',
  shenPiChengGong: '承認しました',
  chengGong: '完了',
  shiBai: '失敗',
  xunYuan: 'ベンダー開拓',
  dingDan: '注文',
  yanShou: '検収',
  gongYingShangLieBiao: 'ベンダーリスト',
  gongYingShangZhangHao: 'ベンダーアカウント',
  xuQiuLaiYuan: '情報源',
  xiaDanGuiZe: '注文ルール',
  jinE: '金額',
  bianGengJiLu_2: '変更履歴-{val1}',
  caoZuoRen_3: '操作者：',
  chuangJianDingDan: '注文書の作成',
  gongYingShangYiRu: 'ベンダー - 登録済み',
  xinXiBianGeng: '情報変更',
  heZuoZhuangTaiBian: 'ステータスの変更',
  gongYingShangWeiRu: 'ベンダー - 未登録',
  chaKanJiLu: '操作履歴',
  chongXinZhunRu: '再審査',
  bianGengJiLuDai: '変更履歴 - 審査待ち',
  shenHe: '審査',
  bianGengJiLuXin: '変更履歴 - 情報変更',
  bianGengJiLuHe: '変更履歴 - ベンダーステータスの変更',
  bianGengJiLuZhun: '変更履歴 - 登録情報審査申請',
  jueSeBianMa: 'ロール番号',
  jueSeBianMaBu: 'ロール番号は必須項目です',
  zhouRi: '日曜日',
  zhouYi: '月曜日',
  zhouEr: '火曜日',
  zhouSan: '水曜日',
  zhouSi: '木曜日',
  zhouWu: '金曜日',
  zhouLiu: '土曜日',
  benCiYanShouShu: '今回の検収数',
  benCiYanShouJin: '今回の確認済み金額',
  yanShouShenPiJie: '承認ルート一覧',
  yanShouXinXi_2: '検収情報',
  yanShouFuJian: '添付ファイル',
  faHuoShuoMing: '備考',
  chaKanYiYanShou: '検収履歴',
  yanShouJiLu: '検収履歴',
  daiYanShouJinE: '確認待ち金額',
  shiWuLei: '有形商品類：',
  qingQueBaoShouDao:
    '商品の数量、型番、仕様、品質、納期などは契約/注文要件を満たしており、需要部署/検収部署の検収基準に合っているかを確認する必要があります。',
  fuWuLei: 'サービス類',
  qingQueBaoFuWu:
    '成果物が契約書または注文書の要件や要求、検収基準に満たしているか、検収基準に合っているかを確認する必要があります。',
  ruanJianLei: 'ソフトウェア類:',
  qingQueBaoNinYi:
    '受け取ったソフトの数、そのライセンス数と有効期間、ドングルなどが契約/注文の要求、要件を満たしているかを確認する必要があります。',
  yanShouYaoQiu: '成果物・商品の検収について：',
  ruYouYiWenQing:
    'ご不明な点がございましたら、対応するする購買担当者に連絡してください。実際の納品数に基づいて検収してください。システムは分割検収に対応しますが、特別な事情がない限り、分割検収機能を利用しないでください。検収を行うと、関連する承認ワークフローが開始されます。',
  caiGouBuGongGong: '詳細については『購買部一般権限リスト-検収審査』をご参照ください。',
  qingAnShiJiLu: '契約条項に基づいて検収を完了してください。ご質問がある場合は、購入者にお問い合わせください。',
  yanShouChengGong: '検収完了',
  queRenShouHuo: '受取確認',
  zuiDuoShangChuanZhang: '100MB以下、最大3枚まで。',
  yanShouShuoMing: '検収説明',
  shangPinXinXi: '商品情報',
  xingBeiZhu: '備考',
  xingFuJian: '添付ファイル',
  shenQing: '申請',
  tiJiaoDingDan: '発注',
  gongYingShangLuYue: '納品',
  dengDaiShouHuo: '受取待ち',
  wanCheng: '完了',
  xinJianFaHuoDan: '新規ASN',
  woDeShenQing: '私の申請：',
  yinCangLiuChengTu: 'フローチャートを非表示',
  qingShouDaoHuoHou:
    '商品・成果物を受け取る前には「受取確認」ボタンをクリックしないでください。ご不明な点がありましたら、購買担当者に連絡してください。',
  yuJiLuYueRi: '納品予定日',
  faHuoFuJian: '添付ファイル',
  shouHuoXinXi_2: 'お届け先情報',
  lianXiFangShi: '携帯番号',
  dingDanZongE: '注文金額',
  xiaDanShiJian: '発注日時',
  jiaJi: '至急',
  qingShuRuXunJia_2: 'RFx番号/OA申請番号を入力してください',
  oADanHaoCai: 'OA申請番号/注文番号',
  xuQiuXinXiBu: '情報補完待ち',
  xunJiaCaoGao: '下書き',
  daiXiaDan: '注文待ち',
  heXiaoXinYongKa: '清算待ちカード利用明細',
  keXiaDanShuLiang: '最大注文可能数',
  keXiaDanJinE: '最大金額',
  xiaDanFangShi: '注文方法',
  gongYingShangBuYi: 'ベンダー名が不一致のため、作成できません。',
  gongYingShangLianXi_3: 'ベンダーの連絡担当者が不一致のため、作成できません。',
  gongYingShangLianXi_4: 'ベンダーの電子メーが不一致のため、作成できません。',
  shiFouFaSongDing: '注文メールを送信するかの設定の有無が不一致のため、作成できません。',
  zhangQiBuYiZhi: '仕入債務回転期間が不一致のため、作成できません。',
  jieSuanGongSiBu: '負担会社が不一致のため、作成できません。',
  shouHuoRenBuYi: '受取人が不一致のため、作成できません。',
  shouHuoRenYouXiang_2: '受取人の電子メールが不一致のため、作成できません。',
  shouHuoGuoJiaBu: 'お届け先所在国・地域が不一致のため、作成できません。',
  shouHuoDiQuBu: 'お届け先所在地が不一致のため、作成できません。',
  shouHuoChengShiBu: 'お届け先所在都市が不一致のため、作成できません。',
  shouHuoDiZhiBu: 'お届け先が不一致のため、作成できません。',
  shouHuoYouBianBu: 'お届け先の郵便番号が不一致のため、作成できません。',
  shouHuoRenLianXi_2: '受取人の連絡先情報が不一致のため、作成できません。',
  heTongHaoBuYi: '契約番号が不一致のため、作成できません。',
  zhiFuXinYongKa: '支払い用カードの口座番号が不一致のため、作成できません。',
  shiFouLaiZiDian: '購買プラットフォームが不一致のため、作成できません。',
  chuangJianRenBuYi: '作成者が不一致のため、作成できません。',
  chuangJianShiBai: '作成に失敗しました',
  gongYingShangLeiXing: 'ベンダーレベルは「小口」です。今回の注文金額は',
  chaoGuoXiaoJinE: 'で、金額制限を超えています。',
  deXianZhiQingZuo: '続行するには、ベンダーレベルをアップさせてください。',
  liJiShengJi: 'レベルアップ',
  dingDanJinEHan: '注文金額（税込）',
  jiZhunTiaoKuan: '仕入債務回転期間開始日計算基準',
  buChongHeTong: '補充合意書',
  oADan: '関連OA申請',
  xingCanKaoZongJia_3: '総参考価格（税込）',
  shouHuoDiQuGuo: 'お届け先（国・州・県）',
  qingShuRuOA: 'OA申請番号/製品名を入力してください',
  oADanHaoXing: 'OA申請番号-明細番号',
  qingShuRuXunJia_3: 'RFx番号/OA申請番号/商品名を入力してください',
  dingDanChuangJianCheng: '注文書を作成しました',
  daYu_2: '（',
  xuQianShuHeTong:
    '以上）契約書を締結する必要があります。発注に影響を与えないように、期限内に契約書の締結を完了してください。締結済みの場合は、契約書を関連付けてください。',
  guanLianHeTong: '関連契約書',
  xinJianCaiGouDing: '新規注文書',
  shouHuoFangShi: '検収者',
  xiaDanJinE: '注文金額',
  dingDanXingJinE: '注文金額',
  xinJianDingDan: '新規注文',
  dingDanXingJinE_2: '注文金額（税抜）',
  dingDanHangJinE_2: '注文金額（税込）',
  feiYongChengDanCheng: '支払コストセンター',
  yanShouJinE: '確認済み金額',
  oADanHaoDing: 'OA申請番号/注文番号',
  keFaHuoShuLiang: '納品可能数',
  keFaHuoJinE: '納品可能金額',
  benCiFaHuoShu: '今回の納品数',
  benCiFaHuoJin: '今回の納品金額',
  faHuoXingBeiZhu: '備考',
  benDanLuYueJin: '納品金額（税込）',
  faHuoXinXi: '納品情報',
  chuangJianRen: '作成者',
  faHuoLeiXing: '郵送手段',
  faHuoBeiZhu: '備考',
  zuiDuoShangChuanZhang_2: '最大で２０枚までアップロード可能。各ファイルは最大で100MBまで',
  mingXiXing: '明細（{val1} 行）',
  heTongPDF: '契約書2.pdf',
  yiZaiYanShouZhong: '検収中のため、操作できませんでした',
  faHuoDanGuanBi: 'ASNをクローズしました',
  guanBiFaHuoDan: 'クローズする',
  gaiFaHuoDanBao: 'このASNには',
  xingJiangQuanBuGuan: '件の明細があります。全てのクローズしますか？',
  guanBiYuanYin: '申請理由',
  faHuoDanXiangQing: 'ASNの詳細{val1}',
  yiGuanBi: 'クローズ済み',
  faHuoDanFuJian: '添付ファイル',
  faHuoXinXi_2: '納品情報 ({val1})',
  faHuoDanHangHao: 'ASN明細番号',
  shiJiFaHuoJin: '納品金額',
  danJiaHanShui: '単価（税込）',
  yunFeiHanShui: '運賃（税込）',
  baoJiaBeiZhu: '見積に関する備考',
  gongYingShangLianXi_5: 'ベンダー側連絡担当者のメールアドレス',
  gongYingShangLeiXing_2: 'ベンダー種類',
  gongYingShangYouXiang: 'ベンダーのメールアドレス',
  dangQianHeZuoZhuang: '現在のステータス',
  bianGengXinXi: '情報の変更',
  muBiaoHeZuoZhuang: '変更後のステータス',
  bianGengShuoMing: '変更理由',
  zhunRuXinXi: '登録審査用情報',
  tuiJianRen: '推薦者',
  tuiJianLiYou: '推薦理由',
  zhunRuShuoMing: '参入説明',
  danJiaHanShui_2: '単価（税込）',
  dingBiaoJinEWei: '落札金額（税抜）',
  ziDongZhuanPO: 'POの自動作成',
  dingBiaoXinXi: '落札情報',
  zhongBiao: '落札',
  weiZhong: '落札されない',
  ruKuDanHangHao: '入庫チケット-明細番号',
  ruKuZiGuan: '操作者',
  jiaoYanJieGuo: '検証結果',
  shiBaiYuanYin: '失敗理由',
  shouHuoDanXiangQing: '受取チケット詳細情報',
  faHuoDanHangHao_2: 'ASN-申請明細行番号',
  weiShuiDanJia: '税抜単価',
  faPiaoLeiXing: '請求書種類',
  yanShouDanHangHao: '検収チケット-明細番号',
  ruKuXinXi: '入庫情報',
  faHuoDan: 'ASN',
  daiYanShouJinE_2: '確認待ち金額（税込）',
  faHuoShiJian: '納品日時',
  oADanHaoWu: 'OA申請番号/追跡番号/ASN番号',
  dingDanHangHao_2: '注文書-明細番号',
  yanShouJinEHan_2: '検収金額（税込）',
  shouHuoShiJian: '受取日時',
  oADanHaoShou: 'OA申請番号/受取チケット番号/注文番号/計上チケット番号',
  biaoTi: 'タイトル',
  feiYongChengDanBu: '支払部署',
  shouHuoRenDianHua: '電話番号',
  queDingWanChengXun: '見積書を送信しますか？',
  xunJiaYiWanCheng: '見積書を送信しました',
  wanChengXunJia: '見積書を送信',
  xuQiuChi: 'リクエストプール',
  xunJia: '見積書',
  shenQingDanHao: '申請書番号',
  gongYingShangJianCheng: '略称',
  shenPiZhuangTai: '審査ステータス',
  qingShuRuGongYing: 'ベンダー名、略称、OA申請番号、申請番号で検索',
  heZuoZhuangTai_2: 'ステータス：',
  bianGengShuoMing_2: '変更理由：',
  shiJian: '日時',
  dangQianShaiXuanTiao: '一致する結果がありません。こちらをクリックして、',
  qingKongShaiXuanTiao: 'フィルターをクリアします',
  neiRongBianGeng: '情報の変更',
  bianGengDanHao: '変更チケット番号',
  bianGengNeiRong: '変更内容',
  shenPiJieDianMing: '承認ルート一覧',
  queRenFanHuiShang: '前のステップに戻ってもよろしいですか?',
  tianXieDeShuJu: '編集内容は破棄されます。',
  shangYiBu: '前へ',
  xiaYiBu: '次へ',
  jiChuXinXi: '基本情報',
  qingShuRuGongYing_2: 'ベンダー名を入力してください',
  tongYiSheHuiXin: '統一社会信用コード',
  qingShuRuTongYi: '統一社会信用コードを入力してください',
  qingShuRuTI: 'TIN を入力してください',
  guanLiYuanXingMing: '管理者',
  qingShuRuGuanLi: '管理者の氏名を入力してください',
  guanLiYuanYouXiang: '管理者のメールアドレス',
  qingShuRuGuanLi_2: '管理者のメールアドレスを入力してください',
  qingShuRuXingMing: '氏名を入力してください',
  zhengJianLeiXing: '本人確認書類',
  qingShuRuZhengJian: '本人確認書類の種類を入力してください',
  zhengJianHaoMa: '証明書番号',
  qingShuRuZhengJian_2: '証明書番号を入力してください',
  qingShuRuLianXi: 'メールアドレスを入力してください',
  qiYeXinXi: '事業体情報',
  dangAnJiLu: '変更履歴',
  dangQianZhanShiNei:
    '変更内容は現在審査中ですので、変更前の内容を表示しています。変更の反映までしばらくお待ちください。',
  daiShenHe: '審査待ち',
  zhunRuShenQing: '登録情報審査申請',
  qingShuRuGongYing_3: 'ベンダーの名称/略称/統一社会信用コード/TINで検索',
  fuWuPinLei: '商品カテゴリ',
  zhunRuShiJian: '承認日時',
  yiRuKu: '登録済み',
  weiRuKu: '登録待ち',
  zhunRuShenPi: '登録情報審査結果',
  qingShuRuGongYing_4: 'ベンダー名/略称/申請書番号を入力してください',
  caiGouYuanCaiGou: '購買担当者-購買部２級部署責任者',
  shouGongFaQi: '手動で行うレベル変更申請が必要です',
  ruVMDM: 'VMDMで「ブラックリスト」に登録された場合（ECCに同期されるデータ）、レベルアップは自動的に禁止されます。',
  caiGouYuanFaWu: '購買担当者-法務部BP-財務部BP-購買部２級部署責任者',
  ruVMDM_2: 'VMDMで「ブラックリスト」に登録された場合、レベルアップは自動的に禁止されます。',
  zhongXuanBingKaiZhan: '落札され、取引がある場合に、自動的にレベルアップされます',
  shouCiXiaDanChu: '取引する際の自動的にレベルアップされます',
  nianNeiWeiKaiZhan: '2年以内に取引がない場合に、自動的にレベルダウンされます',
  youHeTongQuHe:
    '契約を締結した場合は、契約の最終有効期限から計算します。契約を締結していないが取引がある場合は、最終注文日から計算します。契約も注文もない場合は、登録情報が承認された日から計算します。',
  caiGouYuanCaiGou_2: '購買担当者-購買部２級部署責任者-購買部1級部署責任者',
  caiGouYuanFaWu_2: '購買担当者 - 法務部BP- 財務部BP- 購買部２級部署責任者 - 購買部1級部署責任者',
  caiGouYuanCaiGou_3:
    '購買担当者-購買部２級部署責任者-購買部1級部署責任者。財務部BP、法務部BP、内部統制部BPにコピーを送信',
  yiJinXingXinXi:
    '１．情報登録は完了したが、審査を受けていないベンダー。\r\r\n２．審査に合格しているが、取引がないか、前回の取引から2年以上経過しているベンダー。',
  yiTongGuoRuKu:
    '登録情報審査に合格しているが、合格してから2年未満のベンダー。取引金額が少なく、取引リスクが低い。財務・法務部による審査が不要です。（半年間の累積取引金額が10万元以下、毎回の取引の金額が5万元以下である必要がります）',
  yiTongGuoRuKu_2: '審査に合格しているが、合格してから2年未満のベンダー。',
  zhiZaiJinNianNei:
    '過去2年間に取引のあるベンダー。（当社と取引した「潜在/小口」のベンダーのレベルは自動的に「馴染み/小口」に変更されます）',
  zhiZaiJinNianNei_2: '過去2年間に取引のあるベンダー。',
  heZuoGuoChengZhong:
    '取引過程で成果物は検収基準に満たしません。または契約履行上の問題があるなどの異常が発生した後、購買部による評価結果に基づき、当社との取引が一時的に中止されたベンダー。',
  yuanZeShangYingYong: '原則として永久に本社との取引が禁止されているベンダー。',
  buQuFen: '分類なし',
  muBiaoJiBie: '変更後レベル',
  shenPiLianLu: '承認ルート',
  shengJiangJiChuFa: 'レベル変更トリガー',
  jieDuanShuoMingXiang: '説明',
  shengMingZhouQiJi: 'ベンダーレベルとその説明',
  shengMingZhouQiLiu: 'ベンダーレベル変更ルール',
  ziZhiPinLeiYu: '資格カテゴリとサービス カテゴリが一致しません。再編集してください',
  tuiJianYuanYin: '推薦理由',
  faDingDaiBiaoRen: '法定代理人',
  gongSiZhuYeWang: '会社の公式サイト',
  yingYeQiXian: '営業許可書の有効期限',
  chengLiRiQi: '会社設立日',
  naShuiRenZiZhi: '納税者資質',
  shengShi: '省/市',
  quXian: '区（県）',
  yingYeZhiZhaoFu: '営業許可証のスキャンファイル',
  zhunRuZiLiao: '登録情報審査用ファイル',
  dengJiXinXi: '登録情報',
  fuWuPinLeiNei: 'サービスカテゴリー内容',
  ziZhiXinXi: '資格情報',
  ziZhiMingCheng: '資格名',
  ziZhiWenJian: '資格証明書類',
  ziZhiYouXiaoQi: '資格有効期限',
  yiCunZaiXiangTong: '同じ資格情報が既に存在します。修正して送信してください。',
  tianJiaZiZhi: '新規資格',
  ziZhiXinXi_2: '{val1}資格情報',
  tianJia: '追加',
  lianXiRenXinXi: '連絡担当者情報',
  lianXiRenLeiXing: '連絡担当者種類',
  zhiWu: '役職',
  shouJiHao: '携帯番号',
  yiCunZaiXiangTong_2: '同じ連絡先がすでに存在します。変更してから再試行してください',
  tianJiaLianXiRen: '連絡担当者を追加',
  lianXiRen_2: '{val1}連絡担当者',
  yiCunZaiMoRen: 'デフォルトの口座がすでに存在します。このアカウントをデフォルトのアカウントとして設定しますか?',
  yinHangZhangHaoXin: '口座情報',
  zhangHaoMingCheng: '口座名義',
  jieSuanBiZhong: '取引通貨',
  kaiHuHangMingCheng_2: '取引銀行名',
  zhiHangMingCheng: '支店名',
  fuZhiXinZeng: '複製',
  yiCunZaiXiangTong_3: '同じ銀行口座がすでに存在します。変更してから再試行してください',
  tianJiaYinHangXin: '新規銀行情報',
  yinHangXinXi_2: '{val1}銀行情報',
  lianHangHao: 'CNAPS番号',
  kaiHuHangShengShi: '取引銀行所在省市',
  yinHangDiZhi: '詳細住所',
  shouXuFeiChengDan: '手数料負担側',
  shiFouWeiMoRen: 'デフォルトのアカウントですか?',
  caiWuZhuangKuang: '財務状況',
  nianFen: '年度',
  yingYeShouRuWan: '売上高（万）',
  liRunZongEWan: '営業利益（万）',
  shiJiaoZiBenWan: '資本金（万）',
  nianDuShenJiBao: '年次監査報告書',
  caiWuBaoBiao: '財務諸表',
  youXiaoQiXian: '有効期間',
  yiCunZaiXiangTong_4: '同じ財務状況がすでに存在します。変更して再送信してください。',
  tianJiaCaiWuZhuang: '新規財務状況',
  caiWuZhuangKuang_2: '{val1}財務状況',
  chengGongAnLi: '実績紹介',
  anLiMingCheng: '名称',
  keHuMingCheng: '取引先',
  fuWuChanPinFan: 'サービス/製品範囲',
  xiangMuGuiMoWan: '取引金額（万）',
  xiangMuShiJian: '日付け',
  xiangMuJianJie: '概要',
  xiangMuChengGuo: '成果',
  yiCunZaiXiangTong_5: '同じ実績がすでに存在します。変更してから再試行してください',
  tianJiaChengGongAn: '実績を追加',
  chengGongAnLi_2: '{val1}実績',
  lianXiRenZhiWei: '業種',
  keHuPingJiaTui: '取引先からの評価/推薦書',
  heTongZhengMingHuo: '関連契約書またはプロジェクト資料',
  liYiGuanXiShen: '利害関係者報告',
  yuanGongXingMing: '氏名',
  yuanGongSuoZaiGong: '所属事業体',
  guanXi: '利害関係',
  yiCunZaiXiangTong_6: '同じ利害関係者がすでに存在します。変更してから再試行してください',
  tianJiaLiYiGuan: '利害関係者を追加',
  liYiGuanXi: '{val1}利害関係者',
  gongYingShangID_2: 'ベンダーID:',
  gongYingShangYouXiang_2: 'ベンダーのメールアドレス:',
  zhuCeGuoJiaDi_2: '登記国・地域',
  gongYingShangLeiXing_3: 'ベンダー種類',
  qiYeMingCheng: '会社名',
  dengJiXinXi_2: '登録情報',
  moRenZhangHao: 'デフォルトアカウント',
  ziGuanDaiShou: '資産管理者（代理検収）',
  yuanGongBenRenShou: '申請者本人',
  yongHuGuanLi: 'ユーザー管理',
  xunYuanGang: 'ベンダー開拓担当',
  caiGouJingLi: '購買マネージャー',
  caiGouBuMenFu: '購買部責任者',
  caiGouGuiZeGuan: '購買ルール管理',
  caiWu: '財務',
  shenJi: '会計監査',
  neiKong: '内部統制',
  tian_7: '90日',
  qiYe: '企業',
  geRen: '個人',
  juMinShenFenZheng: '居民身分証（中国）',
  gangAoJuMinLai: '港澳居民来往大陸通行証',
  zhongHuaRenMinGong: '中華人民共和国港澳居民居住証',
  taiWanJuMinLai: '台湾居民来往大陸通行証',
  zhongHuaRenMinGong_2: '中華人民共和国台湾居民居住証',
  zhongGuoHuZhao: '中国のパスポート',
  waiGuoHuZhao: '外国のパスポート',
  waiGuoRenYongJiu: '外国人永久居留身分証',
  zhongHuaRenMinGong_3: '中華人民共和国外国人就労許可証（A類）',
  zhongHuaRenMinGong_4: '中華人民共和国外国人就労許可証（B類）',
  zhongHuaRenMinGong_5: '中華人民共和国外国人就労許可証（C類）',
  qiTaGeRenZheng: 'その他',
  yiBanNaShuiRen: '一般納税者',
  xiaoGuiMoNaShui: '小規模納税者',
  feiZengZhiShuiNa: '非増値税納税者',
  qiTa: 'その他',
  shangWuDuiJieRen: 'ビジネス部連絡担当者',
  caiWuDuiJieRen: '財務部連絡担当者',
  wangYiJiTuan: 'NetEaseグループ',
  shuangFang: '両側',
  shengMingZhouQiBian: 'ベンダーレベルの変更',
  zhuZhangHao: 'メインアカウント',
  ziZhangHao: 'サブアカウント',
  wuLiuKuaiDi: '物流',
  feiWuLiuKuaiDi: '速達',
  wuXinXi: 'データなし',
  weiFaHuo: '発送待ち',
  yiFaHuo: '発送済み',
  yiQianShou: '受取済み',
  yiTuiHuo: '返品済み',
  peiSongZhong: '配達中',
  zengZhiShuiZhuanPiao: '増値税専用発票',
  zengZhiShuiPuPiao: '増値税普通発票',
  dianZiFaPiaoZhuan: '全電発票（専用発票）',
  dianZiFaPiaoPu: '全電発票（普通発票）',
  niMeiYouFangWen: 'アクセス権限がありません',
  chongXinDengLu: '再ログイン',
  fanHuiShouYe: 'ホームページへ',
  ziChanXinXi: '資産情報',
  ziChanBianHao: '資産番号',
  pODanHao: '注文書番号',
  qingShuRuWanZheng_2: '注文書番号を正しく入力してください',
  daiXuQiuQueRen: '確認待ち申請',
  daiXunJia: '見積結果待ち',
  daiXunJiaQueRen: '見積書の下書き',
  dingDanXinXiBu: '情報補完待ち',
  daiBuChongHeTong_2: '契約書情報補完待ち',
  zhengDanFaHuo: '全部発送',
  dingDanHangFaHuo: '一部発送',
  guanLianXinYongKa_2: '関連するコーポレートカード利用明細書',
  qingShuRuWanZheng_3: '注文書番号を正しく入力してください',
  qingXianBuChongXu: '購買申請の情報を補完してください',
  queRenTongGuo: '確認する',
  xuQiuYiQueRen: '確認しました',
  xingCanKaoZongJia_2: '参考価格の小計',
  buKeQuXiao: 'キャンセルできません',
  gaiXuQiuGongXing: '計{val1}件の購買申請を取り消します。続行しますか？',
  xuQiuXingQueRen: '申請を確認しました',
  buTongYiJiPin: '商品の所属カテゴリが異なるため、見積書を作成できません',
  xuQiuQueRen: '申請を確認',
  feiYongDaLei: '費用カテゴリー',
  feiYongXiaoLei: 'ビジネスサイド用サブカテゴリー',
  xuQiuXinXiQue: '申請情報確認済み',
  xuQiuXinXiQue_2: '確認済み申請情報の一覧',
  fangDiChanJiShe: '不動産・設備',
  yuanGongFuLiQi: '福利厚生-その他',
  shiJiShiYongRen_10: '実際使用者の所属部署が異なるため、見積書を作成できません',
  xunJiaBiaoTi: '見積書タイトル',
  wenXinTiShiShe: 'ご注意：CEOによる審査が必要な場合、【{val1}】のコンテンツは自動的にPOPOに同期されます。',
  xunJiaBiaoTiHe: '見積書タイトル、落札理由、添付ファイル',
  qingShuRuXunJia:
    '見積書タイトルを入力してください。CEOによる審査が必要な場合、記入されたタイトルはPOPOに同期されます。',
  qingShuRuHeJia: '落札理由を入力してください。CEOによる審査が必要な場合、記入された理由はPOPOに同期されます。',
  queDingJinYongYongHu: 'ユーザーを無効にしますか？',
  queDingQiYongYongHu: 'ユーザーを有効にしますか？',
  jinYongYongHuHou: '続行すると、このユーザーはログインできなくなります。',
  zuiHouGengXinRen: '最終編集者',
  lianXiRenXingMing: '連絡担当者',
  banGongYeWuYong: 'オフィス/ビジネス用',
  iTBuMenZhuan: 'IT部専用 - アクセサリー',
  tian_2: '日',
  dianJiFaQiP: 'popoで会話する',
  buTongJieSuanZhu: '支払会社が異なるため、見積書を作成できません',
  zhongZhi: 'リセット',
  huiLuQiJian: '期間',
  yuanBiZhong: '換算元通貨',
  zheSuanBiZhong: '目標通貨',
  chaKanXuQiu: '申請詳細の閲覧',
  chaKanXunJiaDan: 'RFx詳細の閲覧',
  shanChuXunJiaDan: 'RFxの削除',
  chaKanDingDan: '注文書詳細の閲覧',
  gengXinDingDan: '注文書の編集',
  xinZeng: '新規作成',
  jieDanGuiZe: '購買担当者割り当てルール',
  rFXShenPi_2: 'RFx承認ルール',
  teDingBiaoQian: 'タグ',
  suoYouQuanXian: 'すべて',
  quanXianPeiZhi: '権限設定',
  chuangJianJiaoSe: '新規ロール',
  bianJiJueSe: 'ロールの編集',
  jueSeZhuangTai: 'ステータス',
  jueSeMingCheng: 'ロール名',
  jueSeMingChengBu: 'ロール名は必須項目です',
  shanChuChengGong: '削除しました',
  shanChuShiBai: '削除に失敗しました',
  bianJiGuiZe: 'ルールの編集',
  guiZeMingCheng: 'ルール名',
  guoJiaLeiXing: '国/種別',
  caiGouLeiXing: '商品の種別',
  shangPinMingChengPei: '品名・構成・ブランド',
  guoJiaDiQu_2: '国・地域',
  buMen: '部署',
  shenQingRenJiBie: '申請者の管理等級',
  shenQingRenXingMing: '申請者氏名',
  shiJiShiYongRen_5: '実際使用者の管理等級',
  shiJiShiYongRen_6: '実際使用者氏名',
  jieSuanZhuTi: '支払会社',
  xinJianBiaoQian: '新規タブ',
  bianJiBiaoQian: 'タブの編集',
  biaoQianMing: 'タグ名称',
  biaoQianMingBuDe: 'タグ名は必須項目です',
  biaoQianYanSe: 'カラー',
  panDuanTiaoJian: 'トリガー',
  xinZengTiaoJian: '追加',
  xuHao: '番号',
  guiZe: 'ルール',
  shenQingRenBuMen_2: '申請者の所属：{val1}',
  shenQingRenJiBie_2: '申請者の管理等級：{val1}',
  shiJiShiYongRen_7: '実際使用者の所属：{val1}',
  shiJiShiYongRen_8: '実際使用者の管理等級：{val1}',
  shiJiShiYongRen_9: '実際使用者：{val1}',
  jieSuanZhuTi_2: '支払会社：{val1}',
  gongYingShang_2: 'ベンダー：{val1}',
  yiXuanZeQuanBu: 'すべての従業員を選択しました',
  yiXuanZeQuanBu_2: 'すべての支払会社を選択しました',
  dangQianMoRenXuan:
    'デフォルトでは、「すべて」が選択されていますが、「指定された従業員のみ」に変更することができます。',
  dangQianMoRenXuan_2:
    'デフォルトでは、「すべて」が選択されていますが、「指定された支払会社のみ」に変更することができます。',
  xinZengCaiGouYuan: '担当者の追加',
  xinZengJieSuanZhu: '支払会社の追加',
  xingMing: '氏名',
  gongHao: '従業員番号',
  jieSuanZhuTiMing: '支払会社',
  jieSuanZhuTiI: '支払会社ID',
  quanBuRenYuan: 'すべて',
  zhiDingRenYuan: '指定された従業員のみ',
  yiChuCaiGouYuan: '削除',
  quanBuJieSuanZhu: 'すべて',
  zhiDingJieSuanZhu: '指定された支払会社のみ',
  yiChuJieSuanZhu: '削除',
  tianJiaYongHu: 'ユーザーの追加',
  bianJiYongHu: 'ユーザーの編集',
  qingZhengQueTianXie: '権限範囲は必須項目です',
  fenPeiJueSe: 'ロール',
  shuJuQuanXian: '権限範囲',
  xingMingGongHao: '氏名/従業員番号',
  xuanPinYiWanCheng: '選択が完了しました',
  qingFanHuiOA: 'OAシステムに戻って申請手続きの操作を継続してください',
  caiGouDingDanZheng: '発注書一覧',
  caiGouDingDanMing: '発注書明細',
  xunJiaDanHao: 'RFx番号',
  tiJiaoRiQi: '申請日',
  tiJiaoKaiShiRi: '時間で絞り込む（送信日）',
  dingDanZongEWei: '金額（税抜）',
  laiYuan: 'ソース',
  gengXinZhuTiHe: '主体、契約書、コメントの変更',
  dingDanHangHao: '注文書明細番号',
  baoJiaJinEWei: '見積金額（税抜）',
  chuangJianRiQi: '作成日',
  chuangJianKaiShiRi: '時間で絞り込む（作成日）',
  qiWangDaoHuoDi: '希望のお届け先',
  shouHuoLianXiRen: '受取人',
  gaiXingDuiYingDe: '続行すると対応するRFxが削除されます。続行しますか？',
  shanChuXunJiaDan_2: '削除しました',
  zanWu: 'なし',
  yanShouDanXiangQing: '検収チケットの詳細',
  yanShouDanHao: '検収チケット番号',
  yanShouRen: '検収担当者',
  yanShouShiJian: '検収日時',
  yanShouLeiXing: '操作種類',
  yanShouXinXi: '検収情報',
  oADanHaoXu: 'OA申請番号-申請明細行番号',
  caiGouDingDanHao: '注文番号-注文書行番号',
  jiTiDanHao: '計上チケット番号',
  jiTiZhuangTai: '計上ステータス',
  shouQuanQiXianYue: 'ライセンス有効期間/月',
  yanShouJinEHan: '金額（税込）',
  biZhong: '通貨',
  shiFouZiChan: '資産管理システムに登録済み物ですか？',
  caoGao: '下書き',
  tiJiaoJieShuRi: '時間で絞り込む（送信日）',
  daiYanShouShiChang: '保留期間',
  daiYanShouShuLiang: '検収待ち数',
  zuiXinWuLiuXin: '配送状況',
  yiShouHuoShuLiang: '検収済み数',
  weiQueRen: '未確認',
  yiTuiHui: '返品済み',
  weiShenHe: '審査待ち',
  yiShenHe: '審査済み',
  yiRuZhang: '記帳済み',
  yiChongXiao: '帳消し済み',
  caiGouDingDanHao_2: '注文番号',
  yanShouRiQi: '検収日',
  yanShouKaiShiRi: '時間で絞り込む（検収日）',
  yanShouJieShuRi: '時間で絞り込む（検収日）',
  jiTiRiQi: '計上日',
  jiTiKaiShiRi: '時間で絞り込む（計上日）',
  jiTiJieShuRi: '時間で絞り込む（計上日）',
  caiGouDingDanHao_3: '注文番号-明細番号',
  weiBaoQiXianYue: '保証期間/月',
  yiWanCheng: '処理済み',
  qingShuRuWanZheng: 'OA申請番号',
  shenQingRiQi: '申請日',
  shenQingKaiShiRi: '時間で絞り込む（申請日）',
  oADanHangHao_2: 'OA申請番号-申請明細行番号',
  renMinBiJinE: '人民元換算額（税込）',
  shouHuoDianHua: '電話番号',
  shouJianRen: '受取人',
  yuanYinJiXuQiu: '購買理由',
  chanPin: '関連製品',
  chanPinChengBenZhong: 'コストセンター',
  buChongXingCanKao: '参考金額、商品カテゴリを補完',
  tingYong: '無効',
  xingHao: '行番号',
  kaiHuHangMingCheng: '（取引銀行）支店名',
  zhangHuMingCheng: '口座名義',
  yinHangZhangHao: '銀行口座',
  kaiHuXuKeZheng: '口座開設許可',
  tiJiaoChengGong: '送信しました',
  tiJiao: '送信',
  gongYingShangID: 'ベンダー番号',
  gongYingShangMingCheng: 'ベンダー',
  dengBaiShiBianMa: 'DUNSナンバー',
  zhuCeGuoJiaDi: '登記国・地域',
  lianXiRen: '連絡担当者',
  lianXiYouXiang: 'メールアドレス',
  chengLiShiJian: '会社設立日',
  zhuCeZiBenWan: '資本金（万）',
  gengXinShiJian: '更新日時',
  gengXinRen: '編集者',
  xiangXiDiZhi: '詳細情報',
  jingYingFanWei: '事業目的',
  heFaZhuCeZheng: '会社の登記事項証明書',
  zhunRuZiLiaoShang: '登録情報審査用ファイル',
  yinHangXinXi: '口座情報',
  dengBaiShi: 'DUNSナンバー',
  faRenDaiBiaoRen: '法定代理人/管理者',
  zuiHouGengXinShi: '最終編集日時',
  caoZuoShiBai: '操作に失敗しました',
  xinZengGongYingShang: 'ベンダーの追加',
  zhiChiKuoZhanMing: '対応ファイル形式：pdf .jpg. png。サイズ：100MB/ファイル。',
  shangChuanFuJian: '添付ファイル',
  chuangJianChengGong: '作成しました',
  qingShuRuZhengQue: '有効なメールアドレスを入力してください',
  qingShuRuZhengQue_2: '有効な携帯電話番号を入力してください',
  zhuCeBiZhong: '通貨',
  tian_3: '15日',
  tian_4: '30日',
  tian_5: '45日',
  tian_6: '60日',
  shouHuo: '検収',
  tuiHuo: '返品',
  zongYunFeiHanShui: '運賃総額（税込）：{val1}',
  dingBiaoJinEHan: '落札金額（税込、運賃の按分額を含む）',
  dingBiaoJinEHan_2: '落札金額（税込）',
  yunFei: '(運賃:{val1} )',
  yunFei_2: '運賃',
  zaiGaiBaoJiaDan: '【{val1}】の運賃',
  zongYunFeiHanShui_2: '運賃総額（税込）',
  quXiaoXuQiu: '購買申請の取り消し',
  xuQiuYiQuXiao: '申請を取り消しました',
  hanShui: '税込金額',
  weiShui: '税抜金額',
  gongYingShangXiangQing: 'ベンダー情報',
  caiGouDingDanXiang: '発注書詳細情報{val1}',
  faSongDingDan: '発注',
  faSongZhi: '送信先',
  faSongChengGong: '送信しました',
  quXiaoDingDan: '注文の取り消し',
  caiGouDingDanYi: '注文を取り消しました',
  dingDanHao: '注文番号',
  dingDanLaiYuan: 'ソース',
  danJuBiaoQian: 'タグ',
  dingDanJinE: '金額',
  yuFuKuanShenQing: '前払い申請',
  xinYongKaZhangDan: '関連するコーポレートカード利用明細書',
  shouHuoXinXi: 'お届け先情報',
  guoJiaDiQu: '国・地域',
  shouHuoLianXiDian: '電話番号',
  oADanHangHao: 'OA申請明細番号',
  xunJiaDingBiaoXing: '見積書明細番号',
  shouQuanQiXian: 'ライセンス有効期間',
  weiBaoQiXian: '保証期間',
  xuQiuShuLiang: '必要数',
  xiaDanShuLiang_2: '注文数',
  dingDanHangBiZhong: '通貨',
  xingYunFeiHanShui: '運賃（税込）',
  dingDanHangJinE: '合計（運賃を含む）',
  shouHuoShuLiang: '検収数',
  shouHuoDanHao: '検収チケット番号',
  fuJiaXinXi: '付加情報',
  chengBenZhongXin: 'コストセンター',
  chanPinDuan: '関連製品情報',
  wuLiuXinXi: '配送状況',
  zhangDanID: '利用明細書ID',
  shangJiaMingCheng: '利用先',
  jiaoYiShiJian: '利用日時',
  jiaoYiJinE: '利用金額',
  jiaoYiBiZhong: '利用通貨',
  xinYongKaBenBi: '金額（カードの通貨）',
  zhangDanXiangQing: '明細書詳細情報',
  guanLianXinYongKa: '関連するコーポレートカード利用明細書({val1})',
  beiZhuXinXi: '備考',
  caoZuoShiJian: '編集日時',
  feiKuaiDiWuLiu: '非配達業者',
  wuLiuGongSi: '配送業者',
  wuLiuDanHao: '送り状番号',
  shiJiFaHuoShu: '発送数',
  yuJiDaoHuoRi: 'お届け予定日',
  faHuoRiQi: '発送日',
  queDingFaHuoMa: '発送情報を更新しますか？',
  faHuoShiBaiShi: '発送情報の更新に失敗しました。発送数は、注文数と発送待ち数を超えることはできません。',
  yiTiJiaoFaHuo: '更新しました',
  faHuo: '発送情報の更新',
  tianJiaXing: '追加',
  mingXiHangHao: '明細行番号',
  daiFaHuoShuLiang: '発送待ち数/注文数',
  faHuoShiBaiShi_2: '更新に失敗しました。発送数は0より大きい必要があります',
  piLiangFaSong: '一括で送信',
  piLiangFaHuo: '一括で発送情報を更新',
  queRenFaHuo: '確認',
  shiJiFaHuoShu_2: '総発送数：{val1}',
  caoZuoRen_2: '編集者：',
  bianGengWei: '変更後',
  xuQiuDanXiangQing: '詳細情報',
  zhiHangQingKuang: '進捗状況',
  shenQingRen: '申請者',
  shenQingRenYouXiang: '申請者メールアドレス',
  shenQingRenBuMen: '申請者所属部署',
  shenQingSuoShuGong: '申請者所属会社',
  shenQingRenChengBen: '申請者所属コストセンター',
  shenQingShiJian: '申請日時',
  shiFouDianShang: 'ECサイトでの購買ですか？',
  shiFouBenRenShi: '本人申請ですか？',
  shiJiShiYongRen_4: '実際使用者の所属コストセンター',
  canKaoZongJinE: '参考金額',
  guoJia: '国',
  diQu: '地域',
  shouHuoLianXiFang: '電話番号',
  qiWangDaoHuoRi: 'お届け希望日',
  xuQiuXinXi: '基本情報',
  oAHangHao: 'OA申請明細番号',
  shuLiang: '数量',
  xuQiuJiYuanYin: '申請理由',
  yongTu: '用途',
  dengDaiShiChang: '保留時間',
  xiaoShi: '{val1}時間',
  xingCanKaoZongJia: '参考価格の小計（税込）',
  xuQiuShenPiJie: '承認ステップ一覧',
  queRen: '確認',
  jinDu: '進捗状況',
  fanKuiShiJian: '送信日時',
  tiJiaoFanKui: '送信する',
  tianJiaJinDu: '進行状況の追加',
  zhuanJiaoCaiGouYuan: '購買担当者の変更',
  zhuanJiaoChengGong: '変更しました',
  dangQianCaiGouYuan: '現在の担当者',
  xinCaiGouYuan: '新しい担当者',
  faQiXunJia: 'RFxの作成',
  buChongCanKaoJia: '見積書を作成するには参考価格の提出が必要です',
  queDingYaoFaQi: '見積書を作成しますか？',
  quanXuan: 'すべてを選択',
  yiXuanXingCaiGou: '選択中：{val1}行のデータ',
  oAHangHao_2: 'OA申請明細番号',
  shangPin: '商品',
  xunJiaXinXi: '見積結果',
  xunJiaDan: 'RFx',
  dingDanXinXi: '注文書情報',
  dingDanZhuangTai: 'ステータス',
  yanShouShuLiang: '検収数',
  zhiXingMingXi: '明細',
  daiBuChongCanKao: '参考価格提出待ち',
  zhen: 'true',
  jia: 'false',
  buQiYong: '無効',
  youXiao: '有効',
  banGongYong: 'オフィス用品',
  iTBeiJi: 'IT予備機',
  yuanGongFuLiNian: '福利厚生-忘年会景品',
  yuanGongFuLiJie: '福利厚生-季節、祭りのギフト',
  huoDongJiangPin: 'イベント景品',
  daiBuChongHeTong: '契約書情報提出待ち',
  daiFaHuo: '発送待ち',
  daiYanShou: '検収待ち',
  buFenYanShou: '一部検収完了',
  yiYanShou: '検収済み',
  caiGouShenQing: '購買申請',
  dianShang: 'EC',
  shenPiZhong: '審査中',
  shenPiJuJue: '否認済み',
  shenPiTongGuo: '承認済み',
  daiRenLingXinYong: '関連支払明細書設定待ち',
  chaoGuoCanKaoJia: '参考価格{val1}%を超えています',
  wan: '万',
  shiWan: '十万',
  baiWan: '百万',
  qianWan: '千万',
  yi: '億',
  shiYi: '十億',
  baiYi: '百億',
  qianYi: '千億',
  weiBaoCunNeiRong: 'このページを離れると、未保存の編集は破棄されます。よろしいですか？',
  cheHuiYuanYin: '取り下げ理由',
  cheHuiQingQiuYi: '取り下げ請求は送信されました。しばらくしてから再読み込みしてみてください。',
  zhiDaoLe: 'OK',
  quXiaoYuanYin: '落札取り消しの理由',
  xingVALDing: 'Line{val1}：落札金額に0より大きい数字を入力してください',
  jieDian: 'ステップ',
  shenPiShiJian: '承認日時',
  caoZuoRen: '承認者',
  shenPiYiJian: 'コメント',
  rPXShenPi: '承認ルート一覧',
  shenPiDanHao: 'チケット番号：',
  shouQi: '折り畳む',
  zhanKai: '展開する',
  dingBiaoShuLiang: '落札数：',
  dingBiaoZongE: '落札金額：',
  tiShiGaiXunJia:
    '注意: 参考価格の合計が¥ 20,000.00を超えているため、３社以上のベンダーの見積を提供する必要があります。',
  dingBiao: '落札者決定',
  zuiYouJia: '最低価格',
  zanWuBaoJiaXin: '見積データがありません',
  qingDianJiXiaFang: '下のボタンをクリックして見積データを登録してください',
  liJiBaoJia: '登録する',
  heTongMingXi: '契約書情報',
  heTongBianHao: '契約書番号',
  heTongMingCheng: '契約書タイトル',
  qianDingRiQi: '締結日',
  heTongKaiShiRi: '契約開始日',
  heTongZhongZhiRi: '契約終了日',
  zongJinE: '総額',
  biaoQian: 'タグ',
  heJiaShuoMing: '落札理由',
  baoJiaFuJian: '見積書のアップロード',
  shiJiShiYongRen: '実際使用者',
  shiJiShiYongRen_2: '実際使用者の所属部署',
  shiJiShiYongRen_3: '実際使用者のメールアドレス',
  caiGouXuQiuFu: '購買依頼関連添付ファイル',
  shouHuoRenLianXi: '電話番号',
  caiGouYuanYin: '購買理由',
  dingBiaoZongE_2: '総額',
  yiXunJiaZongShu: '見積結果あり/合計',
  ruanJian: 'ソフトウェア',
  yingJian: 'ハードウェア',
  baoJia: '見積書{val1}',
  dingBiaoJinE: '落札金額',
  gongYingShangLianXi: 'ベンダー側連絡担当者',
  gongYingShangLianXi_2: '連絡担当者の連絡先',
  faSongDingDanTong: '発注通知の送信',
  shi: 'はい',
  fou: 'いいえ',
  xiaZai: 'ダウンロード',
  queDingCheHuiShen: 'この見積書を取り下げますか？',
  cheHuiShenPiCheng: '取り下げました',
  cheHuiShenPi: '取り下げ',
  queDingQuXiaoDing: '落札を取り消しますか？',
  quXiaoDingBiaoCheng: '取り消しました',
  quXiaoDingBiao: '落札の取り消し',
  shouHuoRenYouXiang: '受取人のメールアドレス',
  tiJiaoDingBiaoShi: '送信に失敗しました',
  dingBiaoShuLiangWei: '注文数は 0 です。送信できませんでした。',
  woZhiDaoLe: 'OK',
  xingZhongBiaoShuLiang: 'Line{val1}：落札数が誤っています。再編集してください。',
  xingYuFuJinE: 'Line{val1}：「前払金」に0より大きい数字を入力してください',
  queDingYaoTiJiao: '送信しますか？',
  dingBiaoTiJiaoHou: '送信後の見積書は審査を受けます。審査が承認された場合、注文書が自動的に作成されます。',
  bianJiXunJiaDan: 'RFxの編集',
  chuangJianXunJiaDan: 'RFxの作成',
  tiJiaoDingBiao: '送信する',
  xinZengBaoJia: '追加する',
  baoJia_2: 'Line{val1}-見積書{val2}',
  queDingYaoShanChu: 'このデータを削除しますか？',
  danGeWenJianBu: '1つのファイルにつき100MBまでです',
  zhiZhiChiMYi: '{val1}以下のファイルをアップロードしてください',
  zhiNengShangChuanGe: 'アップロードできるファイル数は{val1}個までです',
  xuanZeHeTong: '契約書を選択',
  qingXuanZeHeTong: '契約書を選択してください',
  shenPiZhongDaiXun_2: '承認待ち/見積依頼中',
  yiShenPiDaiXun_2: '承認済み/見積依頼中',
  shenPiZhongYiXun_2: '承認待ち/見積依頼中',
  shenPiZhongXunJia_2: '承認待ち/RFx承認待ち',
  yiShenPiXunJia_2: '承認済み/RFx承認待ち',
  pUNCH: 'Punchout ホワイトリスト・エイリアス管理',
  zhanDian: 'サイト',
  yongHuLieBiao: 'ユーザー一覧',
  baiMingDanGongNeng: 'ホワイトリストのステータス',
  queDingJinYongMa: '無効にしますか？',
  queDingQiYongMa: '有効にしますか？',
  yiJinYong: '無効に設定しました',
  yiQiYong: '有効に設定しました',
  qiYong: '有効',
  jinYong: '無効',
  yongHuYouXiang: 'ユーザーのメールアカウント',
  huaMingYouXiang: 'メール エイリアス',
  qingTianXieYongHu: '入力してください',
  xinZengYongHu: '追加',
  zanWuXianXiaBao: '添付ファイルはありません',
  xianXiaBaoJiaFu: '見積書のアップロード',
  shenPiZhongXunJia: '購買依頼承認待ち/RFx承認待ち',
  yiShenPiXunJia: '購買依頼承認済み/RFx承認待ち',
  queDingShanChuMa: 'このデータを削除しますか？',
  shanChu: '削除する',
  liKaiYeMianDang: 'このページを離れると、入力内容は破棄されます。',
  ciXiangBiTian: '必須項目です',
  quXiao: 'キャンセル',
  baoCun: '保存する',
  quanBuPinLei: 'すべて',
  gengDuoShaiXuan: '絞り込み検索',
  yongHuPeiZhi: 'ユーザー管理',
  jueSe: 'ロール',
  yongHu: 'ユーザー',
  yeWuPeiZhi: 'ビジネス情報管理',
  huiLu: '為替レート',
  guiZePeiZhi: '割り当てルール',
  caiGouJieDanGui: '注文書割り当てルール',
  shenPiGuiZe: '承認ルール',
  teShuBiaoQian: 'タブ',
  gongZuoTai: 'ワークプレイス',
  caiGouXuQiu: '購買依頼',
  xunJiaDingBiao: '見積書',
  caiGouDingDan: '発注書',
  shouHuoGuanLi: '検収管理',
  gongYingShangGuanLi: 'ベンダー管理',
  xiTongGuanLi: 'システム設定',
  danHaoBaoKuoV: '{val1}を含むOA申請',
  danHaoBaoKuoV_2: '{val1}を含むRFx',
  danHaoBaoKuoV_3: '{val1}を含むPO',
  shangPinMingBaoKuo: '{val1}を含むPO',
  qingSouSuoDanHao: 'チケット番号/ベンダー/品名で検索',
  guanLiYuan: '管理者',
  tuiChuDengLu: 'ログアウト',
  guiZeZhuangTai: 'ステータス',
  manZuYiXiaTiao: '以下の条件を満たすと審査手続きが開始',
  tiaoJian: '条件',
  shanChuTiaoJian: '削除',
  tianJiaTiaoJian: '追加',
  xinJianShenPiGui: '新規審査ルールの作成-RFx審査',
  bianJiShenPiGui: '審査ルールの編集-RFx審査',
  baoCunChengGong: '保存しました',
  xinJianChengGong: '作成しました',
  xinJianGuiZe: '割り当てルールの作成',
  bianJi: '編集',
  shenPiGuiZeMing: '名称',
  youXianJi: '優先度',
  oAShenPiLian: '承認ルート（OA）',
  bianGengQian: 'ビフォー',
  bianGengHou: 'アフター',
  chaKan: '表示',
  bianGengJiLuR: '編集履歴-RFx審査-{val1}',
  zanWuBianGengJi: 'データがありません',
  qingShuRuGuiZe: 'ルール名を入力してください',
  leiXing: 'カテゴリー',
  zhuangTai: 'ステータス',
  bianGengJiLu: '編集履歴',
  caoZuo: '操作',
  queDingShiXiaoMa: '無効に設定しますか？',
  queDingShengXiaoMa: '有効に設定しますか？',
  shiXiao: '無効',
  shengXiao: '有効',
  yiShiXiao: '無効にしました',
  yiShengXiao: '有効にしました',
  zanWuShuJu: 'データがありません',
  rFXShenPi: 'RFx審査',
  daYu: '>',
  xiaoYu: '<',
  dengYu: '=',
  daYuDengYu: '≥',
  xiaoYuDengYu: '≤',
  buDengYu: '≠',
  cunZaiYu: 'に以下のユーザーが含まれる',
  zhengDanJinE: '合計',
  tiJiaoRen: '提出者',
  jieSuanGongSi: '支払会社',
  shouHuoGuoJiaDi: 'お届け国・地域',
  pinLei: 'カテゴリー',
  dingBiaoGongYingShang: '落札ベンダー',
  zhiFuFangShi: '支払方法',
  zhangQi: '仕入債務回転期間',
  gongYingShang: 'ベンダー',
  weiBao: '保証期間',
  danJiaWeiShui: '単価（税抜）',
  baoJiaBiZhong: '通貨（見積）',
  shuiLu: '税率',
  xiaDanShuLiang: '落札数',
  hanShuiJinE: '合計（税込）',
  tian: '{val1} 日',
  yuFuJinE: '前払金',
  xinYongKaHao: 'コーポレートカード番号',
  heTongHao: '契約番号',
  beiZhu: '備考',
  fuJian: '添付ファイル',
  shouQuan: 'ライセンス種類',
  yongJiu: '買い切り型',
  geYue: '{val1}ヶ月',
  baoJiaXiangQing: '見積情報',
  oADanHao: 'OA申請番号',
  canKaoLianJie: '参考URL',
  shangPinMingCheng: '品名',
  pinPai: 'ブランド',
  peiZhi: '構成',
  canKaoJia: '参考価格（税込）',
  canKaoBiZhong: '通貨（参考価格）',
  shouHuoRen: '受取人',
  shouHuoDiZhi: 'お届け先',
  youBian: '郵便番号',
  lianXiDianHua: '電話番号',
  youXiang: 'メールアドレス',
  daiXunJiaZongShu: '見積結果待ち/合計',
  jiBenXinXi: '基本情報',
  xunJiaDingBiaoHao: '見積書番号',
  chuangJianShiJian: '作成日時',
  tiJiaoShiJian: '提出日時',
  caiGouYuan: '購買担当者',
  caiGouYuanYouXiang: 'メール',
  canKaoJiaHeJi: '参考金額（税込）',
  dingBiaoJinEHe: '落札額（税込）',
  jieSuanGongSiBi: '支払通貨',
  shenPiZhongDaiXun: '購買依頼承認待ち/見積結果待ち',
  yiShenPiDaiXun: '購買依頼承認済み/見積結果待ち',
  shenPiZhongYiXun: '購買依頼承認待ち/見積結果あり',
  daiJiaGeHuiZong: '見積まとめ待ち',
  yiXiaDan: '注文済み',
  yiQuXiao: 'キャンセル済み',
  zhuanZhang: '振り込み - 後払い',
  xinYongKa: 'コーポレートカード',
  zhuanZhangYuFu: '振り込み - 前払い',
  wangLuoYiChang: 'ネットワークでエラーが発生しました',
}

import { BEMGenerator } from '@oahz/neact-utils'
import { ELightDatePicker } from '@bedrock-e/common'
import { useManualService } from '@oahz/neact'
import { apiService, type defs } from '@/services'
import { ShipListItemView } from '@/components/workbench/ship'
import { DiyTable } from '@/components/workbench'
import './ship.less'

const bem = BEMGenerator('pwbs')

export default () => {
  const { data, loading, request } = useManualService(apiService.fmWorkbenchQueryWaitDeliveryList)

  const queryItems = [
    {
      name: 'orderTimePick',
      label: '下单时间',
      children: <ELightDatePicker range />,
    },
  ]

  const onTableChange = (queryParams: any) => {
    const params = {
      current: queryParams.current,
      limit: queryParams.limit,
      orderTimeStart: queryParams?.orderTimePick?.[0],
      orderTimeEnd: queryParams?.orderTimePick?.[1],
      keyword: queryParams.keyword,
    }
    request?.(params)
  }

  return (
    <section className={bem()}>
      <DiyTable
        queryItems={queryItems}
        total={data?.total}
        request={onTableChange}
        loading={loading}
        placeholder="请输入PO单号/商品名称">
        {data?.records?.map(item => {
          return <ShipListItemView key={item.id} data={item} />
        })}
      </DiyTable>
    </section>
  )
}

import { ReactNode } from 'react'
import { Link, useRoute } from '@oahz/neact'
import { BEMGenerator, useRCComputed } from '@oahz/neact-utils'
import { Archive, NoticeText } from '@bedrock/icons-react'
import { Layout, Menu, Scrollbar } from '@bedrock/components'
import { MenuItemProps } from '@bedrock/components/lib/Menu/MenuItem'
import './style.less'

const bem = BEMGenerator('lorder')

const Aisde = (props: { menuList: (MenuItemProps & { pathname: string; key: string; pattern: RegExp })[] }) => {
  const { menuList } = props
  const { pathname } = useRoute()

  const selectedKeys = useRCComputed(() => {
    const hit = menuList.find(m => {
      if (m.pattern instanceof RegExp) return m.pattern.test(pathname)
    })
    return hit ? [hit.key] : []
  }, [pathname])

  const MenuItem = (menu: any) =>
    menu.children ? (
      <Menu.SubMenu icon={menu.icon} expandIcon={menu.expandIcon} title={menu.title} key={menu.key}>
        {menu.children.map(m => MenuItem(m))}
      </Menu.SubMenu>
    ) : (
      <Menu.Item icon={menu.icon} title={menu.title} key={menu.key}>
        <Link className={bem('menu-link')} to={menu.pathname}>
          {menu.title}
        </Link>
      </Menu.Item>
    )

  return (
    <Layout.Sider className={bem('sider')}>
      <Scrollbar>
        <Menu className={bem('menu')} mode="inline" highlightMode="default" selectedKeys={selectedKeys}>
          {menuList.map(menu => (
            <Menu.Item icon={menu.icon} title={menu.title} key={menu.key}>
              <Link className={bem('menu-link')} to={menu.pathname}>
                {menu.title}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Scrollbar>
    </Layout.Sider>
  )
}

const getSupplierRoutePathname = (pathname: string) => {
  return `/order${pathname}`
}
const MENU_OPTIONS: (MenuItemProps & { pathname: string; key: string; pattern: RegExp })[] = [
  {
    title: '订单列表',
    icon: <NoticeText size={16} />,
    pathname: getSupplierRoutePathname('/list'),
    key: 'list',
    pattern: /\/list/i,
  },
  {
    title: '履约列表',
    icon: <Archive size={16} />,
    pathname: getSupplierRoutePathname('/delivery-list'),
    key: 'delivery-list',
    pattern: /\/delivery-list/i,
  },
]

export default (props: { children?: ReactNode }) => {
  const { pathname } = useRoute()
  const hasSider = ['/order/list', '/order/delivery-list'].includes(pathname)

  return (
    <div className={bem()}>
      {hasSider && <Aisde menuList={MENU_OPTIONS} />}
      <div className={bem('c', [bem('c-with-sider')])}>{props.children}</div>
    </div>
  )
}

import { createStore } from '@oahz/neact'
import account from './account'
import workbenchStatistics from './workbenchStore'

// rematch的类型推导还是有些缺陷的，如effect中的this不能获取到reduce中的方法，需要外面多一层dispatch调用
// https://juejin.cn/post/7005410454709207054
// https://github.com/rematch/rematch/issues/870
// https://juejin.cn/post/6953544303608070152

const { StorePrivder, store, useDispatch, useSelector } = createStore({
  models: { account, workbenchStatistics },
  errorHandler: e => {
    // store.effect中的错误统一方式
    // Message.warn(e.message)
    console.log(e)
  },
})

export { StorePrivder, store, useDispatch, useSelector }

export const HOST_NAME = 'xxx.netease.com'

export const API_PREFIX = '/api'

// uat & test 应用此key
// export const RSA_PUBLIC_KEY =
//   'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDucDJxRCIU/z3dgAunynAXM2CG5IUUeI6+HSO3EH9R1MDfsN52TLvqhsK/aT0CZaQ2I3zDt6tmfNtImZ0362G/9wFA8CMBH/cj8TRWW64q3Cn+aCXRexbugGGEm4IUlJhArN4INECMay+v0HVtGe9xC03Cj2zksn9a3qMBe/fLuQIDAQAB'

// master 应用此key
export const RSA_PUBLIC_KEY =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC9CMBZCf+6U16Z8go6geUuF1hmRXttkQjmxfXngaxnDR8+pjIuLzPAT5CR2dWegKE1onO4bsQvv2VNl0hDL6aEaS1L/GE+0wmGKJIvzCHfBhK7oj9Zceyu8SYF+qCI+gKTLKEcVeanfxhF5k+QjvFh6cdPeaBQCUcp2Cg2ZZzpTQIDAQAB'

import { FC, Fragment, ReactNode } from 'react'
import { appScout, BEMGenerator, useRCComputed, useRCMount, useRCUpdate, useRCWatch } from '@oahz/neact-utils'
import { Link, useRoute, useRouter, useAsyncRouter } from '@oahz/neact'
import { Avatar, Dropdown, Menu, Select, Tag } from '@bedrock/components'
import { Building3, Key, SignOut } from '@bedrock/icons-react'
import { formatAmountNumber } from '@feature/shared'
import I18N from '@feature/i18n'
import { useDispatch, useSelector } from '@/store'
import { apiService, bizEnums } from '@/services'
import './style.less'

const bem = BEMGenerator('layout')

const NAV_MENUS = [
  { key: 'workbench', name: '工作台', pathname: '/workbench', pattern: /\/workbench/ },
  { key: 'quotation', name: '报价管理', pathname: '/quotation', pattern: /\/quotation/ },
  { key: 'order', name: '订单履约', pathname: '/order', pattern: /\/order/ },
  // { key: 'tender', name: '投标管理', pathname: '/tender', pattern: /\/tender/ },
  { key: 'invoice', name: '发票', pathname: '/invoice', pattern: /\/invoice/ },
  { key: 'enterprise', name: '企业管理', pathname: '/enterprise', pattern: /\/enterprise/ },
]

const Navigation = () => {
  const { pathname } = useRoute()
  const { replace } = useRouter()
  const { supplierInfo } = useSelector(state => state.account)
  const workbenchStatistics = useSelector(state => state.workbenchStatistics)
  const { getWorkbenchStatistics } = useDispatch(dispatch => dispatch.workbenchStatistics)

  const selectedKeys = useRCComputed(() => {
    const hit = NAV_MENUS.find(v => v.pattern.test(pathname))
    return hit ? [hit.key] : []
  }, [pathname])

  const unonbound = useRCComputed(() => {
    getWorkbenchStatistics()
    return supplierInfo.onboardProgress !== bizEnums.SpOnboardProgressEnum.SUCCEED
  }, [supplierInfo])

  useRCWatch(() => {
    // 未准入的供应商访问站点路由时，需要规约到企业信息页面
    if (!unonbound) return
    // 如果在准入审核失败后，重新发起准入（进入重新提交页面）时，就不做路由规约处理了
    if (pathname === '/enterprise/resubmit') return
    // 如果当前未准入的供应商访问页面时，规约到企业信息页面
    if (pathname === '/' || NAV_MENUS.some(v => v.pattern.test(pathname))) {
      // 因为路由配置中有个 /workbench 的重定向，这时需要一个Timeout
      setTimeout(() => replace('/enterprise'), 0)
    }
  }, [unonbound])

  // 如果当前供应商主体，未准入状态，就不展示站点导航，同时导航到企业信息页面
  if (unonbound) return null

  return (
    <Menu className={bem('menu')} mode="horizontal" highlightMode="default" selectedKeys={selectedKeys}>
      {NAV_MENUS.map(v => (
        <Menu.Item key={v.key}>
          <Link className={bem('menu-link')} to={v.pathname}>
            {v.name}
            {v.key === 'workbench' && workbenchStatistics && (
              <Tag
                className={bem('menu-tag')}
                size="xSmall"
                color="red"
                border={false}
                text={formatAmountNumber(workbenchStatistics?.total)}
              />
            )}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  )
}

const User = () => {
  const { push } = useRouter()
  const { userInfo } = useSelector(state => state.account)

  return (
    <Dropdown
      className={bem('user-dropdown')}
      title={<Avatar className={bem('user-avatar')} size="small" />}
      arrow={false}
      overlay={
        <Menu className={bem('user-menu')}>
          <Menu.Item key="1" className={bem('user-info')}>
            <Avatar className={bem('user-avatar')} />
            <div className={bem('user-data')}>
              <div className={bem('user-name')}>{userInfo.name}</div>
            </div>
          </Menu.Item>
          <Menu.Item key="d-2" className={bem('user-divider')} />
          <Menu.Item key="3" icon={<Key />} onClick={() => push('/setpwd')}>
            密码修改
          </Menu.Item>
          <Menu.Item
            key="4"
            icon={<SignOut />}
            onClick={async () => {
              apiService.identityAuthenticationLogout()
              window.location.replace('/login')
            }}>
            {I18N.auto.tuiChuDengLu}
          </Menu.Item>
        </Menu>
      }
    />
  )
}

const Layout = (props: { children?: ReactNode }) => {
  const { replace } = useAsyncRouter()
  const { userInfo } = useSelector(state => state.account)
  const { changeEnterpriseId } = useDispatch(dispatch => dispatch.account)

  const enterpriseOptions = useRCComputed(() => {
    return userInfo.enterprises?.map(v => ({ name: v.name!, value: v.id! }))
  }, [userInfo])

  return (
    <div className={bem()}>
      <header className={bem('header')}>
        <Link to="/">
          <div className={bem('logo')} />
        </Link>
        <div className={bem('header-center')}>
          <Select
            className={bem('enterprise')}
            dropdownClassName={bem('enterprise-dropdown')}
            fill
            options={enterpriseOptions}
            renderSelection={v => (
              <>
                <Building3 className={bem('enterprise-icon')} />
                {v}
              </>
            )}
            value={userInfo.enterpriseId}
            onChange={async v => {
              // 重新定位到工作台页面
              await replace('/workbench')
              // 等待路由切换之后，再实际切换供应商主体，因为可能有路由拦截跳转不成功
              changeEnterpriseId(v as string)
            }}
          />
          <Navigation />
        </div>
        <User />
      </header>
      <Fragment key={userInfo.enterpriseId}>{props.children}</Fragment>
    </div>
  )
}

function withAuthLogin<T>(Component: FC<T>) {
  return (props: T) => {
    const { userInfo } = useSelector(state => state.account)
    const { getAccountInfo } = useDispatch(dispatch => dispatch.account)

    useRCMount(getAccountInfo)

    useRCUpdate(() => {
      userInfo && appScout.setUserData({ userId: `${userInfo.name}-${userInfo.mail}` })
    }, [userInfo])

    return userInfo ? <Component {...(props as JSX.IntrinsicAttributes & T)} /> : null
  }
}

export default withAuthLogin(Layout)

import { BEMGenerator, useRCComputed, useRCMount } from '@oahz/neact-utils'
import { Message } from '@bedrock/components'
import type { FormInstance } from '@bedrock/components/lib/Form'
import I18N from '@feature/i18n'
import { useService } from '@oahz/neact'
import { MainContainer, PageContainer, PageLoading } from '@/components/page-view'
import {
  SpBaseData,
  SpHeaderData,
  SupplierContentFormItems,
  SupplierForm,
  SupplierFormActions,
  SupplierHeader,
  convertFormFieldsValueToSubmitCommand,
  isChinaEnterprise,
  isChinaPerson,
  isOverseaEnterprise,
  isOverseaPerson,
  useSupplierForm,
} from '@/components/supplier'
import { useDispatch, useSelector } from '@/store'
import { apiService, bizEnums, defs } from '@/services'
import './style.less'

const bem = BEMGenerator('peionsu')

export const OnboundSubmitPage = () => {
  const { supplierInfo } = useSelector(state => state.account)
  const { refreshSupplierInfo } = useDispatch(dispatch => dispatch.account)

  const { fm, canSubmit, onValuesChange } = useSupplierForm()

  const baseData = useRCComputed(() => {
    return {
      supplierType: supplierInfo.supplierType,
      countryId: supplierInfo.countryId,
      countryCode: supplierInfo.countryCode,
      baseInfo: {
        supplierName: supplierInfo.name,
        creditCode: supplierInfo.creditCode,
        tin: supplierInfo.tin,
        userName: supplierInfo.name,
        personIdType: supplierInfo.personIdType,
        personId: supplierInfo.personId,
      },
    }
  }, [supplierInfo])

  const headerData = useRCComputed(() => {
    return {
      supplierId: supplierInfo.id,
      supplierName: supplierInfo.name,
      supplierType: supplierInfo.supplierType,
      supplierMail: supplierInfo.contactMail,
      countryName: supplierInfo.countryName,
    } as SpHeaderData
  }, [supplierInfo])

  const doSubmit = async () => {
    await fm.validateFields()
    const fmData = fm.getFieldsValue()

    const data = convertFormFieldsValueToSubmitCommand({ ...baseData, ...fmData })
    // 国内统一社会信用代码和国外TIN码，以MDM数据为准
    data.baseInfo.creditCode = fmData.checkIn.creditCode
    data.baseInfo.tin = fmData.checkIn.tin

    if (isChinaEnterprise(baseData)) {
      await apiService.identityEnterpriseBoardedSubmitChinaEnterprise(data)
    } else if (isChinaPerson(baseData)) {
      await apiService.identityEnterpriseBoardedSubmitChinaPerson(data)
    } else if (isOverseaEnterprise(baseData)) {
      await apiService.identityEnterpriseBoardedSubmitOverseaEnterprise(data)
    } else if (isOverseaPerson(baseData)) {
      await apiService.identityEnterpriseBoardedSubmitOverseaPerson(data)
    }

    Message.success(I18N.auto.tiJiaoChengGong)
    // 刷新最新的供应商主页
    refreshSupplierInfo()
  }

  return (
    <PageContainer className={bem()} htmlTitle="Vendor creation">
      <SupplierHeader
        data={headerData}
        action={
          <SupplierFormActions fm={fm} onSubmit={doSubmit} submitDisabled={!canSubmit}>
            {({ submitButton }) => submitButton}
          </SupplierFormActions>
        }
      />
      <MainContainer w1280 className={bem('main')}>
        <SupplierForm form={fm} onValuesChange={onValuesChange}>
          <CreateContentInfo fm={fm} baseValue={baseData} />
        </SupplierForm>
      </MainContainer>
    </PageContainer>
  )
}

const CreateContentInfo = (props: { fm: FormInstance; baseValue: SpBaseData }) => {
  const { fm, baseValue } = props
  const { supplierInfo } = useSelector(state => state.account)

  useRCMount(() => {
    if (isChinaEnterprise(baseValue)) {
      const { provinceId, provinceCode, city, addressDetail, ...restCheckIn } = supplierInfo?.checkIn || {}
      fm.setFieldsValue({
        checkIn: {
          enterpriseName: baseValue.baseInfo?.supplierName,
          creditCode: baseValue.baseInfo?.creditCode,
          provinceId_provinceCode__city:
            provinceId && provinceCode && city ? `${provinceId}_${provinceCode}__${city}` : '',
          address: addressDetail,
          ...restCheckIn,
        },
      })
    }
    if (isOverseaEnterprise(baseValue)) {
      fm.setFieldsValue({
        checkIn: { enterpriseName: baseValue.baseInfo?.supplierName, tin: baseValue.baseInfo?.tin },
      })
    }
    if (isChinaPerson(baseValue) || isOverseaPerson(baseValue)) {
      fm.setFieldsValue({
        checkIn: {
          userName: baseValue.baseInfo?.userName,
          personIdType: baseValue.baseInfo?.personIdType,
          personId: baseValue.baseInfo?.personId,
        },
      })
    }
    fm.setFieldsValue({ serviceCategories: { categoryIds: supplierInfo.serviceCategories?.map(v => v.categoryId) } })
  })

  return <SupplierContentFormItems defaultValue={{ ...baseValue, serviceCategories: supplierInfo.serviceCategories }} />
}

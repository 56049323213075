import JSEncrypt from 'jsencrypt'
import { RSA_PUBLIC_KEY } from '@/shared/consts'

export const xxx = 1

export const authLogin = () => {
  const { pathname, search } = window.location
  const originUrl = pathname === '/' ? '' : `?originUri=${pathname}${search}`
  window.location.replace(`/login${originUrl}`)
}

export const rsaEncrypt = (data: string) => {
  const encrypt = new JSEncrypt()
  /* 公钥加密 */
  encrypt.setPublicKey(RSA_PUBLIC_KEY)
  const encrypted = encrypt.encrypt(data)
  // 转为 16进制字符串
  // const hex = enc.Hex.stringify(enc.Base64.parse(encrypted)).toUpperCase()
  return encrypted
}

import { useState, useRef } from 'react'
import { Empty, Button, Timeline } from '@bedrock/components'
import { BEMGenerator, useRCMount } from '@oahz/neact-utils'
import I18N from '@feature/i18n'
import dayjs from 'dayjs'
import { Link, useManualService } from '@oahz/neact'
import { ELightQuery, ELightDatePicker } from '@bedrock-e/common'
import { formatDateTime } from '@feature/shared'
import NoResults from '@bedrock/components/es/Empty/images/middle/NoResults'
import NoData from '@bedrock/components/es/Empty/images/large/NoData'
import { ChevronThinRight } from '@bedrock/icons-react'
import { PageLoading } from '@/components/page-view'
import { apiService, bizEnums, type defs } from '@/services'
import Tag, { TagEnumMap } from '@/components/tag'

import './style.less'

const bem = BEMGenerator('peiarchives')

const ChangeView = (props: { data: defs.SpChangeRecordListDto[] | defs.SpChangeRecordListDto[] }) => {
  const { data } = props

  if (!data?.length)
    return (
      <Empty
        className={bem('empty')}
        imageNode={<NoData />}
        text={<span className={bem('empty-text')}>{I18N.auto.zanWuShuJu}</span>}
      />
    )

  return (
    <Timeline className={bem('tl')}>
      {(data || []).map((item, i) => (
        <Timeline.Item
          key={i}
          status={i === 0 ? 'done' : 'pending'}
          dot={i === 0 ? <div className={bem('li-cd')} /> : null}
          title={
            <div className={bem('li-title')}>
              {formatDateTime(item.createdAt)}
              {item.oaFlowStatus &&
                item.oaFlowStatus !== bizEnums.SpOaFlowStatusEnum.DRAFT &&
                item.oaFlowStatus !== bizEnums.SpOaFlowStatusEnum.APPROVE_SUCCESS && (
                  <Tag status={item.oaFlowStatus} statusMap={TagEnumMap.SpOaFlowStatusEnum} />
                )}
            </div>
          }
          className={bem('li')}>
          <div>
            <div className={bem('li-l')}>
              <div className={bem('li-l-text')}>{I18N.auto.caoZuoRen_3}</div>
              <div className={bem('li-l-val')}>
                {item.buyer ? '采购员代理' : `${item.userName}${item.userMail ? `(${item.userMail})` : ''}`}
              </div>
            </div>
            {item.remark && (
              <div className={bem('li-l', 'li-l-remark')}>
                <div className={bem('li-l-text')}>{I18N.auto.bianGengShuoMing_2}</div>
                <div className={bem('li-l-val')}>{item.remark}</div>
              </div>
            )}
          </div>
          {item.applicationType === bizEnums.SpApplicationTypeEnum.MODIFICATION && (
            <Link to={`/enterprise/info-changes?applicationId=${item.id}`} target="_blank">
              <Button className={bem('li-c')} type="text" suffix={<ChevronThinRight />}>
                {I18N.auto.chaKan}
              </Button>
            </Link>
          )}
        </Timeline.Item>
      ))}
    </Timeline>
  )
}

export default (props: { enterpriseId: string }) => {
  const { enterpriseId } = props
  const sectionRef = useRef<any>(null)
  //TODO 接口替换
  const { data, loading, request } = useManualService(apiService.identityEnterpriseBoardedQueryAllSpChangeRecordListDto)
  const [searchTime, setSearchTime] = useState<any[] | undefined>()

  const onSearch = (searchQuery: any) => {
    const params: {
      enterpriseId: string
      startAt?: string
      endAt?: string
    } = {
      enterpriseId,
      startAt: undefined,
      endAt: undefined,
    }
    if (searchQuery?.time) {
      setSearchTime(searchQuery.time)
      params.startAt = dayjs(searchQuery.time[0]).toJSON()
      params.endAt = dayjs(searchQuery.time[1]).toJSON()
    } else {
      setSearchTime(undefined)
      sectionRef.current?.form?.resetFields()
    }
    request(params)
  }

  useRCMount(() => request({ enterpriseId }))

  return (
    <div className={bem('common')}>
      <ELightQuery
        ref={sectionRef}
        items={[
          {
            label: I18N.auto.shiJian,
            name: 'time',
            isDefault: true,
            children: <ELightDatePicker value={searchTime} range />,
          },
        ]}
        name={'app-query'}
        onValuesChange={(_, v) => onSearch(v)}
      />

      {loading ? (
        <PageLoading />
      ) : !data?.length && searchTime?.length ? (
        <Empty
          className={bem('empty')}
          imageNode={<NoResults />}
          text={
            <span className={bem('empty-text-search')}>
              {I18N.auto.dangQianShaiXuanTiao}
              <Button type="text" onClick={() => onSearch(undefined)}>
                {I18N.auto.qingKongShaiXuanTiao}
              </Button>
            </span>
          }
        />
      ) : (
        <ChangeView data={data || []} />
      )}
    </div>
  )
}

import { ReactNode, useState } from 'react'
import I18N from '@feature/i18n'
import { BEMGenerator, useBoolean } from '@oahz/neact-utils'
import { Input, Form, Button, Modal, Scrollbar } from '@bedrock/components'
import { Mall } from '@bedrock/icons-react'
import { useManualService } from '@oahz/neact'
import { Send } from '@bedrock/icons-react'
import { formatUtcTimeZone } from '@feature/shared'
import BaseEditor from '../base-editor'
import { DataFormItem, DataForm } from '../data-view'
import Netbuy from '#assets/netbuy.png'
import './style.less'

const bem = BEMGenerator('ftcm')

export const ClarifyFormField = (props: {
  title?: string
  changeTitle?: (title?: string) => void
  content?: string
  changeContent?: (content?: string) => void
}) => {
  const { title, changeTitle, content, changeContent } = props
  return (
    <>
      <DataFormItem className={bem('fm-item')} name="title">
        <Input
          value={title}
          onChange={e => changeTitle?.(e.target.value)}
          maxLength={50}
          placeholder={I18N.auto.qingShuRuBiaoTi}
        />
      </DataFormItem>
      <DataFormItem className={bem('fm-item')} name="description">
        <BaseEditor onChange={e => changeContent?.(e)} placeholder={I18N.auto.qingShuRuZiXun} />
      </DataFormItem>
    </>
  )
}

export const ClarifyModal = (props: {
  questionId: string
  api: <A extends any[], T>(...args: A) => Promise<T>
  refresh?: () => void
  renderButton?: (show: () => void) => ReactNode
  consultation?: QuotationTenderQuestionContentDto
  title?: string
}) => {
  const { api, questionId, refresh, renderButton, consultation, title: modalTitle } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [fm] = Form.useForm()
  const { loading, request } = useManualService(api)
  const [title, setTitle] = useState<string | undefined>()
  const [content, setContent] = useState<string | undefined>()

  const onClose = () => {
    hide()
    setTitle(undefined)
    setContent(undefined)
    fm.resetFields()
  }

  const onSubmit = async () => {
    if (!title || !content) return
    const params = { questionId: questionId, title: title, content: content }
    await request(params)
    onClose()
    refresh?.()
  }

  return (
    <>
      {renderButton?.(show) || <Button type="text-subtle" icon={<Send size={16} />} onClick={show} />}
      <Modal
        width={740}
        className={bem()}
        title={modalTitle || I18N.auto.chengQingHuiFu}
        visible={visible}
        onCancel={onClose}
        onOk={onSubmit}
        destroyOnClose
        okButtonProps={{ loading, disabled: !title || !content }}>
        <>
          {consultation && (
            <ClarifyItem data={consultation} placeholder={I18N.auto.weiTianXieNeiRong} type={'supplier'} />
          )}
          {consultation && <div className={bem('divider')}>{I18N.auto.huiFuZiXun}</div>}
          <DataForm className={bem('fm')} form={fm}>
            <ClarifyFormField title={title} content={content} changeContent={setContent} changeTitle={setTitle} />
          </DataForm>
        </>
      </Modal>
    </>
  )
}

export const ClarifyItem = (props: {
  placeholder?: string
  data?: QuotationTenderQuestionContentDto
  type: 'supplier' | 'netbuy'
  light?: boolean
  className?: string
}) => {
  const { placeholder, data, light, type, className } = props
  const { title, content, creator, createdAt } = data || {}

  const renderIcon = () => {
    if (type === 'supplier') {
      return <Mall className={bem('show-icon', { supplier: true })} />
    }
    return <img className={bem('show-icon')} src={Netbuy} alt="" />
  }

  return (
    <div className={bem('show', [className])}>
      <div className={bem('show-header')}>
        {renderIcon()}
        <div className={bem('show-header-info')}>
          <div className={bem('show-header-info-name')}>
            {`${creator?.name || ''}${creator?.mail ? `(${creator?.mail})` : ''}`}
          </div>
          <div className={bem('show-header-info-time')}>{formatUtcTimeZone(createdAt)}</div>
        </div>
      </div>
      <div className={bem('show-content', { light: !!light })}>
        <Scrollbar>
          <div className={bem('show-content-title')}>{title}</div>
          <BaseEditor readOnly initialValue={content} placeholder={placeholder || I18N.auto.zanWuNeiRong} />
        </Scrollbar>
      </div>
    </div>
  )
}

type PartnerOperator = {
  id?: number
  name?: string
  jobNumber?: string
  mail?: string
  system?: boolean
}

type QuotationTenderQuestionContentDto = {
  /** 回复标题 */
  title?: string
  /** 回复内容 */
  content?: string
  creator?: PartnerOperator
  /** 创建时间 */
  createdAt?: string
}

type QuotationTenderQuestionDto = {
  consultation?: QuotationTenderQuestionContentDto
  reply?: QuotationTenderQuestionContentDto
  questionStatus?: '0' | '1'
}

type ClarifyShowPropsType = {
  data: QuotationTenderQuestionDto
  key?: string
  renderItem?: (show: () => void) => ReactNode
  hideConsultation?: boolean
  title?: string
}

export const ClarifyShow = (props: ClarifyShowPropsType) => {
  const { data, key, renderItem, hideConsultation, title: modalTitle } = props
  const { consultation, reply, questionStatus } = data
  const [visible, [show, hide]] = useBoolean(false)
  const title = hideConsultation ? reply?.title : consultation?.title

  return (
    <div key={key}>
      {renderItem ? (
        renderItem?.(show)
      ) : (
        <Button type="link" onClick={show} className={bem('clarify-title')} title={title}>
          {title || '-'}
        </Button>
      )}
      <Modal
        width={740}
        className={bem()}
        title={modalTitle || I18N.auto.chengQingZiXun}
        visible={visible}
        onCancel={hide}
        destroyOnClose
        footer={null}>
        {reply && <ClarifyItem data={reply} placeholder={I18N.auto.qingShuRuZiXun} type={'netbuy'} light={true} />}
        {reply && consultation && !hideConsultation && <div className={bem('divider')}>{I18N.auto.ziXunXiangQing}</div>}
        {!hideConsultation && consultation && (
          <ClarifyItem data={consultation} placeholder={I18N.auto.qingShuRuZiXun} type={'supplier'} />
        )}
      </Modal>
    </div>
  )
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { Tooltip } from '@bedrock/components'
import { BEMGenerator } from '@oahz/neact-utils'
import I18N from '@feature/i18n'
import { connectPopo } from '@feature/shared'

import './index.less'

const bem = BEMGenerator('ftcom-nwp')

interface NameWithPopoProps {
  name?: string
  mail?: string
  showMail?: boolean //是否展示mail具体信息
  className?: string
}

export const NameWithPopo: React.FC<NameWithPopoProps> = ({ name, mail, showMail = false, className }) => {
  const connect = () => {
    mail && connectPopo(mail)
  }
  return (
    <div className={bem('', [className])}>
      <span className={bem('name')} title={name}>
        {name || '-'}
      </span>
      {!!mail && (
        <Tooltip overlay={I18N.auto.dianJiFaQiP} placement="bottom">
          <img
            src={require('@/assets/popo.png')}
            alt=""
            onClick={e => {
              e.stopPropagation()
              connect()
            }}
          />
        </Tooltip>
      )}

      {showMail && mail && <span className={bem('mail')}>{mail}</span>}
    </div>
  )
}

import { useState, ReactNode, useCallback } from 'react'
import { BEMGenerator, useRCMount, useRCComputed, useRCUpdate, useRCWatch } from '@oahz/neact-utils'
import { useRouter } from '@oahz/neact'
import I18N from '@feature/i18n'
import NoData from '@bedrock/components/es/Empty/images/large/NoData'
import { DataGrid, DataItem, DataBlock, DataForm, DataFormItem, TimeCountDown } from '@feature/views'
import {
  Bill,
  Close,
  Delete,
  YuanCircleFilled,
  TrophyFilled,
  CrownFilled,
  CloseCircle,
  GoldMedals,
  Clock,
} from '@bedrock/icons-react'
import {
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  Modal,
  Message,
  InputNumber,
  Tooltip,
  Tag,
  Empty,
} from '@bedrock/components'
import { map, forEach } from 'lodash'
import dayjs from 'dayjs'
import { PageContainer, MainContainer, PageHeader } from '@/components/page-view'
import { useDispatch } from '@/store'
import { apiService, bizEnums, type defs } from '@/services'
import { DataMoney, DataTaxRate, MoneyInput, renderPeriod } from '@/components/data-view'
import {
  AmountInput,
  LicensingPeriodSelect,
  QuoteLaxSelect,
  QutotationAttachments,
  QutotationUpload,
} from '@/components/quotation'
import {
  Chemistry,
  useCurrentdata,
  HistoryLog,
  BiddingRuleCard,
  QuotationDetailCard,
  TenderQuestion,
} from '@/components/tender'
import { computeBidMoney, createMoney } from '@/components/app-rfx'
import './style.less'

const bem = BEMGenerator('pquote-bidding')

const useTotalPrice = (data: defs.QuotationDetailDto) => {
  const [totalPrice, setTotalPrice] = useState(data?.totalPrice?.amount)
  const [lineMoneyList, setlLineMoneyList] = useState({}) //竞价整单情况下的报价总金额

  const isAllBid = data.quotationRule?.isAllBid || data.quotationTenderConfig?.isAllBid
  const currency = data.quotationCurrency

  const lineQuotationMoneyChange = useCallback(
    (lineId: string, amount: number) => {
      let _list = { ...lineMoneyList, [lineId]: amount }
      let _total = 0
      forEach(_list, (item: number) => {
        _total = _total + (item || 0)
      })
      const fixedCount = currency && ['JPY', 'KRW'].includes(currency) ? 0 : 2
      _total = Number(_total.toFixed(fixedCount))
      setlLineMoneyList(_list)
      setTotalPrice(_total)
    },
    [lineMoneyList, currency]
  )

  useRCWatch(() => {
    if (data?.lines) {
      let _res = {}
      data?.lines.map((item: defs.QuotationLineDto) => {
        _res[item.rfxLineId] = item?.quotationMoney?.amount
      })
      setlLineMoneyList(_res)
    }
  }, [data])

  return {
    lineQuotationMoneyChange: isAllBid ? lineQuotationMoneyChange : undefined,
    totalMoney: { amount: totalPrice, currency: currency },
  }
}

const LineQuotationView = (props: { data: defs.QuotationLineDto; quotationMethod?: defs.QuotationMethod }) => {
  const { data, quotationMethod } = props
  const isQuantity = data.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY
  const unTaxBenchmark = data.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  if (data.taxRate === null || data.taxRate === undefined) {
    return (
      <DataGrid>
        <Empty
          className={bem('empty')}
          imageNode={<NoData />}
          text={<span className={bem('empty-text')}>{'暂无报价'}</span>}
        />
      </DataGrid>
    )
  }

  return (
    <>
      <DataGrid>
        {isQuantity ? (
          <DataItem label={unTaxBenchmark ? '单价（未税）' : '单价（含税）'}>
            <DataMoney
              fontWeight="bold"
              size="xsmall"
              highlight={false}
              value={unTaxBenchmark ? data?.unitUnTaxMoney : data.unitMoney}
            />
          </DataItem>
        ) : (
          <DataItem label={unTaxBenchmark ? '报价金额 (未税)' : '报价金额（含税）'}>
            <DataMoney
              fontWeight="bold"
              size="xsmall"
              highlight={false}
              value={unTaxBenchmark ? data?.quotationUnTaxMoney : data.quotationMoney}
            />
          </DataItem>
        )}
        <DataItem label="税率" value={<DataTaxRate value={data.taxRate} />} />
        {isQuantity && (
          <>
            <DataItem label="运费（含税）" value={data.freight?.amount} />
            <DataItem label="采购数量" value={data.quantity} />
            {unTaxBenchmark ? (
              <DataItem
                label={`单价 (含税)`}
                value={<DataMoney fontWeight="extraBold" highlight value={data?.unitMoney} />}
              />
            ) : (
              <DataItem
                label={`单价 (未税)`}
                value={<DataMoney fontWeight="extraBold" highlight value={data?.unitUnTaxMoney} />}
              />
            )}
          </>
        )}
        {isQuantity ? (
          <>
            <DataItem
              label={`报价金额\n(未税)`}
              value={<DataMoney fontWeight="extraBold" highlight value={data?.quotationUnTaxMoney} />}
            />
            <DataItem
              label={`报价金额\n(含税)`}
              value={<DataMoney fontWeight="extraBold" highlight value={data?.quotationMoney} />}
            />
          </>
        ) : unTaxBenchmark ? (
          <DataItem
            w100
            label={`报价金额\n(含税)`}
            value={<DataMoney fontWeight="extraBold" highlight value={data?.quotationMoney} />}
          />
        ) : (
          <DataItem
            w100
            label={`报价金额\n(未税)`}
            value={<DataMoney fontWeight="extraBold" highlight value={data?.quotationUnTaxMoney} />}
          />
        )}
        <DataItem label="报价有效期至" value={data?.validityPeriod} />
        <DataItem label="维保期限 (月)" value={renderPeriod(data.maintainPeriod)} />
        <DataItem label="供货周期 (自然日)" value={data.supplyCycle} />
        <DataItem label="发票类型" value={bizEnums.InvoiceTypeEnum.pick(data.invoiceType!)?.name} />
        {bizEnums.QuotationMethodEnum.TENDER == quotationMethod ? (
          <>
            <DataItem
              w100
              label="商务标附件"
              value={<QutotationAttachments dataId={''} attachments={data.commercialMarkAttachments} />}
            />
            <DataItem
              w100
              label="技术标附件"
              value={<QutotationAttachments dataId={''} attachments={data.technicalMarkAttachments} />}
            />
          </>
        ) : (
          <>
            <DataItem
              w100
              label="报价附件"
              value={<QutotationAttachments dataId={''} attachments={data.quotationAttachments} />}
            />
            <DataItem w100 label="供应商报价备注" value={data.quotationRemark} />
          </>
        )}
      </DataGrid>
    </>
  )
}

const LineQuotationFormView = (props: {
  data: defs.QuotationLineDto
  title: string
  step?: defs.Money
  quotationCurrency?: defs.Currency
  quotationMethod?: defs.QuotationMethod
  lineQuotationMoneyChange?: (rfxId: string, money: defs.MoneyDto) => void
}) => {
  const { data = {}, title, step, quotationCurrency, quotationMethod, lineQuotationMoneyChange } = props
  const isQuantity = data.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY
  const unTaxBenchmark = data.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
  // 计算单价、本行报价的依赖数据
  const [unitUnTaxMoney, setUnitUnTaxMoney] = useState<defs.MoneyDto>()
  const [quotationUnTaxMoney, setQuotationUnTaxMoney] = useState<defs.MoneyDto>()
  const [taxRate, setTaxRate] = useState<number>()
  // 计算单价、本行报价的依赖数据
  const [unitMoney, setUnitMoney] = useState<defs.MoneyDto>()
  const [quotationMoney, setQuotationMoney] = useState<defs.MoneyDto>()

  const bidStartTaxPrice = useRCComputed(() => {
    if (!data.bidStartPrice) return
    return createMoney((data.bidStartPrice?.amount || 0) * (1 + (data.taxRate || 0)), quotationCurrency)
  }, [data])

  useRCMount(() => {
    if (data) {
      setTaxRate(data.taxRate)
      setUnitUnTaxMoney(data.unitUnTaxMoney || data.bidStartPrice)
      setUnitMoney(data.unitMoney || bidStartTaxPrice)
      setQuotationUnTaxMoney(data.quotationUnTaxMoney || data.bidStartPrice)
      setQuotationMoney(data.quotationMoney || bidStartTaxPrice)
    }
  })

  const doChangeMoney = (v: typeof data) => {
    const draftData = { unitUnTaxMoney, unitMoney, quotationUnTaxMoney, quotationMoney, taxRate, ...v }

    const { bidUnitUnTaxMoney, bidUnitMoney, bidUnTaxMoney, bidMoney } = computeBidMoney({
      bidUnitUnTaxMoney: draftData.unitUnTaxMoney,
      bidUnitMoney: draftData.unitMoney,
      bidUnTaxMoney: draftData.quotationUnTaxMoney,
      bidMoney: draftData.quotationMoney,
      taxRate: draftData.taxRate,
      // 原始数据
      quantity: data.quantity,
      moneyCalBenchmark: data.moneyCalBenchmark,
      orderMethod: data.orderMethod,
    })

    setTaxRate(draftData.taxRate)
    setUnitUnTaxMoney(bidUnitUnTaxMoney)
    setUnitMoney(bidUnitMoney)
    setQuotationUnTaxMoney(bidUnTaxMoney)
    setQuotationMoney(bidMoney)

    lineQuotationMoneyChange?.(data.rfxLineId, bidMoney?.amount)
  }

  return (
    <>
      <DataGrid>
        <DataFormItem style={{ display: 'none' }} name={`${title}-id`} label="id" required initialValue={data.id}>
          <Input />
        </DataFormItem>
        {isQuantity ? (
          <DataFormItem
            {...(unTaxBenchmark
              ? {
                  name: `${title}-unitUnTaxMoney`,
                  label: '单价（未税）',
                  initialValue: data.unitUnTaxMoney || data.bidStartPrice,
                }
              : {
                  name: `${title}-unitMoney`,
                  label: '单价（含税）',
                  initialValue: data.unitMoney || bidStartTaxPrice,
                })}
            rules={[
              {
                required: true,
                message: '单价不可为0',
                validateTrigger: ['submit'],
                validator: (_: any, value: any) => {
                  if (!value || value.amount <= 0) {
                    return Promise.reject()
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}>
            <AmountInput
              step={step?.amount}
              currency={quotationCurrency!}
              max={
                unTaxBenchmark
                  ? data.unitUnTaxMoney?.amount || data?.bidStartPrice?.amount
                  : data.unitMoney?.amount || bidStartTaxPrice?.amount
              }
              onChange={v => doChangeMoney({ unitUnTaxMoney: v, unitMoney: v })}
            />
          </DataFormItem>
        ) : (
          <DataFormItem
            {...(unTaxBenchmark
              ? {
                  name: `${title}-quotationUnTaxMoney`,
                  label: '报价金额（未税）',
                  initialValue: data.quotationUnTaxMoney || data.bidStartPrice,
                }
              : {
                  name: `${title}-quotationMoney`,
                  label: '报价金额（含税）',
                  initialValue: data.quotationMoney || bidStartTaxPrice,
                })}
            className={bem('fmi-nowrap')}
            rules={[
              {
                required: true,
                message: '报价金额不可为0',
                validateTrigger: ['submit'],
                validator: (_: any, value: any) => {
                  if (!value || value.amount <= 0) {
                    return Promise.reject()
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}>
            <AmountInput
              currency={quotationCurrency!}
              step={step?.amount}
              max={
                unTaxBenchmark
                  ? data.quotationUnTaxMoney?.amount || data?.bidStartPrice?.amount
                  : data.quotationMoney?.amount || bidStartTaxPrice?.amount
              }
              onChange={v => doChangeMoney({ quotationUnTaxMoney: v, quotationMoney: v })}
            />
          </DataFormItem>
        )}
        <DataFormItem name={`${title}-taxRate`} label="税率" required initialValue={data.taxRate}>
          <QuoteLaxSelect
            onChange={e => {
              setTaxRate(e as number)
              doChangeMoney({ taxRate: e as number })
            }}
          />
        </DataFormItem>
        {isQuantity && (
          <>
            <DataFormItem name={`${title}-freight`} label="运费（含税）" required initialValue={data.freight}>
              <AmountInput currency={quotationCurrency!} />
            </DataFormItem>
            <DataItem label="需求数量" value={data.quantity} />
            {unTaxBenchmark ? (
              <DataItem
                label={`单价 (含税)`}
                value={<DataMoney fontWeight="extraBold" size="default" value={unitMoney} />}
              />
            ) : (
              <DataItem
                label={`单价 (未税)`}
                value={<DataMoney fontWeight="extraBold" size="default" value={unitUnTaxMoney} />}
              />
            )}
          </>
        )}
        {isQuantity ? (
          <>
            <DataItem
              label={`报价金额\n(未税)`}
              value={<DataMoney fontWeight="extraBold" size="default" value={quotationUnTaxMoney} />}
            />
            <DataItem
              label={`报价金额\n(含税)`}
              value={<DataMoney fontWeight="extraBold" size="default" value={quotationMoney} />}
            />
          </>
        ) : unTaxBenchmark ? (
          <DataItem
            w100
            label={`报价金额\n(含税)`}
            value={<DataMoney fontWeight="extraBold" size="default" value={quotationMoney} />}
          />
        ) : (
          <DataItem
            w100
            label={`报价金额\n(未税)`}
            value={<DataMoney fontWeight="extraBold" size="default" value={quotationUnTaxMoney} />}
          />
        )}
        <DataFormItem name={`${title}-validityPeriod`} label="报价有效期至" required initialValue={data.validityPeriod}>
          <DatePicker />
        </DataFormItem>
        <DataFormItem
          name={`${title}-maintainPeriod`}
          label="维保期限 (月)"
          required
          initialValue={data.maintainPeriod}>
          <LicensingPeriodSelect />
        </DataFormItem>
        <DataFormItem
          name={`${title}-supplyCycle`}
          className={bem('fmi-nowrap')}
          label="供货周期 (自然日)"
          required
          initialValue={data.supplyCycle}>
          <InputNumber direction="vertical" min={0} toFixed={0} />
        </DataFormItem>
        <DataFormItem name={`${title}-invoiceType`} label="发票类型" required initialValue={data.invoiceType}>
          <Select options={bizEnums.InvoiceTypeEnum.values()} />
        </DataFormItem>
        {bizEnums.QuotationMethodEnum.TENDER == quotationMethod ? (
          <>
            <DataFormItem
              w100
              required
              name={`${title}-commercialMarkAttachments`}
              label="商务标附件"
              initialValue={data.commercialMarkAttachments}>
              <QutotationUpload
                fileSizeLimit={1024 * 1024 * 100}
                tip={'单个文件小于100M'}
                defaultValue={data.commercialMarkAttachments || []}
              />
            </DataFormItem>
            <DataFormItem
              w100
              name={`${title}-technicalMarkAttachments`}
              label="技术标附件"
              required
              initialValue={data?.technicalMarkAttachments}>
              <QutotationUpload
                fileSizeLimit={1024 * 1024 * 100}
                tip={'单个文件小于100M'}
                defaultValue={data.technicalMarkAttachments || []}
              />
            </DataFormItem>
          </>
        ) : (
          <>
            <DataFormItem
              w100
              name={`${title}-quotationAttachments`}
              label="报价附件"
              initialValue={data.quotationAttachments}>
              <QutotationUpload
                fileSizeLimit={1024 * 1024 * 100}
                tip={'单个文件小于100M'}
                defaultValue={data.quotationAttachments || []}
              />
            </DataFormItem>
            <DataFormItem
              w100
              name={`${title}-quotationRemark`}
              label="供应商报价备注"
              initialValue={data.quotationRemark}>
              <Input.TextArea showCount maxLength={1000} />
            </DataFormItem>
          </>
        )}

        <DataFormItem w100 name={`${title}-orderMethod`} style={{ display: 'none' }} initialValue={data.orderMethod}>
          <Input />
        </DataFormItem>
        <DataFormItem w100 name={`${title}-rfxLineId`} style={{ display: 'none' }} initialValue={data.rfxLineId}>
          <Input />
        </DataFormItem>
      </DataGrid>
    </>
  )
}

const LightTag = (props: { icon: ReactNode; label: ReactNode; value: any }) => {
  const { value, label, icon } = props
  if (!value) return null
  return (
    <div className={bem('line-tags-item')}>
      <div className={bem('line-tags-label')}>
        {icon}
        <span>{label}</span>
      </div>
      <span className={bem('line-tags-value')}>{value}</span>
    </div>
  )
}

const RenderTitletags = (params: {
  firstPrice?: defs.Money
  rank?: number
  totalBidRank?: defs.TotalBidRank
  quotationRule?: defs.QuotationRuleDto
}) => {
  const { firstPrice, rank, totalBidRank, quotationRule } = params

  const { showFirstPlacePrice } = quotationRule?.showType || {} // 第一名报价 起拍价
  const isAllBid = quotationRule?.isAllBid

  if (!isAllBid) return null
  const _firstPrice = totalBidRank?.firstRankAmount || firstPrice
  const _rank = totalBidRank?.rank || rank
  return (
    <div className={bem('line-tags')}>
      {showFirstPlacePrice && _firstPrice ? (
        <LightTag
          icon={<TrophyFilled />}
          label="第一名报价"
          value={`${_firstPrice?.currency} ${_firstPrice?.amount}`}
        />
      ) : null}
      {_rank ? <LightTag icon={<CrownFilled />} label="当前报价排名" value={`第${_rank}名`} /> : null}
    </div>
  )
}

const RenderLineTags = (params: {
  bidStartPrice?: defs.Money
  firstPrice?: defs.Money
  rank?: number
  bidRankInfo?: defs.LineBidRank
  quotationRule?: defs.QuotationRuleDto
  isQuantity?: boolean
}) => {
  const { bidStartPrice, firstPrice, rank, bidRankInfo, quotationRule, isQuantity } = params
  const { showFirstPlacePrice, showStartingBid } = quotationRule?.showType || {} // 第一名报价 起拍价
  const isAllBid = quotationRule?.isAllBid
  if (isAllBid && !showStartingBid) return null

  const _firstPrice = bidRankInfo?.firstRankAmount || firstPrice
  const _rank = bidRankInfo?.rank || rank
  return (
    <div className={bem('line-tags')}>
      {showStartingBid && bidStartPrice ? (
        <LightTag
          icon={<YuanCircleFilled />}
          label={`起拍价${isQuantity ? '（未税单价）' : ''}`}
          value={`${bidStartPrice?.currency} ${bidStartPrice?.amount}`}
        />
      ) : null}
      {!isAllBid ? (
        <>
          {showFirstPlacePrice && _firstPrice ? (
            <LightTag
              icon={<TrophyFilled />}
              label="第一名报价"
              value={`${_firstPrice?.currency} ${_firstPrice?.amount}`}
            />
          ) : null}
          {_rank ? <LightTag icon={<CrownFilled />} label="当前报价排名" value={`第${_rank}名`} /> : null}
        </>
      ) : null}
    </div>
  )
}

const BiddingLines = (params: {
  quotationCurrency?: defs.Currency
  quotable?: boolean
  lines: defs.QuotationLineDto[]
  lineBidRanks?: defs.LineBidRank[]
  quotationRule?: defs.QuotationRuleDto
  quotationMethod?: defs.QuotationMethod
  lineQuotationMoneyChange?: (rfxId: string, money: defs.MoneyDto) => void
}) => {
  const { lines, quotable, quotationRule, quotationCurrency, lineBidRanks, quotationMethod, lineQuotationMoneyChange } =
    params
  const step = quotationRule?.adjustStep?.amount

  return (
    <>
      {map(lines, (data, index) => {
        const bidRankInfo = lineBidRanks?.find(item => item.rfxLineId === data.rfxLineId)
        const isQuantity = data.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY
        return (
          <div key={`Line${index + 1}`}>
            <DataBlock title={`Line${index + 1}`}>
              <DataGrid>
                <DataItem w100 label="商品名称" value={<span className={bem('goodsName')}>{data.goodsName}</span>} />
                <DataItem label="品牌" value={data.goodsBrand} />
                <DataItem label="配置" value={data.goodsConfiguration} />
                <DataItem label="授权期限 (月)" value={renderPeriod(data.licensingPeriod)} />
                <DataItem
                  label="收货地址"
                  value={[data?.countryName, data?.stateName, data?.cityName, data?.address]
                    .filter(item => !!item)
                    .join('-')}
                />
                <DataItem
                  w100
                  label="询价附件"
                  value={<QutotationAttachments dataId={''} attachments={data.attachments} />}
                />
                <DataItem w100 label="备注" value={data.remark} />
              </DataGrid>
            </DataBlock>

            <DataBlock
              title={'报价信息'}
              extra={
                bizEnums.QuotationLineStatusEnum.HAS_WINNING_BID === data.status && (
                  <Tag
                    color={'light-green'}
                    // border={border}
                    text={
                      <>
                        <GoldMedals />
                        中标
                      </>
                    }
                  />
                )
              }>
              <RenderLineTags
                bidRankInfo={bidRankInfo}
                bidStartPrice={data.bidStartPrice}
                rank={data.rank}
                firstPrice={data.firstPrice}
                quotationRule={quotationRule}
                isQuantity={isQuantity}
              />
              {quotable ? (
                <LineQuotationFormView
                  data={data}
                  title={`Line${index + 1}`}
                  step={step}
                  quotationCurrency={quotationCurrency}
                  quotationMethod={quotationMethod}
                  lineQuotationMoneyChange={lineQuotationMoneyChange}
                />
              ) : (
                <LineQuotationView data={data} quotationMethod={quotationMethod} />
              )}
            </DataBlock>
          </div>
        )
      })}
    </>
  )
}

const genSubmitParams = (formVal: any) => {
  const linesFormVal = Object.fromEntries(Object.entries(formVal).filter(([key, value]) => key.includes('-')))
  let lines = []
  for (var key in linesFormVal) {
    // 从键名中获取索引和属性名
    var parts = key.split('-')
    var index = parseInt(parts[0].replace('Line', ''))
    var prop = parts[1]
    // 如果结果数组中还没有对应索引的对象，就创建一个
    if (!lines[index]) {
      lines[index] = {}
    }
    // 将值添加到对应索引的对象中
    if ('validityPeriod' === prop) {
      // 报价有效期，要求这个格式
      // @ts-ignore
      lines[index][prop] = dayjs(formVal[key]).format('YYYY-MM-DD')
    } else {
      // @ts-ignore
      lines[index][prop] = formVal[key]
    }
  }
  return { lines: lines.filter(v => v) } as defs.UpdateQuotationCommand
}

const QuotationHeader = (props: {
  data?: defs.QuotationDetailDto
  satisfyTimeInterval?: boolean
  doSubmit?: any
  doGiveUp?: any
}) => {
  const { data, doGiveUp, doSubmit, satisfyTimeInterval } = props
  const isTender = data?.quotationMethod === bizEnums.QuotationMethodEnum.TENDER //是否为招投标
  const quotationStatus = bizEnums.QuotationStatusEnum.pick(data?.status!) //当前报价状态
  const delayTime = data?.quotationRule?.delayTime //延时时长

  const statusIcon = () => {
    switch (data?.status) {
      case bizEnums.QuotationStatusEnum.HAS_WINNING_BID:
        return <GoldMedals />
      case bizEnums.QuotationStatusEnum.ABANDONED_BID:
        return <Delete />
      case bizEnums.QuotationStatusEnum.NOT_BID:
      case bizEnums.QuotationStatusEnum.NOT_WINNING_BID:
        return <CloseCircle />
      case bizEnums.QuotationStatusEnum.HAS_QUOTATION:
        return <Clock />
      default:
        return <></>
    }
  }

  return data?.quotable ? (
    <PageHeader
      title={`报价单详情`}
      action={
        <>
          <Button icon={<Close />} type="checked-neutral" onClick={() => window.close()}>
            取消
          </Button>
          {data?.status === bizEnums.QuotationStatusEnum.WAIT_QUOTATION && !isTender && (
            <Button icon={<Delete />} danger onClick={doGiveUp}>
              弃标
            </Button>
          )}

          {!delayTime || satisfyTimeInterval ? (
            <Button icon={<Bill />} type="primary" onClick={doSubmit}>
              报价
            </Button>
          ) : (
            <Tooltip title={`报价间隔时长不小于 ${delayTime} 分钟`}>
              <Button disabled={true} icon={<Bill />} type="primary" onClick={doSubmit}>
                报价
              </Button>
            </Tooltip>
          )}
        </>
      }
    />
  ) : (
    <header className={bem('header', { [quotationStatus?.value!]: true })}>
      <div className={bem('header-title')}>
        {statusIcon()}
        {quotationStatus?.name!}
      </div>
    </header>
  )
}

export default (params: { data: defs.QuotationDetailDto; id: string; refresh?: () => void }) => {
  const { data, id, refresh } = params
  const { push } = useRouter()
  const [fm] = Form.useForm()
  const { getWorkbenchStatistics } = useDispatch(dispatch => dispatch.workbenchStatistics)
  const { deadlineInfo } = useCurrentdata(id, data?.quotable && data?.isBidding) //轮询报价信息
  const { totalMoney, lineQuotationMoneyChange } = useTotalPrice(data)
  const { bidRank, satisfyTimeInterval } = deadlineInfo

  const isTender = data.quotationMethod === bizEnums.QuotationMethodEnum.TENDER //是否为招投标

  const goBack = () => {
    getWorkbenchStatistics()
    if (isTender) {
      push(`/tender`)
    } else {
      push(`/quotation`)
    }
  }

  const doGiveUp = async () => {
    Modal.confirm({
      title: '您即将弃标，一旦提交，将被视为正式且不可更改。您确定要继续提交吗？',
      confirmLoading: true,
      onOk: async () => {
        await apiService.quotationQuotationGiveUpQuotation({ quotationId: id })
        Message.success('弃标成功')
        goBack()
      },
    })
  }

  const doSubmit = async () => {
    const _deadline = deadlineInfo?.deadlineAt || data.deadlineAt

    if (!(_deadline && dayjs().isBefore(_deadline))) {
      Modal.info({
        title: '报价时间已截止',
        content: '已超过报价截止时间',
        okText: I18N.auto.woZhiDaoLe,
        cancelButton: false,
        onOk: refresh,
      })
      return
    }

    await fm.validateFields()
    const formVal = fm.getFieldsValue()
    const params: defs.UpdateQuotationCommand = genSubmitParams(formVal)
    // 金额基准值再传回去
    for (const v of params.lines || []) {
      v.moneyCalBenchmark = data.moneyCalBenchmark
    }
    Modal.confirm({
      title: '您即将提交报价，请确认所有信息无误。您确定要继续提交吗？',
      confirmLoading: true,
      onOk: async () => {
        try {
          await apiService.quotationQuotationUpdateQuotation({ quotationId: id }, params)
          Message.success('提交成功')
          if (data.isBidding) {
            refresh?.()
          } else {
            goBack()
          }
        } catch (error) {
          console.log(error)
        }
      },
    })
  }

  return (
    <PageContainer className={bem()}>
      <QuotationHeader data={data} satisfyTimeInterval={satisfyTimeInterval} doSubmit={doSubmit} doGiveUp={doGiveUp} />
      <DataForm form={fm}>
        <MainContainer w1220>
          <div className={bem('main')}>
            <div className={bem('main-left')}>
              <div className={bem('content')}>
                <div className={bem('content-header')}>
                  <div className={bem('content-header-title')}>我的报价</div>
                  {(data.quotationRule?.isAllBid || data.quotationTenderConfig?.isAllBid) && (
                    <div>
                      <span className={bem('content-header-label')}> 报价金额(含税)：</span>
                      <DataMoney
                        fontWeight="extraBold"
                        size="xsmall"
                        highlight={false}
                        value={totalMoney}
                        showSign={false}
                      />
                    </div>
                  )}
                </div>
                <RenderTitletags
                  rank={data?.rank}
                  firstPrice={data?.firstPrice}
                  totalBidRank={bidRank?.totalBidRank}
                  quotationRule={data?.quotationRule}
                />
                <BiddingLines
                  lines={data?.lines || []}
                  lineBidRanks={bidRank?.lineBidRanks}
                  quotationRule={data?.quotationRule}
                  quotable={data?.quotable}
                  quotationCurrency={data?.quotationCurrency}
                  quotationMethod={data.quotationMethod}
                  lineQuotationMoneyChange={lineQuotationMoneyChange}
                />
              </div>
            </div>
            <div className={bem('main-right')}>
              {data?.quotable && (
                <TimeCountDown
                  className={bem('time')}
                  targetDate={deadlineInfo?.deadlineAt || data?.deadlineAt}
                  onEnd={() => {}}
                  interval={110}
                  type="bidding"
                />
              )}
              <QuotationDetailCard data={data} isTender={isTender} quotationCurrency={data?.quotationCurrency} />
              <BiddingRuleCard data={data?.quotationRule} />
              {!!data?.quotationHistories?.length && data.isBidding && <Chemistry id={id!} />}
              <HistoryLog list={data?.quotationHistories} id={id} />
              {isTender && <TenderQuestion questionId={id} rfxId={data?.rfxId} canClarify={data.quotable} />}
            </div>
          </div>
        </MainContainer>
      </DataForm>
    </PageContainer>
  )
}

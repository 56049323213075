import { BEMGenerator, useRCMount } from '@oahz/neact-utils'
import { useManualService, useRoute } from '@oahz/neact'
import { DataContainer, DataCard, DataGrid, DataItem } from '@feature/views'
import { Table } from '@bedrock/components'
import type { ColumnsType } from '@bedrock/components/lib/Table'
import I18N from '@feature/i18n'
import { formatDateTime } from '@feature/shared'
import { PageContainer, MainContainer, PageHeader, PageLoading } from '@/components/page-view'
import { DeliveryLineGoodsInfo, PoAttachments, POValueCopyView } from '@/components/order'
import { apiService, bizEnums, type defs } from '@/services'
import { ListMoneyView, DataLink } from '@/components/data-view'
import './style.less'

const bem = BEMGenerator('pss')

const DeliveryDetails = (props: {
  poData?: defs.FmDeliveryFulfillmentDetailDto
  data: defs.FmDeliveryFulfillmentDetailLineDto[]
}) => {
  const { poData, data } = props
  const unTaxBenchmark = poData?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  const columns: ColumnsType = [
    { dataIndex: 'orderLineNo', title: 'PO行号', width: 140, align: 'left', render: v => v || '-' },
    {
      dataIndex: 'goodsInfo',
      title: I18N.auto.shangPinXinXi,
      width: 350,
      align: 'left',
      render: (_, record) => (
        <DeliveryLineGoodsInfo
          value={{
            goodsInfo: record?.goodsName,
            brand: record.goodsBrand,
            configuration: record.goodsConfiguration,
          }}
        />
      ),
    },
    {
      dataIndex: 'quantity_amount',
      title: `本次发货/履约信息${unTaxBenchmark ? '（未税）' : '（含税）'}`,
      width: 170,
      align: 'right',
      render: (_, record) =>
        record.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY ? (
          <span className={bem('quantiy')}>{record.quantity}</span>
        ) : (
          <ListMoneyView value={unTaxBenchmark ? record.unTaxAmount : record.amount} />
        ),
    },
    {
      dataIndex: 'remark',
      title: '发货行备注',
      width: 200,
      align: 'left',
      render: v => (v ? <span className={bem('remark')}>{v}</span> : '-'),
    },
    {
      dataIndex: 'attachments',
      title: '附件',
      width: 235,
      align: 'left',
      render: (_, record) => <PoAttachments dataId={''} attachments={record.attachments} />,
    },
  ]

  return (
    <Table
      data={data || []}
      columns={columns}
      scroll={{ x: true }}
      rowKey={(_, i) => i!}
      className={bem('table')}
      outerBorder={false}
    />
  )
}

export default () => {
  const { query } = useRoute()
  const { data, loading, request } = useManualService(apiService.fmFmFulfillmentDetail)
  useRCMount(() => query.id && request({ id: query.id }))

  if (loading) return <PageLoading />

  return (
    <PageContainer className={bem()}>
      <PageHeader title={`发货单详情：${data?.id}`} />
      <MainContainer>
        <DataContainer>
          <DataCard title={'收货信息'}>
            <DataGrid>
              <DataItem label="收货人" value={data?.receivingInfo?.receiver} />
              <DataItem label="电话" value={data?.receivingInfo?.contactWay} />
              <DataItem label="邮箱" value={data?.receivingInfo?.receiverEmail} />
              <DataItem label="邮编" value={data?.receivingInfo?.postalCode} />
              <DataItem w100 label="收货地址" value={data?.receivingInfo?.address} />
            </DataGrid>
          </DataCard>
          <DataCard title={'发货信息'}>
            <DataGrid>
              <DataItem label="发货人" value={data?.deliveryInfo?.deliverer} />
              <DataItem label="发货时间" value={formatDateTime(data?.deliveryInfo?.deliveryTime, 'YYYY-MM-DD')} />
              <DataItem
                label="发货类型"
                value={data?.deliveryInfo?.type && bizEnums.DeliveryTypeEnum.pick(data?.deliveryInfo?.type)?.name}
              />
              <DataItem label="预计到货日期" value={data?.deliveryInfo?.estimateArrivalTime} />
              {data?.deliveryInfo?.type === bizEnums.DeliveryTypeEnum.EXPRESS_AND_LOGISTICS && (
                <>
                  <DataItem label="物流公司" value={data?.deliveryInfo?.courierCompanyName} />
                  <DataItem
                    label="物流单号"
                    value={
                      <DataLink href={data?.deliveryInfo?.trackingUrl || ''}>{data?.deliveryInfo?.trackingNo}</DataLink>
                    }
                  />
                </>
              )}
              <DataItem w100 label="供应商发货备注" value={data?.deliveryInfo?.remark} />
              <DataItem
                label="发货单附件"
                value={<PoAttachments dataId={''} attachments={data?.deliveryInfo?.attachments} />}
              />
            </DataGrid>
          </DataCard>
          <DataCard title={'明细'} action={<POValueCopyView value={data?.orderNo} />}>
            <DataGrid>
              <DeliveryDetails poData={data} data={data?.lines || []} />
            </DataGrid>
          </DataCard>
        </DataContainer>
      </MainContainer>
    </PageContainer>
  )
}

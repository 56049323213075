import { useCallback, useRef, useEffect } from 'react'

const sleep = (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time))
}

const useUnMount = (fn: () => void) => {
  useEffect(
    () => () => {
      fn()
    },
    [fn]
  )
}

type PollingRes<T> = {
  run: boolean
  handleOk?: (params: T) => void
}

const usePolling = <T>(polling: (params: T) => Promise<PollingRes<T>>, time: number, cancelFn?: () => void) => {
  const isPollingRef = useRef(false)
  const cancelRef = useRef(false)

  const cancelPolling = useCallback(() => {
    if (isPollingRef.current) {
      cancelRef.current = true
      cancelFn?.()
    }
  }, [cancelFn])

  const doPolling = useCallback(
    (params: T) => {
      if (isPollingRef.current) {
        return
      }
      isPollingRef.current = true

      const pollNext = async () => {
        if (cancelRef.current) {
          isPollingRef.current = false
          cancelRef.current = false
          return
        }

        const next: PollingRes<T> = await polling(params)

        if (next.run) {
          await sleep(time)
          pollNext()
        } else {
          isPollingRef.current = false
          next.handleOk?.(params)
          cancelFn?.()
        }
      }

      pollNext()
    },
    [cancelFn, polling, time]
  )

  useUnMount(cancelPolling)
  return [doPolling, cancelPolling]
}

export default usePolling

import { ReactNode, useState } from 'react'
import { useRoute, useRouter } from '@oahz/neact'
import { BEMGenerator, useRCMount, useRCUpdate } from '@oahz/neact-utils'
import { Button, Form, Input, Radio, Spin, Empty, Select, Checkbox, Link } from '@bedrock/components'
import NoFile from '@bedrock/components/es/Empty/images/large/NoFile'
import { checkChinaPersonId } from '@feature/shared'
import I18N from '@feature/i18n'
import { AsyncButton } from '@feature/views'
import { apiService, defs, bizEnums } from '@/services'
import {
  ConfirmPasswordFormItem,
  CountrySelect,
  PasswordFormItem,
  SinginContainer,
  SupplierVerificationCode,
} from '@/components/signin'
import { rsaEncrypt, setBizHeaderEnterpriseId } from '@/shared'
import './style.less'

const bem = BEMGenerator('pregi')

const FormMergeView = (props: { children: ReactNode }) => {
  return <div className={bem('merge-view')}>{props.children}</div>
}

export default () => {
  const [fm] = Form.useForm()
  const { query } = useRoute()
  const { replace } = useRouter()
  const [loading, setLoading] = useState(false)
  const [contactMail, setContactMail] = useState('')
  const [checkError, setCheckError] = useState(false)
  const [type, setType] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [hasAgreed, setHasAgreed] = useState(false)
  const [personIdType, setPersonIdType] = useState<defs.PersonIdType>()
  useRCUpdate(() => {
    if (type === bizEnums.SupplierTypeEnum.PERSON && countryCode === 'CN') {
      fm.setFieldValue('personIdType', bizEnums.PersonIdTypeEnum.RESIDENCE_ID_CARD)
      setPersonIdType(bizEnums.PersonIdTypeEnum.RESIDENCE_ID_CARD)
    }
  }, [type, countryCode])

  useRCMount(() => onCheckToken())

  const onCheckToken = async () => {
    try {
      setLoading(true)
      if (query.token) {
        const res = await apiService.identityEnterpriseRegistrationGetRegisteredName(
          { token: query.token },
          { msgable: false }
        )
        if (res) {
          setContactMail(res?.mail || '')
          fm.setFieldsValue({
            name: res?.name,
            contactMail: res?.mail,
          })
        }
      } else {
        setCheckError(true)
      }
    } catch (error) {
      console.log(error)
      setCheckError(true)
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async () => {
    await fm.validateFields()
    const formVal = await fm.getFieldsValue()
    const params: defs.IdEnterpriseRegistrationCommand = {
      ...formVal,
      token: query.token,
      countryId: formVal['countryId_countryCode'].toString().split('_')[0],
      countryCode: formVal['countryId_countryCode'].toString().split('_')[1],
      password: rsaEncrypt(formVal.password),
      confirmPassword: rsaEncrypt(formVal.confirmPassword),
    }
    const enterpriseId = await apiService.identityEnterpriseRegistrationRegister(params)
    setBizHeaderEnterpriseId(enterpriseId)
    replace('/enterprise')
  }

  const personRender = () => (
    <>
      <Form.Item name="name" label="姓名" rules={[{ required: true, message: '请输入姓名' }]}>
        <Input disabled placeholder="请输入姓名" />
      </Form.Item>
      <Form.Item name="personIdType" label="证件类型" rules={[{ required: true, message: '请选择证件类型' }]}>
        <Select
          disabled={countryCode === 'CN'}
          options={bizEnums.PersonIdTypeEnum.values()}
          placeholder="请选择证件类型"
          onChange={e => setPersonIdType(e)}
        />
      </Form.Item>
      <Form.Item
        name="idNumber"
        label="证件号码"
        {...(personIdType === bizEnums.PersonIdTypeEnum.RESIDENCE_ID_CARD
          ? {
              rules: [
                {
                  message: I18N.auto.shuRuYouWu,
                  validator: async (_, v) => {
                    if (!checkChinaPersonId(v)) return Promise.reject()
                  },
                },
              ],
            }
          : { required: true, message: '请输入证件号码' })}>
        <Input placeholder="请输入证件号码" />
      </Form.Item>
    </>
  )

  const companyRender = () => (
    <>
      <Form.Item name="name" label="企业名称" rules={[{ required: true, message: '请输入企业名称' }]}>
        <Input disabled placeholder="请输入企业名称" />
      </Form.Item>
      {/* 国内统一信用代码，海外Tin */}
      {countryCode === 'CN' ? (
        <Form.Item
          name="creditCode"
          label="统一社会信用代码"
          rules={[{ required: true, message: '请输入统一社会信用代码' }]}>
          <Input placeholder="请输入统一社会信用代码" />
        </Form.Item>
      ) : (
        <Form.Item name="tin" label="TIN" rules={[{ required: true, message: '请输入TIN' }]}>
          <Input placeholder="请输入TIN" />
        </Form.Item>
      )}
      <Form.Item name="contactName" label="管理员姓名" rules={[{ required: true, message: '请输入管理员姓名' }]}>
        <Input placeholder="请输入管理员姓名" />
      </Form.Item>
    </>
  )

  const content = () => (
    <>
      <Form form={fm} layout="vertical" autoComplete="off" className={bem('form')}>
        <FormMergeView>
          <Form.Item
            initialValue={bizEnums.SupplierTypeEnum.ENTERPRISE}
            name="type"
            label="供应商类型"
            rules={[{ required: true, message: '请选择供应商类型' }]}>
            <Radio.Group options={bizEnums.SupplierTypeEnum.values()} onChange={e => setType(e.target.value)} />
          </Form.Item>
          <Form.Item
            name="countryId_countryCode"
            label="注册国家/地区"
            rules={[{ required: true, message: '请国家/地区' }]}>
            <CountrySelect onChange={e => setCountryCode(e.toString().split('_')[1])} />
          </Form.Item>
        </FormMergeView>
        {type === bizEnums.SupplierTypeEnum.PERSON ? personRender() : companyRender()}
        <Form.Item
          name="contactMail"
          label="管理员邮箱"
          rules={[{ required: true, message: '请输入管理员邮箱', type: 'email' }]}>
          <Input disabled />
        </Form.Item>
        <Form.Item name="verificationCode" label="验证码" rules={[{ required: true, message: '请输入验证码' }]}>
          <SupplierVerificationCode mail={contactMail} />
        </Form.Item>
        <FormMergeView>
          <PasswordFormItem />
          <ConfirmPasswordFormItem form={fm} />
        </FormMergeView>
      </Form>
      <Checkbox className={bem('agreed')} onChange={e => setHasAgreed(e.target.checked)}>
        已阅读并同意
        <Link className={bem('protocol')} href="/supplier-management-system-usage-agreement.pdf" target="_blank">
          《网易供应商平台使用协议》
        </Link>
      </Checkbox>
      <AsyncButton type="primary" size="large" disabled={!hasAgreed} className={bem('submit')} onClick={onSubmit}>
        注册并登录
      </AsyncButton>
    </>
  )

  return (
    <SinginContainer>
      <div className={bem()}>
        <div className={bem('title')}>供应商注册</div>
        <div className={bem('content')}>
          {checkError ? (
            <Empty
              className={bem('empty')}
              imageNode={<NoFile />}
              text={<span className={bem('empty-text')}>链接失效</span>}
            />
          ) : loading ? (
            <Spin />
          ) : (
            content()
          )}
        </div>
        <div className={bem('f')}>
          已有账号？去{' '}
          <span className={bem('f-login')} onClick={() => (window.location.href = '/login')}>
            登录
          </span>{' '}
        </div>
      </div>
    </SinginContainer>
  )
}

import type { ReactNode } from 'react'
import { useRoute } from '@oahz/neact'
import { BEMGenerator, useRCVars } from '@oahz/neact-utils'
import type { QueryParams } from '@bedrock-e/common'
import I18N from '@feature/i18n'
import { ELightDatePicker } from '@bedrock-e/common'
import { AppTable, createTableColumns, AppDefaultTableHeader } from '@feature/views'
import { formatDateTime } from '@feature/shared'
import { apiService, defs } from '@/services'
import { OrderPageLink } from '@/components/order'
import { PageContainer, PageList } from '@/components/page-view'
import { ListMoneyView } from '@/components/data-view'
import './style.less'

const bem = BEMGenerator('pol')
interface ColumnsProps extends Omit<defs.FmOrderListDto, 'FmOrderLineDto'>, defs.FmOrderListLineDto {
  rowSpan: number
}

const genListData = (data: defs.FmOrderListDto[]) => {
  return data.reduce((acc: defs.FmOrderListLineDto[], item: defs.FmOrderListDto) => {
    const { orderLines, ...rest } = item
    const newAsnLineList = !orderLines?.length
      ? [item]
      : orderLines?.map((line, index) => ({
          ...line,
          ...rest,
          rowSpan: index === 0 ? orderLines.length : 0,
        })) || []
    return [...acc, ...newAsnLineList]
  }, [])
}

export default () => {
  const { query } = useRoute()
  const vars = useRCVars({ keyword: query.keyword as string })

  const colums = createTableColumns<ColumnsProps>([
    {
      dataIndex: 'orderNo',
      title: I18N.auto.pODanHao,
      width: 200,
      align: 'left',
      fixed: 'left',
      onCell: row => ({ rowSpan: row.rowSpan }),
      render: (_, record) => <OrderPageLink orderId={record.id!}>{record.orderNo}</OrderPageLink>,
    },
    {
      dataIndex: 'lineNo',
      title: I18N.auto.xingHao,
      width: 63,
      align: 'left',
      fixed: 'left',
      render: v => (v ? v.split('-')[1] : '-'),
    },
    {
      dataIndex: 'goodsName',
      title: I18N.auto.shangPinMingCheng,
      width: 200,
      align: 'left',
      render: v => v || '-',
    },
    {
      dataIndex: 'goodsBrand',
      title: I18N.auto.pinPai,
      width: 140,
      align: 'left',
      render: v => v || '-',
    },
    {
      dataIndex: 'goodsConfiguration',
      title: I18N.auto.peiZhi,
      width: 153,
      align: 'left',
    },
    {
      dataIndex: 'shippingQuantity',
      title: I18N.auto.keFaHuoShuLiang,
      width: 105,
      align: 'left',
      render: v => (v !== undefined ? v : '-'),
    },
    {
      dataIndex: 'shippingAmount',
      title: '可履约金额',
      width: 145,
      align: 'right',
      render: val => (val ? <ListMoneyView value={val} /> : '-'),
    },
    {
      dataIndex: 'countryName',
      title: I18N.auto.guoJiaDiQu_2,
      width: 200,
      align: 'left',
      render: val => val || '-',
    },
    {
      dataIndex: 'address',
      title: I18N.auto.shouHuoDiZhi,
      width: 170,
      align: 'left',
      paragraph: true,
    },
    {
      dataIndex: 'expectedDeliveryDate',
      title: I18N.auto.qiWangDaoHuoRi,
      width: 145,
      align: 'left',
      render: v => formatDateTime(v, 'YYYY-MM-DD'),
    },
    {
      dataIndex: 'settlementCompanyName',
      title: I18N.auto.jieSuanGongSi,
      width: 199,
      align: 'left',
    },
    {
      dataIndex: 'orderTime',
      title: I18N.auto.xiaDanShiJian,
      width: 209,
      align: 'left',
      fixed: 'right',
      render: v => formatDateTime(v, 'YYYY-MM-DD HH:mm:ss'),
    },
  ])

  const queryItems = [
    {
      name: 'orderTime',
      label: I18N.auto.xiaDanShiJian,
      children: <ELightDatePicker range />,
    },
    {
      name: 'expectedDeliveryDate',
      label: I18N.auto.qiWangDaoHuoRi,
      children: <ELightDatePicker range />,
    },
  ]

  const request = (params: Pick<QueryParams, 'orderItemList'>) => {
    const [orderTimeStart, orderTimeEnd] = params.orderTime || []
    const [expectedDeliveryDateStart, expectedDeliveryDateEnd] = params.expectedDeliveryDate || []
    const genParmas: defs.FmOrderListQuery = {
      ...params,
      // @ts-ignore
      orderTime: undefined,
      expectedDeliveryDate: undefined,
      orderTimeStart: orderTimeStart,
      orderTimeEnd: orderTimeEnd,
      expectedDeliveryDateStart: expectedDeliveryDateStart,
      expectedDeliveryDateEnd: expectedDeliveryDateEnd,
    }

    return apiService.fmFmList(genParmas).then(res => {
      return {
        records: genListData(res?.records || []),
        total: res?.total,
      }
    })
  }

  const renderHeader = ({ setting, doRequest }: { setting: ReactNode; doRequest: (params: any) => void }) => (
    <AppDefaultTableHeader
      onQuery={doRequest}
      queryItems={queryItems}
      actions={<div />}
      settings={setting}
      queryInput={{
        defaultValue: vars.keyword,
        placeholder: '请输入PO单号/商品名称',
      }}
    />
  )

  return (
    <PageContainer className={bem()} htmlTitle={'订单列表'}>
      <PageList className={bem('list')}>
        <AppTable
          rowKey={(_, i) => i!}
          request={request}
          columns={colums}
          settable={{ id: 'orderList' }}
          className={bem('table')}
          renderHeader={renderHeader}
        />
      </PageList>
    </PageContainer>
  )
}

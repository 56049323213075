import { ReactNode, forwardRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import ReactToPrint from 'react-to-print'
import dayjs from 'dayjs'
import { Button, Spin, Divider } from '@bedrock/components'
import { MainContainer as MainContainerView } from '@feature/views'
import { Download } from '@bedrock/icons-react'
import { BEMGenerator } from '@oahz/neact-utils'
import I18N from '@feature/i18n'
import DepartmentLog from '@/assets/footer-department-log.png'
import ProtalLog from '@/assets/footer-protal-log.png'
import './style.less'

const bem = BEMGenerator('cpv')

export const PageContainer = (props: { className?: string; htmlTitle?: string; children?: ReactNode }) => {
  const { className, htmlTitle, children } = props
  return (
    <>
      <Helmet>
        <title>{htmlTitle}</title>
      </Helmet>
      <section className={bem('page', [className])}>{children}</section>
    </>
  )
}

export const PageLoading = () => {
  return <Spin className={bem('loading')} size="small" />
}

export const PageHeader = (props: { className?: string; title: ReactNode; action?: ReactNode }) => {
  const { className, title, action } = props

  return (
    <header className={bem('header', [className])}>
      <div className={bem('header-title')}>{title}</div>
      <div className={bem('header-action')}>{action}</div>
    </header>
  )
}

export const PageList = (props: { className?: string; children?: ReactNode }) => {
  const { className, children } = props
  return (
    <section className={bem('list', [className])}>
      <main className={bem('list-content')}>{children}</main>
    </section>
  )
}

export const MainContainer = forwardRef<
  HTMLDivElement,
  {
    className?: string
    mainRect?: boolean
    loading?: boolean
    footer?: ReactNode
    children?: ReactNode
    w1280?: boolean
    w1220?: boolean
  }
>((props, ref) => {
  const { className, mainRect = true, w1280 = false, w1220 = false, loading, footer, children, ...rest } = props
  return (
    <MainContainerView
      className={bem('main', { w1280, w1220 }, [className])}
      footer={footer || <PageFooter />}
      {...(mainRect ? {} : { ref })}
      {...rest}>
      {loading && <Spin className={bem('main-loading')} />}
      {mainRect ? (
        <main className={bem('main-content')} ref={ref}>
          {children}
        </main>
      ) : (
        children
      )}
    </MainContainerView>
  )
})

export const PrintButton = (props: { content: () => HTMLDivElement }) => {
  const [loading, setLoading] = useState(false)
  return (
    <ReactToPrint
      onBeforeGetContent={() => setLoading(true)}
      onAfterPrint={() => setLoading(false)}
      onPrintError={() => setLoading(false)}
      trigger={() => (
        <Button className={bem('print-btn')} icon={<Download />} type="checked-neutral" loading={loading}>
          {I18N.auto.xiaZai}
        </Button>
      )}
      content={props.content}
      removeAfterPrint
    />
  )
}

export const PageContent = (props: { className?: string; children?: ReactNode }) => {
  const { className, children } = props
  return <div className={bem('content', [className])}>{children}</div>
}

export const PageFooter = (props: { className?: string }) => {
  const { className } = props
  return (
    <div className={bem('footer', [className])}>
      <div className={bem('footer-l')}>
        <img className={bem('footer-log-d')} src={DepartmentLog} alt="" />
        <Divider type="vertical" className={bem('footer-divider')} />
        <img className={bem('footer-log-p')} src={ProtalLog} alt="" />
      </div>
      <div className={bem('footer-r')}>网易公司版权所有 ©1997-{dayjs().format('YYYY')}</div>
    </div>
  )
}

import { useState } from 'react'
import { BEMGenerator } from '@oahz/neact-utils'
import { Button, Form, Input, Message } from '@bedrock/components'
import { ChevronLeft } from '@bedrock/icons-react'
import { AsyncButton } from '@feature/views'
import { apiService, defs } from '@/services'
import { ConfirmPasswordFormItem, PasswordFormItem, SinginContainer, VerificationCodeInput } from '@/components/signin'
import { rsaEncrypt } from '@/shared'
import './style.less'

const bem = BEMGenerator('psetpwd')

export default () => {
  const [fm] = Form.useForm()
  const [mail, setMail] = useState<string>('')

  const onSubmit = async () => {
    await fm.validateFields()
    const formVal = await fm.getFieldsValue()
    const params: defs.IdResetPasswordCommand = {
      ...formVal,
      password: rsaEncrypt(formVal.password),
      confirmPassword: rsaEncrypt(formVal.confirmPassword),
    }
    await apiService.identityAuthenticationResetPassword(params)
    Message.success('密码修改成功')
    window.location.replace('/login')
  }

  return (
    <SinginContainer>
      <div className={bem()}>
        <div className={bem('title')}>密码修改</div>
        <Form form={fm} layout="vertical" autoComplete="off" className={bem('form')}>
          <Form.Item
            name="mail"
            label="联系邮箱"
            rules={[{ required: true, message: '请输入联系邮箱', type: 'email' }]}>
            <Input size="large" placeholder="请输入邮箱" onChange={v => setMail(v.target.value)} />
          </Form.Item>
          <Form.Item
            name="enterpriseName"
            label="目标供应商名称"
            rules={[{ required: true, message: '请输入目标供应商名称' }]}>
            <Input size="large" placeholder="请输入要找回密码的供应商名称" />
          </Form.Item>
          <Form.Item name="verificationCode" label="验证码" rules={[{ required: true, message: '请输入验证码' }]}>
            <VerificationCodeInput mail={mail} />
          </Form.Item>
          <PasswordFormItem />
          <ConfirmPasswordFormItem form={fm} />
        </Form>
        <AsyncButton type="primary" size="large" className={bem('submit')} onClick={onSubmit}>
          确定
        </AsyncButton>
        <Button
          type="text-subtle"
          icon={<ChevronLeft />}
          className={bem('back')}
          onClick={() => (window.location.href = '/login')}>
          返回登录
        </Button>
      </div>
    </SinginContainer>
  )
}

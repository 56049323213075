import { createModel } from '@oahz/neact'
import { apiService, type defs } from '@/services'

const initialState: defs.WorkbenchStatisticsDto = {
  total: 0,
  quotation: undefined,
  shipping: undefined,
  pendingBidding: undefined,
}

const workbenchStatistics = createModel({
  state: initialState,
  reducers: {
    setWorkbenchStatistics(state, payload) {
      return payload
    },
  },
  effects: {
    async getWorkbenchStatistics() {
      try {
        const res = await apiService.commonWorkbenchGetStatistics()
        this.setWorkbenchStatistics(res)
      } catch (e) {
        //
      }
    },
  },
})

export default workbenchStatistics

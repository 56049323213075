import { useState, useEffect } from 'react'
import { Link, useManualService } from '@oahz/neact'
import { BEMGenerator, useBoolean, useRCVars, useRCMount } from '@oahz/neact-utils'
import { Popover, Button, Modal, Form, Select, Table } from '@bedrock/components'
import type { Options } from '@bedrock/components/lib/Select'
import type { ColumnsType } from '@bedrock/components/lib/Table'
import { InfoCircle } from '@bedrock/icons-react'
import { useDebouncedCallback } from 'use-debounce'
import { ELightSelect } from '@bedrock-e/common'
import I18N from '@feature/i18n'
import { DataForm, DataFormItem } from '@/components/data-view'
import './style.less'

const bem = BEMGenerator('sp-view')

export default () => {
  return <section className={bem()}>csv</section>
}

export const StageIcon = () => {
  return (
    <Popover
      title={
        <div className={bem('sl-pt')}>
          {I18N.auto.heZuoZhuangTaiShuo}
          {/* <Link to={getRoutePathname('/supplier/SupplierStage')} target="_blank">
            <Button type="text"> {I18N.auto.chaKanXiangQing}</Button>
          </Link> */}
        </div>
      }
      placement="rightBottom"
      content={I18N.auto.caiGouYuanKeDui}>
      <InfoCircle />
    </Popover>
  )
}

export const StageLabel = (props: { title?: string; className?: string }) => {
  const { title = I18N.auto.heZuoZhuangTai, className } = props
  return (
    <div className={bem('sl', [className])}>
      {title}
      <StageIcon />
    </div>
  )
}

const BUSINESSLINE_DESC_DATA = [
  {
    user: 'Adam Smith',
    mail: 'adam.smith@corp.netease.com',
    business: 'overseas',
  },
  {
    user: I18N.auto.wangYunHui,
    mail: 'wangyunhui@corp.netease.com',
    business: I18N.auto.jiTuanRenLiGong,
  },
  {
    user: I18N.auto.liuLingYan,
    mail: 'liulingyan@corp.netease.com',
    business: I18N.auto.gLOBA,
  },
  {
    user: I18N.auto.liuFang,
    mail: 'liufang01@corp.netease.com',
    business: I18N.auto.yunFuWuDC,
  },
  {
    user: I18N.auto.guoXia,
    mail: 'guoxia02@corp.netease.com',
    business: I18N.auto.iTRuanYingJian,
  },
  {
    user: I18N.auto.shiMengQing,
    mail: 'shimengqing@corp.netease.com',
    business: I18N.auto.qingPuSanYaMeng,
  },
  {
    user: I18N.auto.dengWenJing,
    mail: 'dengwenjing@corp.netease.com',
    business: I18N.auto.hangSanGuiAnHang,
  },
  {
    user: '闫从翔',
    mail: 'yancongxiang@corp.netease.com',
    business: I18N.auto.xuHuiBeiJingYuan,
  },
]

export const BusinessLineLabel = (props: { className?: string }) => {
  const columns: ColumnsType = [
    { dataIndex: 'user', title: I18N.auto.duiJieRen, width: 96, align: 'left' },
    { dataIndex: 'mail', title: I18N.auto.youXiang, width: 248, align: 'left' },
    { dataIndex: 'business', title: I18N.auto.duiJieYeWu, width: 577, align: 'left' },
  ]

  const renderContent = () => {
    return <Table className={bem('biz-table')} columns={columns} data={BUSINESSLINE_DESC_DATA} rowKey={(_, i) => i!} />
  }

  return (
    <Popover title={I18N.auto.yeWuXianShuoMing} content={renderContent()}>
      {I18N.auto.yeWuXian} <InfoCircle />
    </Popover>
  )
}

export const Transfer = (props: {
  data: any
  request: <A extends any[], T>(...args: A) => Promise<T>
  hasBelongSupplier?: boolean
  refresh?: () => void
}) => {
  const { data, request, hasBelongSupplier, refresh } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [fm] = Form.useForm()
  const onClose = () => {
    hide()
    fm.resetFields()
  }
  const onSubmit = async () => {
    try {
      await fm.validateFields()
      onClose()
      refresh && refresh()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <Button type="text" onClick={show}>
        {I18N.auto.zhuanJiao}
      </Button>
      <Modal width={500} title={I18N.auto.zhuanJiao} visible={visible} onCancel={onClose} onOk={onSubmit}>
        <DataForm form={fm} className={bem('content')}>
          {hasBelongSupplier && (
            <DataFormItem w100 name={'status'} label={I18N.auto.suoShuGongYingShang}>
              <div>{I18N.auto.zhiFuBaoZhongGuo}</div>
            </DataFormItem>
          )}

          <DataFormItem w100 name={'status'} label={I18N.auto.zhuanJiaoYouXiang} required>
            <Select />
          </DataFormItem>
        </DataForm>
      </Modal>
    </>
  )
}

export const ELightAsyncSearchSelect = (
  props: Parameters<typeof ELightSelect>[0] & {
    service: (...args: any) => Promise<any>
    className?: string
    value?: number[]
    onChange?: (value?: number[]) => void
  }
) => {
  const { className, value, onChange, service, fieldNames, ...resetProps } = props
  const [visible, setVisible] = useState(false)
  const [options, setOptions] = useState<any[]>([])
  const [initFlag, setInitFlag] = useState(false)
  const { data, loading, request } = useManualService(service)
  const vars = useRCVars(() => ({ params: { current: 1, limit: 50, keyword: undefined } as any, hasMore: false }))
  useEffect(() => {
    vars.hasMore = !!data?.hasMore
    if (vars.params.current === 1) {
      setOptions(data?.records || [])
    } else {
      setOptions(prev => prev.concat(data?.records || []))
    }
  }, [data])

  useEffect(() => {
    if (initFlag || !options.length) return
    if (!value) {
      setInitFlag(true)
      return
    }
    let hasValNoOption = false
    let chaeckKey = fieldNames?.value ? fieldNames.value : 'value'
    value?.forEach(v => {
      if (!options.find(o => o[chaeckKey] === v)) {
        hasValNoOption = true
      }
    })
    if (hasValNoOption) {
      request((vars.params = { ...vars.params, current: vars.params.current + 1, limit: 50 }))
    } else {
      setInitFlag(true)
    }
  }, [options, value, initFlag])

  useRCMount(() => request((vars.params = { current: 1, limit: 50 })))

  useEffect(() => {
    setTimeout(async () => {
      const dom = document.getElementsByClassName('cqf__assd')[0]
      const scrollEl = dom && dom.getElementsByClassName('rock-scrollbar-view')[0]
      if (!dom || !visible) {
        setTimeout(() => scrollEl?.scrollTo({ top: 0 }), 100)
        return
      }

      if (!scrollEl) return
      const clientHeight = scrollEl.clientHeight
      const fn = () => {
        const buf = scrollEl.scrollHeight - scrollEl.scrollTop - clientHeight < 1
        if (vars.hasMore && buf && !loading) {
          request((vars.params = { ...vars.params, current: vars.params.current + 1, limit: 50 }))
        }
      }
      scrollEl?.addEventListener('scroll', fn)
      // return () => scrollEl?.removeEventListener('scroll', fn)
    })
  }, [visible])

  const onSearch = useDebouncedCallback(async (keyword?: string) => {
    // if (!visible) return //不知道为什么关闭dropdown会触发onsearch，这里return一下
    console.log('keyword', keyword)
    request((vars.params = { current: 1, limit: 50, keyword }))
  }, 200)

  return (
    <ELightSelect
      {...resetProps}
      value={value}
      multiple
      visible={visible}
      // virtual
      dropdownClassName={bem('assd')}
      options={(options || []) as Options}
      showSearch
      onSearch={onSearch}
      onChange={onChange}
      fieldNames={fieldNames}
      onOpenChange={e => {
        setVisible(e)
        console.log('e', e)
      }}
    />
  )
}

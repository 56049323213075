import { useState, useRef, useEffect } from 'react'
import Editor, {
  EditorState,
  EditorConfig,
  createLineHeightPlugin,
  RockEditorPlugin,
  PluginKey,
  RichTextEditorRef,
  convertToHTML,
  isStateEmptyContent,
} from '@bedrock/editor'
import { BEMGenerator } from '@oahz/neact-utils'
import { LangEnum, getLanguage } from '@feature/i18n'
import './style.less'

const bem = BEMGenerator('ftbe')

export const getCommonConfig = (opts: { locale: LangEnum }): EditorConfig => {
  const { locale } = opts

  return {
    locale: locale,
    features: {
      image: { allowEdit: true, singleButton: false, isBlock: true, allowFloat: true },
    },
    getPlugins: ps => [
      ...ps,
      createLineHeightPlugin({ insertAfter: 'IndentDropdown' }),
      // 自定义一个插件
      new RockEditorPlugin({
        key: new PluginKey('myPlugin'),
        getToolbarConfig: plugins => {
          const a = plugins[2]
          const mark = plugins[3]
          const alignStyles = plugins[4].slice(0, 4)

          return [a, mark, alignStyles]
        },
      }),
    ],
  }
}

export default (props: {
  className?: string
  placeholder?: string
  initialValue?: string
  onChange?: (v: string) => void
  readOnly?: boolean
}) => {
  const { className, initialValue, placeholder, readOnly = false, onChange } = props

  const locale = getLanguage()
  const [es, setEs] = useState<EditorState>()
  const [focused, setFocused] = useState(false)
  const editorRef = useRef<RichTextEditorRef>(null)

  useEffect(() => {
    if (!editorRef.current || !initialValue) return
    setEs(editorRef.current.convertFromHtml(initialValue))
  }, [initialValue])

  const doChange = (es: EditorState) => {
    setEs(es)

    if (onChange && !isStateEmptyContent(es)) {
      const esValue = convertToHTML(es, { prependEditorType: false, clearUploadFailSrc: true })
      onChange(esValue as string)
    }
  }

  return (
    <Editor
      className={bem(['rock-input-wrapper', `rock-input-wrapper${focused ? '-focused' : ''}`, className])}
      ref={editorRef}
      config={getCommonConfig({ locale })}
      editorState={es}
      placeholder={placeholder}
      onFocusChange={setFocused}
      onChange={doChange}
      readOnly={readOnly}
      // 一般来说你是需要隐藏toolbar的，这个属性也是v1.0.42新增的
      isHideToolbar={readOnly}
    />
  )
}

import { ReactNode, forwardRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Spin } from '@bedrock/components'
import { BEMGenerator } from '@oahz/neact-utils'
import './style.less'

const bem = BEMGenerator('ftcpv')

export const PageContainer = (props: { className?: string; htmlTitle?: string; children?: ReactNode }) => {
  const { className, htmlTitle, children } = props
  return (
    <>
      <Helmet>
        <title>{htmlTitle}</title>
      </Helmet>
      <section className={bem('page', [className])}>{children}</section>
    </>
  )
}

export const PageLoading = () => {
  return <Spin className={bem('loading')} size="small" />
}

export const PageHeader = (props: {
  className?: string
  title: ReactNode
  action?: ReactNode
  children?: ReactNode
}) => {
  const { className, title, action, children } = props

  return (
    <header className={bem('header', [className])}>
      <div className={bem('header-title')}>{title}</div>
      <div className={bem('header-content')}>{children}</div>
      <div className={bem('header-action')}>{action}</div>
    </header>
  )
}

export const MainContainer = forwardRef<
  HTMLDivElement,
  { className?: string; scrollable?: boolean; footer?: ReactNode; children?: ReactNode }
>((props, ref) => {
  const { className, scrollable = false, footer, children } = props
  return (
    <section className={bem('main', { scrollable }, [className])} ref={ref}>
      {children}
      {footer}
    </section>
  )
})

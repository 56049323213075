import { createModel } from '@oahz/neact'
import { accountService } from '@/services'
import { setBizHeaderEnterpriseId } from '@/shared/nxios'

type NonUndefined<T> = { [P in keyof T]-?: T[P] extends infer R | undefined ? R : T[P] }

type AccountState = NonUndefined<Awaited<ReturnType<typeof accountService.getAccountInfo>>>

const initialState: AccountState = {
  userInfo: undefined!,
  supplierInfo: undefined!,
}

const account = createModel({
  state: initialState,
  reducers: {
    setAccountInfo(state, payload) {
      return payload
    },
    setEnterpriseIdAndSupplierInfo(state, payload) {
      const { enterpriseId, supplierInfo } = payload
      state.userInfo = { ...state.userInfo, enterpriseId }
      state.supplierInfo = supplierInfo
      return state
    },
    setSupplierInfo(state, payload) {
      state.supplierInfo = payload
      return state
    },
  },
  effects: {
    async getAccountInfo() {
      try {
        // 如果createStore中提供errorHandler，那个store.effect的错误会统一捕获并处理
        // 这里可以使用try-catch，单独主动拦截一下错误，就会触发统一的errorHandler了
        const res = await accountService.getAccountInfo()
        setBizHeaderEnterpriseId(res.userInfo?.enterpriseId)
        this.setAccountInfo(res)
      } catch (e) {
        // 用户未登录的message不要展示
      }
    },
    async changeEnterpriseId(enterpriseId: string) {
      setBizHeaderEnterpriseId(enterpriseId)
      const res = await accountService.getSupplierInfo()
      this.setEnterpriseIdAndSupplierInfo({ enterpriseId, supplierInfo: res })
    },
    async refreshSupplierInfo() {
      const res = await accountService.getSupplierInfo()
      this.setSupplierInfo(res)
    },
  },
})

export default account

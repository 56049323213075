export default {
  jiZhunJia: '基准价',
  weiShuiJinE: '未税金额',
  keFaHuoJinE_2: '可发货金额（未税）',
  keFaHuoJinE_3: '可发货金额（含税）',
  benCiFaHuoJin_2: '本次发货金额（未税）',
  benCiFaHuoJin_3: '本次发货金额（含税）',
  xuQiuJiZhunJia: '需求基准价：',
  dingBiaoZongEWei: '定标总额（未税）',
  dingBiaoZongEHan: '定标总额（含税）',
  daiYanShouJinE_3: '待验收金额（未税）',
  daiYanShouJinE_4: '待验收金额（含税）',
  benCiYanShouJin_4: '本次验收金额（未税）',
  benCiYanShouJin_5: '本次验收金额（含税）',
  weiShui_2: '（未税）',
  hanShui_2: '（含税）',
  benCiYanShouJin_6: '本次验收金额（未税）：',
  weiShui_3: '{val1}（未税）',
  hanShui_3: '{val1}（含税）',
  xuQiuDanXiaHai: '需求单下还存在以下收货信息',
  xiaDanJinEWei: '下单金额（未税）',
  xiaDanJinEHan: '下单金额（含税）',
  daiKaiBiao: '待开标',
  yanShouJinEWei: '验收金额（未税）',
  hanShuiDanJia: '含税单价',
  guanLianZongJinE_2: '关联总金额（含税）',
  shengYuKeKaiPiao_2: '剩余可开票金额（含税）',
  guanLianJinEHan: '关联金额（含税）',
  buTongJiZhunJia: '不同基准价，不可关联',
  oaLineNoBuYiZhiBuKe: 'OA单来源不一致，不可创建',
  pingFenManFenYiBai: '评分（满分100）',
  queDingYaoFangQi: '确定要放弃评估吗？',
  fangQiHouBuKe: '放弃后不可再次进行评估',
  wuLiHaiGuanXi: '无利害关系承诺书',
  zhaoBiaoPingShiXiao: '(招标评审小组承诺书)',
  benRen: '本人  ',
  zuoWeiWangYiJi: '作为网易集团员工，',
  biaoShuBianHaoZhao: '标书编号【{val1}】，招标事项【{val2}】，',
  zhaoTouBiaoXiaoZu: '招投标小组成员现承诺:',
  yiZunShouZhongHua: '一、遵守《中华人民共和国政府采购法》相关规定,与参加本项目的供应商',
  wuLiHaiGuanXi_2: '无利害关系。',
  erZaiPingShenGuo: '二、在评审过程中，严格遵守',
  zhongHuaRenMinGong_6: '《中华人民共和国招标投标法》',
  zhongHuaRenMinGong_7: '《中华人民共和国政府采购法》',
  jiYouGuanFaLu:
    '及有关法律法规坚拼“公平、公正、科学、择优”的原则,客观、公正地履行职责遵守职业道德遵守工作纪律与保密规定认真负责地做好评审工作',
  yYYYNian: 'YYYY年M月D日',
  queRenQianShu: '确认签署',
  fangQiPingGu: '放弃评估',
  zaiCiFaSong: '再次发送',
  zaiCiFaSong_2: '再次发送 ({val1})',
  qingShuRuKaiBiao: '请输入开标密码',
  queRenKaiBiao: '确认开标',
  weiShouDaoYanZheng: '未收到验证码？',
  jiShuBiaoMiFeng: '技术标密封中',
  shangWuBiaoMiFeng: '商务标密封中',
  huoQuKaiBiaoMi: '获取开标密码',
  dianJiHouKaiBiao: '点击后开标密码将自动发送至您的邮箱，有效期为5分钟',
  kaiBiaoHouJiangZi: '开标后将自动发送评标通知至以下成员：',
  pingBiaoZuYuan: '评标组员',
  jiShuPingGuZhong: '技术评估中',
  zhaoBiaoShiJianHao: '招标事件号',
  pinPai_3: '品牌：',
  peiZhi_3: '配置：',
  beiZhu_2: '备注：{val1}',
  beiZhu_3: '备注：',
  shangPinFuWuMing_2: '商品/服务名',
  xuQiuXingFuJian: '需求行附件',
  jiShuBiaoFuJian: '技术标附件',
  shangWuBiaoFuJian: '商务标附件',
  baoJiaShiJianU: '报价时间  (UTC+08:00)',
  touBiaoLieBiao: '投标列表',
  zhaoBiaoXuQiuFu: '招标需求附件',
  pingBiaoZuZhang: '评标组长',
  heJiaXinXi: '核价信息',
  jiangBenJinE: '降本金额',
  dingBiaoFuJian: '定标附件',
  feiBiao: '废标',
  pingFenZuiYou: '评分最优',
  paiMing: '排名',
  zongHePingFen: '综合评分',
  baoJiaZongJinE: '报价总金额 (含税)',
  pingFenRen: '评分人',
  pingFen: '评分',
  pingFenFuJian: '评分附件',
  jiShuBiao: '技术标',
  shangWuBiao: '商务标',
  pingFenQuanZhong: '评分权重：',
  mingXi: '明细',
  pingFenMingXi: '评分明细',
  zongHeFenShu: '综合分数',
  baoJiaWeiShui: '报价 (未税)',
  baoJiaMingXi: '报价明细',
  touBiaoXiangQing: '投标详情',
  weiPingFen: '未评分',
  yiFangQi: '已放弃',
  yiPingFen: '已评分',
  daiChengQing: '待澄清',
  yiChengQing: '已澄清',
  pingGuGuanLi: '评估管理',
  jiShuPingFenQuan: '技术评分权重',
  shangWuPingFenQuan: '商务评分权重',
  jiShuBiaoPingFen: '技术标评分模板',
  xiaoZuChengYuan: '小组成员',
  shangWuBiaoPingFen: '商务标评分模板',
  pingBiaoChengYuanQian: '评标成员签署无利害关系承诺书',
  zuoFeiZhaoBiao: '作废招标',
  zhaoBiaoLiuChengJie: '招标流程结束，供应商不可继续投标',
  zuoFeiChengGong: '作废成功',
  feiBiaoYuanYin: '废标原因',
  zhaoTouBiaoShiJian: '招投标事件名',
  zhaoTouBiao: '招投标',
  yuFuKuan: '预付款',
  heJiaTiJiaoShen: '核价提交申请时间',
  dingBiaoZongJinE_2: '定标总金额',
  pingFenBuNengWei: '评分不能为空',
  shangWuPingGuZhong: '商务评估中',
  touBiaoKaiShiShi: '投标开始时间',
  touBiaoJieShuShi: '投标结束时间',
  jiShuBiaoKaiBiao: '技术标开标人',
  shangWuBiaoKaiBiao: '商务标开标人',
  jiShuPingGu: '技术评估',
  shangWuPingGu: '商务评估',
  jiShuPingFenQuan_2: '技术评分权重：',
  shangWuPingFenQuan_2: '商务评分权重：',
  queDingYaoFaSong: '确定要发送评估吗？',
  pingGuTiJiaoHou: '评估提交后将进入定标环节，不可更改',
  pingGu: '评估',
  faSongPingGu: '发送评估',
  zanWuQuanXian: '暂无权限',
  dingBiaoZhuangTai: '定标状态',
  caoZuoRen_4: '操作人：',
  shiJian_2: '时间：',
  yuanYin: '原因：',
  dingBiaoShenPiShi: '定标审批时间',
  shiFouFeiBiao: '是否废标',
  baoJiaZongEHan: '报价总额（含税）',
  queDingYaoJinRu: '确定要进入核价吗？',
  dangQianCunZaiWei: '当前存在{val1}位成员未完成评分',
  tiJiaoHouJiangJin: '提交后将进入核价环节',
  zhaoBiaoXiangQing: '招标详情 {val1}',
  jinRuHeJia: '进入核价',
  jiShuKaiBiaoRen: '技术开标人',
  shangWuKaiBiaoRen: '商务开标人',
  baoJiaJinEWei_2: '`报价金额（未税）`',
  zhaoBiaoXuQiuFu_2: '招标需求附件（发送给供应商）',
  yingBiaoQueRenHan: '应标确认函',
  baoMiXieYi: '保密协议',
  gongYingShangXiangGuan: '供应商相关附件',
  shenFen: '身份',
  pingFenZhuangTai: '评分状态',
  wuFaTiJiao: '无法提交',
  zanWeiTianJiaPing: '暂未添加评标组长，请添加后再提交',
  queDingYaoTiJiao_2: '确定要提交招标吗？',
  tiJiaoHouJiangJin_2: '提交后将进入投标环节',
  chuangJianZhaoBiao: '创建招标',
  faSongZhaoBiao: '发送招标',
  zhaoBiaoXuQiuFu_3: '招标需求附件(发给供应商)',
  shiJianSheZhi: '事件设置',
  shiQu_2: '时区',
  faSongYingBiaoQue: '发送应标确认函',
  queRenHanMuBan: '确认函模板',
  faSongBaoMiXie: '发送保密协议',
  baoMiXieYiMo: '保密协议模板',
  zhongBiaoGongGaoFan: '中标公告范围',
  tiJiaoHouKaiShi: '提交后开始',
  dingBiaoTiJiaoHou_2: '定标提交后将进入审批环节。',
  dingBiaoShenQingShi: '定标申请时间',
  shiFouDingBiao_2: '是否定标',
  pingBiaoRen: '评标人',
  jiShuPingBiaoZu: '技术评标组长',
  shangWuPingBiaoZu: '商务评标组长',
  jinRuPingBiaoShi: '进入评标失败',
  jiShuPingBiaoZu_2: '技术评标组长未设置',
  shangWuPingBiaoZu_2: '商务评标组长未设置',
  jinRuPingBiao: '进入评标',
  jiangYaoQingPingBiao: '将邀请评标组长启动评标流程',
  xuQiuLieBiao: '需求列表',
  daiYingBiao: '待应标',
  daiTouBiao: '待投标',
  yiTouBiao: '已投标',
  yiQiBiao: '已弃标',
  wenTiBiaoTi: '问题标题',
  tiChuGongYingShang: '提出供应商',
  wenTiChuangJianRen: '问题创建人',
  wenTiTiJiaoShi: '问题提交时间',
  chengQingGongGaoBiao: '澄清公告标题',
  chengQingRen: '澄清人',
  chengQingGongGao: '澄清公告',
  zhaoBiao: '招标',
  touBiao: '投标',
  heJia: '核价',
  touBiaoZhong: '投标中',
  yiKaiBiao: '已开标',
  pRShenPiZhong: 'PR审批中/投标中',
  pRYiShenPi: 'PR已审批/投标中',
  pRShenPiZhong_2: 'PR审批中/已开标',
  pRYiShenPi_2: 'PR已审批/已开标',
  wuGongGao: '无公告',
  canYuCaiGouShang: '参与采购商可见',
  gongYingShangMenHu: '供应商门户全部可见',
  zuZhangFangQiPing: '组长放弃评估',
  zuZhangWeiQianShu: '组长未签署无利害关系承诺书',
  zuZhangWeiKaiBiao: '组长未开标',
  zuChangYiKaiBiao: '组长已开标',
  zuYuanWeiQianShu: '组员未签署无利害关系承诺书',
  zuYuanYiQianShu: '组员已签署无利害关系承诺书',
  zuYuanYiFangQi: '组员已放弃',
  zuZhang: '组长',
  zuYuan: '组员',
  weiQianShuLiHai: '未签署利害承诺书',
  qingShuRuBiaoTi: '请输入标题',
  qingShuRuZiXun: '请输入咨询内容',
  chengQingHuiFu: '澄清回复',
  weiTianXieNeiRong: '未填写内容',
  huiFuZiXun: '回复咨询',
  chengQingZiXun: '澄清咨询',
  ziXunXiangQing: '咨询详情',
  jiaZaiZhong: '加载中...',
  sheZhiYunFei: '设置运费',
  dianShangDiZhi: '电商地址',
  zhouShengDiQu: '州省地区',
  chengShi: '城市',
  qingXuanZeRenYuan: '请选择人员',
  qingXianSouSuoZai: '请先搜索再选择',
  xinJianDianShangDi: '新建电商地址',
  bianJiDianShangDi: '编辑电商地址',
  xinJianDiZhiCheng: '新建地址成功',
  bianJiDianShangDi_2: '编辑电商地址成功',
  xinJianDiZhi: '新建地址',
  dianShangPingTai: '电商平台',
  dianShangPingTaiBu: '电商平台不能为空',
  diZhiBianMa: '地址编码',
  diZhiBianMaBu: '地址编码不能为空',
  shouHuoRenBuNeng: '收货人不能为空',
  qingShuRuZhengQue_4: '请输入正确的联系方式',
  fenPeiLeiXing: '分配类型',
  shouHuoRenXingMing: '收货人姓名',
  qingShuRuDiZhi: '请输入地址编码/姓名',
  yaMaXun: '亚马逊',
  daiEr: '戴尔',
  shiTaiBo: '史泰博',
  jingDong: '京东',
  wuRenYuanShiYong: '无人员使用',
  quanYuanShiYong: '全员使用',
  buFenYuanGong: '部分员工',
  zuiDuoShuRuGe: '最多输入 20个字符',
  yanShouJianJie: '验收简介',
  yanShouJinEChao: '验收金额超出数额需CEO审批，【验收简介、验收说明、验收凭证】将通过POPO发送审批',
  tiJiaoZhong: '提交中',
  tiJiaoShenPi: '提交审批',
  benCiYanShouJin_3: '本次验收金额 (含税):',
  benCiYanShouJin_2: '本次验收金额/待验收金额',
  benCiYanShouShu_2: '本次验收数量/待验收数量',
  qiPaiJiaWeiShuiDanJia: '起拍价（未税单价）',
  yuFuJinEXuDaYu: '预付金额需大于0',
  zhiFuFangShiWeiYuFu: '支付方式为预付款，必须关联合同！',
  tiaoZhengBuFuDanJia: '调价步幅 (单价)',
  xinZengBaoJia_2: '新增报价：{val1}',
  diYiMing: '第一名',
  diErMing: '第二名',
  diSanMing: '第三名',
  anJinE: '按金额',
  anBiLi: '按比例',
  buXian: '不限',
  qiPaiJia: '起拍价',
  diYiMingJiaGe: '第一名价格',
  jianGe: '间隔',
  fenZhong: '分钟',
  zuiHou: '最后',
  fenZhongNeiChuXian: '分钟内，出现新的第一名延时',
  yanShi: '延时',
  wuYanShi: '无延时',
  kaiQiZaiXianJing: '开启在线竞价',
  shiFouZhengDanZhong: '是否整单中标',
  jingJiaMoShi: '竞价模式',
  tiaoJiaBuFuZheng: '调价步幅 (整单)',
  tiaoJiaBuFuB: '调价步幅 (by行)',
  jingJiaGuiZe: '竞价规则',
  baoJiaJianGeShi: '报价间隔时长',
  ziDongYanShiShi: '自动延时时长',
  gongYingShangBaoJia_2: '供应商报价显示',
  anJinE_2: '按金额（{val1} {val2}）',
  anBiLi_2: '按比例（{val1} %）',
  fenZhongNeiChuXian_2: '{val1} 分钟内，出现新的第一名延时 {val2} 分钟',
  baoJiaShiJian: '报价时间',
  jiaGe: '价格',
  gengGaiBaoJiaBian: '更改报价：{val1} 变为 {val2}',
  caoZuoXing: '操作行',
  weiCanYu: '未参与',
  baoJiaJinEHan_2: '报价金额 (含税)：',
  shiFouDingBiao: '是否定标：',
  wuBaoJiaJinE: '无报价金额',
  zanWuGongYingShang: '暂无供应商排名',
  baoJiaLiShi: '报价历史',
  gongYingShangPaiMing: '供应商排名',
  canYuGongYingShang_2: '参与供应商数量较少',
  kaiQiZaiXianJing_2: '开启在线竞价后，需要参与供应商大于等于2家',
  yingShiBaoJiaFan: '英式（反向）',
  qianSanMingBuYun: '前三名不允许报价相同',
  suoYouBaoJiaXu: '所有报价需超越第一名',
  suoYouPaiMingBu: '所有排名不允许报价相同',
  juJieShuHaiSheng: '距结束还剩',
  tiaoZhengHeTongJieSuan: '调整合同结算公司、供应商、币种，将清空价格清单，请确认。',
  chaiFenDingBiaoMing: '拆分定标明细',
  xingVALDing_2: '行{val1}：定标拆分明细行合计金额不等于定标金额',
  duiWaiLiPin: '对外礼品',
  banGongYongFeiI: '办公用（非IT统管的软硬件）',
  zhuanShou: '转售',
  banShuSongShen: '版署送审',
  banGongYongIT: '办公用-IT公用',
  banGongYongYeWu: '办公用-业务公用',
  guoNeiITRuan: '国内IT软硬件申领及采购',
  zhuCeDiWei: '{val1}注册地为{val2}',
  cunZaiDuoZhong: '存在多种，{val1}',
  cunZaiDuoKaHao: '{val1}（存在多卡号，{val2}）',
  yuFuJinE_2: '{val1}(预付金额：',
  zhuangTaiWeiBianHua: '状态未变化',
  zhuangTaiXiuGaiCheng: '状态修改成功',
  xiuGaiZhuangTai: '修改状态',
  guanLianWangYiJi: '关联网易集团全部公司',
  yiGuanLian: '已关联：',
  guanLianGongSi: '关联公司',
  rFXXingBao: 'RFx-行-报价',
  peiZhiGuiGe: '配置/规格',
  xuanZeRFX: '选择RFx',
  qingShuRuRF: '请输入RFx单号',
  ruoTianLeShuLiang: '若填了数量自动计算出含税金额=数量*单价（含税）；{val1}若只填含税金额，不会反算出数量',
  xingHao_2: '型号',
  shuLiangBuKeWei: '数量不可为0',
  bianJiJiaGeQing: '编辑价格清单',
  tianJiaJiaGeQing: '添加价格清单',
  laiYuanXinXi: '来源信息',
  mingXiXinXi: '明细信息',
  jiaGeYouXiaoQi: '价格有效期',
  xuJieChuDuiYing: '需解除对应关联单据后方可编辑',
  xuJieChuDuiYing_2: '需解除对应关联单据后方可删除',
  jiaGeQingDan: '价格清单',
  zanWuNeiRong: '暂无内容',
  cRPHeTong: 'CRP合同',
  nETBU: 'Netbuy合同',
  heTongLeiXing: '合同类型',
  heTongYuE: '合同余额',
  qiShiRiQi: '起始日期',
  zhongZhiRiQi: '终止日期',
  tianJiaHeTong: '添加合同',
  woFangZhuTi: '我方主体',
  qingShuRuHeTong: '请输入合同编号/合同名称',
  shengXiaoRiQi: '生效日期',
  qingXuanZeShengXiao: '请选择生效日期',
  heTongZhuangTaiFei: '合同状态非生效，不可选择',
  biZhongBuYiZhi: '币种不一致，不可选择',
  heTongYuEBu: '合同余额不足，请检查后提交',
  dingDanHanShuiJin: '订单含税金额为：',
  heTong: '合同',
  dangQianKeYongYu: '当前可用余额为：',
  qingJianChaHouZai: '请检查后再提交',
  jiaGeKuangJiaHe: '价格框架合同',
  xuXuanZeYouJia: '需选择有价格清单，且生效状态、余额大于0的合同',
  xuanZeJiaGeQing: '选择价格清单',
  xiaDanFangShiLei: '下单方式类型不一致，请检查后提交',
  baoJiaQingDan: '报价清单',
  dingBiaoShuLiangBi: '定标数量必须大于0',
  dingBiaoJinEBi: '定标金额必须大于0',
  shangPinFuWuMing: '商品/服务名称',
  yiYouYunFeiBu: '已有运费不可添加',
  chuangJian: '创建',
  gaiHeTongYiCun: '该合同已存在，是否跳转至对应详情页编辑？',
  qieHuanHeTongBi: '切换合同币种，价格清单将删除，请确认',
  jiaGeQingDanBu: '价格清单不可为空',
  chuangJianHeTong: '创建合同',
  qingXuanZeZhengQue: '请选择正确的我方主体/供应商/合同，修改会清空下方内容',
  daoQiCaoZuo: '到期操作',
  xuQianTiXing: '续签提醒',
  buXuQian: '不续签',
  woFangZhuTiJie: '我方主体（结算公司）',
  faBuRiQi: '发布日期',
  youXiaoQi: '有效期',
  danJuLeiXing: '单据类型',
  guanLianShiJian: '关联时间',
  guanLianJiaGeQing: '关联价格清单-序号',
  guanLianJiaGeQing_2: '关联价格清单-商品名称',
  heTongXiangQing: '合同详情-{val1}',
  faBuShiJian: '发布时间',
  heTongYuEHe: '合同余额=合同总额-定标总额',
  xuQian: '续签',
  guanLianRFX: '关联RFx/PO',
  rFXShenPi_3: 'RFx审批中+已下单+非取消PO',
  heTongBianJi: '合同编辑-{val1}',
  dingDanJinEHan_2: '订单金额（含运费）',
  shangPin_2: '商品：{val1}',
  jiaGeQingDanDe: '价格清单的定标总额超过定标金额',
  rFXDingBiao: 'RFx定标金额为：',
  dangQianKeYongYu_2: '当前可用余额为',
  yiYouJiaGeQing: '已有价格清单不可添加',
  kuangJiaXiaDan: '框架下单',
  daiShengXiao: '待生效',
  yiGuoQi: '已过期',
  kuangJiaHeTong: '框架合同',
  danXiangMuHeTong: '单项目合同',
  yiBanHeTong: '一般合同',
  shouGong: '手工',
  oADanHaoDing_2: 'OA单号/订单号/物流单号/发货单号',
  zhiFuJinE: '支付金额',
  oADanHaoFa: 'OA单号/发货单号/收货单号/订单号/计提单号',
  zhiFuZhong: '支付中',
  yuGuJinE: '预估金额',
  shenPiJuJueYuan: '审批拒绝原因',
  shenQingFuKuanJin: '申请付款金额与发票金额有差异',
  shenQingFuKuanJin_2: '申请付款金额与发票金额无差异',
  shenQingFuKuanJin_3: '申请付款金额（含税）',
  faPiaoJinEHan: '发票金额（含税）',
  chaYiJinEHan: '差异金额（含税）',
  jinEWeiShui: '金额（未税）',
  faPiaoXinXi: '发票信息',
  benCiShenQingJin: '本次申请金额（含税）',
  fuKuanXinXi: '付款信息',
  lianXingHangHao: '联行行号',
  benCiShiJiFu: '本次实际付款金额\n(含税)',
  qiTaFuJian: '其他附件',
  daiJiTiRuZhang: '待计提入账',
  yiGuiDang: '已归档',
  yiCheHui: '已撤回',
  fuKuanDan: '付款单',
  gongYingShangBaoJia: '供应商报价',
  qingShuRuShangPin: '请输入商品名',
  heJiaCeLue: '核价策略',
  fuKuanShenQing: '付款申请',
  guanLianXinXi: '关联信息',
  shiFouXuYongHu: '是否需用户确认',
  xuanZeShiJiangYou: '选择是，将由用户在OA上确认发票和验收信息。',
  dingDanHaoHangHao: '订单号-行号',
  oCRShiBie: 'OCR识别失败',
  oCRShiBie_2: 'OCR识别中...',
  haiWaiFaPiao: '海外发票',
  queRenHouJiangJie: '确认后将解除发票已关联的PO信息，并清除当前关联，可基于发票重新关联',
  jieChuGuanLian: '解除关联',
  yanZhenZhuangTai: '验真状态',
  chuangJianFang: '创建方',
  qingShuRuFaPiao_2: '请输入发票号码/PO单号/供应商',
  shangPinMingChengHuo: '商品名称或服务名称',
  guiGeXingHao: '规格型号',
  danWei: '单位',
  xinZengXing: '新增行',
  shanChuXing: '删除行',
  faPiaoXing: '发票行',
  zhiHangMingCheng_2: '支行名称：',
  lianXingHangHao_2: '联行行号：',
  zhangHuMingCheng_2: '账户名称：',
  yinHangZhangHao_2: '银行账号：',
  shouXuFeiChengDan_2: '手续费承担方：',
  xuanZeKaiHuHang: '选择开户行',
  xuanZeYinHangXin: '选择银行信息',
  chuangJianFuKuanShen: '创建付款申请',
  baoXiaoRen: '报销人',
  shenQingFuKuanShuo: '申请付款说明',
  danGeWenJianXiao: '单个文件小于100M',
  fuKuanShenQingXiang: '付款申请详情：{val1}',
  jieSuanGongSiGong: '结算公司、供应商、合同号、币种不相同的单据不能合并付款，请确认',
  qingShuRuFaPiao_3: '请输入发票号码/PO单号',
  jieSuanDanHao: '结算单号',
  fuKuanJinEHan: '付款金额（含税）',
  fuKuanDanHao: '付款单号',
  zhiFuJinEHan: '支付金额（含税）',
  qingShuRuJieSuan: '请输入结算单号/订单号/OA单号',
  tian_8: '0天',
  caiGouFang: '采购方',
  weiYanZhen: '未验真',
  yanZhenChengGong: '验真成功',
  yanZhenShiBai: '验真失败',
  zengZhiShuiZhuanYong: '增值税专用发票',
  zengZhiShuiDianZi: '增值税电子专用发票',
  zengZhiShuiPuTong: '增值税普通发票',
  zengZhiShuiDianZi_2: '增值税电子普通发票',
  zengZhiShuiDianZi_3: '增值税电子普通发票（通行费）',
  quKuaiLianDianZi: '区块链电子发票',
  zengZhiShuiPuTong_2: '增值税普通发票（卷式）',
  jiDongCheXiaoShou: '机动车销售统一发票',
  tongYongJiDaFa: '通用机打发票（纸质）',
  tongYongJiDaFa_2: '通用机打发票（电子）',
  hangKongXingChengDan: '航空行程单',
  huoChePiao: '火车票',
  keYunQiChePiao: '客运汽车票',
  chuanPiao: '船票',
  chuZuChePiao: '出租车票',
  dingEFaPiao: '定额发票',
  diDiXingChengDan: '滴滴行程单',
  haiWaiINV: '海外INVOICE',
  shi_2: '时',
  fen: '分',
  miao: '秒',
  jieSuanGongSiZhu: '结算公司注册地为',
  gongYingShangZhuCe_2: '供应商注册地为',
  wuYunFei: '无运费',
  wangYiGongSiBan: '网易公司版权所有 ©1997-{val1}',
  oADanHao_2: 'OA单号：',
  xunJiaBeiZhu: '询价备注',
  faGeiGongYingShang: '(发给供应商)',
  xunJiaFuJian: '询价附件',
  dingBiaoJinEChao: '定标金额超过预估金额',
  dingBiaoJinEDi: '定标金额低于预估金额',
  xunJiaShuLiang: '询价数量',
  gongJiJiaBaoJia: '共计 {val1} 家报价供应商',
  shouQiQuanBu: '收起全部',
  zhanKaiQuanBu: '展开全部',
  gongYingShangZhuCe: '供应商注册地为{val1}',
  jinEBuWanZheng: '金额不完整',
  buTongOADan: '不同OA单号，不可发起询价',
  qingShuRuXingMing_2: '请输入姓名/工号/邮箱',
  daiHeJia: '待核价',
  xunJiaGongYingShang: '询价供应商',
  kaiBiaoHeJia: '开标核价',
  pinPai_2: '品牌:',
  peiZhi_2: '配置:',
  shiFouZhongBiao: '是否中标',
  shiFouZuiYouJia: '是否最优价',
  zhongBiaoShuLiang: '中标数量',
  zhongBiaoJinEHan: '中标金额（含税）',
  baoJiaDanJiaWei: '报价单价（未税）',
  weiBaoQiYue: '维保期（月）',
  shouQuanQiRuanJian: '授权期 (软件/月)',
  jiaoFuZhouQiZi: '交付周期 (自然日)',
  canKaoDanJiaHan: '参考单价(含税)',
  canKaoZongJiaHan: '参考总价(含税)',
  shenPiJieDian: '审批节点',
  shenPiRen: '审批人',
  caiGouXuQiuShen: '采购需求申请信息',
  xuQiuShenQingRen: '需求申请人',
  xuQiuShenQingRen_2: '需求申请人部门',
  xuQiuShenQingYuan: '需求申请原因',
  xuQiuShenPiFu: '需求审批附件',
  xuQiuYiShenPi: '需求已审批人',
  dingJiaDingBiaoShen: '定价/定标审批',
  xunJiaDingBiaoHao_2: '询价定标号：',
  dingBiaoShenPiBiao: '定标审批标题',
  canKaoJiaJinE: '参考价金额',
  dingBiaoZongJinE: '定标总金额(含税)',
  dingBiaoShuoMing: '定标说明',
  dingJiaDingBiaoMing: '定价/定标明细',
  dingJiaDingBiaoFu: '定价/定标附件',
  qingZaiDingBiaoMing: '请在定标明细中查看详情',
  dingBiaoShenQing: '定标申请-{val1}',
  xunYuanFangShi: '寻源方式',
  dingBiaoCeLue: '定标策略',
  heJiaFuJian: '核价附件',
  heJiaShenQingTi: '核价申请提交时间',
  canKaoJiaHeJi_2: '参考价合计 (含税)',
  qingZaiBaoJiaXin: '请在报价信息中查看详情',
  renYuanXinXi: '人员信息',
  shiYongRenYouXiang: '使用人邮箱',
  shiYongRenSuoShu: '使用人所属部门',
  shouHuoRenYouBian: '收货人邮编',
  shouQuanQi: '授权期',
  qiWangJiaoHuoRi: '期望交货日期',
  caiGouXinXi: '采购信息',
  baoJiaXinXi: '报价信息',
  fuJianXinXi: '附加信息',
  baoJiaYouXiaoQi: '报价有效期',
  gongHuoZhouQiZi: '供货周期 (自然日)',
  dingDanSheZhi: '订单设置',
  faSongDingDanZhi: '发送订单至供应商',
  ziDongFaSongDing_2: '自动发送订单',
  baoJiaHeJia: '报价&核价',
  chuangJianBaoJiaDan: '创建报价单',
  xunYuanCeLue: '寻源策略',
  dingJiaCeLue: '定价策略',
  tiJiaoHeJiaHou: '提交核价后显示',
  queDingYaoShanChu_2: '确定要删除行吗？',
  zanWeiXuanZeGong: '暂未选择供应商',
  dingBiaoShenQingTi: '定标申请提交时间',
  kaiBiaoShiJian: '开标时间',
  heJiaRen: '核价人',
  baoJia_3: '报价',
  faSongXunJia: '发送询价',
  queRenYaoFaSong: '确认要发送询价单给供应商吗？',
  baoJiaJieZhiShi: '报价截止时间',
  shiQu: '时区:',
  xunJiaShiJian: '询价时间',
  faChuXunJiaHou: '发出询价后显示',
  canYuGongYingShang: '参与供应商',
  lianXiRen_3: '联系人：{val1}',
  xunJiaShuLiang_2: '询价数量:',
  xunJiaXiangQing: '询价详情 {val1}',
  baoJiaXiangQing_2: '报价详情 {val1}',
  quXiaoXunJia: '取消询价',
  quXiaoChengGong: '取消成功',
  xunJiaFaQiRen: '询价发起人',
  tiaoZhengBaoJiaShi: '调整报价时间',
  tiaoZhengHouKeNeng: '调整后，可能会影响供应商的报价',
  dangQianYiYouJia: '当前已有 {val1} 家供应商完成报价',
  tiaoZhengShiJian: '调整时间',
  queDingTiJiaoKai: '确定提交开标吗？',
  cunZaiBuFenBao: '存在部分报价仍未收到，确认要开标吗？',
  baoJiaTiJiaoHou: '开标后将进入核价环节，供应商不可再进行报价',
  baoJiaDaoJiShi: '报价倒计时:',
  xunJiaJieZhiShi: '询价截止时间',
  yiBaoJiaGongYing: '已报价供应商：{val1}',
  weiBaoJia: '未报价',
  yiBaoJia: '已报价',
  buCanYu: '不参与',
  caiGouFangShi: '采购方式',
  zhiDingCaiGouLei: '指定采购类型',
  yuanYinShuoMing: '原因说明',
  juJue: '拒绝',
  tongYi: '同意',
  shenHeXinXi: '审核信息',
  shenHeShuoMing: '审核说明',
  caiGouYuanDaiLi: '采购员代理注册',
  gongYingShangZiZhu: '供应商自助注册',
  faSongYaoQingCheng: '发送邀请成功',
  yaoQingGongYingShang: '邀请供应商注册',
  faQiYaoQing: '发起邀请',
  tiJiaoChengGongHou: '提交成功后，触发邮件通知供应商',
  qingXuanZeFuWu: '请选择服务品类',
  zhangHaoGuanLi: '账号管理',
  xianXiaBaoJia: '线下报价',
  chuangJianXunJia: '创建询价',
  yiZhongBiao: '已中标',
  weiZhongBiao: '未中标',
  xianXiaBaoJiaHe: '线下-报价&核价',
  xianXiaDingBiao: '线下-定标',
  xianShangXunJia: '线上-询价',
  xianShangBaoJia: '线上-报价',
  xianShangHeJia: '线上-核价',
  xianShangDingBiao: '线上-定标',
  xunBiJia: '询比价',
  zhiJieCaiGou: '直接采购',
  zhiDingCaiGou: '指定采购',
  zuiDiJiaFa: '最低价法',
  zongHePingFenFa: '综合评分法',
  zuiGaoJiaFa: '最高价法',
  dingBiaoShenPiZhong: '定标审批中',
  baoJiaZhong: '报价中',
  heJiaZhong: '核价中',
  shenPiZhongBaoJia: 'PR审批中/报价中',
  yiShenPiBaoJia: 'PR已审批/报价中',
  shenPiZhongHeJia: 'PR审批中/核价中',
  yiShenPiHeJia: 'PR已审批/核价中',
  feiITWuZi: '非IT物资',
  xingZhengZhuanYongGong: '行政专用-公共采购',
  weiXiuYongPin: '维修用品',
  yuanGongFuLi: '员工福利',
  qingJieLei: '清洁类',
  shiTangBaoXiang: '食堂包厢',
  canYinShangPei: '餐饮商配',
  changGuiCaiGou: '常规采购',
  gongYingShangXuYue: '供应商续约',
  cunZaiJiShuBi:
    '存在技术必要性的情况：如为保证原有采购项目一致性或服务配套的要求、技术延续要求、或其它产品或服务无法替代的情况等。业务技术的负责人（不低于二级部门负责人或者该业务最高的技术负责人）负责确认该技术必要性，如存在和采购部门的意见冲突，可考虑采购前组织专家评审',
  youTeShuBaoMi: '有特殊保密要求或特殊服务限制条件的',
  keHuZhiDingDe:
    '客户指定的供应商，应取得客户指定的确切意思表达，如书面文件、聊天记录等，并获得相关业务一级部门负责人的确认',
  shouDaoCaiGouShi: '受到采购时效要求、地域限制的影响，采购的产品或服务仅有一家合格供应商可以提供',
  zhengFuHangYeZhun: '政府、行业准则、资质管理机构或法律法规指定的供应商',
  shuDianFaPiaoZhuan: '数电发票（专票）',
  shuDianFaPiaoPu: '数电发票（普票）',
  haiWaiCaiGou: '海外采购',
  guoNeiZongHeCai: '国内综合采购',
  xingJinErBuHanShui: '行金额（不含税）',
  jinEHanBuShui: '金额（不含税）',
  yiJuJue: '已拒绝',
  qingShuRu: '请输入',
  qingShuRuFaPiaoJinE: '请输入发票金额',
  chuangJianLuYueJi: '创建履约计划',
  meiYue: '每月',
  ri: '日',
  geYue_2: '个月',
  faPiaoHaoMa: '发票号码',
  faPiaoDaiMa: '发票代码',
  jiaoYanMa: '校验码',
  kaiPiaoRiQi: '开票日期',
  zhangQiTian: '账期（天）',
  daoQiRi: '到期日',
  shuiE: '税额',
  buHanShuiJinE: '不含税金额',
  faPiaoBiZhong: '发票币种',
  yanCongXiang: '闫从翔',
  weiZhiFu: '未支付',
  yiZhiFu: '已支付',
  buFenZhiFu: '部分支付',
  jieSuan: '结算',
  jieSuanGuanLi: '结算管理',
  faPiao: '发票',
  shangChuanFaPiao: '上传发票',
  luYueXinXi: '履约信息',
  jiaoFuFangShi: '交付方式',
  luYueRiQi: '履约日期',
  jiaoFuZhouQiMei: '交付周期（每月）',
  luYueJiHuaXiang: '履约计划详情{val1}',
  meiYueRi: '每月 {val1} 日',
  geYueMeiYueRi: '{val1} 个月; 每月 {val2} 日',
  guanLianDingDanZheng: '关联订单（整单）',
  guanLianDingDanMing: '关联订单（明细）',
  guanLianYanShouDan: '关联验收单',
  gaiFaPiaoZanWei: '该发票暂未关联任何订单，若需要关联，请选择关联方式：',
  guanLianZongJinE: '关联总金额 (不含税)：',
  jinEHanShui: '金额（含税）',
  shengYuKeKaiPiao: '剩余可开票金额（不含税）',
  guanLianJinEBu: '关联金额 (不含税）',
  xingJinE: '行金额',
  yanShouDingDan: '验收订单',
  guanLian: '关联',
  queDing: '确定',
  qingJianChaBiZhong: '请检查币种、结算公司、供应商等字段输入是否正确',
  qingShuRuCaiGou: '请输入采购订单号/OA单号',
  faPiaoFuJian: '发票附件',
  zhiChiPNG: '支持png. jpg. jpeg. pdf及ofd文件;',
  daoQiRiBuKe: '到期日不可早于开票日期，请确认！',
  buHanShuiJinE_2: '不含税金额不可大于含税金额，请确认！',
  guanLianJinEBu_2: '关联金额（不含税）之和需与发票（不含税）金额一致',
  shanChuHouBuKe: '删除后不可恢复',
  faPiao_2: '{val1}发票',
  shangChuan: '上传',
  faPiaoXiangQing: '发票详情',
  guanLianDanJu: '关联单据',
  zhiFuZhuangTai: '支付状态',
  guanLianFuKuanDan: '关联付款单',
  faPiaoDaoQiRi: '发票到期日',
  faPiaoID: '发票id',
  shangChuanShiJian: '上传时间',
  qingShuRuFaPiao: '请输入发票号码、供应商',
  fuWuLeiJiaoFu: '服务类交付',
  zhongGuoDaLuFa: '中国大陆发票',
  feiZhongGuoDaLu: '非中国大陆发票',
  yiCiXingJiaoFu: '一次性交付',
  meiYueGuDingJiao: '每月固定交付',
  meiYueAnXuJiao: '每月按需交付',
  shiJiLvYueJinE: '实际履约金额',
  shiJiLvYueShuLiang: '实际履约数量',
  lvYueDanHangHao: '履约单-行号',
  lvYueXinXi: '履约信息({val1})',
  buTongShouHuoQu: '不同收货区域，不可发起询价',
  zhiFuFangShiCunZaiYu: '支付方式存在预付，不可合并创建订单',
  tuiJianRenXingMing: '推荐人（姓名+邮箱）',
  guanLiYuanShiZhi: '管理员是指供应商内部管理供应商门户系统的员工',
  gongYingShangLaiYuan: '供应商来源',
  caiGouXunYuan: '采购寻源',
  qiTaRenTuiJian: '其他人推荐',
  yiTiJiao: '已提交',
  dingBiaoJinEWeiLing: '定标金额为0，请完善信息后提交',
  shuRuYouWu: '输入有误',
  biTian: '必填',
  biZhongBuYiZhiBuKe: '币种不一致，不可创建',
  gaiShuJuBuCunZai: '该数据不存在',
  caiGouFuJian: '采购附件',
  xunJiaDanShenPiZhong: '询价单审批中',
  ziDongChuangJianDing: '自动创建订单',
  baoJiaJinEHan: '报价金额（含税）',
  dingDanJinEWeiShui: '订单金额（未税）',
  weiBaoQiXianYue_2: '维保期限（月）',
  shouQuanQiXianYue_2: '授权期限（月）',
  xuQiuBeiZhu: '需求备注',
  xuQiuFuJian: '需求附件',
  daiShouHuo: '待收货',
  xianShiLiuChengTu: '显示流程图',
  shenPiBuTongGuo: '审批不通过',
  jinDuFanKui: '进度反馈',
  zheSuanRenMinBi: '折算人民币金额（含税）',
  xuQiuYuYuanYin: '需求与原因',
  zhiFuFangShiBu: '支付方式不一致，不可创建',
  qingShuRuZhengQue_3: '请输入正确的邮箱地址',
  caiGouXuQiuXiang: '采购需求详情 {val1}',
  kaPian: '卡片',
  lieBiao: '列表',
  dengDaiShiChangChao: '等待时长超3天',
  qingShuRuDingDan: '请输入订单号/OA单号',
  dingDanXiangQing: '订单详情',
  baoJiaHangHao: '报价行号',
  ziDongFaSongDing: '自动发送订单给供应商',
  bianGeng: '变更',
  chaKanBianGeng: '查看变更',
  chaKanBianGeng_2: '查看变更-{val1}',
  fuWuLei_2: '服务类',
  shenQingJieShuRi: '申请结束日期',
  yongJiuYouXiao: '永久有效',
  shiQuShuoMing: '时区说明',
  gaiShiJianShiAn: '该时间是按本地时区转换后的时间',
  fuZhiChengGong: '复制成功',
  queDingLiKaiYe: '确定离开页面？',
  xunJiaDanChuangJian: '询价单创建时间',
  caiGouPinLei: '采购品类',
  buTongXuQiuBan: '不同需求版本，不可发起询价',
  shouHuoDiQu: '收货地区',
  xuQiuRen: '需求人',
  heZuoZhuangTai: '合作状态',
  heZuoZhuangTaiShuo: '合作状态说明',
  chaKanXiangQing: '查看详情',
  caiGouYuanKeDui: '采购员可对供应商生命周期进行管理，包括：注册、潜在、合作、淘汰、黑名单。',
  wangQi: '王琦',
  shangHaiXingZhengZong: '上海行政综合服务需求',
  wangYunHui: '王运辉',
  jiTuanRenLiGong:
    '人力资源部除Global HRO Center、Global Studios BP Center、Global Total Rewards、互娱薪酬中心以外的综合服务需求',
  liuLingYan: '刘灵燕',
  gLOBA: '人力资源部Global HRO Center、Global Studios BP Center、Global Total Rewards的综合服务需求',
  linYang: '林杨',
  jiTuanRenLiRen: '集团人力人才运营中心/人才发展中心/组织发展与文化中心/EHR产品中心综合服务需求',
  liuFang: '刘芳',
  yunFuWuDC: '云服务/DC硬件/除上海行政、北京行政和HR部门之外的其他综合服务采购需求/杭州园区工程改造/北京园区工程改造',
  guoXia: '郭霞',
  iTRuanYingJian: 'IT软硬件、其他IDC资源、短信',
  shiMengQing: '史梦清',
  qingPuSanYaMeng: '徐汇、青浦、三亚、蒙特利尔装修工程',
  xuHuiBeiJingYuan: '上海行政综合服务需求/徐汇园区工程改造',
  dengWenJing: '邓文婧',
  hangSanGuiAnHang: '杭三、贵安、广州基建及弱电改造、北京行政的综合采购需求',
  duiJieRen: '对接人',
  duiJieYeWu: '对接业务',
  yeWuXianShuoMing: '业务线说明',
  yeWuXian: '业务线',
  zhuanJiao: '转交',
  suoShuGongYingShang: '所属供应商',
  zhiFuBaoZhongGuo: '支付宝（中国）网络科技有限公司',
  zhuanJiaoYouXiang: '转交邮箱',
  zhuCe: '注册',
  qianZaiXiaoJinE: '潜在/小金额',
  qianZaiChangGui: '潜在/常规',
  heZuoChangGui: '合作/常规',
  heZuoXiaoJinE: '合作/小金额',
  taoTai: '淘汰',
  heiMingDan: '黑名单',
  shenPiChengGong: '审批成功',
  chengGong: '成功',
  shiBai: '失败',
  xunYuan: '寻源',
  dingDan: '订单',
  yanShou: '验收',
  gongYingShangLieBiao: '供应商列表',
  gongYingShangZhangHao: '供应商账号',
  xuQiuLaiYuan: '需求来源',
  xiaDanGuiZe: '下单规则',
  jinE: '金额',
  bianGengJiLu_2: '变更记录-{val1}',
  caoZuoRen_3: '操作人 :',
  chuangJianDingDan: '创建订单',
  gongYingShangYiRu: '供应商-已入库列表',
  xinXiBianGeng: '信息变更',
  heZuoZhuangTaiBian: '合作状态变更',
  gongYingShangWeiRu: '供应商-未入库列表',
  chaKanJiLu: '查看记录',
  chongXinZhunRu: '重新准入',
  bianGengJiLuDai: '变更记录-待审核',
  shenHe: '审核',
  bianGengJiLuXin: '变更记录-信息变更',
  bianGengJiLuHe: '变更记录-合作状态变更',
  bianGengJiLuZhun: '变更记录-准入申请',
  jueSeBianMa: '角色编码',
  jueSeBianMaBu: '角色编码不得为空！',
  zhouRi: '周日',
  zhouYi: '周一',
  zhouEr: '周二',
  zhouSan: '周三',
  zhouSi: '周四',
  zhouWu: '周五',
  zhouLiu: '周六',
  benCiYanShouShu: '本次验收数量',
  benCiYanShouJin: '本次验收金额',
  yanShouShenPiJie: '验收审批节点明细',
  yanShouXinXi_2: '验收信息',
  yanShouFuJian: '验收附件',
  faHuoShuoMing: '发货说明',
  chaKanYiYanShou: '查看已验收记录',
  yanShouJiLu: '验收记录',
  daiYanShouJinE: '待验收金额',
  shiWuLei: '实物类:',
  qingQueBaoShouDao:
    '请确保收到的货物满足合同/订单要求，包括数量、型号、规格、质量、交付时效等，并符合需求部门/验收部门对该商品的验收标准。',
  fuWuLei: '服务类:',
  qingQueBaoFuWu:
    '请确保服务商已按时完成合同/订单中约定的服务内容，满足合同/订单中规定的验收标准，并符合需求部门/验收部门对该项服务的验收标准。',
  ruanJianLei: '软件类:',
  qingQueBaoNinYi:
    '请确保您已接收到合同/订单中约定的软件（包括许可证购买数量/可用数量、授权用户数量、加密狗实物等），且软件授权期限与合同/订单一致。',
  yanShouYaoQiu: '验收要求:',
  ruYouYiWenQing:
    '如有疑问,请联系相应的采购员;  请按实际到货数量进行验收，本流程支持分批验收，如无特殊要求，请勿分批验收！',
  caiGouBuGongGong: '《采购部公共权限表-验收审批》',
  qingAnShiJiLu: '请按实际履约情况完成验收，如有问题请联系采购员！',
  yanShouChengGong: '验收成功',
  queRenShouHuo: '确认收货',
  zuiDuoShangChuanZhang: '最多上传3张，单个文件不能超过100M',
  yanShouShuoMing: '验收说明',
  shangPinXinXi: '商品信息',
  xingBeiZhu: '行备注',
  xingFuJian: '行附件',
  shenQing: '申请',
  tiJiaoDingDan: '提交订单',
  gongYingShangLuYue: '供应商履约',
  dengDaiShouHuo: '等待收货',
  wanCheng: '完成',
  xinJianFaHuoDan: '新建发货单',
  woDeShenQing: '我的申请：',
  yinCangLiuChengTu: '隐藏流程图',
  qingShouDaoHuoHou: '请收到货后再确认收货，如有问题请联系采购员！',
  yuJiLuYueRi: '预计履约日期',
  faHuoFuJian: '发货附件',
  shouHuoXinXi_2: '收货信息',
  lianXiFangShi: '联系方式',
  dingDanZongE: '订单总额',
  xiaDanShiJian: '下单时间',
  jiaJi: '加急',
  qingShuRuXunJia_2: '请输入询价单号/OA单号',
  oADanHaoCai: 'OA单号/采购订单号',
  xuQiuXinXiBu: '需求信息补充',
  xunJiaCaoGao: '询价草稿',
  daiXiaDan: '待下单',
  heXiaoXinYongKa: '核销信用卡',
  keXiaDanShuLiang: '可下单数量',
  keXiaDanJinE: '可下单金额',
  xiaDanFangShi: '下单方式',
  gongYingShangBuYi: '供应商不一致，不可创建',
  gongYingShangLianXi_3: '供应商联系人不一致，不可创建',
  gongYingShangLianXi_4: '供应商联系人邮箱不一致，不可创建',
  shiFouFaSongDing: '是否发送订单邮件不一致，不可创建',
  zhangQiBuYiZhi: '账期不一致，不可创建',
  jieSuanGongSiBu: '结算公司不一致，不可创建',
  shouHuoRenBuYi: '收货人不一致，不可创建',
  shouHuoRenYouXiang_2: '收货人邮箱不一致，不可创建',
  shouHuoGuoJiaBu: '收货国家不一致，不可创建',
  shouHuoDiQuBu: '收货地区不一致，不可创建',
  shouHuoChengShiBu: '收货城市不一致，不可创建',
  shouHuoDiZhiBu: '收货地址不一致，不可创建',
  shouHuoYouBianBu: '收货邮编不一致，不可创建',
  shouHuoRenLianXi_2: '收货人联系方式不一致，不可创建',
  heTongHaoBuYi: '合同号不一致，不可创建',
  zhiFuXinYongKa: '支付信用卡账号不一致，不可创建',
  shiFouLaiZiDian: '是否来自电商不一致，不可创建',
  chuangJianRenBuYi: '创建人不一致，不可创建',
  chuangJianShiBai: '创建失败',
  gongYingShangLeiXing: '供应商类型为小金额，本次下单金额',
  chaoGuoXiaoJinE: '，超过小金额供应商单次',
  deXianZhiQingZuo: '的限制，请做供应商升级后下单。',
  liJiShengJi: '立即升级',
  dingDanJinEHan: '订单金额（含税）',
  jiZhunTiaoKuan: '基准条款',
  buChongHeTong: '补充合同',
  oADan: 'OA单',
  xingCanKaoZongJia_3: '行参考总价(含税)',
  shouHuoDiQuGuo: '收货地区（国家-州省）',
  qingShuRuOA: '请输入OA单号/商品名称',
  oADanHaoXing: 'OA单号-行号',
  qingShuRuXunJia_3: '请输入询价单号/OA单号/商品名称',
  dingDanChuangJianCheng: '订单创建成功',
  daYu_2: '（大于',
  xuQianShuHeTong: '），需签署合同，请及时完成合同的签订，避免影响订单发出，如已签署请关联合同。',
  guanLianHeTong: '关联合同',
  xinJianCaiGouDing: '新建采购订单',
  shouHuoFangShi: '收货方式',
  xiaDanJinE: '下单金额',
  dingDanXingJinE: '订单行金额',
  xinJianDingDan: '新建订单',
  dingDanXingJinE_2: '订单行金额（未税）',
  dingDanHangJinE_2: '订单行金额（含税）',
  feiYongChengDanCheng: '费用承担成本中心',
  yanShouJinE: '验收金额',
  oADanHaoDing: 'OA单号/订单号',
  keFaHuoShuLiang: '可发货数量',
  keFaHuoJinE: '可发货金额',
  benCiFaHuoShu: '本次发货数量',
  benCiFaHuoJin: '本次发货金额',
  faHuoXingBeiZhu: '发货行备注',
  benDanLuYueJin: '本单履约金额（含税）',
  faHuoXinXi: '发货信息',
  chuangJianRen: '创建人',
  faHuoLeiXing: '发货类型',
  faHuoBeiZhu: '发货备注',
  zuiDuoShangChuanZhang_2: '最多上传20张，单个文件不能超过100M',
  mingXiXing: '明细（{val1}行）',
  heTongPDF: '合同2.pdf',
  yiZaiYanShouZhong: '已在验收中，无法关闭。',
  faHuoDanGuanBi: '发货单关闭成功',
  guanBiFaHuoDan: '关闭发货单',
  gaiFaHuoDanBao: '该发货单包含',
  xingJiangQuanBuGuan: '行，将全部关闭。确定关闭吗？',
  guanBiYuanYin: '关闭原因',
  faHuoDanXiangQing: '发货单详情 {val1}',
  yiGuanBi: '已关闭',
  faHuoDanFuJian: '发货单附件',
  faHuoXinXi_2: '发货信息({val1})',
  faHuoDanHangHao: '发货单行号',
  shiJiFaHuoJin: '实际发货金额',
  danJiaHanShui: '单价含税',
  yunFeiHanShui: '运费（含税）',
  baoJiaBeiZhu: '报价备注',
  gongYingShangLianXi_5: '供应商联系人邮箱',
  gongYingShangLeiXing_2: '供应商类型',
  gongYingShangYouXiang: '供应商邮箱',
  dangQianHeZuoZhuang: '当前合作状态',
  bianGengXinXi: '变更信息',
  muBiaoHeZuoZhuang: '目标合作状态',
  bianGengShuoMing: '变更说明',
  zhunRuXinXi: '准入信息',
  tuiJianRen: '推荐人',
  tuiJianLiYou: '推荐理由',
  zhunRuShuoMing: '准入说明',
  danJiaHanShui_2: '单价（含税）',
  dingBiaoJinEWei: '定标金额（未税）',
  ziDongZhuanPO: '自动转PO',
  dingBiaoXinXi: '定标信息',
  zhongBiao: '中标',
  weiZhong: '未中',
  ruKuDanHangHao: '入库单-行号',
  ruKuZiGuan: '入库资管',
  jiaoYanJieGuo: '校验结果',
  shiBaiYuanYin: '失败原因',
  shouHuoDanXiangQing: '收货单详情',
  faHuoDanHangHao_2: '发货单-行号',
  weiShuiDanJia: '未税单价',
  faPiaoLeiXing: '发票类型',
  yanShouDanHangHao: '验收单-行号',
  ruKuXinXi: '入库信息',
  faHuoDan: '发货单',
  daiYanShouJinE_2: '待验收金额(含税)',
  faHuoShiJian: '发货时间',
  oADanHaoWu: 'OA单号/物流单号/发货单号',
  dingDanHangHao_2: '订单-行号',
  yanShouJinEHan_2: '验收金额(含税)',
  shouHuoShiJian: '收货时间',
  oADanHaoShou: 'OA单号/收货单号/订单号/计提单号',
  biaoTi: '标题',
  feiYongChengDanBu: '费用承担部门',
  shouHuoRenDianHua: '收货人电话',
  queDingWanChengXun: '确定完成询价吗？',
  xunJiaYiWanCheng: '询价已完成',
  wanChengXunJia: '完成询价',
  xuQiuChi: '需求池',
  xunJia: '询价',
  shenQingDanHao: '申请单号',
  gongYingShangJianCheng: '供应商简称',
  shenPiZhuangTai: '审批状态',
  qingShuRuGongYing: '请输入供应商名称/简称/申请单号/OA单号',
  heZuoZhuangTai_2: '合作状态 :',
  bianGengShuoMing_2: '变更说明 :',
  shiJian: '时间',
  dangQianShaiXuanTiao: '当前筛选条件下无数据，点击此处',
  qingKongShaiXuanTiao: '清空筛选条件',
  neiRongBianGeng: '内容变更',
  bianGengDanHao: '变更单号',
  bianGengNeiRong: '变更内容',
  shenPiJieDianMing: '审批节点明细',
  queRenFanHuiShang: '确认返回上一步吗？',
  tianXieDeShuJu: '填写的数据将会被清空！',
  shangYiBu: '上一步',
  xiaYiBu: '下一步',
  jiChuXinXi: '基础信息',
  qingShuRuGongYing_2: '请输入供应商名称',
  tongYiSheHuiXin: '统一社会信用代码',
  qingShuRuTongYi: '请输入统一社会信用代码',
  qingShuRuTI: '请输入TIN',
  guanLiYuanXingMing: '管理员姓名',
  qingShuRuGuanLi: '请输入管理员姓名',
  guanLiYuanYouXiang: '管理员邮箱',
  qingShuRuGuanLi_2: '请输入管理员邮箱',
  qingShuRuXingMing: '请输入姓名',
  zhengJianLeiXing: '证件类型',
  qingShuRuZhengJian: '请输入证件类型',
  zhengJianHaoMa: '证件号码',
  qingShuRuZhengJian_2: '请输入证件号码',
  qingShuRuLianXi: '请输入联系邮箱',
  qiYeXinXi: '企业信息',
  dangAnJiLu: '档案记录',
  dangQianZhanShiNei: '当前展示内容更改前信息的，提交的内容更改正在审批中，请耐心等待',
  daiShenHe: '待审核',
  zhunRuShenQing: '准入申请',
  qingShuRuGongYing_3: '请输入供应商名称/简称/统一社会信用代码/TIN',
  fuWuPinLei: '服务品类',
  zhunRuShiJian: '准入时间',
  yiRuKu: '已入库',
  weiRuKu: '未入库',
  zhunRuShenPi: '准入审批',
  qingShuRuGongYing_4: '请输入供应商名称/简称/申请单号',
  caiGouYuanCaiGou: '采购员-采购二级主管',
  shouGongFaQi: '手工发起',
  ruVMDM: '如VMDM标签触发黑名单(来源为ECC)，则不允许发起升级流程',
  caiGouYuanFaWu: '采购员-法务BP-财务BP-采购二级主管',
  ruVMDM_2: '如VMDM标签触发黑名单，则不允许发起升级流程',
  zhongXuanBingKaiZhan: '中选并开展合作，自动升级',
  shouCiXiaDanChu: '首次下单，触发其一',
  nianNeiWeiKaiZhan: '2年内未开展合作，自动降级',
  youHeTongQuHe: '有合同，取合同最晚到期时间；无合同有订单，取最后一次下单时间；无合同无订单，取准入通过时间',
  caiGouYuanCaiGou_2: '采购员-采购二级主管-采购一级负责人',
  caiGouYuanFaWu_2: '采购员-法务BP-财务BP-采购二级主管-采购一级负责人',
  caiGouYuanCaiGou_3: '采购员-采购二级主管-采购一级负责人审批，抄送财务BP、法务BP、内控BP',
  yiJinXingXinXi:
    '1.已进行信息登记但未完成入库审核；\n2.入库审核通过/合作结束超过2年但未产生合作，且未被记录为“淘汰”或“黑名单”状态的供应商。',
  yiTongGuoRuKu:
    '已通过入库审核，但入库审核时间不满2年，且未被记录为“淘汰”或“黑名单”状态的供应商。合作金额小，风险低，豁免财法审批，快速准入合作的供应商（半年内累计滚动合作不得超过10万元，或单次订单不得超过5万元）',
  yiTongGuoRuKu_2: '已通过入库审核，但入库审核时间不满2年，且未被记录为“淘汰”或“黑名单”状态的供应商。',
  zhiZaiJinNianNei:
    '指在近2年内存在合作且未被记录为“淘汰”或“黑名单”状态的供应商（由潜在/小金额开展合作后，升级为合作/小金额）',
  zhiZaiJinNianNei_2: '指在近2年内存在合作且未被记录为“淘汰”或“黑名单”状态的供应商。',
  heZuoGuoChengZhong: '合作过程中存在异常情况，如考核结果不合格或存在履约风险，经商务采购部评估应暂停合作的供应商。',
  yuanZeShangYingYong: '原则上应永久停止合作的供应商。',
  buQuFen: '不区分',
  muBiaoJiBie: '目标级别',
  shenPiLianLu: '审批链路',
  shengJiangJiChuFa: '升降级触发点',
  jieDuanShuoMingXiang: '阶段说明详情',
  shengMingZhouQiJi: '生命周期及说明',
  shengMingZhouQiLiu: '生命周期流转规则',
  ziZhiPinLeiYu: '资质品类与服务品类不匹配，请先修改',
  tuiJianYuanYin: '推荐原因',
  faDingDaiBiaoRen: '法定代表人',
  gongSiZhuYeWang: '公司主页网站',
  yingYeQiXian: '营业期限',
  chengLiRiQi: '成立日期',
  naShuiRenZiZhi: '纳税人资质',
  shengShi: '省/市',
  quXian: '区（县）',
  yingYeZhiZhaoFu: '营业执照附件',
  zhunRuZiLiao: '准入资料',
  dengJiXinXi: '登记信息',
  fuWuPinLeiNei: '服务品类内容',
  ziZhiXinXi: '资质信息',
  ziZhiMingCheng: '资质名称',
  ziZhiWenJian: '资质文件',
  ziZhiYouXiaoQi: '资质有效期限',
  yiCunZaiXiangTong: '已存在相同资质信息，请修改后提交',
  tianJiaZiZhi: '添加资质',
  ziZhiXinXi_2: '{val1}资质信息',
  tianJia: '添加',
  lianXiRenXinXi: '联系人信息',
  lianXiRenLeiXing: '联系人类型',
  zhiWu: '职务',
  shouJiHao: '手机号',
  yiCunZaiXiangTong_2: '已存在相同联系人，请修改后提交',
  tianJiaLianXiRen: '添加联系人',
  lianXiRen_2: '{val1}联系人',
  yiCunZaiMoRen: '已存在默认账号，是否将该账号设置为默认账号？',
  yinHangZhangHaoXin: '银行账号信息',
  zhangHaoMingCheng: '账号名称',
  jieSuanBiZhong: '结算币种',
  kaiHuHangMingCheng_2: '开户行名称',
  zhiHangMingCheng: '支行名称',
  fuZhiXinZeng: '复制新增',
  yiCunZaiXiangTong_3: '已存在相同银行账号，请修改后提交',
  tianJiaYinHangXin: '添加银行信息',
  yinHangXinXi_2: '{val1}银行信息',
  lianHangHao: '联行号',
  kaiHuHangShengShi: '开户行省市',
  yinHangDiZhi: '银行地址',
  shouXuFeiChengDan: '手续费承担方',
  shiFouWeiMoRen: '是否为默认账号',
  caiWuZhuangKuang: '财务状况',
  nianFen: '年份',
  yingYeShouRuWan: '营业收入（万）',
  liRunZongEWan: '利润总额（万）',
  shiJiaoZiBenWan: '实缴资本（万）',
  nianDuShenJiBao: '年度审计报告',
  caiWuBaoBiao: '财务报表',
  youXiaoQiXian: '有效期限',
  yiCunZaiXiangTong_4: '已存在相同财务状况，请修改后提交',
  tianJiaCaiWuZhuang: '添加财务状况',
  caiWuZhuangKuang_2: '{val1}财务状况',
  chengGongAnLi: '成功案例',
  anLiMingCheng: '案例名称',
  keHuMingCheng: '客户名称',
  fuWuChanPinFan: '服务/产品范围',
  xiangMuGuiMoWan: '项目规模（万元）',
  xiangMuShiJian: '项目时间',
  xiangMuJianJie: '项目简介',
  xiangMuChengGuo: '项目成果',
  yiCunZaiXiangTong_5: '已存在相同成功案例，请修改后提交',
  tianJiaChengGongAn: '添加成功案例',
  chengGongAnLi_2: '{val1}成功案例',
  lianXiRenZhiWei: '联系人职位',
  keHuPingJiaTui: '客户评价/推荐信',
  heTongZhengMingHuo: '合同证明或项目文件',
  liYiGuanXiShen: '利益关系申报',
  yuanGongXingMing: '员工姓名',
  yuanGongSuoZaiGong: '员工所在公司',
  guanXi: '关系',
  yiCunZaiXiangTong_6: '已存在相同利益关系，请修改后提交',
  tianJiaLiYiGuan: '添加利益关系',
  liYiGuanXi: '{val1}利益关系',
  gongYingShangID_2: '供应商ID：',
  gongYingShangYouXiang_2: '供应商邮箱：',
  zhuCeGuoJiaDi_2: '注册国家/地区 :',
  gongYingShangLeiXing_3: '供应商类型：',
  qiYeMingCheng: '企业名称',
  dengJiXinXi_2: '登记信息',
  moRenZhangHao: '默认账号',
  ziGuanDaiShou: '资管代收',
  yuanGongBenRenShou: '员工本人收货',
  yongHuGuanLi: '用户管理',
  xunYuanGang: '寻源岗',
  caiGouJingLi: '采购经理',
  caiGouBuMenFu: '采购部门负责人',
  caiGouGuiZeGuan: '采购规则管理',
  caiWu: '财务',
  shenJi: '审计',
  neiKong: '内控',
  tian_7: '90天',
  qiYe: '企业',
  geRen: '个人',
  juMinShenFenZheng: '居民身份证',
  gangAoJuMinLai: '港澳居民来往内地通行证',
  zhongHuaRenMinGong: '中华人民共和国港澳居民居住证',
  taiWanJuMinLai: '台湾居民来往大陆通行证',
  zhongHuaRenMinGong_2: '中华人民共和国台湾居民居住证',
  zhongGuoHuZhao: '中国护照',
  waiGuoHuZhao: '外国护照',
  waiGuoRenYongJiu: '外国人永久居留身份证(外国人永久居留证)',
  zhongHuaRenMinGong_3: '中华人民共和国外国人工作许可证(A类)',
  zhongHuaRenMinGong_4: '中华人民共和国外国人工作许可证(B类)',
  zhongHuaRenMinGong_5: '中华人民共和国外国人工作许可证(C类)',
  qiTaGeRenZheng: '其他个人证件',
  yiBanNaShuiRen: '一般纳税人',
  xiaoGuiMoNaShui: '小规模纳税人',
  feiZengZhiShuiNa: '非增值税纳税人',
  qiTa: '其他',
  shangWuDuiJieRen: '商务对接人',
  caiWuDuiJieRen: '财务对接人',
  wangYiJiTuan: '网易集团',
  shuangFang: '双方',
  shengMingZhouQiBian: '生命周期变更',
  zhuZhangHao: '主账号',
  ziZhangHao: '子账号',
  wuLiuKuaiDi: '物流快递',
  feiWuLiuKuaiDi: '非物流快递',
  wuXinXi: '无信息',
  weiFaHuo: '未发货',
  yiFaHuo: '已发货',
  yiQianShou: '已签收',
  yiTuiHuo: '已退货',
  peiSongZhong: '配送中',
  zengZhiShuiZhuanPiao: '增值税专票',
  zengZhiShuiPuPiao: '增值税普票',
  dianZiFaPiaoZhuan: '电子发票（专票）',
  dianZiFaPiaoPu: '电子发票（普票）',
  niMeiYouFangWen: '你没有访问该页面的权限',
  chongXinDengLu: '重新登录',
  fanHuiShouYe: '返回首页',
  ziChanXinXi: '资产信息',
  ziChanBianHao: '资产编号',
  pODanHao: 'PO单号',
  qingShuRuWanZheng_2: '请输入完整的PO单号',
  daiXuQiuQueRen: '待需求确认',
  daiXunJia: '待询价',
  daiXunJiaQueRen: '待询价确认',
  dingDanXinXiBu: '订单信息补充',
  daiBuChongHeTong_2: '待补充合同信息',
  zhengDanFaHuo: '整单发货',
  dingDanHangFaHuo: '订单行发货',
  guanLianXinYongKa_2: '关联信用卡账单',
  qingShuRuWanZheng_3: '请输入完整的采购订单号',
  qingXianBuChongXu: '请先补充需求信息',
  queRenTongGuo: '确认通过',
  xuQiuYiQueRen: '需求已确认',
  xingCanKaoZongJia_2: '行参考总价',
  buKeQuXiao: '不可取消',
  gaiXuQiuGongXing: '该需求共 {val1} 行，将全部取消。确定取消吗？',
  xuQiuXingQueRen: '需求行确认成功',
  buTongYiJiPin: '不同一级品类，不可发起询价',
  xuQiuQueRen: '需求确认',
  feiYongDaLei: '费用大类',
  feiYongXiaoLei: '业务小类',
  xuQiuXinXiQue: '需求信息确认',
  xuQiuXinXiQue_2: '需求信息确认汇总',
  fangDiChanJiShe: '房地产及设施类',
  yuanGongFuLiQi: '员工福利-其他福利',
  shiJiShiYongRen_10: '实际使用人不同部门，不可发起询价',
  xunJiaBiaoTi: '询价标题',
  wenXinTiShiShe: '温馨提示：涉及CEO审批的，【{val1}】内容将同步至POPO审批。',
  xunJiaBiaoTiHe: '询价标题、核价说明、报价附件',
  qingShuRuXunJia: '请输入询价标题，涉及CEO审批的，该信息将同步至POPO审批',
  qingShuRuHeJia: '请输入核价说明，涉及CEO审批的，该信息将同步至POPO审批',
  queDingJinYongYongHu: '确定要禁用用户吗？',
  queDingQiYongYongHu: '确定要启用用户吗？',
  jinYongYongHuHou: '禁用用户后，用户将不具备登录权限',
  zuiHouGengXinRen: '最后更新人',
  lianXiRenXingMing: '联系人姓名',
  banGongYeWuYong: '办公/业务用',
  iTBuMenZhuan: 'IT部门专用-备机配件',
  tian_2: '天',
  dianJiFaQiP: '点击发起popo会话',
  buTongJieSuanZhu: '不同结算主体，不可发起询价',
  zhongZhi: '重置',
  huiLuQiJian: '汇率期间',
  yuanBiZhong: '源币种',
  zheSuanBiZhong: '折算币种',
  chaKanXuQiu: '查看需求',
  chaKanXunJiaDan: '查看询价单',
  shanChuXunJiaDan: '删除询价单',
  chaKanDingDan: '查看订单',
  gengXinDingDan: '更新订单',
  xinZeng: '新增',
  jieDanGuiZe: '接单规则',
  rFXShenPi_2: 'RFx 审批规则',
  teDingBiaoQian: '特定标签',
  suoYouQuanXian: '所有权限',
  quanXianPeiZhi: '权限配置',
  chuangJianJiaoSe: '创建角色',
  bianJiJueSe: '编辑角色',
  jueSeZhuangTai: '角色状态',
  jueSeMingCheng: '角色名称',
  jueSeMingChengBu: '角色名称不得为空!',
  shanChuChengGong: '删除成功',
  shanChuShiBai: '删除失败',
  bianJiGuiZe: '编辑规则',
  guiZeMingCheng: '规则名称',
  guoJiaLeiXing: '国家/类型',
  caiGouLeiXing: '采购类型',
  shangPinMingChengPei: '商品名称&配置&品牌',
  guoJiaDiQu_2: '国家/地区',
  buMen: '部门',
  shenQingRenJiBie: '申请人级别',
  shenQingRenXingMing: '申请人姓名',
  shiJiShiYongRen_5: '实际使用人级别',
  shiJiShiYongRen_6: '实际使用人姓名',
  jieSuanZhuTi: '结算主体',
  xinJianBiaoQian: '新建标签',
  bianJiBiaoQian: '编辑标签',
  biaoQianMing: '标签名',
  biaoQianMingBuDe: '标签名不得为空!',
  biaoQianYanSe: '标签颜色',
  panDuanTiaoJian: '判断条件',
  xinZengTiaoJian: '新增条件',
  xuHao: '序号',
  guiZe: '规则',
  shenQingRenBuMen_2: '申请人部门：{val1}',
  shenQingRenJiBie_2: '申请人级别：{val1}',
  shiJiShiYongRen_7: '实际使用人部门：{val1}',
  shiJiShiYongRen_8: '实际使用人级别：{val1}',
  shiJiShiYongRen_9: '实际使用人姓名：{val1}',
  jieSuanZhuTi_2: '结算主体：{val1}',
  gongYingShang_2: '供应商：{val1}',
  yiXuanZeQuanBu: '已选择全部人员',
  yiXuanZeQuanBu_2: '已选择全部结算主体',
  dangQianMoRenXuan: '当前默认选择全部创建人，可选择指定人员',
  dangQianMoRenXuan_2: '当前默认选择全部结算主体，可选择指定结算主体',
  xinZengCaiGouYuan: '新增采购员',
  xinZengJieSuanZhu: '新增结算主体',
  xingMing: '姓名',
  gongHao: '工号',
  jieSuanZhuTiMing: '结算主体名称',
  jieSuanZhuTiI: '结算主体ID',
  quanBuRenYuan: '全部人员',
  zhiDingRenYuan: '指定人员',
  yiChuCaiGouYuan: '移除采购员',
  quanBuJieSuanZhu: '全部结算主体',
  zhiDingJieSuanZhu: '指定结算主体',
  yiChuJieSuanZhu: '移除结算主体',
  tianJiaYongHu: '添加用户',
  bianJiYongHu: '编辑用户',
  qingZhengQueTianXie: '请正确填写数据权限内容',
  fenPeiJueSe: '分配角色',
  shuJuQuanXian: '数据权限',
  xingMingGongHao: '姓名/工号',
  xuanPinYiWanCheng: '选品已完成',
  qingFanHuiOA: '请返回OA申请页面完成后续操作',
  caiGouDingDanZheng: '采购订单-整单',
  caiGouDingDanMing: '采购订单-明细',
  xunJiaDanHao: '询价单号',
  tiJiaoRiQi: '提交日期',
  tiJiaoKaiShiRi: '提交开始日期',
  dingDanZongEWei: '订单总额（未税）',
  laiYuan: '来源',
  gengXinZhuTiHe: '更新主体、合同、备注',
  dingDanHangHao: '订单行号',
  baoJiaJinEWei: '报价金额（未税）',
  chuangJianRiQi: '创建日期',
  chuangJianKaiShiRi: '创建开始日期',
  qiWangDaoHuoDi: '期望到货地点',
  shouHuoLianXiRen: '收货联系人',
  gaiXingDuiYingDe: '该行对应的整单询价单都将删除，确定删除吗？',
  shanChuXunJiaDan_2: '删除询价单成功',
  zanWu: '暂无',
  yanShouDanXiangQing: '验收单详情',
  yanShouDanHao: '验收单号',
  yanShouRen: '验收人',
  yanShouShiJian: '验收时间',
  yanShouLeiXing: '验收类型',
  yanShouXinXi: '验收信息',
  oADanHaoXu: 'OA单号-需求行号',
  caiGouDingDanHao: '采购订单-行号',
  jiTiDanHao: '计提单号',
  jiTiZhuangTai: '计提状态',
  shouQuanQiXianYue: '授权期限/月',
  yanShouJinEHan: '验收金额（含税）',
  biZhong: '币种',
  shiFouZiChan: '是否资产',
  caoGao: '草稿',
  tiJiaoJieShuRi: '提交结束日期',
  daiYanShouShiChang: '待验收时长',
  daiYanShouShuLiang: '待验收数量',
  zuiXinWuLiuXin: '最新物流信息',
  yiShouHuoShuLiang: '已收货数量',
  weiQueRen: '未确认',
  yiTuiHui: '已退回',
  weiShenHe: '未审核',
  yiShenHe: '已审核',
  yiRuZhang: '已入账',
  yiChongXiao: '已冲销',
  caiGouDingDanHao_2: '采购订单号',
  yanShouRiQi: '验收日期',
  yanShouKaiShiRi: '验收开始日期',
  yanShouJieShuRi: '验收结束日期',
  jiTiRiQi: '计提日期',
  jiTiKaiShiRi: '计提开始日期',
  jiTiJieShuRi: '计提结束日期',
  caiGouDingDanHao_3: '采购订单号-行号',
  weiBaoQiXianYue: '维保期限/月',
  yiWanCheng: '已完成',
  qingShuRuWanZheng: '请输入完整的OA单号',
  shenQingRiQi: '申请日期',
  shenQingKaiShiRi: '申请开始日期',
  oADanHangHao_2: 'OA单-行号',
  renMinBiJinE: '人民币金额(含税)',
  shouHuoDianHua: '收货电话',
  shouJianRen: '收件人',
  yuanYinJiXuQiu: '原因及需求',
  chanPin: '产品',
  chanPinChengBenZhong: '产品成本中心',
  buChongXingCanKao: '补充行参考价格、品类',
  tingYong: '停用',
  xingHao: '行号',
  kaiHuHangMingCheng: '开户行名称（支行）',
  zhangHuMingCheng: '账户名称',
  yinHangZhangHao: '银行账号',
  kaiHuXuKeZheng: '开户许可证',
  tiJiaoChengGong: '提交成功',
  tiJiao: '提交',
  gongYingShangID: '供应商ID',
  gongYingShangMingCheng: '供应商名称',
  dengBaiShiBianMa: '邓白氏编码',
  zhuCeGuoJiaDi: '注册国家/地区',
  lianXiRen: '联系人',
  lianXiYouXiang: '联系邮箱',
  chengLiShiJian: '成立时间',
  zhuCeZiBenWan: '注册资本（万）',
  gengXinShiJian: '更新时间',
  gengXinRen: '更新人',
  xiangXiDiZhi: '详细地址',
  jingYingFanWei: '经营范围',
  heFaZhuCeZheng: '合法注册证件',
  zhunRuZiLiaoShang: '准入资料上传',
  yinHangXinXi: '银行信息',
  dengBaiShi: '邓白氏',
  faRenDaiBiaoRen: '法人代表人/负责人',
  zuiHouGengXinShi: '最后更新时间',
  caoZuoShiBai: '操作失败',
  xinZengGongYingShang: '新增供应商',
  zhiChiKuoZhanMing: '支持扩展名：pdf .jpg. png，单个文件小于100M',
  shangChuanFuJian: '上传附件',
  chuangJianChengGong: '创建成功',
  qingShuRuZhengQue: '请输入正确的邮箱',
  qingShuRuZhengQue_2: '请输入正确的手机号码',
  zhuCeBiZhong: '注册币种',
  tian_3: '15天',
  tian_4: '30天',
  tian_5: '45天',
  tian_6: '60天',
  shouHuo: '收货',
  tuiHuo: '退货',
  zongYunFeiHanShui: '总运费(含税)：{val1}',
  dingBiaoJinEHan: '定标金额（含税，含分摊运费）',
  dingBiaoJinEHan_2: '定标金额（含税）',
  yunFei: '（运费：{val1}）',
  yunFei_2: '运费',
  zaiGaiBaoJiaDan: '【{val1}】在该报价单内的总运费',
  zongYunFeiHanShui_2: '总运费（含税）',
  quXiaoXuQiu: '取消需求',
  xuQiuYiQuXiao: '需求已取消',
  hanShui: '含税',
  weiShui: '未税',
  gongYingShangXiangQing: '供应商详情',
  caiGouDingDanXiang: '采购订单详情 {val1}',
  faSongDingDan: '发送订单',
  faSongZhi: '发送至',
  faSongChengGong: '发送成功',
  quXiaoDingDan: '取消订单',
  caiGouDingDanYi: '采购订单已取消',
  dingDanHao: '订单号',
  dingDanLaiYuan: '订单来源',
  danJuBiaoQian: '单据标签',
  dingDanJinE: '订单金额',
  yuFuKuanShenQing: '预付款申请',
  xinYongKaZhangDan: '信用卡账单',
  shouHuoXinXi: '收货信息',
  guoJiaDiQu: '国家-地区',
  shouHuoLianXiDian: '收货联系电话',
  oADanHangHao: 'OA单行号',
  xunJiaDingBiaoXing: '询价定标行号',
  shouQuanQiXian: '授权期限',
  weiBaoQiXian: '维保期限',
  xuQiuShuLiang: '需求数量',
  xiaDanShuLiang_2: '下单数量',
  dingDanHangBiZhong: '订单行币种',
  xingYunFeiHanShui: '行运费（含税）',
  dingDanHangJinE: '订单行金额（含运费）',
  shouHuoShuLiang: '收货数量',
  shouHuoDanHao: '收货单号',
  fuJiaXinXi: '附加信息',
  chengBenZhongXin: '成本中心',
  chanPinDuan: '产品段',
  wuLiuXinXi: '物流信息',
  zhangDanID: '账单ID',
  shangJiaMingCheng: '商家名称',
  jiaoYiShiJian: '交易时间',
  jiaoYiJinE: '交易金额',
  jiaoYiBiZhong: '交易币种',
  xinYongKaBenBi: '信用卡本币金额',
  zhangDanXiangQing: '账单详情',
  guanLianXinYongKa: '关联信用卡账单({val1})',
  beiZhuXinXi: '备注信息',
  caoZuoShiJian: '操作时间',
  feiKuaiDiWuLiu: '非快递物流',
  wuLiuGongSi: '物流公司',
  wuLiuDanHao: '物流单号',
  shiJiFaHuoShu: '实际发货数量',
  yuJiDaoHuoRi: '预计到货日期',
  faHuoRiQi: '发货日期',
  queDingFaHuoMa: '确定发货吗？',
  faHuoShiBaiShi: '发货失败，实际发货数量需小于等于可发货数量 。',
  yiTiJiaoFaHuo: '已提交发货',
  faHuo: '发货',
  tianJiaXing: '添加行',
  mingXiHangHao: '明细行号',
  daiFaHuoShuLiang: '待发货数量/总数',
  faHuoShiBaiShi_2: '发货失败，实际发货数量需大于0。',
  piLiangFaSong: '批量发送',
  piLiangFaHuo: '批量发货',
  queRenFaHuo: '确认发货',
  shiJiFaHuoShu_2: '实际发货数量总计：{val1}',
  caoZuoRen_2: '操作人:',
  bianGengWei: '变更为',
  xuQiuDanXiangQing: '需求单详情',
  zhiHangQingKuang: '执行情况',
  shenQingRen: '申请人',
  shenQingRenYouXiang: '申请人邮箱',
  shenQingRenBuMen: '申请人部门',
  shenQingSuoShuGong: '申请所属公司',
  shenQingRenChengBen: '申请人成本中心',
  shenQingShiJian: '申请时间',
  shiFouDianShang: '是否电商',
  shiFouBenRenShi: '是否本人使用',
  shiJiShiYongRen_4: '实际使用人成本中心',
  canKaoZongJinE: '参考总金额',
  guoJia: '国家',
  diQu: '地区',
  shouHuoLianXiFang: '收货联系方式',
  qiWangDaoHuoRi: '期望到货日期',
  xuQiuXinXi: '需求信息',
  oAHangHao: 'OA行号：{val1}',
  shuLiang: '数量',
  xuQiuJiYuanYin: '需求及原因',
  yongTu: '用途',
  dengDaiShiChang: '等待时长',
  xiaoShi: '{val1}小时',
  xingCanKaoZongJia: '行参考总价（含税）',
  xuQiuShenPiJie: '需求审批节点明细',
  queRen: '确认',
  jinDu: '进度',
  fanKuiShiJian: '反馈时间',
  tiJiaoFanKui: '提交反馈',
  tianJiaJinDu: '添加进度',
  zhuanJiaoCaiGouYuan: '转交采购员',
  zhuanJiaoChengGong: '转交成功',
  dangQianCaiGouYuan: '当前采购员',
  xinCaiGouYuan: '新采购员',
  faQiXunJia: '发起询价',
  buChongCanKaoJia: '补充参考价后可发起询价',
  queDingYaoFaQi: '确定要发起询价吗？',
  quanXuan: '全选',
  yiXuanXingCaiGou: '已选：{val1} 行采购需求',
  oAHangHao_2: 'OA行号',
  shangPin: '商品',
  xunJiaXinXi: '询价信息',
  xunJiaDan: '询价单',
  dingDanXinXi: '订单信息',
  dingDanZhuangTai: '订单状态',
  yanShouShuLiang: '验收数量',
  zhiXingMingXi: '执行明细',
  daiBuChongCanKao: '待补充参考价',
  zhen: '真',
  jia: '假',
  buQiYong: '不启用',
  youXiao: '有效',
  banGongYong: '办公用',
  iTBeiJi: 'IT备机',
  yuanGongFuLiNian: '员工福利-年会奖品',
  yuanGongFuLiJie: '员工福利-节日礼品',
  huoDongJiangPin: '活动奖品',
  daiBuChongHeTong: '待补充合同',
  daiFaHuo: '待发货',
  daiYanShou: '待验收',
  buFenYanShou: '部分验收',
  yiYanShou: '已验收',
  caiGouShenQing: '采购申请',
  dianShang: '电商',
  shenPiZhong: '审批中',
  shenPiJuJue: '审批拒绝',
  shenPiTongGuo: '审批通过',
  daiRenLingXinYong: '待认领信用卡流水',
  chaoGuoCanKaoJia: '超过参考价{val1}%',
  wan: '万',
  shiWan: '十万',
  baiWan: '百万',
  qianWan: '千万',
  yi: '亿',
  shiYi: '十亿',
  baiYi: '百亿',
  qianYi: '千亿',
  weiBaoCunNeiRong: '未保存内容将丢失，确认离开页面？',
  cheHuiYuanYin: '撤回原因',
  cheHuiQingQiuYi: '撤回请求已发送，请稍后刷新页面查看',
  zhiDaoLe: '知道了',
  quXiaoYuanYin: '取消原因',
  xingVALDing: '行{val1}：定标金额不得为0',
  jieDian: '节点',
  shenPiShiJian: '审批时间',
  caoZuoRen: '操作人',
  shenPiYiJian: '审批意见',
  rPXShenPi: 'RFx审批节点明细',
  shenPiDanHao: '审批单号：',
  shouQi: '收起',
  zhanKai: '展开',
  dingBiaoShuLiang: '定标数量：',
  dingBiaoZongE: '定标总额：',
  tiShiGaiXunJia: '提示：该询价单参考价合计 ＞ ¥ 20,000.00 需提供至少 3 家供应商的报价信息',
  dingBiao: '定标',
  zuiYouJia: '最优价',
  zanWuBaoJiaXin: '暂无报价信息',
  qingDianJiXiaFang: '请点击下方按钮进行报价信息录入',
  liJiBaoJia: '立即报价',
  heTongMingXi: '合同明细',
  heTongBianHao: '合同编号',
  heTongMingCheng: '合同名称',
  qianDingRiQi: '签订日期',
  heTongKaiShiRi: '合同开始日期',
  heTongZhongZhiRi: '合同终止日期',
  zongJinE: '总金额',
  biaoQian: '标签',
  heJiaShuoMing: '核价说明',
  baoJiaFuJian: '报价附件',
  shiJiShiYongRen: '实际使用人',
  shiJiShiYongRen_2: '实际使用人部门',
  shiJiShiYongRen_3: '实际使用人邮箱',
  caiGouXuQiuFu: '采购需求附件',
  shouHuoRenLianXi: '收货人联系电话',
  caiGouYuanYin: '采购原因',
  dingBiaoZongE_2: '定标总额',
  yiXunJiaZongShu: '已询价/总数',
  ruanJian: '软件',
  yingJian: '硬件',
  baoJia: '报价{val1}',
  dingBiaoJinE: '定标金额',
  gongYingShangLianXi: '供应商联系人',
  gongYingShangLianXi_2: '供应商联系方式',
  faSongDingDanTong: '发送订单通知',
  shi: '是',
  fou: '否',
  xiaZai: '下载',
  queDingCheHuiShen: '确定撤回审批吗？',
  cheHuiShenPiCheng: '撤回审批成功',
  cheHuiShenPi: '撤回审批',
  queDingQuXiaoDing: '确定取消定标吗？',
  quXiaoDingBiaoCheng: '取消定标成功',
  quXiaoDingBiao: '取消定标',
  shouHuoRenYouXiang: '收货人邮箱',
  tiJiaoDingBiaoShi: '提交定标失败',
  dingBiaoShuLiangWei: '定标数量为0，请完善信息后提交',
  woZhiDaoLe: '我知道了',
  xingZhongBiaoShuLiang: '行{val1}：中标数量超过待询价数量，请重新填写',
  xingYuFuJinE: '行{val1}：预付金额不得为0',
  queDingYaoTiJiao: '确定要提交定标吗？',
  dingBiaoTiJiaoHou: '定标提交后将进入审批环节，审批通过后系统会自动创建采购订单。',
  bianJiXunJiaDan: '编辑询价单',
  chuangJianXunJiaDan: '创建询价单',
  tiJiaoDingBiao: '提交定标',
  xinZengBaoJia: '新增报价',
  baoJia_2: 'Line{val1}-报价{val2}',
  queDingYaoShanChu: '确定要删除报价吗？',
  danGeWenJianBu: '单个文件不能超过100M',
  zhiZhiChiMYi: '只支持{val1}M以内的文件',
  zhiNengShangChuanGe: '只能上传{val1}个文件',
  xuanZeHeTong: '选择合同',
  qingXuanZeHeTong: '请选择合同',
  shenPiZhongDaiXun_2: 'PR审批中/待询价',
  yiShenPiDaiXun_2: 'PR已审批/待询价',
  shenPiZhongYiXun_2: 'PR审批中/已询价',
  shenPiZhongXunJia_2: 'PR审批中/询价单审批中',
  yiShenPiXunJia_2: 'PR已审批/询价单审批中',
  pUNCH: 'Punchout白名单&用户化名管理',
  zhanDian: '站点',
  yongHuLieBiao: '用户列表',
  baiMingDanGongNeng: '白名单功能',
  queDingJinYongMa: '确定禁用吗？',
  queDingQiYongMa: '确定启用吗',
  yiJinYong: '已禁用',
  yiQiYong: '已启用',
  qiYong: '启用',
  jinYong: '禁用',
  yongHuYouXiang: '用户邮箱',
  huaMingYouXiang: '化名邮箱',
  qingTianXieYongHu: '请填写用户邮箱',
  xinZengYongHu: '新增用户',
  zanWuXianXiaBao: '暂无线下报价附件',
  xianXiaBaoJiaFu: '线下报价附件上传',
  shenPiZhongXunJia: 'PR审批中/询价单审批中',
  yiShenPiXunJia: 'PR已审批/询价单审批中',
  queDingShanChuMa: '确定删除吗？',
  shanChu: '删除',
  liKaiYeMianDang: '离开页面，当前填写的信息不会保存。',
  ciXiangBiTian: '此项必填',
  quXiao: '取消',
  baoCun: '保存',
  quanBuPinLei: '全部品类',
  gengDuoShaiXuan: '更多筛选',
  yongHuPeiZhi: '用户配置',
  jueSe: '角色',
  yongHu: '用户',
  yeWuPeiZhi: '业务配置',
  huiLu: '汇率',
  guiZePeiZhi: '规则配置',
  caiGouJieDanGui: '采购接单规则',
  shenPiGuiZe: '审批规则',
  teShuBiaoQian: '特殊标签',
  gongZuoTai: '工作台',
  caiGouXuQiu: '采购需求',
  xunJiaDingBiao: '询价定标',
  caiGouDingDan: '采购订单',
  shouHuoGuanLi: '收货管理',
  gongYingShangGuanLi: '供应商管理',
  xiTongGuanLi: '系统管理',
  danHaoBaoKuoV: '单号包括{val1}的OA单号',
  danHaoBaoKuoV_2: '单号包括{val1}的询价单据',
  danHaoBaoKuoV_3: '单号包括{val1}的PO单据',
  shangPinMingBaoKuo: '商品名包括{val1}的PO单据',
  qingSouSuoDanHao: '请搜索单号/商品名称/供应商等',
  guanLiYuan: '管理员',
  tuiChuDengLu: '退出登录',
  guiZeZhuangTai: '规则状态',
  manZuYiXiaTiao: '满足以下条件时进入审批',
  tiaoJian: '条件',
  shanChuTiaoJian: '删除条件',
  tianJiaTiaoJian: '添加条件',
  xinJianShenPiGui: '新建审批规则-RFx审批',
  bianJiShenPiGui: '编辑审批规则-RFx审批',
  baoCunChengGong: '保存成功',
  xinJianChengGong: '新建成功',
  xinJianGuiZe: '新建规则',
  bianJi: '编辑',
  shenPiGuiZeMing: '审批规则名称',
  youXianJi: '优先级',
  oAShenPiLian: 'OA审批链',
  bianGengQian: '变更前',
  bianGengHou: '变更后',
  chaKan: '查看',
  bianGengJiLuR: '变更记录-RFx审批-{val1}',
  zanWuBianGengJi: '暂无变更记录',
  qingShuRuGuiZe: '请输入规则名称',
  leiXing: '类型',
  zhuangTai: '状态',
  bianGengJiLu: '变更记录',
  caoZuo: '操作',
  queDingShiXiaoMa: '确定失效吗？',
  queDingShengXiaoMa: '确定生效吗？',
  shiXiao: '失效',
  shengXiao: '生效',
  yiShiXiao: '已失效',
  yiShengXiao: '已生效',
  zanWuShuJu: '暂无数据',
  rFXShenPi: 'RFx审批',
  daYu: '大于',
  xiaoYu: '小于',
  dengYu: '等于',
  daYuDengYu: '大于等于',
  xiaoYuDengYu: '小于等于',
  buDengYu: '不等于',
  cunZaiYu: '存在于',
  zhengDanJinE: '整单金额',
  tiJiaoRen: '提交人',
  jieSuanGongSi: '结算公司',
  shouHuoGuoJiaDi: '收货国家/地区',
  pinLei: '品类',
  dingBiaoGongYingShang: '定标供应商',
  zhiFuFangShi: '支付方式',
  zhangQi: '账期',
  gongYingShang: '供应商',
  weiBao: '维保',
  danJiaWeiShui: '单价（未税）',
  baoJiaBiZhong: '报价币种',
  shuiLu: '税率',
  xiaDanShuLiang: '定标数量',
  hanShuiJinE: '含税金额',
  tian: '{val1}天',
  yuFuJinE: '预付金额',
  xinYongKaHao: '信用卡号',
  heTongHao: '合同号',
  beiZhu: '备注',
  fuJian: '附件',
  shouQuan: '授权',
  yongJiu: '永久',
  geYue: '{val1}个月',
  baoJiaXiangQing: '报价详情',
  oADanHao: 'OA单号',
  canKaoLianJie: '参考链接',
  shangPinMingCheng: '商品名称',
  pinPai: '品牌',
  peiZhi: '配置',
  canKaoJia: '参考价（含税）',
  canKaoBiZhong: '参考币种',
  shouHuoRen: '收货人',
  shouHuoDiZhi: '收货地址',
  youBian: '邮编',
  lianXiDianHua: '联系电话',
  youXiang: '邮箱',
  daiXunJiaZongShu: '待询价/总数',
  jiBenXinXi: '基本信息',
  xunJiaDingBiaoHao: '询价定标号',
  chuangJianShiJian: '创建时间',
  tiJiaoShiJian: '提交时间',
  caiGouYuan: '采购员',
  caiGouYuanYouXiang: '采购员邮箱',
  canKaoJiaHeJi: '参考价合计（含税）',
  dingBiaoJinEHe: '定标金额合计（含税）',
  jieSuanGongSiBi: '结算公司币种',
  shenPiZhongDaiXun: 'PR审批中/待询价',
  yiShenPiDaiXun: 'PR已审批/待询价',
  shenPiZhongYiXun: 'PR审批中/已询价',
  daiJiaGeHuiZong: '待价格汇总',
  yiXiaDan: '已下单',
  yiQuXiao: '已取消',
  zhuanZhang: '转账',
  xinYongKa: '信用卡',
  zhuanZhangYuFu: '转账-预付',
  wangLuoYiChang: '网络异常',
}

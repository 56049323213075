import cookie from 'js-cookie'
import { Message } from '@bedrock/components'
import { createNxios as createNeactNxios, NxiosOptions } from '@oahz/neact'
import I18N from '@feature/i18n'
import { StorageFactory } from '@oahz/neact-utils'
// import { apiService } from '@/services'
import { authLogin } from './utils'

export type ReqOpts = NxiosOptions

const R_XSRF_TOKEN = 'XSRF-TOKEN'
const W_XSRF_TOKEN = 'X-XSRF-TOKEN'
const BIZ_ENTERPRISE_ID = 'purchase-partnership-enterprise'

const defaultOptions: NxiosOptions = {
  errorMessger: msg => {
    msg === 'Failed to fetch' && (msg = I18N.auto.wangLuoYiChang)
    Message.warn(msg)
  },
}

const BIZ_HEADERS = '__biz_headers__'

// 使用session-storage，保持当前访问时的groupid（包括刷新改页面），
// 而新开页面访问时，或者关闭当前页面再打开时，就使用全新的groupId（服务端会返回的）
// const storage = new StorageFactory({ storage: window.sessionStorage })
const storage = new StorageFactory()

export type BizHeaders = { [BIZ_ENTERPRISE_ID]: string }

export const setBizHeaders = (headers: Partial<BizHeaders>) => {
  storage.set(BIZ_HEADERS, { ...getBizHeaders(), ...headers })
}

export const getBizHeaders = () => {
  return (storage.get(BIZ_HEADERS) || {}) as BizHeaders
}

export const removeBizHeaders = (keys?: Array<keyof BizHeaders>) => {
  if (keys) {
    const bizHeaders = getBizHeaders()
    const newHeaders = Object.fromEntries(
      Object.entries(bizHeaders).filter(([k]) => !keys.includes(k as keyof BizHeaders))
    )
    storage.set(BIZ_HEADERS, newHeaders)
  } else {
    storage.remove(BIZ_HEADERS)
  }
}

export const setBizHeaderEnterpriseId = (id?: string) => {
  id && setBizHeaders({ [BIZ_ENTERPRISE_ID]: id })
}

export const createNxios = (options: NxiosOptions = {}) => {
  const instance = createNeactNxios({ ...defaultOptions, ...options })

  instance.interceptors.request.use((url, options) => {
    options.headers = { ...options.headers, ...getBizHeaders() }

    const reqHeaders = options.headers as Record<string, string>
    const xsrf_token = cookie.get(R_XSRF_TOKEN)
    xsrf_token && (reqHeaders[W_XSRF_TOKEN] = xsrf_token)

    return { url, options }
  })

  instance.interceptors.response.use(async response => {
    const { status } = await response.clone().json()

    if (status === 4001) {
      authLogin()
    }

    // 当前请求头上的企业主体没有权限，就删掉缓存，然后reload一下
    if (status === 4005) {
      removeBizHeaders()
      window.location.reload()
    }

    return response
  })

  // instance.use(async (ctx, next) => {
  //   const { options } = ctx.req
  //   if (options.method?.toLowerCase() === 'post') {
  //     const res = await apiService.identityAuthenticationCsrf({})
  //     const reqHeaders = options.headers as Record<string, string>
  //     res?.token && (reqHeaders[W_XSRF_TOKEN] = res?.token)
  //   }
  //   return next()
  // })

  return instance
}

export default createNxios()

import { useManualService, Link } from '@oahz/neact'
import { BEMGenerator, useRCComputed, useRCMount } from '@oahz/neact-utils'
import { Alert, Button, Tabs } from '@bedrock/components'
import { ChevronRight } from '@bedrock/icons-react'
import I18N from '@feature/i18n'
import { PageContainer, PageLoading, MainContainer } from '@/components/page-view'
import { SupplierHeader, SupplierContentInfos, pickHeaderDataFromDetailDto } from '@/components/supplier'
import { bizEnums, bizService } from '@/services'
import { useSelector } from '@/store'
import { DataBlock, DataPanel } from '@/components/data-view'
import AccountList from './user-list'
import Archives from './archives'
import './style.less'

const bem = BEMGenerator('peihome')

export const EnterpriseHomePage = () => {
  const { supplierInfo } = useSelector(state => state.account)
  const { data, loading, request } = useManualService(() =>
    bizService.supplierQuerySupplierDetail({ subSet: supplierInfo.subSet })
  )

  useRCMount(request)

  const headerData = useRCComputed(() => data && pickHeaderDataFromDetailDto(data), [data])

  if (!data) return loading ? <PageLoading /> : null

  return (
    // @ts-ignore
    <PageContainer className={bem()} htmlTitle={data.baseInfo?.supplierName || I18N.auto.gongYingShang}>
      <SupplierHeader
        data={headerData!}
        action={
          !data.applicationId && (
            <>
              <Link to={`/enterprise/info-update`} target="_blank">
                <Button type="checked-neutral">{I18N.auto.xinXiBianGeng}</Button>
              </Link>
            </>
          )
        }>
        {data.applicationId && (
          <Alert
            className={bem('sh-alert')}
            type="info"
            showIcon
            message={I18N.auto.dangQianZhanShiNei}
            action={
              <Link to={`/enterprise/info-changes`} target="_blank">
                <Button type="text" suffixIcon={<ChevronRight />}>
                  {I18N.auto.chaKan}
                </Button>
              </Link>
            }
          />
        )}
      </SupplierHeader>
      <Tabs className={bem('tabs', { changed: !!data.applicationId })}>
        <Tabs.TabPane tab={I18N.auto.qiYeXinXi} key="1">
          <MainContainer w1280 className={bem('main')}>
            <SupplierContentInfos data={data} />
          </MainContainer>
        </Tabs.TabPane>
        {supplierInfo?.accountType === bizEnums.AccountTypeEnum.MAIN ? (
          <Tabs.TabPane tab="账号管理" key="2">
            <MainContainer w1280 className={bem('main')}>
              <DataPanel>
                <DataBlock title="包含账号">
                  <AccountList />
                </DataBlock>
              </DataPanel>
            </MainContainer>
          </Tabs.TabPane>
        ) : null}
        <Tabs.TabPane tab={I18N.auto.dangAnJiLu} key="3">
          <MainContainer w1280 className={bem('main')}>
            <DataPanel>
              <DataBlock title="档案记录">
                <Archives enterpriseId={supplierInfo.id!} />
              </DataBlock>
            </DataPanel>
          </MainContainer>
        </Tabs.TabPane>
      </Tabs>
    </PageContainer>
  )
}

import { ComponentProps, ComponentType, ReactNode, useState } from 'react'
import { map, omit, pick } from 'lodash'
import { useService } from '@oahz/neact'
import { BEMGenerator, useRCComputed } from '@oahz/neact-utils'
import { Descriptions, ImageViewer, Scrollbar, Select, Tag, InputNumber } from '@bedrock/components'
import { FormInstance } from '@bedrock/components/lib/Form'
import { InputNumberProps } from '@bedrock/components/lib/InputNumber'
import { Download, ZoomIn } from '@bedrock/icons-react'
import { ELightSelect } from '@bedrock-e/common'
import { DataAttachments, DataAttachmentsProps, FilePreviewAndDownloadServices } from '@feature/views'
import I18N from '@feature/i18n'
import { formatDateTime } from '@feature/shared'
import { DataBlock, DataFormItem, ListMoneyView } from '@/components/data-view'
import { apiService, bizEnums, defs } from '@/services'
import './style.less'

const bem = BEMGenerator('cinvoice')

export const PayStatus = (props: { className?: string; value?: string }) => {
  const { className, value } = props
  return (
    <div className={bem('status', 'status-pay', { [value!]: true }, [className])}>
      {value && bizEnums.PayStatusEnum.pick(value)?.name}
    </div>
  )
}

export const InvoiceCheckStatus = (props: { className?: string; value?: string }) => {
  const { className, value } = props
  return (
    <div className={bem('status', 'status-invoice-check', { [value!]: true }, [className])}>
      {value && bizEnums.InvoiceCheckingStatusEnum.pick(value)?.name}
    </div>
  )
}

//附件查看下载地址 确认是否要判断OA情况
export const invoiceFilePreviewAndDownloadServices: FilePreviewAndDownloadServices = {
  getPreviewUrl: v => apiService.settlementConfigGetAttachmentPreviewUrl({ fileId: v.fileId }),
  getDownloadUrl: v => apiService.settlementConfigGetAttachmentDownloadUrl({ fileId: v.fileId }),
}

//附件
export const InvoiceAttachments = (props: Omit<DataAttachmentsProps, 'dataId'>) => {
  return (
    <DataAttachments className={bem('attachment')} dataId="" {...invoiceFilePreviewAndDownloadServices} {...props} />
  )
}

type InvoiceFormDataBlockProps<T extends ComponentType<any>> = ComponentProps<T>
export const InvoiceFormDataBlock = (props: InvoiceFormDataBlockProps<typeof DataBlock>) => {
  return <DataBlock className={bem('invoice-fdb')} {...props} />
}

export const SettlementCompanySelect = (props: {
  value?: string
  onChange?: (v: string) => void
  onSelect?: (v: defs.StCompanyDto) => void
  disabled?: boolean
  forELight?: boolean
}) => {
  const { value, onChange, onSelect, disabled, forELight, ...rest } = props
  const { data } = useService(() => apiService.settlementConfigQuerySettlementCompanies({}))

  const company = useRCComputed(() => {
    return data?.find(t => t.code === value)
  }, [value, data])

  const SelectComponent = forELight ? ELightSelect : Select

  return (
    <div className={bem('company-select')}>
      <SelectComponent
        // @ts-ignore
        options={data}
        fieldNames={{ value: 'code' }}
        value={value}
        onChange={v => {
          onChange?.(v as string)
          onSelect?.(data?.find(t => t.code === v)!)
        }}
        disabled={disabled}
        virtual
        showSearch
        {...rest}
      />
      {company?.countryCode && <Tag color="light-grey" size="small" text={company.countryCode} />}
    </div>
  )
}

const InvoiceItem = (props: {
  image: { name: string; url: string; truthCheckingStatus?: string }
  show: () => void
}) => {
  const { image, show } = props
  const isImg = /\.(jpg|png|jpeg)$/.test(image.name)

  const onClick = async () => {
    if (isImg) {
      show()
    } else {
      window.open(image.url)
    }
  }

  return (
    <div className={bem('il-item')}>
      <div className={bem('il-item-atta')}>
        <img src={isImg ? image.url : require(`@/assets/invoice-default-img.png`)} alt="" />
        <div className={bem('il-item-atta-show')} onClick={onClick}>
          {isImg ? <ZoomIn size={15} /> : <Download size={15} />}
        </div>
      </div>
      <div className={bem('il-item-info')}>
        {/* 海外的情况没有状态标签 */}
        {image.truthCheckingStatus ? <InvoiceCheckStatus value={image.truthCheckingStatus} /> : null}
        <div className={bem('il-item-info-name')} title={image.name}>
          {image.name}
        </div>
      </div>
    </div>
  )
}

export const InvoiceAttamentsList = (props: {
  images: { name: string; url: string; truthCheckingStatus?: defs.InvoiceCheckingStatus }
}) => {
  const { images } = props
  const [current, setCurrent] = useState(-1)

  return (
    <Scrollbar className={bem('st-l')}>
      <div className={bem('il')}>
        {images?.url && [images].map((v, i) => <InvoiceItem key={i} image={v} show={() => setCurrent(i)} />)}
      </div>
      <ImageViewer
        current={current}
        images={[images]}
        onChange={setCurrent}
        onDownload={() => {
          images.url && window.open(images.url)
        }}
      />
    </Scrollbar>
  )
}

export const InvoiceInfoView = (props: {
  className?: string
  attachmentShowData: { name: string; url: string; truthCheckingStatus?: defs.InvoiceCheckingStatus }
  action?: ReactNode
  content?: ReactNode
}) => {
  const { className, attachmentShowData, content, action } = props

  return (
    <div className={bem('iiv', [className])}>
      <InvoiceAttamentsList images={attachmentShowData} />
      <div className={bem('iiv-r')}>
        <Scrollbar className={bem('iiv-r-s', { full: !action })}>{content}</Scrollbar>
        {action}
      </div>
    </div>
  )
}

export const genRelatedData = (relatedData: defs.InvoiceMatchOrderDto[]) => {
  return relatedData.map(order => {
    return {
      orderId: order.orderId,
      moneyCalBenchmark: order.moneyCalBenchmark,
      ...(order.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
        ? { occupiedMoney: order.occupiedMoney }
        : { taxOccupiedMoney: order.occupiedMoney }),
      lines: order.lines?.map(line => {
        return {
          orderId: line.orderId,
          /** 订单行id */
          orderLineId: line.orderLineId,
          /** 订单行 关联的oa单号 */
          oaNo: line.oaNo,
          moneyCalBenchmark: line.moneyCalBenchmark,
          ...(line.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
            ? { occupiedMoney: line.occupiedMoney }
            : { taxOccupiedMoney: line.occupiedMoney }),
          taxRate: line.taxRate,
          grList: line.grList?.map(gr => {
            return {
              orderId: gr.orderId,
              /** 订单行id */
              orderLineId: gr.orderLineId,
              /** 订单行 关联的oa单号 */
              oaNo: gr.oaNo,
              /** ASN id */
              asnId: gr.asnId,
              /** asn 行ID */
              asnLineId: gr.asnLineId,
              /** grId */
              grId: gr.grNo, // 暂时冗余出来一个 这是只有在提交的时候应用的字段
              /** 计提单号 */
              accrualNo: gr.accrualNo,
              moneyCalBenchmark: gr.moneyCalBenchmark,
              ...(gr.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED
                ? { occupiedMoney: gr.occupiedMoney }
                : { taxOccupiedMoney: gr.occupiedMoney }),
              taxRate: line.taxRate,
            }
          }),
        }
      }),
    }
  })
}

export const genSubmitDara = async (
  form: FormInstance,
  relatedData: defs.InvoiceMatchOrderDto[],
  detail?: defs.InvoiceDetailDto,
  lines?: defs.InvoiceSubmitLineCommand & { id?: string }[],
  needUserConfirm?: boolean,
  truthCheckingStatus?: defs.InvoiceCheckingStatus,
  subType?: defs.StOcrInvoiceSubType,
  yqInvoiceKey?: string,
  yqInvoiceId?: number
) => {
  if (detail && JSON.stringify(detail) !== '{}') {
    return {
      ...pick(detail, [
        'id',
        'type',
        'attachment',
        'baseInfo',
        'lines',
        'truthCheckingStatus',
        'subType',
        'yqInvoiceKey',
        'yqInvoiceId',
      ]),
      matchOrders: detail.matchOrders || genRelatedData(relatedData),
      needUserConfirm,
    } as defs.InvoiceSubmitCommand
  }
  const formVal = await form.getFieldsValue()
  const baseInfo: defs.InvoiceSubmitBaseInfoCommand = {
    ...pick(formVal, [
      'number',
      'code',
      'checkSum',
      'accountPeriod',
      'taxMoney',
      'tax',
      'unTaxMoney',
      'currency',
      'settlementCompanyCode',
      'supplierId',
      'remark',
      'expiredDate',
      'openDate',
      'yqInvoiceKey',
      'yqInvoiceId',
    ]),
  }
  const genData: defs.InvoiceSubmitCommand = {
    type: formVal.type as defs.StInvoiceType,
    attachment: formVal.attachment as defs.SaveStorageObjectCommand,
    baseInfo: baseInfo,
    lines: map(lines, item => omit(item, 'id')),
    subType,
    truthCheckingStatus,
    needUserConfirm,
    yqInvoiceKey,
    yqInvoiceId,
    /** 关联的订单列表 */
    matchOrders: genRelatedData(relatedData),
  }
  return genData
}

export const InvoiceDetailView = (props: { className?: string; baseData: defs.InvoiceBaseInfoDto }) => {
  const { className, baseData } = props

  const items = [
    {
      key: 'number',
      label: I18N.auto.faPiaoHaoMa,
      children: baseData?.number || '-',
    },
    {
      key: 'code',
      label: I18N.auto.faPiaoDaiMa,
      children: baseData?.code || '-',
    },
    {
      key: 'checkSum',
      label: I18N.auto.jiaoYanMa,
      children: baseData?.checkSum || '-',
    },
    {
      key: 'settlementCompanyName',
      label: I18N.auto.jieSuanGongSi,
      children: baseData?.settlementCompanyName || '-',
    },
    {
      key: 'supplierName',
      label: I18N.auto.gongYingShang,
      children: baseData?.supplierName || '-',
    },
    {
      key: 'currency',
      label: I18N.auto.faPiaoBiZhong,
      children: baseData?.currency || '-',
    },
    {
      key: 'openDate',
      label: I18N.auto.kaiPiaoRiQi,
      children: formatDateTime(baseData?.openDate, 'YYYY-MM-DD') || '-',
    },
    {
      key: 'accountPeriod',
      label: I18N.auto.zhangQiTian,
      children: baseData?.accountPeriod || 0,
    },
    {
      key: 'expiredDate',
      label: I18N.auto.daoQiRi,
      children: formatDateTime(baseData?.expiredDate, 'YYYY-MM-DD') || '-',
    },
    {
      key: 'taxMoney',
      label: I18N.auto.hanShuiJinE,
      children:
        baseData.taxMoney === 0 ? (
          <span className={bem('money')}>{baseData.taxMoney}</span>
        ) : baseData.taxMoney !== undefined ? (
          <ListMoneyView className={bem('money')} value={{ amount: baseData.taxMoney, currency: baseData?.currency }} />
        ) : (
          '-'
        ),
    },
    {
      key: 'tax',
      label: I18N.auto.shuiE,
      children:
        baseData.tax === 0 ? (
          <span className={bem('money')}>{baseData.tax}</span>
        ) : baseData.tax !== undefined ? (
          <ListMoneyView className={bem('money')} value={{ amount: baseData.tax, currency: baseData?.currency }} />
        ) : (
          '-'
        ),
    },
    {
      key: 'unTaxMoney',
      label: I18N.auto.buHanShuiJinE,
      children:
        baseData.unTaxMoney === 0 ? (
          <span className={bem('money')}>{baseData.unTaxMoney}</span>
        ) : baseData.unTaxMoney !== undefined ? (
          <ListMoneyView
            className={bem('money')}
            value={{ amount: baseData.unTaxMoney, currency: baseData?.currency }}
          />
        ) : (
          '-'
        ),
    },

    {
      key: 'remark',
      label: I18N.auto.beiZhu,
      children: baseData?.remark || '-',
      span: 3,
    },
  ]

  const labelStyle = {
    Width: '120px',
    padding: '9px 16px',
    color: 'var(--text-color-2)',
  }

  const contentStyle = {
    width: '140px',
    padding: '9px 16px',
    color: 'var(--text-color-1)',
  }
  return (
    <div className={bem('idv', [className])}>
      <Descriptions
        className={bem('idv-content')}
        labelStyle={labelStyle}
        contentStyle={contentStyle}
        title={null}
        bordered
        items={items}
        colon={false}
        column={3}
        extra={undefined}
      />
    </div>
  )
}

// 表单中展示的所属父级信息
export const RelatedItemHeaderView = (props: { label: string | ReactNode; value: string | ReactNode }) => {
  const { label, value } = props
  return (
    <div className={bem('rd-item-header-view')}>
      <div className={bem('rd-item-header-label')}>{label}</div>
      <div className={bem('rd-item-header-value')}>{value || '-'}</div>
    </div>
  )
}

export const AmountInput = (props: { name: string; label: string } & InputNumberProps) => {
  const { name, label, ...reset } = props
  return (
    <DataFormItem
      w667
      label={label}
      name={name}
      required
      rules={[
        {
          required: true,
          message: I18N.auto.qingShuRuFaPiaoJinE,
          validator: (_: any, value: any) => {
            console.log('first***', value)
            if (!value || value <= 0) {
              return Promise.reject()
            } else {
              return Promise.resolve()
            }
          },
        },
      ]}>
      <InputNumber direction="vertical" toFixed={2} min={0} {...reset} />
    </DataFormItem>
  )
}

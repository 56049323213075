import { BEMGenerator, useRCComputed, useRCMount, useRCVars } from '@oahz/neact-utils'
import { useManualService, useRoute, useService } from '@oahz/neact'
import { Message } from '@bedrock/components'
import I18N from '@feature/i18n'
import { useDispatch, useSelector } from '@/store'
import { apiService, bizService } from '@/services'
import {
  SupplierContentFormItems,
  SupplierForm,
  SupplierFormActions,
  SupplierHeader,
  convertDetailDtoToFormFieldsValue,
  convertFormFieldsValueToSubmitCommand,
  isChinaEnterprise,
  isChinaPerson,
  isOverseaEnterprise,
  isOverseaPerson,
  pickHeaderDataFromDetailDto,
  useSupplierForm,
} from '@/components/supplier'
import { MainContainer, PageContainer, PageLoading } from '@/components/page-view'
import './style.less'

const bem = BEMGenerator('peonre')

export default () => {
  const { supplierInfo } = useSelector(state => state.account)
  const { refreshSupplierInfo } = useDispatch(dispatch => dispatch.account)
  const { data, loading, request } = useManualService(() =>
    bizService.supplierApplicationQuerySupplierOnboardDetail({
      subSet: supplierInfo.subSet,
      applicationId: supplierInfo.applicationId!,
    })
  )

  useRCMount(request)

  const { fm, canSubmit, onValuesChange, replacePage } = useSupplierForm()

  const doSubmit = async () => {
    if (!data) return

    await fm.validateFields()
    const fmData = fm.getFieldsValue()

    const draftData = convertFormFieldsValueToSubmitCommand({ ...data, ...fmData })

    if (isChinaEnterprise(data)) {
      await apiService.identityEnterpriseBoardedSubmitChinaEnterprise(draftData)
    } else if (isChinaPerson(data)) {
      await apiService.identityEnterpriseBoardedSubmitChinaPerson(draftData)
    } else if (isOverseaEnterprise(data)) {
      await apiService.identityEnterpriseBoardedSubmitOverseaEnterprise(draftData)
    } else if (isOverseaPerson(data)) {
      await apiService.identityEnterpriseBoardedSubmitOverseaPerson(draftData)
    }

    Message.success(I18N.auto.tiJiaoChengGong)
    // 刷新最新的供应商主页
    refreshSupplierInfo()
    // 重新进入供应商主页
    replacePage(`/enterprise`)
  }

  const initialValue = useRCComputed(() => {
    return data ? convertDetailDtoToFormFieldsValue(data) : {}
  }, [data])

  const headerData = useRCComputed(() => (data ? pickHeaderDataFromDetailDto(data) : {}), [data])

  if (!data) return loading ? <PageLoading /> : null

  return (
    <PageContainer className={bem()} htmlTitle="Supplier Onboard Resubmit">
      <SupplierHeader
        data={headerData}
        action={<SupplierFormActions fm={fm} onSubmit={doSubmit} submitDisabled={!canSubmit} />}
      />
      <MainContainer w1280 className={bem('main')}>
        <SupplierForm form={fm} initialValues={initialValue} onValuesChange={onValuesChange}>
          <SupplierContentFormItems defaultValue={data} />
        </SupplierForm>
      </MainContainer>
    </PageContainer>
  )
}

export const LOCALES = {
  'zh-CN': {
    login: '登录',
    oscar: '使用Oscar快速登录',
    openid: '使用OpenID快速登录',
    title: '简体中文',
  },
  'en-US': {
    login: 'Log in',
    oscar: 'Login with Oscar',
    openid: 'Login with OpenID',
    title: 'English',
  },
  'ja-JP': {
    login: '登录',
    oscar: '使用Oscar快速登录',
    openid: 'OpenID登录',
    title: '日本語',
  },
  'fr-FR': {
    login: 'Se connecter',
    oscar: 'Se connecter rapidement via Oscar',
    openid: 'Se connecter via Openid',
    title: 'Français',
  },
}

import { useState } from 'react'
import { BEMGenerator } from '@oahz/neact-utils'
import { Button, Input, InputNumber, Collapse } from '@bedrock/components'
import { Plus, Delete, ChevronDown, ChevronUp } from '@bedrock/icons-react'
import { AppTable, createTableColumns } from '@feature/views'
import { DataTaxRate } from '@feature/views'
import type { defs } from '@/services'
import './style.less'

const bem = BEMGenerator('pseic-lines')

type ColumnsType = defs.InvoiceSubmitLineCommand & { id?: string }
type PropsType = {
  canEdit?: boolean
  list: ColumnsType[]
  onChange: (value: ColumnsType[]) => void
}

const EditTable = (props: PropsType) => {
  const { list: dataList, onChange } = props

  const [batchLines, setBatchLines] = useState<ColumnsType[]>([])

  const itemChange = (value: string | number, name: keyof defs.InvoiceSubmitLineCommand, record: ColumnsType) => {
    const _dataList = dataList.map(item => {
      if (item.id === record.id) return { ...record, [name]: value }
      return item
    })
    onChange(_dataList)
  }

  //添加行
  const addLine = () => {
    const _line: ColumnsType = {
      id: `line${Date.now().toString(36) + Math.random().toString(36).substr(2)}`,
    }
    onChange?.([...dataList, _line])
  }

  const deleteLine = (records?: ColumnsType[]) => {
    const ids = records?.map(item => item.id) || []
    onChange?.(dataList.filter(item => !ids.includes(item.id)))
    setBatchLines(batchLines.filter(item => !ids.includes(item.id)))
  }

  const colums = createTableColumns<ColumnsType>([
    {
      dataIndex: 'productName',
      title: '商品名称或服务名称',
      width: 200,
      align: 'left',
      render: (v, record) => (
        <Input
          name={'productName'}
          defaultValue={v}
          trimOnBlur
          onBlur={e => itemChange(e?.target?.value, 'productName', record)}
        />
      ),
    },
    {
      dataIndex: 'specification',
      title: '规格型号',
      width: 200,
      align: 'left',
      render: (v, record) => (
        <Input
          name={'specification'}
          defaultValue={v}
          trimOnBlur
          onBlur={e => itemChange(e?.target?.value, 'specification', record)}
        />
      ),
    },
    {
      dataIndex: 'unit',
      title: '单位',
      width: 100,
      align: 'left',
      render: (v, record) => (
        <Input name={'unit'} defaultValue={v} trimOnBlur onBlur={e => itemChange(e?.target?.value, 'unit', record)} />
      ),
    },
    {
      dataIndex: 'quantity',
      title: '数量',
      width: 100,
      align: 'left',
      render: (v, record) => (
        <Input
          name={'quantity'}
          type="number"
          defaultValue={v}
          trimOnBlur
          onBlur={e => itemChange(e?.target?.value, 'quantity', record)}
        />
      ),
    },
    {
      dataIndex: 'unTaxUnitPrice',
      title: '单价（未税）',
      width: 150,
      align: 'right',
      render: (v, record) => (
        <InputNumber
          name={'unTaxUnitPrice'}
          defaultValue={v}
          direction="vertical"
          addonAfter={null}
          trimOnBlur
          onChange={value => itemChange(value, 'unTaxUnitPrice', record)}
        />
      ),
    },
    {
      dataIndex: 'unTaxMoney',
      title: '金额（未税）',
      width: 150,
      align: 'right',
      render: (v, record) => (
        <InputNumber
          name={'unTaxMoney'}
          defaultValue={v}
          direction="vertical"
          addonAfter={null}
          trimOnBlur
          onChange={value => itemChange(value, 'unTaxMoney', record)}
        />
      ),
      // render: v => v ?? '-',
    },
    {
      dataIndex: 'taxRate',
      title: '税率',
      width: 120,
      align: 'left',
      render: (v, record) => (
        <InputNumber
          defaultValue={(v ?? 0) * 100}
          direction="vertical"
          addonAfter={null}
          max={100}
          min={0}
          formatter={value => `${value}%`}
          parser={value => (value || '').replace('%', '')}
          onChange={value => itemChange((value as number) / 100, 'taxRate', record)}
        />
      ),
    },
    {
      dataIndex: 'taxAmount',
      title: '税额',
      width: 150,
      align: 'right',
      render: (v, record) => (
        <InputNumber
          name={'taxAmount'}
          defaultValue={v}
          direction="vertical"
          addonAfter={null}
          trimOnBlur
          onChange={value => itemChange(value, 'taxAmount', record)}
        />
      ),
    },
    {
      dataIndex: 'id',
      title: '操作',
      width: 100,
      fixed: 'right',
      render: (v, record) => (
        <Button
          type="link"
          danger
          onClick={() => {
            deleteLine([record])
          }}>
          删除
        </Button>
      ),
    },
  ])

  return (
    <>
      <AppTable
        rowKey={'id'}
        columns={colums}
        data={dataList}
        hideOnSinglePage
        rowSelection={{ type: 'checkbox', selectedRowKeys: batchLines.map(v => v.id) }}
        onSelectChange={setBatchLines}
      />
      <div className={bem('footer')}>
        <Button type="checked" size="small" onClick={addLine} icon={<Plus />}>
          新增行
        </Button>
        <Button
          type="checked"
          size="small"
          disabled={!batchLines?.length}
          danger
          onClick={() => deleteLine(batchLines)}
          icon={<Delete />}>
          删除行
        </Button>
      </div>
    </>
  )
}

const LinesTable = (props: { dataList: ColumnsType[] }) => {
  const { dataList } = props

  const colums = createTableColumns<ColumnsType>([
    {
      dataIndex: 'productName',
      title: '商品名称或服务名称',
      width: 200,
      align: 'left',
      render: v => v ?? '-',
    },
    {
      dataIndex: 'specification',
      title: '规格型号',
      width: 200,
      align: 'left',
      render: v => v ?? '-',
    },
    {
      dataIndex: 'unit',
      title: '单位',
      width: 80,
      align: 'left',
      render: v => v ?? '-',
    },
    {
      dataIndex: 'quantity',
      title: '数量',
      width: 80,
      align: 'left',
      render: v => v ?? '-',
    },
    {
      dataIndex: 'unTaxUnitPrice',
      title: '单价（未税）',
      width: 150,
      align: 'right',
      render: v => v ?? '-',
    },
    {
      dataIndex: 'unTaxMoney',
      title: '金额（未税）',
      width: 130,
      align: 'right',
      render: v => v ?? '-',
    },
    {
      dataIndex: 'taxRate',
      title: '税率',
      width: 100,
      align: 'left',
      render: v => (v ? <DataTaxRate value={v} /> : '-'),
    },
    {
      dataIndex: 'taxAmount',
      title: '税额',
      width: 130,
      align: 'right',
      render: v => v ?? '-',
    },
  ])
  return <AppTable rowKey={'id'} columns={colums} data={dataList} hideOnSinglePage />
}

//TODO 后续代码规整 目前只是基本满足需求 提交时逻辑待优化
export default (props: PropsType) => {
  const { list, canEdit } = props

  return (
    <div className={bem('')}>
      <Collapse
        className={bem('card')}
        accordion
        bordered={false}
        defaultActiveKey={[]}
        pure
        arrow="right"
        expandIcon={panelProps => {
          const { isActive } = panelProps
          return (
            <Button type="link" size="xSmall" suffixIcon={isActive ? <ChevronUp /> : <ChevronDown />}>
              {isActive ? '收起' : '展开'}
            </Button>
          )
        }}>
        <Collapse.Panel
          key="1"
          className={bem('card-collapse-panel')}
          headerClass={bem('card-collapse-header')}
          header={
            <div className={bem('card-collapse-title')} onClick={e => e.stopPropagation()}>
              发票行
            </div>
          }>
          {canEdit ? <EditTable {...props} /> : <LinesTable dataList={list} />}
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

import type { ReactNode } from 'react'
import { BEMGenerator } from '@oahz/neact-utils'
import type { FormInstance } from '@bedrock/components/lib/Form'
import { Form, Input, Button, Message } from '@bedrock/components'
import { AsyncButton } from '@feature/views'
import { apiService } from '@/services'
import { rsaEncrypt } from '@/shared'
import './style.less'

const bem = BEMGenerator('prepwd')

export const PasswordFormItem = (props: { name?: string; lable?: ReactNode; placeholder?: string }) => {
  const { name = 'password', lable = '新密码', placeholder = '请输入密码' } = props
  return (
    <Form.Item
      name={name}
      label={lable}
      validateTrigger={'onBlur'}
      rules={[
        {
          required: true,
          validator: (_, value) => {
            const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{9,15}$/
            if (!value) {
              return Promise.reject(placeholder)
            }
            if (!regex.test(value)) {
              return Promise.reject('请输入9-15位的大小写字母、数字、特殊字符')
            }
            return Promise.resolve()
          },
        },
      ]}>
      <Input.Password placeholder={placeholder} trimOnBlur minLength={9} maxLength={15} />
    </Form.Item>
  )
}

export const ConfirmPasswordFormItem = (props: { form: FormInstance }) => {
  const { form } = props
  return (
    <Form.Item
      name="confirmPassword"
      label="再次输入新密码"
      validateTrigger={['onBlur']}
      rules={[
        {
          required: true,
          validator: (rule, value) => {
            const password = form.getFieldValue('newPassword')
            if (!value) {
              return Promise.reject('请输入密码')
            }
            if (value !== password) {
              return Promise.reject('请输入相同密码')
            }
            return Promise.resolve()
          },
        },
      ]}>
      <Input.Password placeholder="请再次输入新密码" trimOnBlur minLength={9} maxLength={15} />
    </Form.Item>
  )
}

export default () => {
  const [fm] = Form.useForm()
  const onSubmit = async () => {
    await fm.validateFields()
    const formVal = await fm.getFieldsValue()
    const params = {
      oldPassword: rsaEncrypt(formVal.oldPassword),
      newPassword: rsaEncrypt(formVal.newPassword),
      confirmPassword: rsaEncrypt(formVal.newPassword),
    }
    await apiService.identityEnterpriseAccountChangePassword(params)
    Message.success('修改成功')
    window.location.replace('/login')
  }

  return (
    <section className={bem()}>
      <div className={bem('content')}>
        <div className={bem('title')}>密码修改</div>
        <Form form={fm} layout="vertical" autoComplete="off" className={bem('form')}>
          <Form.Item
            name={'oldPassword'}
            label={'旧密码'}
            validateTrigger={'onBlur'}
            required
            rules={[{ required: true, message: '请输入旧密码' }]}>
            <Input.Password placeholder={'请输入旧密码'} trimOnBlur maxLength={15} />
          </Form.Item>
          <PasswordFormItem name={'newPassword'} placeholder="请输入新密码" />
          <ConfirmPasswordFormItem form={fm} />
        </Form>
        <AsyncButton size="large" className={bem('submit')} type="primary" onClick={onSubmit}>
          确定
        </AsyncButton>
      </div>
    </section>
  )
}
